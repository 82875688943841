import { Routes, RouterModule } from '@angular/router';
import { CameraFormComponent } from './camera-form/camera-form.component';
import { NgModule } from '@angular/core';
import { CameraViewComponent } from './camera-view/camera-view.component';
import { CameraPlaylistComponent } from './camera-playlist/camera-playlist.component';
import { AuthGuard } from 'app/Shared/Guards/auth.guard';
import { CameraListComponent } from './camera-list/camera-list.component';
import { CameraListInviteComponent } from './camera-list-invite/camera-list-invite.component';
import { CameraStockComponent } from './camera-stock/camera-stock.component';
import { CameraGroupComponent } from './camera-group/camera-group.component';
import { CameraViewGroupComponent } from './camera-view-group/camera-view-group.component';
import { CameraFormCsvComponent } from './camera-form-csv/camera-form-csv.component';
import { CameraMapComponent } from './camera-map/camera-map.component';

export const camerasRoutes: Routes = [
    {
        path: 'cadastro',
        component: CameraFormComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'cadastros',
        component: CameraFormCsvComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'view',
        component: CameraViewComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'list',
        component: CameraListComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'retrieve',
        component: CameraPlaylistComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'invited',
        component: CameraListInviteComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'stock',
        component: CameraStockComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'groups',
        component: CameraGroupComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'groups/view',
        component: CameraViewGroupComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'map',
        component: CameraMapComponent,
        canActivate: [AuthGuard]
    }
];
