<div id="container" class="wrapper" [ngStyle]="setOverflowStyles()">
    <div *ngIf="navSideShow; else noShowNavSide" id="top-list">
        <app-navbar></app-navbar>
        <app-sidebar></app-sidebar>
        <div class="sidebar-background"></div>
        <div class="main-panel main-content-style" [hidden]="loadingBundle">
            <div class="component-route">
                <router-outlet></router-outlet>
            </div>
            <footer class="copyright">
                <span class="copyright-icon">©</span><span>{{currentYear()}} WeSafer</span>
            </footer>
        </div>
        <div class="main-panel main-content-style center-spinner" *ngIf="loadingBundle">
            <span nz-icon nzType="loading" nzTheme="outline"></span>
        </div>
        <ng-template id="tooltip-support" #popOverGLPI>
            <app-glpi (modalClose)="openModalGLPI(false)" id="app-glpi"></app-glpi>
        </ng-template>
        <div class="button-help">
            <span nz-popover class="inside" [(nzPopoverVisible)]="visibleGLPI" nzPopoverTrigger="click"
            [nzPopoverContent]="popOverGLPI" (nzPopoverVisibleChange)="openModalGLPI($event)">
                <i nz-icon nzType="question-circle" nzTheme="outline"></i>Ajuda
            </span>
        </div>
    </div>
    <ng-template #noShowNavSide>
        <div class="" [hidden]="loadingBundle">
            <router-outlet></router-outlet>
        </div>
        <div class="main-panel main-content-style center-spinner loadingCamera" *ngIf="loadingBundle">
            <span nz-icon nzType="loading" nzTheme="outline"></span>
        </div>
        <!-- <div class="button-help">
            <span (click)="openModalGLPI()" class="inside"><i nz-icon nzType="question-circle" nzTheme="outline"></i>Ajuda</span>
        </div> -->
    </ng-template>
</div>