import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { environment } from 'environments/environment';
import { User, AlarmModalState, CognitoAttributes, UpdateClient } from '../models';
import { UserActions } from '../Services/actions';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import PerfectScrollbar from 'perfect-scrollbar';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { AppState } from 'app/store/model';
import { OperationStatus, ResultAlert } from 'app/Shared/models';
import { SharedService } from 'app/Shared/Services/shared.service';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { validateCEP } from 'app/Login/models';

@Component({
    selector: 'app-user-summary',
    templateUrl: './user-summary.component.html',
    styleUrls: ['./user-summary.component.scss']
})
export class UserSummaryComponent implements OnInit, AfterViewInit, OnDestroy {
    readonly profileURL$ = this.store.select((state: AppState) => state.Users.profile_url);
    readonly modal_openCfg$ = this.store.select((state: AppState) => state.Users.modal_open_config_alarm);
    readonly upPass$ = this.store.select((state: AppState) => state.Users.upPassword);
    readonly delCfg$ = this.store.select((state: AppState) => state.Users.delConfigAlarm);
    readonly userData$ = this.store.select((state: AppState) => state.Users.userData);
    readonly upResult$ = this.store.select((state: AppState) => state.Users.updateResult);
    readonly clientUpResult$ = this.store.select((state: AppState) => state.Users.updateClientResult);
    readonly updatePartnerResult$ = this.store.select((state: AppState) => state.Users.updatePartnerResult);

    @ViewChild('fileinput') fileinput: ElementRef;
    @ViewChild('previewContainer') previewContainer: ElementRef;

    modalCloseCfg: Subscription;
    upPasswordSub: Subscription;
    formSub: Subscription;
    toggleSub: Subscription;
    dataSub: Subscription;
    updateSub: Subscription;
    updateAvvaSub: Subscription;
    updatePartnerResult: Subscription;
    public telMask = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    public cepMask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
    public numberMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
    public editForm = new UntypedFormGroup({
        name: new UntypedFormControl('', [Validators.required]),
        email: new UntypedFormControl({ value: '', disabled: true }, [Validators.email]),
        emailSuport: new UntypedFormControl('', [Validators.email]),
        contract_link: new UntypedFormControl(''),
        cpf: new UntypedFormControl({ value: '', disabled: true }),
        cell: new UntypedFormControl(''),
        CEP: new UntypedFormControl('', [validateCEP]),
        street: new UntypedFormControl('', [Validators.maxLength(70)]),
        num: new UntypedFormControl('', [Validators.pattern('[0-9]+'), Validators.maxLength(10)]),
        bairro: new UntypedFormControl('', [Validators.maxLength(70)]),
        complemento: new UntypedFormControl('', [Validators.maxLength(70)]),
        cidade: new UntypedFormControl('', [Validators.maxLength(70)]),
        estado: new UntypedFormControl('', [Validators.maxLength(70)]),
    });
    states: string[] = environment.estados;
    profilePicture: File = null;
    currentUser: User = {
        name: '',
        email: '',
        email_suport: '',
        contract_link: '',
        telephone: '',
        address: {
            state: '',
            street: '',
            number: 0,
            city: '',
            CEP: ''
        },
        cpf_cnpj: '',
        associated: {
            name: '',
            cpf: '',
            email: '',
            phone_number: '',
            sub: '',
            address: {
                state: '',
                street: '',
                number: 0,
                city: '',
                CEP: '',
                bairro: '',
                comp: ''
            }
        }
    };
    allAttributes: CognitoAttributes;
    estado: string;
    confAlert: boolean;
    urlSub: Subscription;
    updateDB = false;
    updatePayload = {};
    loading = false;
    dataLoading = false;
    profileLoading = false;
    loadingConfig = false;
    delLoading = false;
    errorActiveAnalytic = false;
    currentScroll = 0;
    scrollbar: PerfectScrollbar;
    userSub = localStorage.getItem('sub');
    imageChangedEvent: any = '';
    croppedImage: any = '';
    delSub: Subscription;
    isAvvaUpdated: boolean;
    isNuvUpdated: boolean;
    isPartner: boolean;
    isMaster: boolean;
    linkedCustomer = false;
    accountGuests = false;
    viewProfile = true;
    isClientAssociate = false;
    isGuestCam: boolean;
    isClientPartner: boolean;
    isClient: boolean;
    pictureModify = false;

    isAssociate: boolean;
    adminAssociate: boolean;

    cepAddress: {
        bairro: '';
        cep: '';
        complemento: '';
        ddd: '';
        estado: '';
        localidade: '';
        logradouro: '';
        uf: '';
    };


    constructor(private readonly store: Store<AppState>, private service: SharedService,
        private validationResponse: ValidationResponseHandlerModule) {
    }

    ngOnInit() {
        this.isAssociate = (localStorage.getItem('profile_name') === 'f29868c7-b4c5-4963-9ae8-1dd95699d6c3');
        this.adminAssociate = (this.isAssociate && localStorage.getItem('associate_permission') === 'e816c560-812e-11ed-a1eb-0242ac120002');
        this.isPartner = (localStorage.getItem('profile_name') == 'cd343bfc-17e8-11ec-9621-0242ac130002');
        this.isClientAssociate = (localStorage.getItem('profile_name') == '419bea06-5d4e-4a56-b8b5-04b3ad566d59');
        this.isGuestCam = (localStorage.getItem('profile_name') === 'bb653b3a-fdb3-4438-bce6-012585b5268f');
        this.isMaster = (localStorage.getItem('profile_name') === '7d4e9f24-17e8-11ec-9621-0242ac130002');
        this.isClientPartner = (localStorage.getItem('profile_name') === '61902d2b-3ada-49f3-b42a-1775bc064bb0');
        this.isClient = (localStorage.getItem('profile_name') === 'e50483b8-17e8-11ec-9621-0242ac130002');
        const container = document.querySelector('#containerForm') as HTMLElement;
        // this.scrollbar = new PerfectScrollbar(container);
        // this.cognitoUtils.getUserAttributes(this);
        //Capturando atributos do usuário no Cognito
        this.store.dispatch(UserActions.get_user_data({
            user_sub: localStorage.getItem('sub')
        }));
        this.dataLoading = true;
        //Subscribes
        this.getUserData();
        this.updateProfileResult();
        this.updateClientProfileResult();
        this.getNewProfilePicture();
        this.confirmDeleteConfig();
        this.updateUserPassword();
        this.updatePartner();

        // Recebe o retorno das informações do swal
        this.service.swalDecision.subscribe(returnSwallObject => {
            if (returnSwallObject) {
                this.validateReturnMethodCalled(returnSwallObject as ResultAlert);
            }
        });
    }

    // Valida o retorno do Swal e o método do pai ao qual fez a chamada
    validateReturnMethodCalled(returnResultSwal: ResultAlert) {
        if (returnResultSwal.methodCalled === 'openModalSub') {
            if (returnResultSwal.isConfirmed) {
                this.delLoading = true;
                this.store.dispatch(UserActions.delete_config_alarm({ user_sub: this.userSub }));
                this.validationResponse.validationResponseHandler(200, 'user-summary', 'analityc-disabled-success', 'user.analityc_disabled_success');
                this.loadingConfig = false;
            }
            else if (returnResultSwal.isDismissed) {
            }
        }
    }

    ngAfterViewInit() {
    //Executa a função sempre que o usuário fizer o upload de uma imagem
        if (this.fileinput) {
            const inputElement = this.fileinput.nativeElement;
            inputElement.addEventListener('change', () => {
                const previewContainer = this.previewContainer.nativeElement;
                const img = previewContainer.children[0]; // Supondo que o primeiro filho é a imagem
                img.classList.add('img-fade');
            });
        }
    }

    ngOnDestroy() {
        this.urlSub.unsubscribe();
        if (this.modalCloseCfg) {
            this.modalCloseCfg.unsubscribe();
        }
        if (this.upPasswordSub) {
            this.upPasswordSub.unsubscribe();
        }
        if (this.formSub) {
            this.formSub.unsubscribe();
        }
        if (this.toggleSub) {
            this.toggleSub.unsubscribe();
        }
        if (this.updateSub) {
            this.updateSub.unsubscribe();
        }
        if (this.dataSub) {
            this.dataSub.unsubscribe();
        }
        if (this.delSub) {
            this.delSub.unsubscribe();
        }
        if (this.updateAvvaSub) {
            this.updateAvvaSub.unsubscribe();
        }
        if (this.updatePartnerResult) {
            this.updatePartnerResult.unsubscribe();
        }
        this.store.dispatch(UserActions.cleanup());
        this.store.dispatch(UserActions.close_modal_crop());
    }

    getUserData() {
        this.dataSub = this.userData$.subscribe(data => {
            if (data) {
                this.dataLoading = false;
                if (data.model === 'feedback') {
                    this.validationResponse.validationResponseHandler(400, 'user-summary', 'return-data-error', 'user.client_cognito_error', data.message);
                }
                else {
                    this.allAttributes = data;
                    this.fillUserForm(this.allAttributes);
                }
            }
        });
    }

    updatePartner() {
        this.updatePartnerResult = this.updatePartnerResult$.subscribe(register_feedback => {
            if (register_feedback) {
                if (register_feedback.status === 2) {
                    if (!this.pictureModify) {
                        this.store.dispatch(UserActions.get_user_data({
                            user_sub: localStorage.getItem('sub')
                        }));
                    }
                    this.dataLoading = true;
                }
            }
        });
    }

    updateProfileResult() {
        this.updateSub = this.upResult$.subscribe(async result => {
            if (result) {
                this.loading = false;
                this.profileLoading = false;
                if (result.success) {
                    this.isNuvUpdated = true;

                    if (this.isAvvaUpdated || this.isPartner || this.isGuestCam || this.isClientAssociate || this.adminAssociate || this.isAssociate) {
                        this.validationResponse.validationResponseHandler(200, 'user-summary', 'data-update-success', 'user.data_update_success');

                        if (!this.pictureModify) {
                            this.store.dispatch(UserActions.get_user_data({
                                user_sub: localStorage.getItem('sub')
                            }));
                        }
                        this.pictureModify = false;
                    }
                } else {
                    this.validationResponse.validationResponseHandler(400, 'user-summary', 'data-update-error', 'user.data_update_error');
                }
            }
        });
    }

    updateClientProfileResult() {
        this.updateAvvaSub = this.clientUpResult$.subscribe(async result => {
            if (result) {
                this.loading = false;
                this.profileLoading = false;
                if (result.success || (result.statusCode >= 200 && result.statusCode <= 299)) {
                    this.isAvvaUpdated = true;

                    if (this.isNuvUpdated) {
                        this.validationResponse.validationResponseHandler(200, 'user-summary', 'data-update-success', 'user.data_update_success');
                    }
                }
                else {

                    this.validationResponse.validationResponseHandler(400, 'user-summary', 'data-update-error', 'user.data_update_error');

                }
            }
        });
    }

    updateUserPassword() {
        this.upPasswordSub = this.upPass$.subscribe(data => {
            if ((data != null) && (data != OperationStatus.Unloaded)) {
                this.dataLoading = false;
                if (data === OperationStatus.Success) {
                    this.validationResponse.validationResponseHandler(200, 'user-summary', 'pass-change-confirm', 'user.pass_change_confirm');
                } else if (data === OperationStatus.Fail) {
                    this.validationResponse.validationResponseHandler(400, 'user-summary', 'pass-change-error', 'user.pass_change_error');
                }
                this.store.dispatch(UserActions.change_password_result({ payload: OperationStatus.Unloaded }));
            }
        });
    }

    getNewProfilePicture() {
        this.urlSub = this.profileURL$.subscribe(profileURL => {
            if (profileURL) {
                this.currentUser.imgURL = profileURL;
                localStorage.setItem('picture', profileURL);
                this.validationResponse.validationResponseHandler(200, 'user-summary', 'image-loading-success', 'user.image_loading_success');
                this.pictureModify = true;
                this.updateProfile();
            }
            this.loading = false;
        });
    }

    onCancelModal(event) {
        this.errorActiveAnalytic = true;
        this.editForm.get('configAlarmStatus').setValue(event);
    }

    onUpdateConfig(event) {
        this.loadingConfig = true;
    }

    upUserChangePassword(event) {
        this.dataLoading = true;
    }

    upUserChangeLink(event) {
        this.dataLoading = true;
    }

    confirmDeleteConfig() {
        this.delSub = this.delCfg$.subscribe(delCfgStatus => {
            if (delCfgStatus == OperationStatus.Success) {
                this.store.dispatch(UserActions.get_configurations_alarm({ user_sub: this.userSub }));
            }
            else if (delCfgStatus == OperationStatus.Fail) {

                this.validationResponse.validationResponseHandler(400, 'user-summary', 'not-action-complete', 'user.not_action_complete');

                this.editForm.get('configAlarmStatus').setValue(true);
            }
            this.delLoading = false;
            this.store.dispatch(UserActions.delete_config_alarm_return({ payload: OperationStatus.Unloaded }));
        });
    }

    getField(field: string): string {
        if (field === 'name') {
            const fullName = this.currentUser.name;
            if (fullName) {
                const names = fullName.split(' ');
                if (names.length > 1) {
                    return `${names[0]} ${names.pop()}`;
                }
                else {
                    return names[0];
                }
            } else {
                return '';
            }
        }
        return localStorage.getItem(field);
    }

    validateField(field: any): string {
        return field ? field : '';
    }

    openModalChangePassword() {
        this.store.dispatch(UserActions.change_password_modal({ payload: OperationStatus.Loading }));
    }
    openModalChangeLink() {
        this.store.dispatch(UserActions.change_link_modal({ payload: OperationStatus.Loading }));
    }

    getPlaceholder() {
        if (this.currentUser.imgURL == undefined) {
            return '../../../assets/img/placeholder.jpg';
        } else {
            return (this.currentUser.imgURL && (this.currentUser.imgURL != String('undefined') && this.currentUser.imgURL != '')) ? this.currentUser.imgURL : '../../../assets/img/placeholder.jpg';
        }
    }

    fillUserForm(attr: CognitoAttributes) {
        let cpfCnpj = attr['custom:CPF'];
        if (!cpfCnpj) {
            cpfCnpj = attr['custom:CNPJ'];
        }
        const address = JSON.parse(attr.address);
        const parsedAdd = this.parseAddress(address);
        this.currentUser = {
            email: attr.email,
            email_suport: attr.email_suport,
            contract_link: attr.contract_link,
            name: attr.name,
            cpf_cnpj: cpfCnpj,
            telephone: attr.phone_number,
            imgURL: attr.picture,
            address: {
                CEP: address['postal_code'] ? address['postal_code'] : address['cep'],
                city: address['locality'] ? address['locality'] : address['city'],
                street: parsedAdd.rua,
                number: parsedAdd.num,
                bairro: parsedAdd.bairro,
                comp: parsedAdd.comp == 'null' || parsedAdd.comp == 'undefined' || parsedAdd.comp == undefined ? null : parsedAdd.comp,
                state: address['region'] ? address['region'] : address['state']
            },
            associated: attr.associated
        };
        this.setValueForms();
        localStorage.setItem('picture', this.currentUser.imgURL);
        const estadoUf = address['region'];
        const campo = this.editForm;
        // Função para inserir o estado no section
        this.states.forEach(function (uf) {
            if (uf === estadoUf) {
                campo.get('estado').setValue(uf);
            }
        });
    }

    setValueForms() {
        this.editForm.get('name').setValue(this.currentUser.name);
        this.editForm.get('email').setValue(this.currentUser.email);
        this.editForm.get('emailSuport').setValue(this.currentUser.email_suport);
        this.editForm.get('contract_link').setValue(this.currentUser.contract_link);
        this.editForm.get('cpf').setValue(this.currentUser.cpf_cnpj);
        this.editForm.get('CEP').setValue(this.currentUser.address.CEP);
        this.editForm.get('street').setValue(this.currentUser.address.street);
        if (this.currentUser.address.number != 0) {
            this.editForm.get('num').setValue((this.currentUser.address.number.toString()).trim());
        }
        this.editForm.get('bairro').setValue(this.currentUser.address.bairro);
        this.editForm.get('cidade').setValue(this.currentUser.address.city);
        this.editForm.get('estado').setValue(this.currentUser.address.state);
        this.editForm.get('complemento').setValue(this.currentUser.address.comp);
    }

    parseAddress(fullAddress): any {
        if (fullAddress['street_address']) {
            const fullString: string = fullAddress['street_address'];
            const pieces = fullString.split(',');
            const street = pieces[0];
            const rest = pieces[1].split('\n');
            if (rest.length > 2) {
                return {
                    rua: street,
                    num: rest[0],
                    bairro: rest[1],
                    comp: rest[2]
                };
            }
            else {
                return {
                    rua: street,
                    num: rest[0],
                    bairro: rest[1],
                    comp: null
                };
            }
        } else {
            return {
                rua: '',
                num: 0,
                bairro: '',
                comp: null
            };
        }
    }

    public cpfcnpjMask = function (rawValue) {
        const strip = rawValue.replace(/\D/g, '');
        if (strip.length > 11) {
            return [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
        }
        else {
            return [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
        }
    };

    onCropImage(event: ImageCroppedEvent) {
        if (event) {
            let img64 = event.base64;
            if (img64) {
                // const preview_container = document.querySelector('.fileinput-preview');
                // const img = preview_container.children[0] as HTMLImageElement;
                // img.src = img64;
                img64 = img64.split(',')[1];
                this.store.dispatch(UserActions.publish_picture({
                    sub: localStorage.getItem('sub'),
                    img64: img64
                }));
                this.profileLoading = true;
            }
        }
        const fileInput = document.querySelector('.fileinput') as HTMLInputElement;
        if (fileInput) {
            fileInput.value = '';
        }
        this.store.dispatch(UserActions.close_modal_crop());
    }

    closeModal() {
        this.fileinput.nativeElement.value = '';
        const fileInput = document.querySelector('.fileinput') as HTMLInputElement;
        if (fileInput) {
            fileInput.value = '';
            fileInput.removeAllListeners('change');
        }
        this.store.dispatch(UserActions.close_modal_crop());
    }

    async updatePicture(event: Event) {
        let height;
        let width;

        const webURL = URL || webkitURL;
        const input = event.target as HTMLInputElement;
        const file = input.files[0];
        if (file) {
            const img = new Image();
            img.src = webURL.createObjectURL(file);
            img.onload = () => {
                height = img.height;
                width = img.width;
                if (height !== undefined && width !== undefined && height !== 0 && width !== 0) {
                    if (width > 2560 && height > 2560 || width > 2560 || height > 2560) {
                        this.validationResponse.validationResponseHandler(400, 'user-summary', 'photo-max-size-error', 'user.photo_max_size_error');
                        this.clearFileInput();
                        // this.closeModal();
                    } else if (width < 90 && height < 90 || width < 90 || height < 90) {
                        this.validationResponse.validationResponseHandler(400, 'user-summary', 'photo-min-size-error', 'user.photo_min_size_error');
                        this.clearFileInput();
                        // this.closeModal();
                    }
                }
            };
            this.store.dispatch(UserActions.open_modal_crop({ payload: event }));
        }

    }

    clearFileInput() {
        const fileInput = (document.getElementById('file') as HTMLInputElement);
        if (fileInput) {
            fileInput.value = '';
        }
    }

    openModalConfigAlarm() {
        this.store.dispatch(UserActions.update_modal_alarm({ payload: AlarmModalState.Select }));
    }

    updateAlarmConfig() {
        this.modalCloseCfg = this.modal_openCfg$.subscribe(modalStatus => {

            if (modalStatus == OperationStatus.Success) {
                this.store.dispatch(UserActions.update_modal_alarm({ payload: AlarmModalState.Closed }));
            }
        });
    }

    validateAttr(name: string, objectName: string, comp?: string): string {
        if (comp) {
            return this.editForm.get(name).value;
        }
        else if (name === 'email' || name === 'name') {
            const exp = this.editForm.get(name).value == '';
            if (!exp) {
                this.updateDB = true;
                if (name === 'email') {
                    this.updatePayload = {
                        ...this.updatePayload,
                        emailUser: this.editForm.get(name).value
                    };
                }
                else {
                    this.updatePayload = {
                        ...this.updatePayload,
                        nameUser: this.editForm.get(name).value
                    };
                }
            }
            return this.editForm.get(name).value == '' ? this.currentUser[objectName] : this.editForm.get(name).value;
        }
        else if (name === 'cell') {
            let numNoMask: string = this.editForm.get(name).value;
            numNoMask = numNoMask.replace(/\D/g, '');
            return this.editForm.get(name).value == '' ? this.currentUser[objectName] : `+55${numNoMask}`;
        }
        else {
            return this.editForm.get(name).value == '' ? this.currentUser[objectName] : this.editForm.get(name).value;
        }
    }

    //Consulta o endereço a partir do CEP e seta no formulário
    getCepAddress() {
        this.loading = true;
        let cep: string = this.editForm.get('CEP').value;
        if (cep) {
            cep = cep.replace(/\D/g, '');
            this.service.getAddressFromCEPUserSummary(cep).then(
                result => {
                    if (result.status == 200 && result.body.erro == 'true') {
                        this.validationResponse.validationResponseHandler(404, 'camera-form', 'cep-not-found', 'cameras.cep_not_found');
                        this.loading = false;
                    }
                    else if (result.status == 200 && result.body.erro != 'true') {
                        this.cepAddress = result.body;
                        this.updateProfile();
                    }
                }).catch(error => {
                if (error) {
                    this.validationResponse.validationResponseHandler(400, 'camera-form', 'cep-calculator', 'cameras.cep_calculator_error');
                    this.loading = false;
                }
            });
        } else {
            this.updateProfile();
        }
    }


    updateProfile() {
        this.loading = true;
        let errorPass = false;
        const cep = this.cepAddress ? this.cepAddress.cep : this.validateAttr('CEP', 'address', 'CEP');
        const street = this.cepAddress ? this.cepAddress.logradouro : this.validateAttr('street', 'address', 'street');
        const num = this.validateAttr('num', 'address', 'number');
        const bairro = this.cepAddress ? this.cepAddress.bairro : this.validateAttr('bairro', 'address', 'bairro');
        const complemento = this.validateAttr('complemento', 'address', 'comp');
        const cidade = this.cepAddress ? this.cepAddress.localidade : this.validateAttr('cidade', 'address', 'city');
        const estado = this.cepAddress ? this.cepAddress.uf : this.validateAttr('estado', 'address', 'state');
        const cpfCnpj = this.validateAttr('cpf', 'cpf_cnpj');
        const address = {
            street_address: street + ', ' + num + '\n' + bairro + '\n' + complemento,
            locality: cidade,
            region: estado,
            postal_code: cep,
            country: 'Brasil'
        };

        if (street || bairro || cidade || estado || cep) {
            errorPass = false;
        } else {
            errorPass = true;
        }

        const attributeList: CognitoAttributes = {
            name: this.validateAttr('name', 'name'),
            email: this.validateAttr('email', 'email'),
            email_suport:  this.editForm.get('emailSuport').value,
            contract_link: this.editForm.get('contract_link').value,
            phone_number: this.validateAttr('cell', 'telephone'),
            picture: this.currentUser.imgURL ? this.currentUser.imgURL : 'undefined',
            address: errorPass ? '{}' : JSON.stringify(address),
            sub: this.allAttributes.sub,
            zoneinfo: this.allAttributes.zoneinfo,
            gender: this.allAttributes.gender,
            birthdate: this.allAttributes.birthdate,
            updated_at: this.allAttributes.updated_at,
            email_verified: this.allAttributes.email_verified,
            phone_number_verified: this.allAttributes.phone_number_verified,
            family_name: this.allAttributes.family_name,
            cost_center: this.allAttributes.cost_center,
            cost_center_mark: this.allAttributes.cost_center_mark
        };

        const avvaAttributeList: UpdateClient = {
            sub: localStorage.getItem('sub'),
            name: this.validateAttr('name', 'name'),
            email: this.validateAttr('email', 'email'),
            telephone: this.validateAttr('cell', 'telephone'),
            avva: this.allAttributes.avva,
            createDevice: this.allAttributes.device,
            contactEmergency: this.allAttributes.contactEmergency,
            phoneEmergency: this.allAttributes.phoneEmergency,
            password: '',
            child_sub: null
        };

        if (cpfCnpj) {
            if (cpfCnpj.length > 11) {
                attributeList['custom:CNPJ'] = cpfCnpj;
            } else {
                attributeList['custom:CPF'] = cpfCnpj;
            }
        }

        if (!isNaN(Number(num))) {
            if (this.editForm.enabled) {
                this.isAvvaUpdated = false;
                this.isNuvUpdated = false;
                this.editForm.get('num').setValue(num);
                this.store.dispatch(UserActions.update_user({
                    user_sub: localStorage.getItem('sub'),
                    cognitoAttr: attributeList
                }));
                if (this.isClient || this.isClientPartner) {
                    this.store.dispatch(UserActions.edit_client({
                        edit_client: avvaAttributeList
                    }));
                }
                this.loading = true;
            }
        }
        else {
            this.validationResponse.validationResponseHandler(400, 'user-summary', 'input-number-invalid', 'user.input_number_invalid');
            this.loading = false;
        }
    }

    scrollToElement($element, num: number, nameTab: string): void {
        this.currentScroll = num;
        $element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        if (nameTab == 'perfil') {
            this.viewProfile = true;
            this.linkedCustomer = false;
            this.accountGuests = false;
        }
        if (nameTab == 'cliente_vinculado') {
            this.viewProfile = false;
            this.linkedCustomer = true;
            this.accountGuests = false;
        }
        if (nameTab == 'convidados_conta') {
            this.viewProfile = false;
            this.accountGuests = true;
            this.linkedCustomer = false;
        }
    }

}
