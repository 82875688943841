<div class="main-content">
    <div class="first-row">
      <div class="title-row">
        <span class="modal-title">Excluir câmeras e analíticos do estoque do Parceiro</span>
        <span class="modal-title name">{{partnerName}}</span>
      </div>
      <div (click)="closeModal()" class="close-modal">
          <span nz-icon nzType="close" nzTheme="outline"></span>
      </div>
    </div>
    <div class="scroll-modal">
      <div style="padding: 10px 24px;">
        <span class="description">Essa ação removerá as câmeras e analíticos disponíveis do Kit selecionado para o Parceiro, mas manterá as câmeras e analíticos já distribuídas.</span>
        <form [formGroup]="editForm" class="form-container">
          <div style="display: flex;">
            <div class="container-input-select">
              <div class="deleting-kit">
                <span class="camera-info"><b>Analíticos</b></span>
              </div>
            </div>
            <div class="container-input-select-two">
              <div class="label-input" style="margin-left: 20px;">
                <span>Selecione a quantidade</span>
              </div>
              <div class="info-line">
                <nz-input-number class="input-container-number" formControlName="analyticAmount" (change)="checkLimit()" [nzMin]="1" [nzMax]="qtdAnalytic - 1" [nzStep]="1"></nz-input-number>
              </div>
              <mat-error style="position: relative; bottom: 16%; left: 8%;" *ngIf="editForm.get('analyticAmount').hasError('required') && editForm.get('analyticAmount').touched">
                *Campo obrigatório
              </mat-error>
            </div>
          </div>
          <div style="display: flex;">
            <div class="container-input-select">
              <div class="deleting-kit">
                <span class="camera-info"><b>CÂMERA {{camResolution}}</b> {{camDays}} {{camDays == 1 ? ' dia' : ' dias'}}</span>
              </div>
            </div>
            <div class="container-input-select-two">
              <div class="label-input" style="margin-left: 20px;">
                <span>Selecione a quantidade</span>
              </div>
              <div class="info-line">
                <nz-input-number class="input-container-number" formControlName="cameraAmount" (change)="checkLimit()" [nzMin]="1" [nzMax]="qtdCam" [nzStep]="1"></nz-input-number>
              </div>
              <mat-error style="position: relative; bottom: 16%; left: 8%;" *ngIf="editForm.get('cameraAmount').hasError('required') && editForm.get('cameraAmount').touched">
                *Campo obrigatório
              </mat-error>
            </div>
          </div>
          <span class="final-warning"><span style="margin-right: 5px; color: #306EBF;" nz-icon nzType="warning" nzTheme="outline"></span>Ao alterar a quantidade do estoque outros kits poderão ser afetados</span>
        </form>
      </div>
    </div>
    <div class="final-row">
      <button (click)="closeModal()" class="button-row cancel">Cancelar</button>
      <button [class.button-disabled]="(editForm.get('cameraAmount').hasError('required') && editForm.get('cameraAmount').touched)
              || (editForm.get('analyticAmount').hasError('required') && editForm.get('analyticAmount').touched) || loading"
              (click)="deleteKit()" class="button-row delete">Excluir<span style="margin-left: 5px;" *ngIf="loading" nz-icon nzType="loading" nzTheme="outline"></span>
      </button>
    </div>
</div>