import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError, first } from 'rxjs/operators';
import { UserAction, UserActions, Metadata } from './actions';
import { UserService } from './user.service';
import { HandleError } from '../../Shared/Helpers/HandleError';
import { OperationStatus } from 'app/Shared/models';

@Injectable()
export class UserEffects {
    constructor(private service: UserService, private actions: Actions, private error: HandleError) {}

    publishPicture$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.PUBLISH_PICTURE),
            mergeMap((action: Metadata) => this.service.publishPicture(action.sub, action.img64).pipe(
                map(result => {
                    return UserActions.publish_picture_success({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('getProfile', UserActions.publish_picture_success({ payload: '' }),
                    UserActions.publish_picture(action)))
            )
            )
        )
    );

    notificationVisibility$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.NOTIFICATION_VISIBILITY),
            mergeMap((action: Metadata) => this.service.notificationVisibility(action.sub, action.hashNotification).pipe(
                map(result => {
                    return UserActions.notification_visibility_success({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('notificationVisibility', UserActions.notification_visibility_success({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.notification_visibility(action)))
            )
            )
        )
    );

    getUserData$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.GET_USER_DATA),
            mergeMap((action: Metadata) => this.service.getUserData(action.user_sub, action.child_sub).pipe(
                map(result => {
                    return UserActions.get_user_data_result({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('getUserData', UserActions.get_user_data_result({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.get_user_data(action)))
            )
            )
        )
    );

    createTicketKit$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.CREATE_TICKET_KIT),
            mergeMap((action: Metadata) => this.service.createTickets(action.bodyTicket, action.sub).pipe(
                map(result => {
                    return UserActions.create_ticket_kit_return({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('createTicketKit', UserActions.create_ticket_kit_return({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.create_ticket_kit(action)))
            )
            )
        )
    );

    getUserBillings$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.GET_USER_BILLINGS),
            mergeMap((action: Metadata) => this.service.getUserBillings(action.user_sub).pipe(
                map(result => {
                    return UserActions.get_user_billings_result({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('getUserBillings', UserActions.get_user_billings_result({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.get_user_billings(action)))
            )
            )
        )
    );

    getUserBillingsHistory$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.GET_USER_BILLINGS_HISTORY),
            mergeMap((action: Metadata) => this.service.getUserBillingsHistory(action.user_sub, action.year).pipe(
                map(result => {
                    return UserActions.get_user_billings_history_result({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('getUserBillingsHistory', UserActions.get_user_billings_history_result({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.get_user_billings_history(action)))
            )
            )
        )
    );

    getUserBillingsDetails$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.GET_USER_BILLINGS_DETAILS),
            mergeMap((action: Metadata) => this.service.getUserBillingsDetails(action.user_sub, action.year, action.month).pipe(
                map(result => {
                    return UserActions.get_user_billings_datails_result({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('getUserBillingsDetails', UserActions.get_user_billings_datails_result({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.get_user_billings_datails(action)))
            )
            )
        )
    );

    changePasswordUser$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.CHANGE_PASSWORD),
            mergeMap((action: Metadata) => this.service.changePassword(action.updatePassword).pipe(
                map(result => {
                    return UserActions.change_password_result({ payload: result ? OperationStatus.Success : OperationStatus.Fail });
                }),
                catchError(this.error.handleError<UserAction>('changePassword', UserActions.change_password_result({ payload: OperationStatus.Fail }),
                    UserActions.change_password(action)))
            )
            )
        )
    );

    changeBillingAddress$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.CHANGE_BILLING_ADDRESS),
            mergeMap((action: Metadata) => this.service.changeBillingAddress(action.user_sub, action.upAddress).pipe(
                map(result => {
                    return UserActions.change_billing_address_result({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('changeBillingAddress', UserActions.change_billing_address_result({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.change_billing_address(action)))
            )
            )
        )
    );

    updateProfile$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.UPDATE_USER),
            mergeMap((action: Metadata) => this.service.updateProfile(action.user_sub, action.cognitoAttr).pipe(
                map(result => {
                    return UserActions.update_user_result({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('updateUser', UserActions.update_user_result({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.update_user(action)))
            )
            )
        )
    );

    createConfigAlarm$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.CREATE_CONFIG_ALARM),
            mergeMap((action: Metadata) => this.service.createAlarmConfiguration(action.user_sub, action.configList, action.child_sub).pipe(
                map(result => {
                    return UserActions.create_config_alarm_return({ payload: result ? OperationStatus.Success : OperationStatus.Fail });
                }),
                catchError(this.error.handleError<UserAction>('createAlarmConfigurations', UserActions.create_config_alarm_return({ payload: OperationStatus.Fail }),
                    UserActions.create_config_alarm(action)))
            )
            )
        )
    );

    updateConfigAlarm$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.UPDATE_CONFIG_ALARM),
            mergeMap((action: Metadata) => this.service.updateAlarmConfigurations(action.user_sub, action.configList).pipe(
                map(result => {
                    return UserActions.update_configurations_alarm_return({ payload: result ? OperationStatus.Success : OperationStatus.Fail });
                }),
                catchError(this.error.handleError<UserAction>('updateAlarmConfigurations', UserActions.update_configurations_alarm_return({ payload: OperationStatus.Fail }),
                    UserActions.update_configurations_alarm(action)))
            )
            )
        )
    );

    getConfigAlarm$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.GET_CONFIG_ALARM),
            mergeMap((action: Metadata) => this.service.getAlarmConfigurations(action.user_sub).pipe(
                map(result => {
                    return UserActions.get_configurations_alarm_return({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('getAlarmConfigurations', UserActions.get_configurations_alarm_return(null),
                    UserActions.get_configurations_alarm(action)))
            )
            )
        )
    );

    deleteConfigAlarm$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.DELETE_CONFIG_ALARM),
            mergeMap((action: Metadata) => this.service.deleteAlarmConfiguration(action.user_sub).pipe(
                map(result => {
                    return UserActions.delete_config_alarm_return({ payload: result ? OperationStatus.Success : OperationStatus.Fail });
                }),
                catchError(this.error.handleError<UserAction>('deleteAlarmConfigurations', UserActions.delete_config_alarm_return({ payload: OperationStatus.Unloaded }),
                    UserActions.delete_config_alarm(action)))
            )
            )
        )
    );

    updatePayment$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.UPDATE_PAYMENT),
            mergeMap((action: Metadata) => this.service.updatePayment(action.updatePayment).pipe(
                map(result => {
                    return UserActions.update_payment_result({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('updatePayment', UserActions.update_payment_result({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.update_payment(action)))
            )
            )
        )
    );

    getUserPaymentMethod$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.GET_USER_PAYMENT_METHOD),
            mergeMap((action: Metadata) => this.service.getUserPaymentMethod(action.user_sub).pipe(
                map(result => {
                    return UserActions.get_user_payment_method_result({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('getUserPaymentMethod', UserActions.get_user_payment_method_result({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.get_user_payment_method(action)))
            )
            )
        )
    );

    registerPartnerUser$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.CREATE_PARTNERS),
            mergeMap((action: Metadata) => this.service.registerPartnerUser(action.user_sub, action.name, action.cnpj, action.email, action.password, action.partner_permission,
                action.contract, action.costCenter, action.costCenterMark).pipe(
                map(result => {
                    return UserActions.create_partners_status({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('registerPartnerUser', UserActions.create_partners_status({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.create_partners(action)))
            )
            )
        )
    );

    editPartnerUser$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.UPDATE_PARTNERS),
            mergeMap((action: Metadata) => this.service.editPartnerUser(action.user_sub, action.cognitoAttr).pipe(
                map(result => {
                    return UserActions.update_partner_return({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('updateUser', UserActions.update_partner_return({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.update_user(action)))
            )
            )
        )
    );

    getPartnersList$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.GET_PARTNERS),
            mergeMap((action: Metadata) => this.service.getPartnersList(action.user_sub, action.str_filter).pipe(
                map(result => {
                    return UserActions.get_partners_result({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('getPartnersList', UserActions.get_partners_result({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.get_partners(action)))
            )
            )
        )
    );

    getClients$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.GET_CLIENTS),
            mergeMap((action: Metadata) => this.service.getClients(action.user_sub, action.child_sub, action.str_filter, action.page, action.limit).pipe(
                map(result => {
                    return UserActions.get_clients_return({ payload: result, pageIndex: action.page });
                }),
                catchError(this.error.handleError<UserAction>('getClient', UserActions.get_clients_return({ payload: [] }),
                    UserActions.get_clients(action)))
            )
            )
        )
    );

    getAllGuests$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.GET_ALL_GUESTS),
            mergeMap((action: Metadata) => this.service.getGuests(action.user_sub, action.child_sub, action.str_filter, action.page, action.limit).pipe(
                map(result => {
                    return UserActions.get_all_guests_return({ payload: result, pageIndex: action.page });
                }),
                catchError(this.error.handleError<UserAction>('getGuests', UserActions.get_all_guests_return({ payload: [] }),
                    UserActions.get_all_guests(action)))
            )
            )
        )
    );

    getKits$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.GET_KITS),
            mergeMap((action: Metadata) => this.service.getKits(action.sub).pipe(
                map(result => {
                    return UserActions.get_kits_return({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('getKits', UserActions.get_kits_return({ payload: [] }),
                    UserActions.get_kits(action)))
            )
            )
        )
    );

    updateKits$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.UPDATE_KIT),
            mergeMap((action: Metadata) => this.service.updateKits(action.kit, action.sub).pipe(
                map(result => {
                    return UserActions.update_kit_result({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('updateKits', UserActions.update_kit_result({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.update_kit(action)))
            )
            )
        )
    );

    deleteKits$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.DELETE_KIT),
            mergeMap((action: Metadata) => this.service.deleteKits(action.kit, action.sub).pipe(
                map(result => {
                    return UserActions.delete_kit_result({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('deleteKits', UserActions.delete_kit_result({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.delete_kit(action)))
            )
            )
        )
    );

    createKits$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.CREATE_KIT),
            mergeMap((action: Metadata) => this.service.createKits(action.sub, action.kit).pipe(
                map(result => {
                    return UserActions.create_kit_return({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('createKits', UserActions.create_kit_return({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.create_kit(action)))
            )
            )
        )
    );

    resendWelcomeEmail$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.RESEND_WELCOME_EMAIL),
            mergeMap((action: Metadata) => this.service.resendWelcomeMail(action.sub, action.child_sub).pipe(
                map(result => {
                    return UserActions.resend_welcome_email_return({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('resendWelcomeMail', UserActions.resend_welcome_email_return({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.resend_welcome_email(action)))
            )
            )
        )
    );

    userInventory$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.GET_USER_INVENTORY),
            mergeMap((action: Metadata) => this.service.userInventory(action.user_sub, action.child_sub).pipe(
                map(result => {
                    return UserActions.get_user_inventory_result({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('userInventory', UserActions.get_user_inventory_result({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.get_user_inventory(action)))
            )
            )
        )
    );

    userNotification$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.GET_USER_NOTIFICATION),
            mergeMap((action: Metadata) => this.service.userNotification(action.user_sub).pipe(
                map(result => {
                    return UserActions.get_user_notification_result({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('userNotification', UserActions.get_user_notification_result({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.get_user_notification(action)))
            )
            )
        )
    );

    associatedUsers$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.GET_ASSOCIATED_USERS),
            mergeMap((action: Metadata) => this.service.associatedUsers(action.user_sub, action.child_sub).pipe(
                map(result => {
                    return UserActions.get_associated_users_success({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('associatedUsers', UserActions.get_associated_users_success({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.get_associated_users(action)))
            )
            )
        )
    );

    transferItems$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.TRANSFER_ITEMS),
            mergeMap((action: Metadata) => this.service.transferItems(action.user_sub, action.buyers_sub, action.kits, action.cameras, action.analytics_amount, action.guests_amount).pipe(
                map(result => {
                    return UserActions.transfer_items_result({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('transferItems', UserActions.transfer_items_result({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.transfer_items(action)))
            )
            )
        )
    );

    addClient$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.ADD_CLIENT),
            mergeMap((action: Metadata) => this.service.addClient(action.user_sub, action.client).pipe(
                map(result => {
                    return UserActions.add_client_return({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('addClient', UserActions.add_client_return({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.add_client(action)))
            )
            )
        )
    );

    convertClient$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.ADD_CLIENT_CONVERT),
            mergeMap((action: Metadata) => this.service.convertClient(action.sub, action.child_sub, action.client).pipe(
                map(result => {
                    return UserActions.add_client_convert_return({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('convertClient', UserActions.add_client_convert_return({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.add_client_convert(action)))
            )
            )
        )
    );

    convertGuest$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.ADD_CLIENT_GUEST_CONVERT),
            mergeMap((action: Metadata) => this.service.convertGuest(action.sub, action.child_sub, action.client).pipe(
                map(result => {
                    return UserActions.add_client_guest_convert_return({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('convertGuest', UserActions.add_client_guest_convert_return({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.add_client_guest_convert(action)))
            )
            )
        )
    );

    deletePartner$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.DELETE_PARTNER),
            mergeMap((action: Metadata) => this.service.deletePartner(action.sub, action.child_sub).pipe(
                map(result => {
                    return UserActions.delete_partner_return({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('convertGuest', UserActions.delete_partner_return({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.delete_partner(action)))
            )
            )
        )
    );

    deleteClient$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.DELETE_CLIENT),
            mergeMap((action: Metadata) => this.service.deleteClient(action.sub, action.child_sub).pipe(
                map(result => {
                    return UserActions.delete_client_result({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('deleteClient', UserActions.delete_client_result({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.delete_client(action)))
            )
            )
        )
    );

    blockClient$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.BLOCK_CLIENT),
            mergeMap((action: Metadata) => this.service.blockClient(action.sub, action.child_sub, action.active).pipe(
                map(result => {
                    return UserActions.block_client_result({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('blockClient', UserActions.block_client_result({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.block_client(action)))
            )
            )
        )
    );

    updateClient$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.EDIT_CLIENT),
            mergeMap((action: Metadata) => this.service.editClient(action.edit_client).pipe(
                map(result => {
                    return UserActions.edit_client_return({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('editClient', UserActions.edit_client_return({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.edit_client(action)))
            )
            )
        )
    );

    deleteAssociated$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.DELETE_ASSOCIATED),
            mergeMap((action: Metadata) => this.service.deleteAssociated(action.sub, action.child_sub).pipe(
                map(result => {
                    return UserActions.delete_associated_return({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('deleteAssociated', UserActions.delete_associated_return({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.delete_associated(action)))
            )
            )
        )
    );

    deleteClientInventory$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.DELETE_CLIENT_INVENTORY),
            mergeMap((action: Metadata) => this.service.deleteClientInventory(action.sub, action.child_sub, action.id_plan).pipe(
                map(result => {
                    return UserActions.delete_client_inventory_return({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('deleteClientInventory', UserActions.delete_client_inventory_return({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.delete_client_inventory(action)))
            )
            )
        )
    );

    inviteAssociated$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.INVITE_ASSOCIATED),
            mergeMap((action: Metadata) => this.service.inviteAssociate(action.sub, action.child_sub, action.email, action.resend, action.associates).pipe(
                map(result => {
                    return UserActions.invite_associated_return({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('inviteAssociated', UserActions.invite_associated_return({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.invite_associated(action)))
            )
            )
        )
    );

    updateAssociated$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.UPDATE_ASSOCIATED),
            mergeMap((action: Metadata) => this.service.updateAssociated(action.user_sub, action.associateUser).pipe(
                map(result => {
                    return UserActions.update_associated_result({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('updateAssociated', UserActions.update_associated_result({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.update_associated(action)))
            )
            )
        )
    );

    getNationalHolidays$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.GET_NATIONAL_HOLIDAYS),
            mergeMap((action: Metadata) => this.service.getNationalHolidays(action.user_sub).pipe(
                map(result => {
                    return UserActions.get_national_holidays_return({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('getNationalHolidays', UserActions.get_national_holidays_return({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.get_national_holidays(action)))
            )
            )
        )
    );

    getCalendarList$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.GET_CALENDAR_LIST),
            mergeMap((action: Metadata) => this.service.getCalendarList(action.user_sub, action.child_sub, action.idCamera).pipe(
                map(result => {
                    return UserActions.get_calendar_list_return({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('getCalendarList', UserActions.get_calendar_list_return({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.get_calendar_list(action)))
            ),
            )
        )
    );

    createCalendar$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.CREATE_CALENDAR),
            mergeMap((action: Metadata) => this.service.createCalendar(action.user_sub, action.child_sub, action.holidays).pipe(
                map(result => {
                    return UserActions.create_calendar_return({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('createCalendar', UserActions.create_calendar_return({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.create_calendar(action)))
            )
            )
        )
    );

    updateCalendar$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.UPDATE_CALENDAR),
            mergeMap((action: Metadata) => this.service.updateCalendar(action.user_sub, action.child_sub, action.holidays).pipe(
                map(result => {
                    return UserActions.update_calendar_return({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('UpdateCalendar', UserActions.update_calendar_return({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.update_calendar(action)))
            )
            )
        )
    );

    deleteCalendar$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.DELETE_CALENDAR),
            mergeMap((action: Metadata) => this.service.deleteCalendar(action.user_sub, action.foreignhash, action.hash_holiday, action.child_sub).pipe(
                map(result => {
                    return UserActions.delete_calendar_return({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('deleteCalendar', UserActions.delete_calendar_return({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.delete_calendar(action)))
            )
            )
        )
    );

    deleteMasterKit$ = createEffect(() =>
        this.actions.pipe(
            ofType(UserActions.DELETE_MASTER_KIT),
            mergeMap((action: Metadata) => this.service.deleteMasterKit(action.sub, action.child_sub, action.id_plan, action.cameras_amount, action.analytics_amount).pipe(
                map(result => {
                    return UserActions.delete_master_kit_result({ payload: result });
                }),
                catchError(this.error.handleError<UserAction>('deleteKits', UserActions.delete_master_kit_result({
                    payload: {
                        status: OperationStatus.Loading,
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), UserActions.delete_master_kit(action)))
            )
            )
        )
    );
}

