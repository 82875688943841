import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AddCamGuestData, Camera, PaginationCameras } from '../models';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { AppState } from 'app/store/model';
import { CameraActions } from '../Services/actions';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { SharedService } from 'app/Shared/Services/shared.service';

export interface GuestElements {
    camera: [];
    permission: [];
    id: number;
}

@Component({
    selector: 'app-add-cam-guest-modal',
    templateUrl: './add-cam-guest-modal.component.html',
    styleUrls: ['./add-cam-guest-modal.component.scss']
})
export class AddCamGuestComponent implements OnInit, OnDestroy {
    @Input() data: AddCamGuestData;
    @Output() modalClose = new EventEmitter<string>();

    readonly inviteUsers$ = this.store.select((state: AppState) => state.camera.inviteUsers);
    readonly cameras$ = this.store.select((state: AppState) => state.camera.cameras);

    getCams: Subscription;
    inviteGuest: Subscription;
    cameras: Camera;

    permissionLabels: string[] = ['Receber Alertas', 'Recuperar Videos', 'Visualizar câmeras ao vivo'];
    cameraList: {
        id: number | string;
        text: string;
    }[];

    tableGuestsCam: GuestElements[] = [{
        camera: [],
        permission: [],
        id: 0,
    }];

    newtableGuestsCam: GuestElements = {
        camera: [],
        permission: [],
        id: 1
    };

    pageName = 'cameras-add-guest';
    loadingSubmit: boolean;
    guestEmail: string;
    userSub: string;
    childSub: string;
    cameraName: string;
    cameraId: number;
    camera_id: string[];
    qtdCamera: number;
    viewAlertHistoryCam: boolean;
    receiveAnalyticAlertCam: boolean;
    viewRecordingCam: boolean;
    viewLiveCam: boolean;
    returnCam: any;
    placeholder: string | number;

    message: string;
    messageList = [];
    qtdFail = 0;

    permissionForm = new UntypedFormGroup({
        permissionsFormsUser: new UntypedFormArray([]),
    });

    constructor(private validationResponse: ValidationResponseHandlerModule,
        private readonly store: Store<AppState>, private service: SharedService) { }

    ngOnInit(): void {
        this.loadingSubmit = false;
        this.guestEmail = this.data.email;
        this.userSub = this.data.user_sub;
        this.childSub = this.data.child_sub;
        this.cameraId = this.data.camera_id;
        this.cameraName = this.data.camera_name;
        this.viewAlertHistoryCam = this.data.viewAlertHistory;
        this.receiveAnalyticAlertCam = this.data.receiveAnalyticAlert;
        this.viewRecordingCam = this.data.viewRecording;
        this.viewLiveCam = this.data.viewLive;
        this.getCameras();
    }


    ngOnDestroy(): void {
        if (this.getCams) {
            this.getCams.unsubscribe();
        }
        if (this.inviteGuest) {
            this.inviteGuest.unsubscribe();
        }
        this.tableGuestsCam = [{camera: [], permission: [], id: 0}];
        this.service.cleanSwal();
        this.store.dispatch(CameraActions.close_modal_add_cam_guest());
        this.closeModal();
    }


    closeModal() {
        this.modalClose.emit();
    }


    parseGuestPermissions(id) {
        if (id == 0) {
            const perms = [];
            if (this.viewAlertHistoryCam  ||  this.receiveAnalyticAlertCam) {
                perms.push('Receber Alertas');
            }
            if (this.viewLiveCam) {
                perms.push('Visualizar câmeras ao vivo');
            }
            if (this.viewRecordingCam) {
                perms.push('Recuperar Videos');
            }
            return perms;
        }
    }

    parseGuestCam(id) {
        if (id == 0) {
            for (let j = 0; j < this.cameraList?.length; j++) {
                if (this.cameraList[j].text === this.cameraName) {
                    this.placeholder = this.cameraList[j].id;
                }
            }
            return this.placeholder;
        }
    }


    getCameras() {
        this.getCams = this.cameras$.subscribe(cameras => {
            if (cameras && cameras.model === 'cameras') {
                let cam: any = cameras.cameras as PaginationCameras;
                cam = cam.cameras;
                if (cam.length > 0) {
                    this.cameras = cam;
                    this.cameraList = cam.map(element => {
                        return {
                            id: element.id,
                            text: element.alias
                        };
                    });
                }
            }
        });
    }

    newGuests() {
        const newTable = { camera: this.newtableGuestsCam.camera, permission: this.newtableGuestsCam.permission, id: this.newtableGuestsCam.id };
        this.tableGuestsCam = [...this.tableGuestsCam, newTable];
        this.newtableGuestsCam.id = this.tableGuestsCam.length;
    }

    popGuest(id) {
        this.tableGuestsCam = this.tableGuestsCam.filter(d => d.id !== id);
    }

    changeCam(event, id) {
        this.tableGuestsCam[id].camera = event;
    }

    changePerm(event, id) {
        this.tableGuestsCam[id].permission = event;
    }

    inviteUser() {
        this.loadingSubmit = true;
        let validate = true;
        const guests = this.tableGuestsCam;
        const permissionMapping = {
            'Recuperar Videos': 'viewRecording',
            'Receber Alertas': 'receiveAnalyticAlert' || 'viewAlertHistory',
            'Visualizar câmeras ao vivo': 'viewLive'
        };

        this.returnCam = [];

        guests.forEach((element: any) => {
            if (validate) {
                if (element.camera.length > 0 && element.permission.length > 0) {
                    validate = true;
                    this.camera_id = element.camera;

                    element.camera.forEach(cameraId => {
                        const camera = { idCamera: cameraId };
                        const analyticAlert = element.permission.includes('Receber Alertas');
                        element.permission.forEach(permission => {
                            const permissionName = permissionMapping[permission];
                            if (permissionName) {
                                camera[permissionName] = true;
                            }
                        });
                        camera['receiveAnalyticAlert'] = analyticAlert;
                        camera['viewAlertHistory'] = analyticAlert;

                        for (const permission in permissionMapping) {
                            if (!camera.hasOwnProperty(permissionMapping[permission])) {
                                camera[permissionMapping[permission]] = false;
                            }
                        }

                        this.returnCam.push(camera);
                    });
                } else {
                    validate = false;
                }
            }
        });
        this.qtdCamera = this.returnCam.length;

        const result = {
            user_sub: this.userSub,
            child_sub: this.childSub,
            guestCam: this.returnCam,
            email: this.guestEmail,
            resend: false
        };
        if (validate) {
            this.store.dispatch(CameraActions.invite_user_camera(result));
            this.subscribeGuestResponse();
        } else {
            this.loadingSubmit = false;
            const swalFailureParameters = {
                title: 'Erro ao convidar para ' + (this.qtdCamera > 1 ? ' câmeras' : ' câmera')
            };
            this.validationResponse.validationResponseHandler(400, this.pageName, 'failed-invite', 'cameras.failed_invite', swalFailureParameters);
        }
    }

    subscribeGuestResponse() {
        this.inviteGuest = this.inviteUsers$.subscribe(result => {
            if (result) {
                this.loadingSubmit = false;
                if (result.statusCode === 200) {
                    const swalSuccessParameters = {
                        title: 'Convidado adicionado em ' + this.qtdCamera + (this.qtdCamera > 1 ? ' câmeras' : ' câmera'),
                        text: 'O convidado ',
                        text2: this.guestEmail,
                        text3: 'foi ' + 'adicionado em ' + this.qtdCamera + (this.qtdCamera > 1 ? ' câmeras.' : ' câmera.')
                    };
                    this.validationResponse.validationResponseHandler(200, this.pageName, 'success-invite', 'cameras.success_invite', swalSuccessParameters);
                    this.closeModal();
                }
                else if (result.statusCode === 206) {
                    result.results[0].data.forEach(item => {
                        if (item.success == false) {
                            this.qtdCamera -= 1;
                            this.qtdFail += 1;
                        }
                        for (let i = 0; i < this.cameraList.length; i++) {
                            if (this.cameraList[i].id === item.id_camera) {
                                this.message = (this.cameraList[i].text + ': ' + item.error_reason + '<br>');
                                this.messageList.push(this.message);
                            }
                        }
                        return this.messageList;
                    });
                    const swalParcialSuccessParameters = {
                        title: 'Convidado adicionado em ' + this.qtdCamera  + (this.qtdCamera > 1 ? ' câmeras' : ' câmera'),
                        text: 'O convidado ',
                        text2: this.guestEmail,
                        text3: 'foi ' + 'adicionado em ' + this.qtdCamera + (this.qtdCamera > 1 ? ' câmeras.' : ' câmera.'),
                        title2: 'Erro ao convidar para ' + this.qtdFail + (this.qtdFail > 1 ? ' câmeras.' : ' câmera.'),
                        text4: this.messageList
                    };
                    this.validationResponse.validationResponseHandler(206, this.pageName, 'parcial-success-invite', 'cameras.parcial_success_invite', swalParcialSuccessParameters);
                    this.closeModal();
                }
                else {
                    const swalFailureParameters = {
                        title: 'Erro ao convidar para ' + (this.qtdCamera > 1 ? ' câmeras' : ' câmera'),
                        msg: result.results[0].message
                    };
                    this.validationResponse.validationResponseHandler(400, this.pageName, 'failed-invite', 'cameras.failed_invite', swalFailureParameters);
                }
            }
        });
    }


}
