import { Component, OnInit, OnDestroy, Inject, Output, EventEmitter } from '@angular/core';
import { Subscription, Subject, combineLatest } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, Validators, UntypedFormArray, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { CameraActions } from '../Services/actions';
import { Store } from '@ngrx/store';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Camera, GuestsList, Plan, PaginationCameras, AddCamGuestData } from '../models';
import { AppState } from 'app/store/model';
import { OperationStatus, ResultAlert } from 'app/Shared/models';
import { ModalState } from 'app/cameras/models';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { CameraService } from '../Services/camera.service';
import { SharedService } from 'app/Shared/Services/shared.service';
import { MatCheckbox } from '@angular/material/checkbox';
import { UserInventory } from 'app/Users/models';
import { Router } from '@angular/router';
import {FilterComponent} from '../../components/filter/filter.component';
import { UserActions } from 'app/Users/Services/actions';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-invite-modal',
    templateUrl: './modal-invite.component.html',
    styleUrls: ['./camera-invite.component.scss']
})

export class InviteModalComponent implements OnInit, OnDestroy {

    readonly guests$ = this.store.select((state: AppState) => state.camera.guests);
    readonly delGuest$ = this.store.select((state: AppState) => state.camera.deleteGuest);
    readonly cameras$ = this.store.select((state: AppState) => state.camera.cameras);
    readonly inviteUsers$ = this.store.select((state: AppState) => state.camera.inviteUsers);
    readonly updateGuests$ = this.store.select((state: AppState) => state.camera.updateGuests);
    readonly plans$ = this.store.select((state: AppState) => state.camera.plans);
    readonly userInventory$ = this.store.select((state: AppState) => state.Users.userInventory);

    /*Cria a tabela com nome e email de usuarios convidados para determinada ID Camera */
    tableGuestsCamHead = ['profilePic', 'nameGuest', 'emailGuest', 'permission', 'status', 'delete', 'resend'];
    tableGuestsCam = new MatTableDataSource<GuestsList>([]);
    selection = new SelectionModel<GuestsList>(true, []);
    cameraName: string;
    subGuestsListUpdate: Subscription;
    subGuestsListDelete: Subscription;
    userInventorySub: Subscription;
    getCams: Subscription;
    resendResult: Subscription;
    subUpdateGuest: Subscription;
    swalDeleteGuests: Subscription;
    loadingModalInvite = false;
    deleteGuest = false;
    selectionView = false;
    loadResend = false;
    emailResent = false;
    is_guest = false;
    activeGuest: GuestsList;
    scrollbar;
    permissionLabels: string[] = ['Receber Alertas', 'Recuperar Videos', 'Visualizar câmeras ao vivo'];
    returnValuesForEdit;
    viewRecording: boolean;
    receiveAnalyticAlert: boolean;
    viewLive: boolean;
    viewAlertHistory: boolean;
    emailFormControl = new UntypedFormControl('', [
        Validators.required,
        Validators.email,
    ]);
    permission = new UntypedFormControl('', Validators.required);
    permissionForm = new UntypedFormGroup({
        permissionsFormsUser: new UntypedFormArray([]),
    });
    returnedArrayForForms = [];
    permissionsArrayForFormsEdition = [];
    indexForArray = [];
    returnValuesPermission;
    guestsList = [];
    selected;
    updateGuest: Subscription;
    tableRows: MatTableDataSource<GuestsList> = new MatTableDataSource([] as GuestsList[]);
    pageName = 'camera-invite';
    permissionError: boolean;
    deleteGuestId: number;
    inventories: UserInventory;
    userEmail: string;
    isPartner: boolean;
    isAssociate: boolean;
    adminAssociate: boolean;
    isClientAssociate: boolean = localStorage.getItem('profile_name') === '419bea06-5d4e-4a56-b8b5-04b3ad566d59';
    adminClientAssociate: boolean;
    userSub: string;
    child_sub: string;

    camera_id: number;
    cameraIdList: number;
    associate_id = environment.associateId;
    plans: Plan[] = null;
    getPlan: Subscription;
    camera_max_invites: number;

    cameras: Camera;
    returnCam = [];

    constructor(public dialogRef: MatDialogRef<InviteModalComponent>, @Inject(MAT_DIALOG_DATA) public modalData,
        private readonly store: Store<AppState>, private builder: UntypedFormBuilder, private router: Router,
        private validationResponse: ValidationResponseHandlerModule, private cameraAddressService: CameraService,
        private service: SharedService) {

    }

    ngOnInit() {
        this.camera_id = this.modalData.cam_id;
        this.camera_max_invites = this.modalData.plan_max_invite;
        this.userSub = localStorage.getItem('sub');
        this.child_sub = localStorage.getItem('clientView');
        this.adminClientAssociate = this.isClientAssociate && localStorage.getItem('associate_permission') === 'e816c560-812e-11ed-a1eb-0242ac120002';

        if (localStorage.getItem('profile_name') === 'f29868c7-b4c5-4963-9ae8-1dd95699d6c3') {
            this.isAssociate = true;
        }
        if (this.isAssociate && localStorage.getItem('associate_permission') === 'e816c560-812e-11ed-a1eb-0242ac120002') {
            this.adminAssociate = true;
        }
        if (localStorage.getItem('profile_name') == 'cd343bfc-17e8-11ec-9621-0242ac130002') {
            this.isPartner = true;
        }

        this.tableRows.data = [];
        this.store.dispatch(CameraActions.get_guests({ user_sub: this.userSub, child_sub: this.child_sub, camera_id: this.camera_id }));
        this.loadingModalInvite = true;
        this.updateGuestsList();
        this.confirmDeleteGuest();
        this.resendEmailResult();
        this.updateGuestResult();
        this.getCameras();
        const pressEnter = document.getElementById('pressEnter');
        pressEnter.addEventListener('keypress', this.checkPressEnter.bind(this));
        // Recebe o retorno das informações do swal
        this.swalDeleteGuests = this.service.swalDecision.subscribe(returnSwallObject => {
            if (returnSwallObject) {
                this.validateReturnMethodCalled(returnSwallObject as ResultAlert);
            }
        });
        this.userInventoryClient();

        if (localStorage.getItem('profile_name') == 'bb653b3a-fdb3-4438-bce6-012585b5268f' || (this.isClientAssociate && !this.adminClientAssociate)) {
            this.is_guest = true;
        }
    }

    checkPressEnter(event) {
        const keyName = event.key;
        if (keyName == 'Enter') {
            this.inviteUser();
        }
    }

    ngOnDestroy() {
        if (this.subGuestsListUpdate) {
            this.subGuestsListUpdate.unsubscribe();
        }
        if (this.subGuestsListDelete) {
            this.subGuestsListDelete.unsubscribe();
        }
        if (this.userInventorySub) {
            this.userInventorySub.unsubscribe();
        }
        if (this.getCams) {
            this.getCams.unsubscribe();
        }
        if (this.swalDeleteGuests) {
            this.swalDeleteGuests.unsubscribe();
        }
        if (this.getPlan) {
            this.getPlan.unsubscribe();
        }
        if (this.resendResult) {
            this.resendResult.unsubscribe();
        }

        this.store.dispatch(CameraActions.close_modal_invite_user_cancel());
        this.store.dispatch(CameraActions.close_modal_invite_user_confirm());
        const pressEnter = document.getElementById('pressEnter');
        pressEnter.removeEventListener('keypress', this.checkPressEnter.bind(this));
        this.service.cleanSwal();
    }

    isAllSelected(): boolean {
        if (!this.tableGuestsCam.data) {
            return false;
        }
        const numSelected = this.selection.selected.length;
        const numRows = this.tableGuestsCam.data.length;
        return numSelected === numRows;
    }

    treatEvent(event) {
        event.stopPropagation();
    }

    masterToggle() {
        this.selectionView = true;
    }

    toggleGuest(guest) {
        this.selection.toggle(guest);
        if (this.selection.selected.length === 0) {
            this.selectionView = false;
        }
    }

    clearSelection() {
        this.selectionView = false;
        this.selection.clear();
    }

    closeCancel() {
        this.store.dispatch(CameraActions.close_modal_invite_user_cancel());
        this.dialogRef.close();
    }

    redirect() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        if (localStorage.getItem('clientView')) {
            this.router.navigate(['/users/client/view']);
        } else {
            this.router.navigate(['/cameras/list']);
        }
    }

    showSpinner(guest: GuestsList): boolean {
        return this.loadResend && (this.activeGuest ? this.activeGuest.sub === guest.sub : false);
    }

    showResentMsg(guest: GuestsList): boolean {
        const expr = this.emailResent && (this.activeGuest ? this.activeGuest.sub === guest.sub : false);
        return expr;
    }

    resendEmail(guest: GuestsList) {
        this.returnCam = [{
            idCamera: this.camera_id,
            viewRecording: guest.view_recording,
            receiveAnalyticAlert: guest.receive_analytic_alert,
            viewLive: guest.view_live,
            viewAlertHistory: guest.view_alert_history,
        }];
        this.loadResend = true;
        this.activeGuest = guest;
        this.store.dispatch(CameraActions.invite_user_camera({
            user_sub: this.userSub,
            child_sub: this.child_sub,
            email: guest.email,
            resend: true,
            guestCam: this.returnCam
        }));
    }

    getUrl(guest: GuestsList): string {
        return guest.sub.includes('default sub') ? '../../../assets/img/user_invited.svg' : guest.profilePicture;
    }

    checkUserStatus(guest: GuestsList): boolean {
        return !guest.sub.includes('default sub');
    }

    checkUserStatusDate(guest: GuestsList): string {
        let status = '';
        const dataGuestInvite = guest.invitation_time;
        const data1 = new Date(String(dataGuestInvite));
        const data2 = new Date();
        const daysApart = Math.abs(Math.round((data2.getTime() - data1.getTime()) / 86400000));
        if (daysApart >= 25 && daysApart <= 29) {
            status = 'vinte_cinco';
            const days = 30 - daysApart;
            const member = document.getElementsByClassName('.not-member-vinte');
            for (let i = 0; i < member.length; i++) {
                member.item(i).setAttribute('data-text', 'Convite expira em ' + days + ' dias');
            }
        }
        else if (daysApart === 30) {
            status = 'trinta';
        }
        else {
            this.checkUserStatus(guest);
        }
        return status;
    }

    resendEmailResult() {
        this.resendResult = this.inviteUsers$.subscribe(result => {
            if (result != null) {
                if (result['statusCode'] != 200) {
                    this.validationResponse.validationResponseHandler(400, this.pageName, 'resend-email-update', result['results'][0].message);
                    this.loadResend = false;
                    this.loadingModalInvite = false;
                } else if (this.loadResend) {
                    this.loadResend = false;
                    this.emailResent = true;
                    setTimeout(() => {
                        this.emailResent = false;
                        this.activeGuest = null;
                    }, 3000);
                    this.validationResponse.validationResponseHandler(200, this.pageName, 'operation-success', 'user.invite_associated');
                } else {
                    this.validationResponse.validationResponseHandler(200, this.pageName, 'operation-success', 'cameras.success_invite');
                    this.updateInfoGuests(Number(localStorage.getItem('cameraIdInvite')));
                }
            }
        });
    }

    updateInfoGuests(cam_id) {
        const childSub = this.child_sub ? this.child_sub : localStorage.getItem('clientView');
        if (childSub !== null) {
            this.store.dispatch(UserActions.get_user_inventory({
                user_sub: localStorage.getItem('sub'),
                child_sub: childSub
            }));
            this.store.dispatch(CameraActions.get_guests({ user_sub: this.userSub, child_sub: childSub, camera_id: cam_id }));
        } else {
            if (!(this.isPartner || this.isAssociate)) {
                this.store.dispatch(UserActions.get_user_inventory({
                    user_sub: localStorage.getItem('sub')
                }));
                this.store.dispatch(CameraActions.get_guests({ user_sub: this.userSub, camera_id: cam_id }));
            }
        }
    }

    userInventoryClient() {
        this.userInventorySub = this.userInventory$.subscribe(inventory => {
            if (inventory) {
                if (inventory.model === 'feedback') {
                    this.validationResponse.validationResponseHandler(403, 'user-summary', 'userinventory-error', 'user.userinventory_error', inventory.message);
                } else {
                    this.inventories = inventory[0];
                    this.userEmail = this.inventories.inventory.email;
                }
            }
        });
    }

    updateGuestResult() {
        this.subUpdateGuest = this.updateGuests$.subscribe(result => {
            if (result != null) {
                if (result['statusCode'] != 200) {
                    this.validationResponse.validationResponseHandler(400, this.pageName, 'resend-email-update', result['results'][0].message);
                }
                else {
                    this.validationResponse.validationResponseHandler(200, this.pageName, 'permission-update-guest', 'cameras.permission_update_guest');
                    this.store.dispatch(CameraActions.get_guests({ user_sub: this.userSub, child_sub: this.child_sub, camera_id: this.camera_id }));
                    this.loadingModalInvite = true;
                }
            }
        });
    }

    inviteUser(filterPermission?: FilterComponent) {
        if (this.emailFormControl.valid) {
            if (this.permission.valid && this.permission.value.length !== undefined && this.permission.value.length > 0) {
                const viewAlertHistory = this.permission.value.includes(this.permissionLabels[0]);
                const receiveAnalyticAlert = viewAlertHistory;
                const viewRecording = this.permission.value.includes(this.permissionLabels[1]);
                const viewLive = this.permission.value.includes(this.permissionLabels[2]);

                this.returnCam = [{
                    idCamera: this.camera_id,
                    viewRecording: viewRecording,
                    receiveAnalyticAlert: receiveAnalyticAlert,
                    viewLive: viewLive,
                    viewAlertHistory: viewAlertHistory,
                }];

                this.store.dispatch(CameraActions.invite_user_camera({
                    user_sub: this.userSub,
                    child_sub: this.child_sub,
                    email: this.emailFormControl.value,
                    resend: false,
                    guestCam: this.returnCam
                }));
                this.loadingModalInvite = true;
                this.emailFormControl.reset();
                this.permission.reset();
                if (filterPermission) {
                    filterPermission.clearActiveList();
                }
            } else {
                this.validationResponse.validationResponseHandler(400, this.pageName, 'select-access-type', 'cameras.select_access_type');
                this.permission.markAllAsTouched();
            }
        }
        else {
            // showAlert('Informe um e-mail válido para convidar!', 'danger');
            this.validationResponse.validationResponseHandler(400, this.pageName, 'email-invalid', 'cameras.email_invalid');
            // this.emailFormControl.reset();
            // this.permission.reset();
        }
    }

    selectRow(row: GuestsList) {
        if (this.selection.isSelected(row)) {
            this.selection.clear();
        }
        else {
            this.selection.clear();
            this.selection.select(row);
        }
    }

    isSelected(row: GuestsList): boolean {
        return this.selection.isSelected(row);
    }

    updateInvite(guest, permissions) {
    // let receiveAnalyticAlert = guest['receive_analytic_alert'];
    // let viewAlertHistory = guest['view_alert_history'];
    // let viewRecording = guest['view_recording'];
    // let viewLive = guest['view_live'];

        // for (let item = 0; item < this.permissionsArrayForFormsEdition.length; item++) {
        //   if (this.permissionsArrayForFormsEdition[item][0] === 1) {
        //     viewRecording = this.permissionsArrayForFormsEdition[item][2];
        //   }
        //   else if (this.permissionsArrayForFormsEdition[item][0] === 2) {
        //     viewLive = this.permissionsArrayForFormsEdition[item][2];
        //   }
        //   else if (this.permissionsArrayForFormsEdition[item][0] === 0) {
        //     receiveAnalyticAlert = this.permissionsArrayForFormsEdition[item][2];
        //     viewAlertHistory = this.permissionsArrayForFormsEdition[item][2];
        //   }
        // }
        const result = {
            user_sub: this.userSub,
            child_sub: this.child_sub,
            camera_id: this.camera_id,
            email: guest['email'],
            viewRecording: permissions.includes('Recuperar Videos'),
            receiveAnalyticAlert: permissions.includes('Receber Alertas'),
            viewLive: permissions.includes('Visualizar câmeras ao vivo'),
            viewAlertHistory: permissions.includes('Receber Alertas'),
        };
        if (result.receiveAnalyticAlert == false && result.viewAlertHistory == false && result.viewLive == false && result.viewRecording == false) {
            this.validationResponse.validationResponseHandler(400, this.pageName, 'update-invite', 'cameras.update_invite');
            // showAlert('Usuário deve ter ao menos 1 permissão ativa. Para remover todas as permissões, exclua o convidado', 'danger');
        } else {
            this.store.dispatch(CameraActions.update_invite_user_camera(result));
            this.loadingModalInvite = true;
        }
    }

    onChange(event, guest, permission) {
        if (this.permissionsArrayForFormsEdition.length == 0) {
            this.permissionsArrayForFormsEdition.push([permission, guest, event['checked']]);
        }
        else {
            if (permission) {
                for (let a = 0; a < this.permissionsArrayForFormsEdition.length; a++) {
                    if (this.permissionsArrayForFormsEdition[a][0] === permission) {
                        this.permissionsArrayForFormsEdition.splice(a, 1);
                    }
                }
            }
            if (this.permissionsArrayForFormsEdition[0][1] !== guest) {
                this.permissionsArrayForFormsEdition = [];
            }
            else {
                this.permissionsArrayForFormsEdition.push([permission, guest, event['checked']]);
            }
        }
    }

    resetPermissionsForm(slct, guest, vr: MatCheckbox, vl: MatCheckbox, r: MatCheckbox) {
        r.checked = guest.receive_analytic_alert;
        vl.checked = guest.view_live;
        vr.checked = guest.view_recording;
        slct.toggle();
    }

    updateGuestsList() {
        this.subGuestsListUpdate = this.guests$.subscribe(
            guestsList => {
                if (Array.isArray(guestsList)) {
                    this.tableGuestsCam.data = this.sortGuests(guestsList);
                    this.loadingModalInvite = false;
                    if (guestsList.length > 0) {
                        this.permissionForm.setControl('permissionsFormsUser', this.builder.array([]));
                        const formArray = this.permissionForm.get('permissionsFormsUser') as UntypedFormArray;
                        let arrayBruto = [];
                        const arrayFiltrado = [];
                        this.returnedArrayForForms = [];
                        for (let index = 0; index < guestsList.length; index++) {
                            arrayBruto = [];
                            if (guestsList[index].receive_analytic_alert) {
                                arrayBruto.push(0);
                            }
                            else {
                                arrayBruto.push();
                            }
                            if (guestsList[index].view_live) {
                                arrayBruto.push(1);
                            }
                            else {
                                arrayBruto.push();
                            }
                            if (guestsList[index].view_recording) {
                                arrayBruto.push(2);
                            }
                            else {
                                arrayBruto.push();
                            }
                            arrayFiltrado.push(arrayBruto);
                        }
                        this.returnedArrayForForms.push(arrayFiltrado);
                        guestsList.forEach(guest => {
                            this.guestsList.push(guest);
                            this.returnValuesPermission = [guest.receive_analytic_alert, guest.view_alert_history, guest.view_live, guest.view_recording];
                            formArray.push(this.builder.control(this.returnValuesPermission));
                        });
                    }
                }
                else if (guestsList != null) {
                    this.validationResponse.validationResponseHandler(400, this.pageName, 'failure-list-guest', 'cameras.failure_list_guest');
                    this.tableGuestsCam.data = [];
                }
                else {
                    this.tableGuestsCam.data = [];
                }
            }
        );
    }


    returnPermissions() {
        const values = this.permissionLabels.map(v => new UntypedFormControl(false));
        return this.builder.array(values);
    }

    sortGuests(guests: GuestsList[]): GuestsList[] {
        return guests.sort((a, b) => {
            if (a.sub.includes('default sub')) {
                return -1;
            }
            else if (b.sub.includes('default sub')) {
                return 1;
            }
            else {
                return a.name > b.name ? 1 : -1;
            }
        });
    }

    deleteGuests() {
        this.validationResponse.validationResponseHandler(200, this.pageName, 'delete-guest', 'cameras.delete_guest');
        this.emailFormControl.reset();
    // this.permission.reset();
    }

    // Valida o retorno do Swal e o método do pai ao qual fez a chamada
    validateReturnMethodCalled(returnResultSwal: ResultAlert) {
        if (returnResultSwal.methodCalled === 'deleteGuest') {
            if (returnResultSwal.isConfirmed) {
                const listGuestId = [this.deleteGuestId];
                if (listGuestId.length > 0) {
                    this.store.dispatch(CameraActions.delete_guest({ camera_id: this.camera_id, guestList: listGuestId, user_sub: this.userSub, child_sub: this.child_sub}));
                    this.loadingModalInvite = true;
                    // this.selection.clear();
                }
                else {
                    this.validationResponse.validationResponseHandler(400, this.pageName, 'not-guest-delete', 'cameras.not_guest_delete');
                    // showAlert('Nenhum convidado foi excluído. Selecione ao menos um convidado para começar a excluir.', 'danger');
                }
            }
            else if (returnResultSwal.isDismissed) {
            }
        }
    }

    confirmDeleteGuest() {
        this.subGuestsListDelete = this.delGuest$.subscribe(delStatus => {
            if (delStatus == OperationStatus.Success) {
                this.updateInfoGuests(this.camera_id);
                this.validationResponse.validationResponseHandler(200, this.pageName, 'operation-success', 'cameras.operation_success');
                // showAlert('Operação realizada com sucesso!', 'success');
            }
            if (delStatus == OperationStatus.Fail) {
                this.validationResponse.validationResponseHandler(400, this.pageName, 'operation-failure', 'cameras.operation_failure');
                // showAlert('Erro na operação, tente novamente!', 'danger');
                this.loadingModalInvite = false;
            }
            this.store.dispatch(CameraActions.delete_guest_change_feedback({ payload: OperationStatus.Unloaded }));
        });
    }

    changePermissions(event) {
        this.permission.setValue(event);
    }

    submitPermissionChange(event, guest) {
        this.updateInvite(guest, event);
    }

    delete(guest) {
        this.deleteGuestId = guest.id;
        this.deleteGuests();
    }

    parseGuestPermissions(guest: GuestsList): String[] {
        const perms = [];
        if (guest.view_alert_history) {
            perms.push('Receber Alertas');
        }
        if (guest.view_live) {
            perms.push('Visualizar câmeras ao vivo');
        }
        if (guest.view_recording) {
            perms.push('Recuperar Videos');
        }

        return perms;
    }

    getCameras() {
        this.getCams = this.cameras$.subscribe(cameras => {
            if (cameras && cameras.model === 'cameras') {
                let cam: any = cameras.cameras as PaginationCameras;
                cam = cam.cameras;
                if (cam.length > 0) {
                    this.cameras = cam;
                    this.cameraIdList = Number(localStorage.getItem('cameraIdList'));
                    this.cameraName = this.cameras[this.cameraIdList].alias;
                } else {
                    this.cameras = cam;
                }
            }
        });
    }


    openModalCamGuests() {
        const modalContext: AddCamGuestData = {
            user_sub: this.userSub,
            child_sub: this.child_sub,
            camera_id: this.camera_id,
            email: this.emailFormControl.value,
            camera_name: this.cameras[this.cameraIdList].alias,
            receiveAnalyticAlert: this.permission.value.includes(this.permissionLabels[1]),
            viewLive: this.permission.value.includes(this.permissionLabels[2]),
            viewAlertHistory: this.permission.value.includes(this.permissionLabels[0]),
            viewRecording: this.permission.value.includes(this.permissionLabels[1])
        };
        const dataModal = {
            data: modalContext,
            modalHeight: '550px',
            modalWidth: '944px',
            backdropClass: 'lightBackdrop',
            tipoModal: 'adicionar_cameras_convidados'
        };
        this.service.returnDataToModal(dataModal);
    }

}

@Component({
    selector: 'app-camera-invite',
    templateUrl: './camera-invite.component.html',
    styleUrls: ['./camera-invite.component.scss']
})

export class CameraInviteComponent implements OnInit, OnDestroy {
    readonly modal_open$ = this.store.select((state: AppState) => state.camera.modal_open_invite_user);
    readonly cam_id$ = this.store.select((state: AppState) => state.camera.camera_id);
    readonly plan_max_invite$ = this.store.select((state: AppState) => state.camera.plan_max_invite);
    @Output() loadingCameraList: EventEmitter<boolean> = new EventEmitter;
    @Output() exitModal: EventEmitter<boolean> = new EventEmitter<boolean>();

    currentDialog: MatDialogRef<any, any> = null;
    destroy = new Subject<any>();
    subDialog: Subscription;
    subCombined: Subscription;
    constructor(public dialog: MatDialog, private readonly store: Store<AppState>) { }

    ngOnInit() {
        this.subCombined = combineLatest([this.modal_open$, this.cam_id$, this.plan_max_invite$]).subscribe(([modal, cam_id, plan_max_invite]) => {
            if (modal == ModalState.Open) {
                this.currentDialog = this.dialog.open(InviteModalComponent, {
                    panelClass: 'guest-dialog-container',
                    data: {
                        cam_id: cam_id,
                        plan_max_invite: plan_max_invite
                    }
                });
                this.subDialog = this.currentDialog.afterClosed().subscribe(() => {
                    this.store.dispatch(CameraActions.close_modal_invite_user_confirm());
                });
            } else if (modal == ModalState.Closed) {
                this.exitModal.emit(true);
            }
        });
    }

    ngOnDestroy() {
        this.destroy.next(true);
        if (this.subCombined) {
            this.subCombined.unsubscribe();
        }
        if (this.subDialog) {
            this.subDialog.unsubscribe();
        }
    }

}
