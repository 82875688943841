import { Store } from '@ngrx/store';
import { formatDate } from '@angular/common';
import { AfterViewChecked, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AnalyticCreateComponent } from 'app/analytics/analytic-create/analytic-create.component';
import { GroupAnalytics, ModalState } from 'app/analytics/models';
import { AnalyticActions } from 'app/analytics/Services/actions';
import { AnalyticTypes, Camera, GroupCamera, ListType, PaginationCameras } from 'app/cameras/models';
import { CameraActions } from 'app/cameras/Services/actions';
import { SharedService } from 'app/Shared/Services/shared.service';
import { AppState } from 'app/store/model';
import { Subscription } from 'rxjs';
import { AlertsTotal, PaginationDetections } from '../models';
import { DetectionActions } from '../Services/actions';

@Component({
    selector: 'app-base-detection',
    templateUrl: './base-detection.component.html',
    styleUrls: ['./base-detection.component.scss']
})
export class BaseDetectionComponent implements OnInit, OnDestroy, AfterViewChecked {
    readonly cameraAnalytics$ = this.store.select((state: AppState) => state.analytic.camera_analytics);
    readonly groupAnalytics$ = this.store.select((state: AppState) => state.analytic.groupAnalytics);
    readonly reviewStatus$ = this.store.select((state: AppState) => state.detection.reviewStatus);
    readonly detections$ = this.store.select((state: AppState) => state.detection.detections);
    readonly cameras$ = this.store.select((state: AppState) => state.camera.cameras);
    readonly camerasShared$ = this.store.select((state: AppState) => state.camera.cameraShared);
    readonly typesAnalytic$ = this.store.select((state: AppState) => state.camera.typesAnalytic);
    readonly groupCamera$ = this.store.select((state: AppState) => state.camera.groupCamera);
    readonly groupList$ = this.store.select((state: AppState) => state.camera.groupCamera);
    readonly groupCameraID$ = this.store.select((state: AppState) => state.camera.groupCameraID);

    groupAnalytic: GroupAnalytics[];
    currentDialog: MatDialogRef<any, any> = null;
    detectionsForm = new UntypedFormGroup({
        camForm: new UntypedFormControl(''),
        typeAlert: new UntypedFormControl(''),
        dataAlert: new UntypedFormControl('')
    });
    waiting: boolean;
    finished: boolean;
    loading_alert: boolean;
    no_alerts: boolean;
    typeSelected = [];
    timeActual: number;
    userSub: string;
    client_sub: string;

    // Subscriptions
    analyticSub: Subscription;
    progressBarSub: Subscription;
    getListCamera: Subscription;
    getListShared: Subscription;
    getCameraGroup: Subscription;
    getDetectionsCams: Subscription;
    GroupAnalyticSub: Subscription;
    getTypesAnalytic: Subscription;
    groupListSub: Subscription;

    paginationDetections: PaginationDetections;
    list_cameras: Camera[] = [];

    //player de video
    progressBar: string;

    types_analytic: AnalyticTypes[] = [];

    sum = 1;
    sumCam = 1;
    sumGroup = 1;
    camLimit = 50;

    actual: number;
    allPages = 0;
    totalAmount = 0;
    actualAmount: number;

    actualCam: number;
    allPagesCam = 0;
    totalAmountCam = 0;
    actualAmountCam: number;

    selectedGroup: number[] = [];
    actualGroup: number;
    allPagesGroup = 0;
    totalAmountGroup = 0;
    actualAmountGroup: number;

    alerts_by_date = [];
    camId = [];
    typeAlerts = [];
    dateAlert = null;
    actualPage: number;
    date: Date;
    userCams: Camera[];
    sharedCams: Camera[];
    cam: Camera;
    isDateSelected: boolean;
    filtred: boolean;
    loadingCam: boolean;
    scrHeight: number;
    scrWidth: number;
    reviewAlertId: number;
    sub: Subscription;
    totalResults: AlertsTotal[];
    dateActual: string;
    dateAmount: number;
    filterAlertId;
    isMobile: boolean;
    rightArrow = true;
    leftArrow = false;
    arrows: boolean;
    no_alerts_filter: boolean;
    firstAccess: boolean;
    camFilterList: {
        id: number;
        text: string;
    }[] = [];
    loading_modal: boolean;
    alertOpened: any;
    hasGroups: boolean;
    groups: GroupCamera[];
    groupFilterList: {
        id: number;
        text: string;
    }[] = [];
    @ViewChild('filterCam') filterCam;
    @ViewChild('filterGroup') filterGroup;
    debounceSearch: ReturnType<typeof setTimeout>;

    constructor(public dialog: MatDialog, private readonly store: Store<AppState>, private router: Router,
        private sharedService: SharedService) { }

    ngOnInit(): void {
        this.hasGroups = false;
        this.actualPage = 0;
        this.no_alerts_filter = false;
        this.isDateSelected = false;
        this.date = null;
        this.filtred = true;
        this.userSub = localStorage.getItem('sub');
        this.client_sub = localStorage.getItem('clientView');
        this.waiting = true;
        this.finished = true;
        this.loading_alert = false;
        this.firstAccess = true;
        this.no_alerts = false;
        this.loading_modal = false;
        this.getGroupAnalytics();
        this.store.dispatch(CameraActions.get_cameras({
            user_sub: this.userSub,
            child_sub: this.client_sub,
            page: this.sumCam,
            limit: this.camLimit,
            short: true
        }));
        this.store.dispatch(CameraActions.get_invited_cameras({
            user_sub: localStorage.getItem('sub'),
            list_type: ListType.Report
        }));
        this.store.dispatch(CameraActions.get_analytics_types({
            user_sub: this.userSub,
            myAnalytic: true,
            cameras: []
        }));
        this.store.dispatch(CameraActions.get_group_cameras({
            user_sub: this.userSub,
            child_sub: this.client_sub,
            page: this.sumGroup,
            limit: this.camLimit,
            type_request: 'cameras',
        }));
        if (localStorage.getItem('idCameraChoice')) {
            this.camId = [localStorage.getItem('idCameraChoice')];
        }
        this.getGroupCameraList();
        this.getListAlerts();
        this.getListCams();
        this.getTypesAnalyticsResult();
        this.getAnalyticsResult();
    }

    ngOnDestroy(): void {
        if (this.getListCamera) {
            this.getListCamera.unsubscribe();
        }
        if (this.getDetectionsCams) {
            this.getDetectionsCams.unsubscribe();
        }
        if (this.analyticSub) {
            this.analyticSub.unsubscribe();
        }
        if (this.progressBarSub) {
            this.progressBarSub.unsubscribe();
        }
        if (this.GroupAnalyticSub) {
            this.GroupAnalyticSub.unsubscribe();
        }
        if (this.getTypesAnalytic) {
            this.getTypesAnalytic.unsubscribe();
        }
        if (this.groupListSub) {
            this.groupListSub.unsubscribe();
        }
        if (this.getCameraGroup) {
            this.getCameraGroup.unsubscribe();
        }
        if (localStorage.getItem('idCameraChoice')) {
            localStorage.removeItem('idCameraChoice');
        }
        this.store.dispatch(DetectionActions.cleanup());
        this.store.dispatch(CameraActions.cleanup());
        this.store.dispatch(AnalyticActions.cleanup());
        this.dialog.closeAll();
    }

    ngAfterViewChecked() {
        this.renderArrows();
    }

    //Retorna os grupos de analiticos
    getGroupAnalytics() {
        this.GroupAnalyticSub = this.groupAnalytics$.subscribe(groups => {
            if (groups) {
                this.groupAnalytic = groups;
            } else {
                this.store.dispatch(AnalyticActions.get_analytics_group());
            }
        });
    }

    getTypesAnalytics() {
        this.store.dispatch(CameraActions.get_analytics_types({
            user_sub: this.userSub,
            myAnalytic: true,
            cameras: this.detectionsForm.get('camForm').valid ? this.camId : []
        }));
        this.getTypesAnalyticsResult();
    }

    getTypesAnalyticsResult() {
        this.getTypesAnalytic = this.typesAnalytic$.subscribe(result => {
            if (result) {
                this.types_analytic = result;
            }
        });
    }

    getListAlerts() {
        this.store.dispatch(DetectionActions.get_detections({
            userSub: this.userSub,
            child_sub: this.client_sub,
            page: this.sum,
            limit: 12,
            group_id: this.selectedGroup,
            list_cameras: this.camId ? this.camId : []
        }));
        this.waiting = true;
        this.finished = false;
        this.getListAlertsResult();
    }

    getListAlertsResult() {
        this.getDetectionsCams = this.detections$.subscribe(result => {
            if (result.model === 'detections') {
                this.paginationDetections = result.detections as PaginationDetections;
                this.totalResults = this.paginationDetections.alerts_amount;
                this.totalAmount = this.paginationDetections.total;
                this.actualAmount = this.paginationDetections.actual < this.paginationDetections.pages ? this.paginationDetections.limit * this.paginationDetections.actual : this.totalAmount;
                if (this.paginationDetections.alerts !== undefined) {
                    const displayObj = [];
                    if (this.actualPage != this.paginationDetections.actual) {
                        this.paginationDetections.alerts.map((date_alerts) => {
                            for (const date in date_alerts) {
                                if (date_alerts.hasOwnProperty(date)) {
                                    this.modifyDate(date);
                                    if (date == 'today') {
                                        displayObj.push({
                                            'date': 'Hoje',
                                            'alerts': date_alerts['today'],
                                            'total_result': this.dateAmount
                                        });
                                    } else {
                                        if (date == 'yesterday') {
                                            displayObj.push({
                                                'date': 'ONTEM',
                                                'alerts': date_alerts['yesterday'],
                                                'total_result': this.dateAmount
                                            });
                                        } else {
                                            const formatedDate = formatDate(date, 'dd/MM/yyyy', 'pt');
                                            const dic = {};
                                            dic[formatedDate] = date_alerts[date];
                                            displayObj.push({
                                                'date': formatedDate,
                                                'alerts': date_alerts[date],
                                                'total_result': this.dateAmount
                                            });
                                        }
                                    }
                                }
                            }
                        });
                        displayObj.map(alert => {
                            this.filterAlertId = this.alerts_by_date.findIndex(value => {
                                return value.date === alert.date;
                            });
                            if (this.filterAlertId < 0) {
                                this.alerts_by_date.push(alert);
                            } else {
                                this.alerts_by_date[this.filterAlertId].alerts.push(...alert.alerts);
                            }
                        });
                        this.waiting = false;
                        this.loading_alert = false;
                        this.finished = true;
                        this.actualPage = this.paginationDetections.actual;
                        this.validationAlerts();
                    }
                }
            }
        });
    }

    modifyDate(date: string) {
        this.totalResults.map(result => {
            if (date != this.dateActual) {
                this.dateActual = result.date;
                this.dateAmount = result.amount;
            }
        });
    }

    validationAlerts() {
        if (Object.keys(this.paginationDetections.alerts).length === 0) {
            if (Object.keys(this.camId).length === 0 && this.date === null && Object.keys(this.typeSelected).length === 0 &&
          this.firstAccess && this.selectedGroup.length === 0) {
                this.no_alerts = true;
            } else {
                this.no_alerts_filter = true;
            }
        } else {
            this.no_alerts_filter = false;
        }
    }

    getGroupCameraList() {
        this.store.dispatch(CameraActions.get_group_cameras({
            user_sub: this.userSub,
            child_sub: this.client_sub,
            type_request: 'list',
            active: true
        }));
        this.groupListSub = this.groupList$.subscribe(data => {
            if (data && 'length' in data) {
                data = data as GroupCamera[];
                this.hasGroups = true;
                this.groupFilterList = data.map(c => {
                    return {
                        id: c.id_camera_group,
                        text: c.alias
                    };
                });
                this.hasGroups = this.groupFilterList.length > 0;
            } else {
                this.hasGroups = false;
            }
        });
    }

    getListCams() {
        this.getCameraGroup = this.groupCamera$.subscribe(groupCamera => {
            if (groupCamera && 'cameras' in groupCamera && groupCamera.cameras) {
                if (groupCamera.cameras.length > 0) {
                    this.totalAmountGroup = groupCamera.total;
                    this.allPagesGroup = groupCamera.pages;
                    this.actualGroup = groupCamera.actual;
                    this.actualAmountGroup = this.actualGroup < this.allPagesGroup ? this.camLimit * this.actualGroup : this.totalAmountGroup;
                    this.getUniqueCameras(groupCamera.cameras);
                }
            }
        });

        this.getListShared = this.camerasShared$.subscribe(sharedCam => {
            if (sharedCam && sharedCam.length > 0) {
                this.sharedCams = sharedCam;
                this.getUniqueCameras(sharedCam);
            }
        });

        this.getListCamera = this.cameras$.subscribe(cameras => {
            this.waiting = true;
            this.loadingCam = false;
            if (cameras && cameras.model === 'cameras') {
                const cam: any = cameras.cameras as PaginationCameras;
                this.totalAmountCam = cam.total;
                this.allPagesCam = cam.pages;
                this.actualCam = cam.actual;
                this.camLimit = cam.limit;
                this.userCams = cam.cameras;
                if (cam.cameras.length > 0) {
                    this.getUniqueCameras(cam.cameras);
                }
            }

            if (this.camId && this.list_cameras.length > 0) {
                const returnCamera = this.list_cameras.find(camera => camera.id == this.camId[0]);
                this.detectionsForm.get('camForm').setValue([returnCamera]);
            }
            this.waiting = false;
        });
    }

    getUniqueCameras(cameras: Camera[]): Camera[] {
        if (cameras) {
            // const uniqueList = [];
            const idOrdainedList = cameras.sort((a, b) => {
                return a.id - b.id;
            });

            idOrdainedList.forEach((current, index, list) => {
                if (current) {
                    if (!this.list_cameras) {
                        this.list_cameras = [current];
                    } else if (this.list_cameras.length > 0) {
                        const camExist = this.list_cameras.find(x => x.id === current.id || x.hashname === current.hashname);
                        if (!camExist) {
                            this.camFilterList.push({id: current.id, text: current.alias});
                            this.list_cameras.push(current);
                        }
                    } else {
                        this.camFilterList.push({id: current.id, text: current.alias});
                        this.list_cameras.push(current);
                    }
                }
            });

            return this.list_cameras;
        }
        return [];
    }

    returnFilter() {
        this.filterCam.unmarkAll();
        this.filterGroup.unmarkAll();
        this.firstAccess = false;
        this.camId = [];
        this.alerts_by_date = [];
        this.dateActual = null;
        this.sum = 1;
        this.detectionsForm.get('dataAlert').setValue(null);
        const tag = document.getElementsByClassName('active-alert');
        if (tag.length !== 0) {
            Array.from(tag).forEach((element) => {
                const item = document.getElementById(element.id);
                item.classList.remove('active-alert');
            });
        }
        this.typeSelected = [];
        // this.detectionsForm.reset();
        this.getListAlerts();
        if (this.actualPage == 1) {
            this.actualPage = 0;
        }
        this.getTypesAnalytics();
        this.totalAmount = null;
        this.actualAmount = null;
        this.date = null;
        this.isDateSelected = false;
        this.finished = false;
        this.filtred = true;
    }

    cameraSelection() {
        clearTimeout(this.debounceSearch);
        this.sum = 1;
        this.alerts_by_date = [];
        this.dateActual = null;
        this.actualPage = 0;
        this.firstAccess = false;
        this.totalAmount = 0;
        this.actualAmount = 0;
        this.filtred = false;
        this.finished = false;
        this.debounceSearch = setTimeout(() => {
            this.getTypesAnalytics();
            this.store.dispatch(DetectionActions.get_detections({
                userSub: this.userSub,
                child_sub: this.client_sub,
                page: this.sum,
                limit: 12,
                group_id: this.selectedGroup,
                list_cameras: this.camId,
                current_data: this.date ? formatDate(this.date, 'yyyy-MM-dd', 'pt') : null,
                analytics_type: this.detectionsForm.get('typeAlert').valid ? this.detectionsForm.get('typeAlert').value : []
            }));
            setInterval(() => {
                this.finished = true;
            }, 5700);
        }, 600);
    }

    typeSelection() {
        this.sum = 1;
        this.alerts_by_date = [];
        this.dateActual = null;
        this.actualPage = 0;
        this.firstAccess = false;
        this.totalAmount = 0;
        this.actualAmount = 0;
        this.filtred = false;
        this.finished = false;
        this.store.dispatch(DetectionActions.get_detections({
            userSub: this.userSub,
            child_sub: this.client_sub,
            page: this.sum,
            limit: 12,
            group_id: this.selectedGroup,
            list_cameras: this.camId,
            current_data: this.date ? formatDate(this.date, 'yyyy-MM-dd', 'pt') : null,
            analytics_type: this.detectionsForm.get('typeAlert').valid ? this.detectionsForm.get('typeAlert').value : []
        }));
        setInterval(() => {
            this.finished = true;
        }, 5700);
    }

    dataSelection(event: MatDatepickerInputEvent<Date>) {
        clearTimeout(this.debounceSearch);
        this.filtred = false;
        this.sum = 1;
        this.alerts_by_date = [];
        this.dateActual = null;
        this.actualPage = 0;
        this.firstAccess = false;
        this.totalAmount = 0;
        this.actualAmount = 0;
        this.finished = false;
        this.detectionsForm.get('dataAlert').setValue(event.value);
        this.isDateSelected = true;
        this.date = event.value;
        this.dateAlert = formatDate(this.date, 'yyyy-MM-dd', 'pt');
        this.debounceSearch = setTimeout(() => {
            this.store.dispatch(DetectionActions.get_detections({
                userSub: this.userSub,
                child_sub: this.client_sub,
                page: this.sum,
                limit: 12,
                group_id: this.selectedGroup,
                list_cameras: this.camId,
                current_data: this.detectionsForm.get('dataAlert').valid ? formatDate(this.date, 'yyyy-MM-dd', 'pt') : null,
                analytics_type: this.detectionsForm.get('typeAlert').valid ? this.detectionsForm.get('typeAlert').value : []
            }));
            setInterval(() => {
                this.finished = true;
            }, 5700);
        }, 600);
    }

    selectType(event) {
        clearTimeout(this.debounceSearch);
        this.filtred = false;
        this.sum = 1;
        this.alerts_by_date = [];
        this.dateActual = null;
        this.actualPage = 0;
        const tag = document.getElementById(event);
        if (tag) {
            if (tag.classList.contains('active-alert')) {
                tag.classList.remove('active-alert');
                this.typeSelected.forEach((analytic, index) => {
                    if (analytic == event) {
                        this.typeSelected.splice(index, 1);
                    }
                });
            }
            else {
                tag.classList.add('active-alert');
                this.typeSelected.push(event);
                this.detectionsForm.get('typeAlert').setValue(this.typeSelected);
            }
        }
        if (this.typeSelected.length === 0) {
            this.filtred = true;
        }
        this.finished = false;
        this.debounceSearch = setTimeout(() => {
            this.store.dispatch(DetectionActions.get_detections({
                userSub: this.userSub,
                child_sub: this.client_sub,
                page: this.sum,
                limit: 12,
                group_id: this.selectedGroup,
                list_cameras: this.camId ? this.camId : [],
                current_data: this.date ? formatDate(this.date, 'yyyy-MM-dd', 'pt') : null,
                analytics_type: this.detectionsForm.get('typeAlert').valid ? this.detectionsForm.get('typeAlert').value : []
            }));
            setInterval(() => {
                this.finished = true;
            }, 5700);
        }, 600);
    }

    onScroll() {
        if (this.sum < this.paginationDetections.pages && !this.loading_alert) {
            this.loading_alert = true;
            this.store.dispatch(DetectionActions.get_detections({
                userSub: this.userSub,
                child_sub: this.client_sub,
                page: this.paginationDetections.actual < this.paginationDetections.pages ? this.sum += 1 : this.sum,
                limit: 12,
                group_id: this.selectedGroup,
                list_cameras: this.camId ? this.camId : [],
                analytics_type: this.detectionsForm.get('typeAlert').valid ? this.detectionsForm.get('typeAlert').value : [],
                current_data: this.date ? formatDate(this.date, 'yyyy-MM-dd', 'pt') : null
            }));
            setInterval(() => {
                this.loading_alert = false;
            }, 5000);
        }
    }

    // function para setar o progresso na progressBar
    progressLoop(progressBar: Element, video: HTMLVideoElement) {
        setInterval(() => {
            progressBar.setAttribute('value', Math.round((video.currentTime / video.duration) * 100).toString());
        }, 10);
    }

    //Method ajusta os players de videos e dá play
    playVideo(alertId) {
        const videoCard = document.getElementById(alertId);
        if (videoCard) {
            const video = <HTMLVideoElement>videoCard.children[0];
            const progressBar = videoCard.children[1];
            const playIcon = <HTMLElement>videoCard.children[3];

            this.progressLoop(progressBar, video);
            if (video.paused) {
                video.play();
                playIcon.style.visibility = 'hidden';
                playIcon.style.opacity = '0';
                playIcon.style.transition = 'visibility 0s 0.5s, opacity 0.5s linear';
            } else {
                video.pause();
                playIcon.style.visibility = 'visible';
                playIcon.style.opacity = '1';
                playIcon.style.transition = 'opacity 0.5s linear';
            }
        }
    }

    getClassifiersList(classifiers) {
        return classifiers ? classifiers.split(',') : [];
    }

    //Os dois methods abaixo são para buscar o analítico do alerta selecionado
    getAnalytics(alert) {
        if (!this.loading_modal && this.groupAnalytic) {
            this.loading_modal = true;
            this.alertOpened = alert;
            this.cam = this.userCams.find(cam => cam.id == alert.id_camera);
            this.store.dispatch(AnalyticActions.get_analytics_camera({
                camera_id: alert.id_camera
            }));
        }
    }

    getAnalyticsResult() {
        this.analyticSub = this.cameraAnalytics$.subscribe(analytics => {
            if (analytics && this.loading_modal) {
                this.loading_modal = false;
                const currentAnalytic = analytics[0].analytics.find(analytic => analytic.id_analytic == this.alertOpened.analytic_id);
                this.store.dispatch(AnalyticActions.update_modal_state({ payload: ModalState.Edit }));
                this.store.dispatch(AnalyticActions.put_modal_data({
                    payload: {
                        analytic: currentAnalytic,
                        groupId: currentAnalytic.id_group,
                        groups: this.groupAnalytic
                    }
                }));
                this.openSettingsModal();
            }
        });
    }

    //Mêtodo para ativar o fullscreen
    playFullscreen(alert) {
        const videoCard = document.getElementById(alert.id);
        const video = <HTMLVideoElement>videoCard.children[0];
        const playIcon = <HTMLElement>videoCard.children[2];
        video.pause();
        playIcon.style.visibility = 'visible';
        playIcon.style.opacity = '1';
        playIcon.style.transition = 'opacity 0.5s linear';
        this.timeActual = video.currentTime;
        const dataModal = {
            data: {
                alert,
                timeActual: this.timeActual
            },
            tipoModal: 'video_alertas',
            modalHeight: 'fit-content',
            modalWidth: '80%',
            backdropClass: 'backdropDark'
        };
        this.sharedService.returnDataToModal(dataModal);
    }

    //Abre o modal de edição de analítico
    openSettingsModal() {
        this.dialog.closeAll();
        this.currentDialog = this.dialog.open(AnalyticCreateComponent, {
            panelClass: 'create-modalbox-analytics',
            width: '91vw',
            height: 'fit-content',
            maxHeight: '92vh',
            disableClose: true,
            data: {
                camera: this.cam,
            }
        });
        this.currentDialog.afterClosed().subscribe(() => {
            this.store.dispatch(AnalyticActions.cleanup_camera_analytics());
        });
    }

    //Retorna a imagem do user dono da câm
    getImage(alert, imgDiv) {
        const imageDiv = document.getElementById(imgDiv);
        if (this.sharedCams) {
            const sharedCam = this.sharedCams.find(cam => cam.id == alert.id_camera);
            if (sharedCam) {
                imageDiv.style.display = 'block';
                return sharedCam.user_picture;
            } else {
                imageDiv.style.display = 'none';
            }
        } else {
            imageDiv.style.display = 'none';
        }
    }

    isSettingsEnable(alert) {
        if (this.userCams && alert.link_video && alert.analytic_id) {
            const userCam = this.userCams.find(cam => cam.id == alert.id_camera);
            if (userCam ) {
                return true;
            }
        }
    }

    // Gerencia quantidade de videos dependendo do tamanho da tela
    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.scrWidth = window.innerWidth;
        if (this.scrWidth < 599) {
            return 'mobile-card';
        }
        else if (this.scrWidth < 959) {
            return 'tablet-card';
        } else if (this.scrWidth < 1920) {
            return 'col-4';
        }
        return 'col-3';
    }
    changeCameraName(alert) {
        let newName = null;
        if (alert.camera_name.length > 19) {
            newName = alert.camera_name.substring(0, 16) + '...';
        }
        else {
            newName = alert.camera_name;
        }
        return newName;
    }
    onResize() {
        const son = document.getElementById('son-container');
        if (son) {
            son.style.left = '0px';
            this.renderArrows();
        }
    }

    renderArrows() {
        if (document.getElementsByClassName('types-container')[0] != undefined) {
            const fatherSize = document.getElementsByClassName('types-container')[0].clientWidth;
            const son = document.getElementById('son-container');
            const sonSize = son.clientWidth;
            const left = parseInt(son.style.left, 10);

            if (sonSize > fatherSize) {
                this.arrows = true;
                if (left < 0) {
                    this.leftArrow = true;
                } else {
                    this.leftArrow = false;
                }
                if ((left * -1) + fatherSize >= sonSize) {
                    this.rightArrow = false;
                } else {
                    this.rightArrow = true;
                }
            } else {
                this.arrows = false;
                this.leftArrow = false;
                this.rightArrow = false;
            }
        }
    }

    updateCamId(event) {
        this.camId = event;
        this.cameraSelection();
    }

    updateGroupId(event) {
        clearTimeout(this.debounceSearch);
        this.sum = 1;
        this.actualPage = 0;
        this.alerts_by_date = [];
        this.firstAccess = false;
        this.totalAmount = 0;
        this.actualAmount = 0;
        this.filtred = false;
        this.finished = false;
        this.selectedGroup = event;
        this.getTypesAnalytics();
        this.debounceSearch = setTimeout(() => {
            this.store.dispatch(DetectionActions.get_detections({
                userSub: this.userSub,
                child_sub: this.client_sub,
                page: this.sum,
                limit: 12,
                list_cameras: this.camId ? this.camId : [],
                group_id: this.selectedGroup,
                analytics_type: this.detectionsForm.get('typeAlert').valid ? this.detectionsForm.get('typeAlert').value : [],
                current_data: this.date ? formatDate(this.date, 'yyyy-MM-dd', 'pt') : null
            }));
        }, 600);
    }

    onScrollCam(event) {
        if (!this.loadingCam && event) {
            this.loadingCam = true;
            if (this.sumGroup <= this.allPagesGroup) {
                this.sumGroup = this.actualGroup <= this.allPagesGroup ? this.sumGroup += 1 : this.sumGroup;
                this.store.dispatch(CameraActions.get_group_cameras({
                    user_sub: this.userSub,
                    child_sub: this.client_sub,
                    type_request: 'cameras',
                    page: this.sumGroup,
                    limit: this.camLimit,
                    active: true
                }));
            }
            if (this.sumCam <= this.allPagesCam) {
                this.sumCam = this.actualCam <= this.allPagesCam ? this.sumCam += 1 : this.sumCam;
                this.store.dispatch(CameraActions.get_cameras({
                    user_sub: this.userSub,
                    child_sub: this.client_sub,
                    page: this.sumCam,
                    limit: this.camLimit,
                    short: true
                }));
            }

            setInterval(() => {
                this.loadingCam = false;
            }, 15000);
        }
    }

    getCriticalityInfo(criticality: number) {
        switch (criticality) {
            case 1:
                return { class: 'low', alias: 'Baixa' };
            case 2:
                return { class: 'medium', alias: 'Média' };
            case 3:
                return { class: 'high', alias: 'Alta' };
            default:
                return { class: '', alias: '' };
        }
    }

}
