import { Injectable } from '@angular/core';
import { FluxStandardAction } from 'flux-standard-action';
import { Cameras, Camera, Plan, Mosaic, Video, ListType, GuestsList, HealthCheck, HealthPanel, Address, CheckResolution, PaginationCameras,
    CameraThumbnail, AnalyticTypes, RTMPUri, GroupCamera, ClientsToGroup, CreateGroup, CamerasReport, SortColumnCamera, BlockedCamerasReport,
    GroupCamerasReport, ProviderIntegratedCameras, CamerasByLocation} from '../models';
import { Feedback } from 'app/Shared/models';
import {createAction, props} from '@ngrx/store';

type Payload =  Cameras | Camera | Video[] | ClientsToGroup | number | boolean | Camera[] | Address[] | Plan[] | string | Feedback | any | GuestsList[] | Mosaic[] | ProviderIntegratedCameras[] | CamerasByLocation[];

export interface Metadata {
    cameras?: Camera[];
    camera?: Camera;
    address?: Address;
    check_resolution?: CheckResolution;
    camera_thumbnail?: CameraThumbnail;
    camera_id?: number;
    camera_hash?: string;
    associateId?: number;
    email?: string;
    user_sub?: string;
    child_sub?: string;
    timeBegin?: string;
    timeEnd?: string;
    newName?: string;
    newPlan?: number;
    guestList?: any[];
    mosaic?: Mosaic;
    idMosaic?: number;
    previewCam?: string;
    countRTMP?: number;
    status?: boolean;
    viewRecording?: boolean;
    receiveAnalyticAlert?: boolean;
    viewLive?: boolean;
    viewAlertHistory?: boolean;
    index?: number;
    list_type?: ListType;
    page?: number;
    urlSigned?: string;
    myAnalytic?: boolean;
    resend?: boolean;
    create_off?: boolean;
    group_id?: number;
    type_request?: 'list'|'data'|'cameras';
    groups_camera?: Camera[] | GroupCamera[];
    openViewGroup?: boolean;
    createCamGroup?: CreateGroup;
    nameCameraGroup?: string;
    listDeleteCameras?: number[];
    listDeleteClients?: number[];
    listDeleteAssociates?: number[];
    listAddCameras?: number[];
    listAddClients?: number[];
    listAddAssociates?: number[];
    str_filter?: string;
    cam_stat_filter?: boolean;
    limit?: number;
    withAlert?: boolean;
    refresh?: boolean;
    clients_id?: number[];
    guestCam?: string[];
    short?: boolean;
    active?: boolean;
    sort_by?: SortColumnCamera[];
    protocol?: string;
    boxes?: boolean;
    providerIntegratedCameras?: ProviderIntegratedCameras[];
    camerasByLocation?: CamerasByLocation[];
    lat?: number;
    lng?: number;
    radius?: number;
    id_user?: number[];
    text?: string;
}

export type CameraAction = FluxStandardAction<Payload, Metadata>;

@Injectable()
export class CameraActions {
    //Ações de cameras genéricas
    static readonly CREATE_CAMERA = 'CREATE_CAMERA';
    static readonly CREATE_CAMERA_STATUS = 'CREATE_CAMERA_STATUS';
    static readonly UPDATE_CAMERA_STATUS = 'UPDATE_CAMERA_STATUS';
    static readonly CLEANUP_POST = 'CLEANUP_POST';
    static readonly OPEN_MODAL_UPDATE_CAMERA = 'OPEN_MODAL_UPDATE_CAMERA';
    static readonly CLOSE_MODAL_UPDATE_CAMERA_CONFIRM = 'CLOSE_MODAL_UPDATE_CAMERA_CONFIRM';
    static readonly CLOSE_MODAL_UPDATE_CAMERA_UPDATE = 'CLOSE_MODAL_UPDATE_CAMERA_UPDATE';
    static readonly CLOSE_MODAL_UPDATE_CAMERA_CANCEL = 'CLOSE_MODAL_UPDATE_CAMERA_CANCEL';
    static readonly PUT_ID_INVITE_USER = 'PUT_ID_INVITE_USER';
    static readonly PUT_PLAN_MAX_INVITE_USER = 'PUT_ID_PLAN_INVITE_USER';
    static readonly PUT_CAMERA_UPDATE = 'PUT_CAMERA_UPDATE';
    static readonly PUT_LIST_CAMERA_ADDRESS = 'PUT_LIST_CAMERA_ADDRESS';
    static readonly PUT_VIEW_CAMERA = 'PUT_VIEW_CAMERA';
    static readonly GET_CAMERAS = 'GET_CAMERAS';
    static readonly GET_CAMERAS_RETURN = 'GET_CAMERAS_RETURN';
    static readonly GET_INVITED_CAMERAS = 'GET_INVITED_CAMERAS';
    static readonly GET_INVITED_CAMERAS_RETURN = 'GET_INVITED_CAMERAS_RETURN';
    static readonly GET_INVITED_CAMERAS_CLEAR = 'GET_INVITED_CAMERAS_CLEAR';
    static readonly GET_CAMERAS_CHANGE_RETURN = 'GET_CAMERAS_CHANGE_RETURN';
    static readonly GET_PLANS = 'GET_PLANS';
    static readonly GET_PLANS_RETURN = 'GET_PLANS_RETURN';
    static readonly GET_PLANS_CLEANUP = 'GET_PLANS_CLEANUP';
    static readonly GET_ANALYTICS_TYPES = 'GET_ANALYTICS_TYPES';
    static readonly GET_ANALYTICS_TYPES_RETURN = 'GET_ANALYTICS_TYPES_RETURN';
    static readonly UPDATE_CAMERA_INFOR = 'UPDATE_CAMERA_INFOR';
    static readonly UPDATE_CAMERA_INFOR_RETURN = 'UPDATE_CAMERA_INFOR_CAMERA_RETURN';
    static readonly UPDATE_CAMERA_INFOR_CHANGE_RETURN = 'UPDATE_CAMERA_INFOR_CHANGE_RETURN';
    static readonly CLEANUP = 'CLEANUP';
    static readonly DELETE_CAMERA = 'DELETE_CAMERA';
    static readonly DELETE_CAMERA_SUCCESS = 'DELETE_CAMERA_SUCCESS';
    static readonly GET_VIDEOS = 'GET_VIDEOS';
    static readonly GET_VIDEOS_SUCCESS = 'GET_VIDEOS_SUCCESS';
    static readonly GET_VIDEOS_CLEANUP = 'GET_VIDEOS_CLEANUP';
    static readonly RESETTING_VIDEOS = 'RESETTING_VIDEOS';
    static readonly DELETE_CAMERA_FEEDBACK = 'DELETE_CAMERA_FEEDBACK';
    static readonly DELETE_CAMERA_CHANGE_FEEDBACK = 'DELETE_CAMERA_CHANGE_FEEDBACK';
    static readonly UPDATE_CAMERA = 'UPDATE_CAMERA';
    static readonly UPDATE_CAMERA_FEEDBACK = 'UPDATE_CAMERA_FEEDBACK';
    static readonly UPDATE_CAMERA_CHANGE_FEEDBACK = 'UPDATE_CAMERA_CHANGE_FEEDBACK';
    static readonly UPDATE_STATUS_CAMERA = 'UPDATE_STATUS_CAMERA';
    static readonly UPDATE_STATUS_CAMERA_FEEDBACK = 'UPDATE_STATUS_CAMERA_FEEDBACK';
    static readonly UPDATE_STATUS_CAMERA_CHANGE_FEEDBACK = 'UPDATE_STATUS_CAMERA_CHANGE_FEEDBACK';
    static readonly GET_PREVIEW_CAMERA = 'GET_PREVIEW_CAMERA';
    static readonly GET_PREVIEW_CAMERA_RETURN = 'GET_PREVIEW_CAMERA_RETURN';
    static readonly RESET_PREVIEW = 'RESET_PREVIEW';
    static readonly PUT_ANALYTIC_CAMERA = 'PUT_ANALYTIC_CAMERA';
    static readonly CLEAR_ANALYTIC_CAMERA = 'CLEAR_ANALYTIC_CAMERA';
    static readonly GET_HEALTHCHECK = 'GET_HEALTHCHECK';
    static readonly GET_HEALTHCHECK_RETURN = 'GET_HEALTHCHECK_RETURN';
    static readonly GET_HEALTHCHECK_CLEANUP = 'GET_';
    static readonly GET_HEALTH_HISTORY = 'GET_HEALTH_HISTORY';
    static readonly GET_HEALTH_HISTORY_RETURN = 'GET_HEALTH_HISTORY_RETURN';
    static readonly GET_HEALTH_HISTORY_FILTER = 'GET_HEALTH_HISTORY_FILTER';
    static readonly GET_HEALTH_HISTORY_FILTER_RETURN = 'GET_HEALTH_HISTORY_FILTER_RETURN';
    static readonly CLEAR_HISTORY = 'CLEAR_HISTORY';
    static readonly GET_LIST_ADDRESS = 'GET_LIST_ADDRESS';
    static readonly GET_LIST_ADDRESS_RETURN = 'GET_LIST_ADDRESS_RETURN';
    static readonly CHECK_CAMERA_RESOLUTION = 'CHECK_CAMERA_RESOLUTION';
    static readonly CHECK_CAMERA_RESOLUTION_RETURN = 'CHECK_CAMERA_RESOLUTION_RETURN';
    static readonly CLEAR_CAMERA_RESOLUTION = 'CLEAR_CAMERA_RESOLUTION';
    static readonly CREATE_CAMERA_THUMBNAIL = 'CREATE_CAMERA_THUMBNAIL';
    static readonly CREATE_CAMERA_THUMBNAIL_RETURN = 'CREATE_CAMERA_THUMBNAIL_RETURN';
    static readonly CREATE_CAMERA_THUMBNAIL_CLEANUP = 'CREATE_CAMERA_THUMBNAIL_CLEANUP';
    static readonly CLEAR_CREATE_CAMERA_THUMBNAIL = 'CLEAR_CREATE_CAMERA_THUMBNAIL';
    static readonly GET_URL_SIGNED_VIDEO = 'GET_URL_SIGNED_VIDEO';
    static readonly GET_URL_SIGNED_VIDEO_RETURN = 'GET_URL_SIGNED_VIDEO_RETURN';
    static readonly GET_URL_SIGNED_VIDEO_CLEAR_RETURN = 'GET_URL_SIGNED_VIDEO_CLEAR_RETURN';
    static readonly GET_RTMP_GENERATED_URL = 'GET_RTMP_GENERATED_URL';
    static readonly GET_RTMP_GENERATED_URL_RETURN = 'GET_RTMP_GENERATED_URL_RETURN';
    static readonly CLEAR_RTMP_GENERATED_URL = 'GET_RTMP_GENERATED_URL_RETURN';
    static readonly GET_CAMERAS_REPORT = 'GET_CAMERAS_REPORT';
    static readonly GET_CAMERAS_REPORT_RETURN = 'GET_CAMERAS_REPORT_RETURN';
    static readonly GET_CAMERAS_REPORT_CLEAR = 'GET_CAMERAS_REPORT_CLEAR';
    static readonly GET_BLOCKED_CAMERAS_REPORT = 'GET_BLOCKED_CAMERAS_REPORT';
    static readonly GET_BLOCKED_CAMERAS_REPORT_RETURN = 'GET_BLOCKED_CAMERAS_REPORT_RETURN';
    static readonly GET_BLOCKED_CAMERAS_REPORT_CLEAR = 'GET_BLOCKED_CAMERAS_REPORT_CLEAR';
    static readonly GET_PROVIDER_INTEGRATED_CAMERAS = 'GET_PROVIDER_INTEGRATED_CAMERAS';
    static readonly GET_PROVIDER_INTEGRATED_CAMERAS_RETURN = 'GET_PROVIDER_INTEGRATED_CAMERAS_RETURN';
    static readonly GET_PROVIDER_INTEGRATED_CAMERAS_CLEAR = 'GET_PROVIDER_INTEGRATED_CAMERAS_CLEAR';
    static readonly GET_CAMERAS_BY_LOCATION = 'GET_CAMERAS_BY_LOCATION';
    static readonly GET_CAMERAS_BY_LOCATION_RETURN = 'GET_CAMERAS_BY_LOCATION_RETURN';
    static readonly GET_CAMERAS_BY_LOCATION_CLEAR = 'GET_CAMERAS_BY_LOCATION_CLEAR';



    //Ações de convidado
    static readonly PUT_GUESTS = 'PUT_GUESTS';
    static readonly GET_GUESTS = 'GET_GUESTS';
    static readonly GET_GUESTS_RETURN = 'GET_GUESTS_RETURN';
    static readonly DELETE_GUEST = 'DELETE_GUEST';
    static readonly DELETE_GUEST_FEEDBACK = 'DELETE_GUEST_FEEDBACK';
    static readonly DELETE_GUEST_CAMERA = 'DELETE_GUEST_CAMERA';
    static readonly DELETE_GUEST_CHANGE_FEEDBACK = 'DELETE_GUEST_CHANGE_FEEDBACK';
    static readonly INVITE_USER_CAMERA = 'INVITE_USER_CAMERA';
    static readonly INVITE_USER_CAMERA_RETURN = 'INVITE_USER_CAMERA_RETURN';
    static readonly INVITE_USER_CAMERA_CHANGE_RETURN = 'INVITE_USER_CAMERA_CHANGE_RETURN';
    static readonly UPDATE_INVITE_USER_CAMERA = 'UPDATE_INVITE_USER_CAMERA';
    static readonly UPDATE_INVITE_USER_CAMERA_RETURN = 'UPDATE_INVITE_USER_CAMERA_RETURN';
    static readonly UPDATE_INVITE_USER_CAMERA_CHANGE_RETURN = 'UPDATE_INVITE_USER_CAMERA_CHANGE_RETURN';
    static readonly OPEN_MODAL_INVITE_USER = 'OPEN_MODAL_INVITE_USER';
    static readonly CLOSE_MODAL_INVITE_USER_CONFIRM = 'CLOSE_MODAL_INVITE_USER_CONFIRM';
    static readonly CLOSE_MODAL_INVITE_USER_UPDATE = 'CLOSE_MODAL_INVITE_USER_UPDATE';
    static readonly CLOSE_MODAL_INVITE_USER_CANCEL = 'CLOSE_MODAL_INVITE_USER_CANCEL';
    static readonly CLOSE_MODAL_ADD_CAM_GUEST = 'CLOSE_MODAL_ADD_CAM_GUEST';

    //Ações de mosaico
    static readonly GET_MOSAICS = 'GET_MOSAICS';
    static readonly GET_MOSAICS_RETURN = 'GET_MOSAICS_RETURN';
    static readonly GET_USER_MOSAICS = 'GET_USER_MOSAICS';
    static readonly GET_USER_MOSAICS_RETURN = 'GET_USER_MOSAICs_RETURN';
    static readonly SAVE_MOSAIC = 'SAVE_MOSAIC';
    static readonly SAVE_MOSAIC_RETURN = 'SAVE_MOSAIC_RETURN';
    static readonly EDIT_MOSAIC = 'EDIT_MOSAIC';
    static readonly EDIT_MOSAIC_RETURN = 'EDIT_MOSAIC_RETURN';
    static readonly DELETE_MOSAIC = 'DELETE_MOSAIC';
    static readonly DELETE_MOSAIC_RETURN = 'DELETE_MOSAIC_RETURN';
    static readonly RESET_RETURN = 'RESET_RETURN';
    static readonly OPEN_MODAL_MOSAIC = 'OPEN_MODAL_MOSAIC';
    static readonly OPEN_EDIT_MODAL_MOSAIC = 'OPEN_EDIT_MODAL_MOSAIC';
    static readonly CLOSE_MODAL_MOSAIC = 'CLOSE_MODAL_MOSAIC';
    static readonly CANCEL_MODAL_MOSAIC = 'CANCEL_MODAL_MOSAIC';

    // Ações de lista de Endereços - Inserção de câmeras
    static readonly OPEN_MODAL_LIST_CAMERA_ADDRESS = 'OPEN_MODAL_LIST_CAMERA_ADDRESS';
    static readonly CLOSE_MODAL_LIST_CAMERA_ADDRESS_CONFIRM = 'CLOSE_MODAL_LIST_CAMERA_ADDRESS_CONFIRM';
    static readonly CLOSE_MODAL_LIST_CAMERA_ADDRESS_UPDATE = 'CLOSE_MODAL_LIST_CAMERA_ADDRESS_UPDATE';
    static readonly CLOSE_MODAL_LIST_CAMERA_ADDRESS_CANCEL = 'CLOSE_MODAL_LIST_CAMERA_ADDRESS_CANCEL';

    // Ações de grupos
    static readonly OPEN_VIEW_GROUP = 'OPEN_VIEW_GROUP';
    static readonly OPEN_VIEW_GROUP_CLEAR = 'OPEN_VIEW_GROUP_CLEAR';
    static readonly GET_CLIENTS_GROUP = 'GET_CLIENTS_GROUP';
    static readonly GET_CLIENTS_GROUP_RETURN = 'GET_CLIENTS_GROUP_RETURN';
    static readonly GET_CLIENTS_GROUP_CLEAR = 'GET_CLIENTS_GROUP_CLEAR';
    static readonly CREATE_GROUP = 'CREATE_GROUP';
    static readonly CREATE_GROUP_RETURN = 'CREATE_GROUP_RETURN';
    static readonly CREATE_GROUP_CLEAR = 'CREATE_GROUP_CLEAR';
    static readonly GET_GROUP_CAMERAS = 'GET_GROUP_CAMERAS';
    static readonly GET_GROUP_CAMERAS_RETURN = 'GET_GROUP_CAMERAS_RETURN';
    static readonly GET_GROUP_CAMERASID_RETURN = 'GET_GROUP_CAMERASID_RETURN';
    static readonly CLEAR_GROUP_CAMERAS = 'CLEAR_GROUP_CAMERAS';
    static readonly DELETE_GROUP_CAMERAS = 'DELETE_GROUP_CAMERAS';
    static readonly DELETE_GROUP_CAMERAS_RETURN = 'DELETE_GROUP_CAMERAS_RETURN';
    static readonly DELETE_GROUP_CAMERAS_CLEAR = 'DELETE_GROUP_CAMERAS_CLEAR';
    static readonly UPDATE_CAMERA_GROUP = 'UPDATE_CAMERA_GROUP';
    static readonly UPDATE_CAMERA_GROUP_RESULT = 'UPDATE_CAMERA_GROUP_RESULT';
    static readonly UPDATE_CAMERA_GROUP_CLEAR = 'UPDATE_CAMERA_GROUP_CLEAR';
    static readonly GET_GROUP_CAMERAS_REPORT = 'GET_GROUP_CAMERAS_REPORT';
    static readonly GET_GROUP_CAMERAS_REPORT_RETURN = 'GET_GROUP_CAMERAS_REPORT_RETURN';
    static readonly GET_GROUP_CAMERAS_REPORT_CLEAR = 'GET_GROUP_CAMERAS_REPORT_CLEAR';

    static create_camera = createAction(
        CameraActions.CREATE_CAMERA,
        props<Metadata>()
    );

    static create_camera_status = createAction(
        CameraActions.CREATE_CAMERA_STATUS,
        props<{ payload: Payload }>()
    );

    static update_camera_status = createAction(
        CameraActions.UPDATE_CAMERA_STATUS,
        props<{ payload: Payload }>()
    );

    static cleanup_post = createAction(
        CameraActions.UPDATE_CAMERA_STATUS
    );

    static get_cameras = createAction(
        CameraActions.GET_CAMERAS,
        props<Metadata>()
    );

    static get_cameras_return = createAction(
        CameraActions.GET_CAMERAS_RETURN,
        props<{ payload: Payload }>()
    );

    static get_list_address = createAction(
        CameraActions.GET_LIST_ADDRESS,
        props<Metadata>()
    );

    static get_list_address_return = createAction(
        CameraActions.GET_LIST_ADDRESS_RETURN,
        props<{ payload: Payload }>()
    );

    static check_camera_resolution = createAction(
        CameraActions.CHECK_CAMERA_RESOLUTION,
        props<Metadata>()
    );

    static check_camera_resolution_return = createAction(
        CameraActions.CHECK_CAMERA_RESOLUTION_RETURN,
        props<{ payload: Payload }>()
    );

    static clear_camera_resolution = createAction(
        CameraActions.CLEAR_CAMERA_RESOLUTION
    );

    static create_camera_thumbnail = createAction(
        CameraActions.CREATE_CAMERA_THUMBNAIL,
        props<Metadata>()
    );

    static create_camera_thumbnail_return = createAction(
        CameraActions.CREATE_CAMERA_THUMBNAIL_RETURN,
        props<{ payload: Payload }>()
    );

    static create_camera_thumbnail_cleanup = createAction(
        CameraActions.CREATE_CAMERA_THUMBNAIL_CLEANUP
    );

    static clear_camera_create_thumbnail = createAction(
        CameraActions.CLEAR_CREATE_CAMERA_THUMBNAIL
    );

    static get_preview_camera = createAction(
        CameraActions.GET_PREVIEW_CAMERA,
        props<Metadata>()
    );

    static get_preview_camera_return = createAction(
        CameraActions.GET_PREVIEW_CAMERA_RETURN,
        props<{ payload: Payload }>()
    );

    static reset_preview = createAction(
        CameraActions.RESET_PREVIEW
    );

    static get_url_signed_video = createAction(
        CameraActions.GET_URL_SIGNED_VIDEO,
        props<Metadata>()
    );

    static get_url_signed_video_return = createAction(
        CameraActions.GET_URL_SIGNED_VIDEO_RETURN,
        props<{ payload: Payload }>()
    );

    static get_url_signed_video_clear_return = createAction(
        CameraActions.GET_URL_SIGNED_VIDEO_CLEAR_RETURN
    );

    static get_invited_cameras = createAction(
        CameraActions.GET_INVITED_CAMERAS,
        props<Metadata>()
    );

    static get_invited_cameras_return = createAction(
        CameraActions.GET_INVITED_CAMERAS_RETURN,
        props<{ payload: Payload }>()
    );

    static get_invited_cameras_clear = createAction(
        CameraActions.GET_INVITED_CAMERAS_CLEAR
    );

    static get_cameras_change_return = createAction(
        CameraActions.GET_CAMERAS_CHANGE_RETURN
    );

    static get_plans = createAction(
        CameraActions.GET_PLANS,
        props<Metadata>()
    );

    static get_plans_return = createAction(
        CameraActions.GET_PLANS_RETURN,
        props<{ payload: Payload }>()
    );

    static get_plans_cleanup = createAction(
        CameraActions.GET_PLANS_CLEANUP
    );

    static get_analytics_types = createAction(
        CameraActions.GET_ANALYTICS_TYPES,
        props<Metadata>()
    );

    static get_analytics_types_return = createAction(
        CameraActions.GET_ANALYTICS_TYPES_RETURN,
        props<{ payload: Payload }>()
    );

    static open_modal_invite_user = createAction(
        CameraActions.OPEN_MODAL_INVITE_USER
    );

    static close_modal_invite_user_cancel = createAction(
        CameraActions.CLOSE_MODAL_INVITE_USER_CANCEL
    );

    static close_modal_add_cam_guest = createAction(
        CameraActions.CLOSE_MODAL_ADD_CAM_GUEST
    );

    static close_modal_invite_user_confirm = createAction(
        CameraActions.CLOSE_MODAL_INVITE_USER_CONFIRM
    );

    static close_modal_invite_user_update = createAction(
        CameraActions.CLOSE_MODAL_INVITE_USER_UPDATE
    );

    static open_modal_update_camera = createAction(
        CameraActions.OPEN_MODAL_UPDATE_CAMERA,
        props<{ payload: Payload }>()
    );

    static close_modal_update_camera_cancel = createAction(
        CameraActions.CLOSE_MODAL_UPDATE_CAMERA_CANCEL
    );

    static close_modal_update_camera_confirm = createAction(
        CameraActions.CLOSE_MODAL_UPDATE_CAMERA_CONFIRM
    );

    static close_modal_update_camera_update = createAction(
        CameraActions.CLOSE_MODAL_UPDATE_CAMERA_UPDATE
    );

    static put_id_invite_user = createAction(
        CameraActions.PUT_ID_INVITE_USER,
        props<{ payload: Payload }>()
    );

    static put_plan_max_invite_user = createAction(
        CameraActions.PUT_PLAN_MAX_INVITE_USER,
        props<{ payload: Payload }>()
    );

    static put_camera_update = createAction(
        CameraActions.PUT_CAMERA_UPDATE,
        props<{ payload: Payload }>()
    );

    static put_list_camera_address = createAction(
        CameraActions.PUT_LIST_CAMERA_ADDRESS,
        props<{ payload: Payload }>()
    );

    static put_view_camera = createAction(
        CameraActions.PUT_VIEW_CAMERA,
        props<{ payload: Payload }>()
    );

    static put_analytic_camera = createAction(
        CameraActions.PUT_ANALYTIC_CAMERA,
        props<{ payload: Payload }>()
    );

    static clear_analytic_camera = createAction(
        CameraActions.CLEAR_ANALYTIC_CAMERA
    );

    static get_healthcheck = createAction(
        CameraActions.GET_HEALTHCHECK,
        props<Metadata>()
    );

    static get_healthcheck_return = createAction(
        CameraActions.GET_HEALTHCHECK_RETURN,
        props<{ payload: Payload }>()
    );

    static get_healthcheck_cleanup = createAction(
        CameraActions.GET_HEALTHCHECK_CLEANUP
    );

    static get_health_history = createAction(
        CameraActions.GET_HEALTH_HISTORY,
        props<Metadata>()
    );

    static get_health_history_return = createAction(
        CameraActions.GET_HEALTH_HISTORY_RETURN,
        props<{ payload: Payload }>()
    );

    static get_health_history_filter = createAction(
        CameraActions.GET_HEALTH_HISTORY_FILTER,
        props<Metadata>()
    );

    static get_health_history_filter_return = createAction(
        CameraActions.GET_HEALTH_HISTORY_FILTER_RETURN,
        props<{ payload: Payload }>()
    );

    static clear_history = createAction(
        CameraActions.CLEAR_HISTORY
    );

    static invite_user_camera = createAction(
        CameraActions.INVITE_USER_CAMERA,
        props<Metadata>()
    );

    static invite_user_camera_return = createAction(
        CameraActions.INVITE_USER_CAMERA_RETURN,
        props<{ payload: Payload }>()
    );

    static invite_user_camera_change_return = createAction(
        CameraActions.INVITE_USER_CAMERA_CHANGE_RETURN,
        props<{ payload: Payload }>()
    );

    static update_invite_user_camera = createAction(
        CameraActions.UPDATE_INVITE_USER_CAMERA,
        props<Metadata>()
    );

    static update_invite_user_camera_return = createAction(
        CameraActions.UPDATE_INVITE_USER_CAMERA_RETURN,
        props<{ payload: Payload }>()
    );

    static update_invite_user_camera_change_return = createAction(
        CameraActions.UPDATE_INVITE_USER_CAMERA_CHANGE_RETURN,
        props<{ payload: Payload }>()
    );

    static update_camera_infor = createAction(
        CameraActions.UPDATE_CAMERA_INFOR,
        props<Metadata>()
    );

    static update_camera_infor_return = createAction(
        CameraActions.UPDATE_CAMERA_INFOR_RETURN,
        props<{ payload: Payload }>()
    );

    static update_camera_infor_change_return = createAction(
        CameraActions.UPDATE_INVITE_USER_CAMERA_CHANGE_RETURN,
        props<{ payload: Payload }>()
    );

    static delete_camera = createAction(
        CameraActions.DELETE_CAMERA,
        props<Metadata>()
    );

    static delete_guest_camera = createAction(
        CameraActions.DELETE_GUEST_CAMERA,
        props<Metadata>()
    );

    static delete_camera_feedback = createAction(
        CameraActions.DELETE_CAMERA_FEEDBACK,
        props<{ payload: Payload }>()
    );

    static delete_camera_change_feedback = createAction(
        CameraActions.DELETE_CAMERA_CHANGE_FEEDBACK,
        props<{ payload: Payload }>()
    );

    static update_camera = createAction(
        CameraActions.UPDATE_CAMERA,
        props<Metadata>()
    );

    static update_camera_feedback = createAction(
        CameraActions.UPDATE_CAMERA_FEEDBACK,
        props<{ payload: Payload }>()
    );

    static update_camera_change_feedback = createAction(
        CameraActions.UPDATE_CAMERA_CHANGE_FEEDBACK,
        props<{ payload: Payload }>()
    );

    static update_status_camera = createAction(
        CameraActions.UPDATE_STATUS_CAMERA,
        props<Metadata>()
    );

    static update_status_camera_feedback = createAction(
        CameraActions.UPDATE_STATUS_CAMERA_FEEDBACK,
        props<{ payload: Payload }>()
    );

    static update_status_camera_change_feedback = createAction(
        CameraActions.UPDATE_STATUS_CAMERA_CHANGE_FEEDBACK,
        props<{ payload: Payload }>()
    );

    static put_guests = createAction(
        CameraActions.PUT_GUESTS,
        props<{ payload: Payload }>()
    );

    static get_guests = createAction(
        CameraActions.GET_GUESTS,
        props<Metadata>()
    );

    static get_guests_return = createAction(
        CameraActions.GET_GUESTS_RETURN,
        props<{ payload: Payload }>()
    );

    static cleanup = createAction(
        CameraActions.CLEANUP
    );

    static get_videos = createAction(
        CameraActions.GET_VIDEOS,
        props<Metadata>()
    );

    static get_videos_success = createAction(
        CameraActions.GET_VIDEOS_SUCCESS,
        props<{ payload: Payload }>()
    );

    static get_videos_cleanup = createAction(
        CameraActions.GET_VIDEOS_CLEANUP
    );

    static reseting_videos = createAction(
        CameraActions.RESETTING_VIDEOS,
        props<{ reset: boolean }>()
    );

    static delete_guest = createAction(
        CameraActions.DELETE_GUEST,
        props<Metadata>()
    );

    static delete_guest_feedback = createAction(
        CameraActions.DELETE_GUEST_FEEDBACK,
        props<{ payload: Payload }>()
    );

    static delete_guest_change_feedback = createAction(
        CameraActions.DELETE_GUEST_CHANGE_FEEDBACK,
        props<{ payload: Payload }>()
    );

    static resetting_videos = createAction(
        CameraActions.RESETTING_VIDEOS,
        props<{ reset: boolean }>()
    );

    static get_mosaics = createAction(
        CameraActions.GET_MOSAICS
    );

    static get_mosaics_return = createAction(
        CameraActions.GET_MOSAICS_RETURN,
        props<{ payload: Payload }>()
    );

    static get_user_mosaics = createAction(
        CameraActions.GET_USER_MOSAICS,
        props<Metadata>()
    );

    static get_user_mosaics_return = createAction(
        CameraActions.GET_USER_MOSAICS_RETURN,
        props<{ payload: Payload }>()
    );

    static save_mosaic = createAction(
        CameraActions.SAVE_MOSAIC,
        props<Metadata>()
    );

    static save_mosaic_return = createAction(
        CameraActions.SAVE_MOSAIC_RETURN,
        props<{ payload: Payload }>()
    );

    static edit_mosaic = createAction(
        CameraActions.EDIT_MOSAIC,
        props<Metadata>()
    );

    static edit_mosaic_return = createAction(
        CameraActions.EDIT_MOSAIC_RETURN,
        props<{ payload: Payload }>()
    );

    static delete_mosaic = createAction(
        CameraActions.DELETE_MOSAIC,
        props<Metadata>()
    );

    static delete_mosaic_return = createAction(
        CameraActions.DELETE_MOSAIC_RETURN,
        props<{ payload: Payload }>()
    );

    static reset_return = createAction(
        CameraActions.RESET_RETURN
    );

    static open_modal_mosaic = createAction(
        CameraActions.OPEN_MODAL_MOSAIC
    );

    static open_edit_modal_mosaic = createAction(
        CameraActions.OPEN_EDIT_MODAL_MOSAIC,
        props<{ payload: Payload }>()
    );

    static close_modal_mosaic = createAction(
        CameraActions.CLOSE_MODAL_MOSAIC
    );

    static cancel_modal_mosaic = createAction(
        CameraActions.CANCEL_MODAL_MOSAIC
    );

    static open_modal_list_camera_address = createAction(
        CameraActions.OPEN_MODAL_LIST_CAMERA_ADDRESS
    );

    static close_modal_list_camera_address_cancel = createAction(
        CameraActions.CLOSE_MODAL_LIST_CAMERA_ADDRESS_CONFIRM
    );

    static close_modal_list_camera_address_confirm = createAction(
        CameraActions.CLOSE_MODAL_LIST_CAMERA_ADDRESS_UPDATE
    );

    static close_modal_list_camera_address_update = createAction(
        CameraActions.CLOSE_MODAL_LIST_CAMERA_ADDRESS_CANCEL
    );

    static get_rtmp_url = createAction(
        CameraActions.GET_RTMP_GENERATED_URL,
        props<Metadata>()
    );

    static get_rtmp_url_return = createAction(
        CameraActions.GET_RTMP_GENERATED_URL_RETURN,
        props<{ payload: Payload }>()
    );

    static open_view_group = createAction(
        CameraActions.OPEN_VIEW_GROUP
    );

    static open_view_group_clear = createAction(
        CameraActions.OPEN_VIEW_GROUP_CLEAR
    );

    static get_clients_group = createAction(
        CameraActions.GET_CLIENTS_GROUP,
        props<Metadata>()
    );

    static get_clients_group_return = createAction(
        CameraActions.GET_CLIENTS_GROUP_RETURN,
        props<{ payload: Payload }>()
    );

    static get_clients_group_clear = createAction(
        CameraActions.GET_CLIENTS_GROUP_CLEAR
    );

    static get_group_cameras = createAction(
        CameraActions.GET_GROUP_CAMERAS,
        props<Metadata>()
    );

    static get_group_cameras_return = createAction(
        CameraActions.GET_GROUP_CAMERAS_RETURN,
        props<{ payload: Payload }>()
    );

    static get_group_camerasID_return = createAction(
        CameraActions.GET_GROUP_CAMERASID_RETURN,
        props<{ payload: Payload }>()
    );

    static clear_group_cameras = createAction(
        CameraActions.CLEAR_GROUP_CAMERAS
    );

    static update_camera_group = createAction(
        CameraActions.UPDATE_CAMERA_GROUP,
        props<Metadata>()
    );

    static update_camera_group_result = createAction(
        CameraActions.UPDATE_CAMERA_GROUP_RESULT,
        props<{ payload: Payload }>()
    );

    static update_camera_group_clear = createAction(
        CameraActions.UPDATE_CAMERA_GROUP_CLEAR
    );

    static create_group = createAction(
        CameraActions.CREATE_GROUP,
        props<Metadata>()
    );

    static create_group_return = createAction(
        CameraActions.CREATE_GROUP_RETURN,
        props<{ payload: Payload }>()
    );

    static create_group_clear = createAction(
        CameraActions.CREATE_GROUP_CLEAR
    );

    static delete_group_cameras = createAction(
        CameraActions.DELETE_GROUP_CAMERAS,
        props<Metadata>()
    );

    static delete_group_cameras_return = createAction(
        CameraActions.DELETE_GROUP_CAMERAS_RETURN,
        props<{ payload: Payload }>()
    );

    static delete_group_cameras_clear = createAction(
        CameraActions.DELETE_GROUP_CAMERAS_CLEAR
    );

    static get_cameras_report = createAction(
        CameraActions.GET_CAMERAS_REPORT,
        props<Metadata>()
    );

    static get_cameras_report_return = createAction(
        CameraActions.GET_CAMERAS_REPORT_RETURN,
        props<{ payload: Payload }>()
    );

    static get_cameras_report_clear = createAction(
        CameraActions.GET_CAMERAS_REPORT_CLEAR
    );

    static get_blocked_cameras_report = createAction(
        CameraActions.GET_BLOCKED_CAMERAS_REPORT,
        props<Metadata>()
    );

    static get_blocked_cameras_report_return = createAction(
        CameraActions.GET_BLOCKED_CAMERAS_REPORT_RETURN,
        props<{ payload: Payload }>()
    );

    static get_blocked_cameras_report_clear = createAction(
        CameraActions.GET_BLOCKED_CAMERAS_REPORT_CLEAR
    );

    static get_group_cameras_report = createAction(
        CameraActions.GET_GROUP_CAMERAS_REPORT,
        props<Metadata>()
    );

    static get_group_cameras_report_return = createAction(
        CameraActions.GET_GROUP_CAMERAS_REPORT_RETURN,
        props<{ payload: Payload }>()
    );

    static get_group_cameras_report_clear = createAction(
        CameraActions.GET_GROUP_CAMERAS_REPORT_CLEAR
    );

    static get_provider_integrated_cameras = createAction(
        CameraActions.GET_PROVIDER_INTEGRATED_CAMERAS,
        props<Metadata>()
    );

    static get_provider_integrated_cameras_return = createAction(
        CameraActions.GET_PROVIDER_INTEGRATED_CAMERAS_RETURN,
        props<{ payload: Payload }>()
    );

    static get_provider_integrated_cameras_clear = createAction(
        CameraActions.GET_PROVIDER_INTEGRATED_CAMERAS_CLEAR
    );

    static get_cameras_by_location = createAction(
        CameraActions.GET_CAMERAS_BY_LOCATION,
        props<Metadata>()
    );

    static get_cameras_by_location_return = createAction(
        CameraActions.GET_CAMERAS_BY_LOCATION_RETURN,
        props<{ payload: Payload }>()
    );

    static get_cameras_by_location_clear = createAction(
        CameraActions.GET_CAMERAS_BY_LOCATION_CLEAR
    );

}
