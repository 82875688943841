import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { environment } from 'environments/environment';
import { Camera, Plan, UpdateState, GroupCamera, PaginationCameras, ClientsToGroup, SortColumnCamera, CameraThumbnail } from '../models';
import { Sort, MatSort } from '@angular/material/sort';
import { Subscription, of } from 'rxjs';
import { CameraActions } from '../Services/actions';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UntypedFormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { UserActions } from 'app/Users/Services/actions';
import { Client, CognitoAttributes, UserInventory } from 'app/Users/models';
import { AppState } from 'app/store/model';
import { AnalyticActions } from 'app/analytics/Services/actions';
import { ModalState } from 'app/cameras/models';
import * as AnalModels from 'app/analytics/models';
import { OperationStatus, ResultAlert } from 'app/Shared/models';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { CameraService } from '../Services/camera.service';
import { SharedService } from 'app/Shared/Services/shared.service';
import { Associate } from 'app/Login/models';
import { ImageService } from 'app/Shared/Services/image.service';

@Component({
    selector: 'app-camera-list',
    templateUrl: './camera-list.component.html',
    styleUrls: ['./camera-list.component.scss'],
})

export class CameraListComponent implements OnInit, OnDestroy, AfterViewInit {
    // thumbUrl = environment.wowzaThumbNailUrl;
    readonly cameras$ = this.store.select((state: AppState) => state.camera.cameras);
    readonly plans$ = this.store.select((state: AppState) => state.camera.plans);
    readonly delCam$ = this.store.select((state: AppState) => state.camera.deleteCam);
    readonly upCam$ = this.store.select((state: AppState) => state.camera.updateCam);
    readonly modal_open$ = this.store.select((state: AppState) => state.camera.modal_open_invite_user);
    readonly modal_open_camera$ = this.store.select((state: AppState) => state.camera.modal_open_update_camera);
    readonly modal_open_analytic$ = this.store.select((state: AppState) => state.analytic.modal_open_analytics);
    readonly blockCam$ = this.store.select((state: AppState) => state.camera.updateStatusCam);
    readonly groupCamera$ = this.store.select((state: AppState) => state.camera.groupCamera);
    readonly clientsTogroup$ = this.store.select((state: AppState) => state.camera.clientsGroup);
    readonly camera_thumb$ = this.store.select((state: AppState) => state.camera.camera_thumbnail);
    readonly userInventory$ = this.store.select((state: AppState) => state.Users.userInventory);
    readonly userData$ = this.store.select((state: AppState) => state.Users.userData);

    loadingCameraList: boolean;
    loadingClientData: boolean;
    loadingUpdateActive: boolean[] = new Array(50).map(x => false);
    is_processing: boolean;
    awaitCompletion = null;
    hasCameras: boolean;

    plans: Plan[] = null;
    userSub: string;
    client_sub: string;
    sub: Subscription;

    //Subs
    deleteCam: Subscription;
    updateCam: Subscription;
    updatePlanSub: Subscription;
    updateActiveSub: Subscription;
    updateBlockSub: Subscription;
    getCams: Subscription;
    getPlan: Subscription;
    getGroups: Subscription;
    inviteUser: Subscription;
    updateGuest: Subscription;
    modalClose: Subscription;
    swalDecisionSub: Subscription;
    userInventorySub: Subscription;
    clientsSub: Subscription;
    cameraThumbnail: Subscription;

    analytics_available: number;
    sortingTable = false;
    associate_id = environment.associateId;
    confirm: any;
    opState: OperationStatus;

    editCamEnable = false;
    camIdinEdit = -1;
    newNameCam = new UntypedFormControl('', [Validators.required]);

    pageName = 'camera-list';

    searchInputControl = new UntypedFormControl('', []);
    debounceSearch: ReturnType<typeof setTimeout>;

    delCamera;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    selectedColumn = 'timestamp';

    hasGroups = false;

    paginationCameras: PaginationCameras;
    camLimit: number;
    camTotal: number;
    camPages: number;
    camActual: number;
    camActualAmount: number;
    pageIndex: number;
    loading: boolean;

    indexUpdatingThumb: number;
    cameras: Camera[];
    initialCamsOrder: Camera[];
    changedCam: Camera;
    changedCamsIndex: number;
    inventories: UserInventory = null;
    clientInventory: UserInventory;
    associate: Associate;

    is_partner = false;
    is_partner_client = false;
    is_guest = false;

    dashboardList = (localStorage.getItem('context') == 'home');
    loadingDashCameras: boolean;

    clientList: string;
    clientsID: number[];
    clients: ClientsToGroup[];
    clientSimple: {
        id: number | string;
        text: string;
    }[] = [];
    clientPlaceholder: number[];

    user_data_sub: Subscription;
    client: Client = null;
    ciList: any[];
    sortOptions = [
        {
            id: 1,
            text: 'Recentes',
            value: { 'column': 'cam.registered_at', 'sort': 'DESC' }
        },
        {
            id: 2,
            text: 'Online',
            value: { 'column': 'cam.status', 'sort': 'DESC' }
        },
        {
            id: 3,
            text: 'Offline',
            value: { 'column': 'cam.status', 'sort': 'ASC' }
        },
        {
            id: 4,
            text: 'Ordem Alfabética',
            value: { 'column': 'cam.alias', 'sort': 'ASC' }
        },
        {
            id: 5,
            text: 'Bloqueadas',
            value: { 'column': 'cam.active', 'sort': 'ASC' }
        },
        {
            id: 6,
            text: 'Desbloqueadas',
            value: { 'column': 'cam.active', 'sort': 'DESC' }
        }
    ];

    sortBy: SortColumnCamera[];

    statusOptions = [
        {
            id: 1,
            text: 'Online',
        },
        {
            id: 2,
            text: 'Offline',
        }
    ];

    statusOptionsPartner = [
        {
            id: 1,
            text: 'Online',
        },
        {
            id: 2,
            text: 'Offline',
        },
        {
            id: 3,
            text: 'Bloqueadas',
        },
        {
            id: 4,
            text: 'Desbloqueadas',
        },
    ];

    blockedList: boolean;
    offlineList: boolean;
    onlineList: boolean;
    placeholder: number = null;

    partnerView = false;
    partnerDashboard = false;
    generalList = false;

    openExpandedCameraList: boolean;
    isTooltipVisible = false;

    isAssociate: boolean;
    adminAssociate: boolean;
    groupsCameras: Camera[] = [];
    group: GroupCamera;
    isClientAssociate: boolean = localStorage.getItem('profile_name') === '419bea06-5d4e-4a56-b8b5-04b3ad566d59';
    adminClientAssociate: boolean;

    constructor(private router: Router, private builder: UntypedFormBuilder, private cameraService: CameraService, private readonly store: Store<AppState>,
        private validationResponse: ValidationResponseHandlerModule, private service: SharedService, private IS: ImageService) { }

    ngOnInit() {
        this.userSub = localStorage.getItem('sub');
        this.client_sub = localStorage.getItem('clientView');
        this.associate = JSON.parse(localStorage.getItem('associate'));
        this.adminClientAssociate = this.isClientAssociate && localStorage.getItem('associate_permission') === 'e816c560-812e-11ed-a1eb-0242ac120002';
        this.blockedList = localStorage.getItem('list') == 'blocked';
        this.offlineList = localStorage.getItem('list') == 'offline';
        this.onlineList = localStorage.getItem('list') == 'online';
        this.clientList = localStorage.getItem('clientsID');
        this.clientsID = this.clientList !== '' ? this.clientList?.split(',').map(Number) : null;
        this.camLimit = 50;
        this.pageIndex = 1;

        if (localStorage.getItem('profile_name') === 'f29868c7-b4c5-4963-9ae8-1dd95699d6c3') {
            this.isAssociate = true;
        }
        if (this.isAssociate && localStorage.getItem('associate_permission') === 'e816c560-812e-11ed-a1eb-0242ac120002') {
            this.adminAssociate = true;
        }

        this.store.dispatch(CameraActions.clear_group_cameras());
        this.store.dispatch(UserActions.cleanup());
        this.getProfile();

        if (this.client_sub) {
            this.partnerView = true;
        }
        else if ((this.is_partner || this.isAssociate) && this.dashboardList) {
            this.partnerDashboard = true;
            this.getClients();
        }
        else {
            this.generalList = true;
        }

        this.loadingCameraList = true;
        this.loadingDashCameras = true;
        this.cameras = [];
        this.hasCameras = false;
        this.is_processing = false;
        this.loadingClientData = true;
        this.getClients();

        if (this.client_sub !== null) {
            this.getUserData();
            this.store.dispatch(UserActions.get_user_inventory({
                user_sub: this.userSub,
                child_sub: this.client_sub
            }));
        } else {
            this.store.dispatch(UserActions.get_user_inventory({
                user_sub: this.userSub
            }));
        }
        this.userInventoryClient();

        if (this.onlineList) {
            this.placeholder = 1;
            this.clientPlaceholder = this.clientsID;
            // Commented because the function changeSort call allusercameras on component init
        }
        else if (this.offlineList) {
            this.placeholder = 2;
            this.clientPlaceholder = this.clientsID;
            // Commented because the function changeSort call allusercameras on component init
        }
        else if (this.blockedList) {
            this.placeholder = 3;
            this.clientPlaceholder = this.clientsID;
            // Commented because the function changeSort call allusercameras on component init
        } else {
            this.clientPlaceholder = this.clientsID;
            const query = this.searchInputControl.value;
            // Commented because the function changeSort call allusercameras on component init
        }

        this.getCameras();
        this.getPlans();
        this.getCameraGroups();
        this.confirmDeleteFeedback();
        this.confirmUpdateCamera();
        this.confirmUpdateBlockCamera();
        this.updateNumberGuests();
        this.updateNumberAnalytics();
        localStorage.setItem('previousRouter', this.router.url);
        this.changeSortedColumn();
        // this.changeSortedStatusColumn(this.placeholder);
        this.getCreateThumbnailResult();
        // Recebe o retorno das informações do swal
        this.swalDecisionSub = this.service.swalDecision.subscribe(returnSwallObject => {
            if (returnSwallObject) {
                this.validateReturnMethodCalled(returnSwallObject as ResultAlert);
            }
        });
        this.openExpandedCameraList = false;
    }


    getProfile() {
        const userProfile = localStorage.getItem('profile_name');
        if (userProfile == 'cd343bfc-17e8-11ec-9621-0242ac130002') {
            this.is_partner = true;
        } else if (userProfile == '61902d2b-3ada-49f3-b42a-1775bc064bb0') {
            this.is_partner_client = true;
        }

        if (userProfile == 'bb653b3a-fdb3-4438-bce6-012585b5268f' || (this.isClientAssociate && !this.adminClientAssociate)) {
            this.is_guest = true;
        }
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        if (this.deleteCam) {
            this.deleteCam.unsubscribe();
        }
        if (this.updateCam) {
            this.updateCam.unsubscribe();
        }
        if (this.modalClose) {
            this.modalClose.unsubscribe();
        }
        if (this.getCams) {
            this.getCams.unsubscribe();
        }
        if (this.getPlan) {
            this.getPlan.unsubscribe();
        }
        if (this.getGroups) {
            this.getGroups.unsubscribe();
        }
        if (this.inviteUser) {
            this.inviteUser.unsubscribe();
        }
        if (this.updateGuest) {
            this.updateGuest.unsubscribe();
        }
        if (this.updatePlanSub) {
            this.updatePlanSub.unsubscribe();
        }
        if (this.updateActiveSub) {
            this.updateActiveSub.unsubscribe();
        }
        if (this.updateBlockSub) {
            this.updateBlockSub.unsubscribe();
        }
        if (this.swalDecisionSub) {
            this.swalDecisionSub.unsubscribe();
        }
        if (this.userInventorySub) {
            this.userInventorySub.unsubscribe();
        }
        if (this.clientsSub) {
            this.clientsSub.unsubscribe();
        }
        if (this.cameraThumbnail) {
            this.cameraThumbnail.unsubscribe();
        }
        if (this.awaitCompletion) {
            clearInterval(this.awaitCompletion);
        }
        this.service.cleanSwal();
        this.store.dispatch(CameraActions.cleanup());
        this.isTooltipVisible = false;
        localStorage.removeItem('context');
        localStorage.removeItem('list');
    }

    ngAfterViewInit() {
        this.changeSortedColumn();
        // this.changeSortedStatusColumn(this.placeholder);
    }

    // Compara o nome ou status e ordena corretamente
    regularCompare(a, b, isAsc) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    onPageIndexChange() {
        clearTimeout(this.debounceSearch);
        const query = this.searchInputControl.value;
        this.scrolltoTop();
        this.debounceSearch = setTimeout(() => {
            this.store.dispatch(CameraActions.get_cameras({
                user_sub: this.userSub,
                child_sub: this.client_sub,
                str_filter: query,
                cam_stat_filter: (this.placeholder == 1) ? true : (this.placeholder == 2) ? false : null,
                limit: this.camLimit,
                page: this.pageIndex,
                clients_id: this.clientsID,
                sort_by: this.sortBy,
                active: (this.placeholder == 3) ? false : (this.placeholder == 4) ? true : null
            }));
            this.loading = true;
        }, 300);
    }

    changeSortedStatusColumn(id) {
        clearTimeout(this.debounceSearch);
        const queryStr = this.searchInputControl.value;
        switch (id) {
            case 1:
                this.placeholder = 1;
                this.debounceSearch = setTimeout(() => {
                    this.store.dispatch(CameraActions.get_cameras({
                        user_sub: this.userSub,
                        child_sub: this.client_sub,
                        cam_stat_filter: true,
                        str_filter: queryStr,
                        limit: this.camLimit,
                        page: 1,
                        clients_id: this.clientsID,
                        sort_by: this.sortBy
                    }));
                    this.loadingDashCameras = true;
                }, 300);
                break;
            case 2:
                this.placeholder = 2;
                this.debounceSearch = setTimeout(() => {
                    this.store.dispatch(CameraActions.get_cameras({
                        user_sub: this.userSub,
                        child_sub: this.client_sub,
                        cam_stat_filter: false,
                        str_filter: queryStr,
                        limit: this.camLimit,
                        page: 1,
                        clients_id: this.clientsID,
                        sort_by: this.sortBy
                    }));
                    this.loadingDashCameras = true;
                }, 300);
                break;
            case 3:
                this.placeholder = 3;
                this.debounceSearch = setTimeout(() => {
                    this.store.dispatch(CameraActions.get_cameras({
                        user_sub: this.userSub,
                        child_sub: this.client_sub,
                        str_filter: queryStr,
                        limit: this.camLimit,
                        page: 1,
                        clients_id: this.clientsID,
                        sort_by: this.sortBy,
                        active: false
                    }));
                    this.loadingDashCameras = true;
                }, 300);
                break;
            case 4:
                this.placeholder = 4;
                this.debounceSearch = setTimeout(() => {
                    this.store.dispatch(CameraActions.get_cameras({
                        user_sub: this.userSub,
                        child_sub: this.client_sub,
                        str_filter: queryStr,
                        limit: this.camLimit,
                        page: 1,
                        clients_id: this.clientsID,
                        sort_by: this.sortBy,
                        active: true
                    }));
                    this.loadingDashCameras = true;
                }, 300);
                break;
            default:
                this.placeholder = null;
                this.debounceSearch = setTimeout(() => {
                    this.store.dispatch(CameraActions.get_cameras({
                        user_sub: this.userSub,
                        child_sub: this.client_sub,
                        str_filter: queryStr,
                        limit: this.camLimit,
                        page: 1,
                        clients_id: this.clientsID,
                        sort_by: this.sortBy
                    }));
                    this.loadingDashCameras = true;
                }, 300);
        }
    }


    changeSortedColumn(id = 1) {
        this.sortingTable = true;
        let sortType;
        switch (id) {
            case 1:
                sortType = 'timestamp';
                break;
            case 2:
                sortType = 'status_ativo';
                break;
            case 3:
                sortType = 'status_inativo';
                break;
            case 4:
                sortType = 'nome';
                break;
            case 5:
                sortType = 'bloqueada';
                break;
            case 6:
                sortType = 'desbloqueada';
                break;
            default:
                sortType = 'timestamp';
        }
        const sortState: Sort = { active: sortType, direction: 'asc' };
        const isAsc = sortState.direction === 'asc';
        const isDesc = sortState.direction === 'desc';

        let status = '';
        if (sortState.active === 'status_ativo') {
            status = sortState.active;
            sortState.active = 'status';
            sortState.direction = 'asc';
        }
        if (sortState.active === 'status_inativo') {
            status = sortState.active;
            sortState.active = 'status';
            sortState.direction = 'desc';
        }
        if (sortState.active === 'bloqueada') {
            status = sortState.active;
            sortState.active = 'status';
            sortState.direction = 'asc';
        }
        if (sortState.active === 'desbloqueada') {
            status = sortState.active;
            sortState.active = 'status';
            sortState.direction = 'desc';
        }
        this.cameras = this.cameras.sort((a, b) => {
            if (status == 'status_ativo') {
                return this.regularCompare(a.active, b.active, isDesc);

            } else if (status == 'status_inativo') {
                return this.regularCompare(a.active, b.active, isAsc);

            } else if (sortState.active == 'nome') {
                return this.regularCompare(a.alias.toUpperCase(), b.alias.toUpperCase(), isAsc);

            } else if (status == 'bloqueada') {
                return this.regularCompare(a.active, b.active, isAsc);

            } else if (status == 'desbloqueada') {
                return this.regularCompare(a.active, b.active, isDesc);

            } else {
                return this.dateCompare(a.last_modify, b.last_modify, isDesc);
            }
        });
        //}
        this.sortingTable = false;
    }

    sortArray(arr) {
        const sortState: Sort = { active: this.selectedColumn, direction: 'asc' };
        const isAsc = sortState.direction === 'asc';
        const isDesc = sortState.direction === 'desc';
        let status = '';
        if (sortState.active === 'status_ativo') {
            status = sortState.active;
            sortState.active = 'status';
            sortState.direction = 'asc';
        }
        if (sortState.active === 'status_inativo') {
            status = sortState.active;
            sortState.active = 'status';
            sortState.direction = 'desc';
        }
        arr = arr.sort((a, b) => {
            switch (status) {
                case 'status_ativo': return this.regularCompare(a.active, b.active, isDesc);
                case 'status_inativo': return this.regularCompare(a.active, b.active, isAsc);
                default: return 0;
            }
        });
        arr = arr.sort((a, b) => {
            switch (sortState.active) {
                case 'nome': return this.regularCompare(a.alias.toUpperCase(), b.alias.toUpperCase(), isAsc);
                case 'timestamp': return this.dateCompare(a.ultima_modificacao, b.ultima_modificacao, isAsc);
                default: return 0;
            }
        });
        return arr;
    }

    // Compara a data e retorna a ordenação corretamente
    dateCompare(a, b, isAsc) {
        const dateA = new Date(a).valueOf();
        const dateB = new Date(b).valueOf();
        return (dateA < dateB ? -1 : 1) * (isAsc ? 1 : -1);
    }

    loadingEvent(event: boolean) {
        this.loadingCameraList = event;
    }

    addCamera() {
        if (this.inventories.inventory.ci.total.available > 0) {
            localStorage.setItem('previousRouter', this.router.url);
            this.router.navigateByUrl('/cameras/cadastro');
        }
    }

    addCameraCSV() {
        if (this.inventories.inventory.ci.total.available > 0) {
            localStorage.setItem('previousRouter', this.router.url);
            this.router.navigateByUrl('/cameras/cadastros');
        }
    }

    reloadComponent() {
        if (this.loadingCameraList == true) {
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            if (this.client_sub) {
                this.router.navigate(['/users/client/view']);
                this.loadingCameraList = false;
            } else {
                this.router.navigate(['/cameras/list']);
                this.loadingCameraList = false;
            }
        }
    }

    getCameraGroups() {
        this.getGroups = this.groupCamera$.subscribe(groups => {
            if (groups) {
                groups = groups as GroupCamera[];
                if (groups.length > 0) {
                    this.group = groups[0];
                    this.hasGroups = true;
                    this.groupsCameras = [];
                    groups.forEach(group => {
                        this.groupsCameras.push(...group.cameras_info_group.cameras);
                    });
                }
            }
        });
    }

    getCameras() {
        this.getCams = this.cameras$.subscribe(cameras => {
            if (cameras) {
                this.hasCameras = true;
                if (cameras.model === 'cameras') {
                    this.paginationCameras = cameras.cameras as PaginationCameras;
                    this.camTotal = this.paginationCameras.total;
                    this.camPages = this.paginationCameras.pages;
                    this.camActual = this.paginationCameras.actual;
                    this.camLimit = this.paginationCameras.limit;
                    this.camActualAmount = this.camActual < this.camPages ? this.camLimit * this.camActual : this.camTotal;
                    if (cameras.cameras !== undefined) {
                        if (typeof this.changedCamsIndex !== 'undefined') {
                            this.reloadComponent();
                        } else {
                            this.paginationCameras.cameras = this.sortArray(this.paginationCameras.cameras);

                            //Passa a lista de câmeras para a table
                            const fullCameraList = this.getUniqueCameras(this.paginationCameras.cameras.concat(this.groupsCameras));

                            this.paginationCameras.cameras.map(async camera => {
                                if (camera.is_processing_analytic) {
                                    this.is_processing = true;
                                    if (this.awaitCompletion == null) {
                                        this.waitForCompletion();
                                    }
                                    this.loadingUpdateActive.push(false);
                                }
                                // TESTAR LOGOUT, UPDATE, DELETE, CREATE E ROTINA DE ATUALIZAÇÃO DE THUMB
                                this.IS.saveCacheThumbs(camera.thumbnail);
                                camera.cache_thumb = of(
                                    await this.IS.getCSSBackgroundImageURL(camera.thumbnail)
                                );
                            });
                            this.cameras = [];
                            this.cameras = this.paginationCameras.cameras;
                            this.loadingCameraList = false;
                            this.loadingDashCameras = false;
                            this.loading = false;
                            this.hasCameras = true;
                        }
                    }
                } else {
                    this.cameras = [];
                    this.loadingCameraList = false;
                    this.loadingDashCameras = false;
                    this.loading = false;
                    this.hasCameras = false;
                }
            }
        });
    }

    getUniqueCameras(cameras: Camera[]): Camera[] {
        if (cameras) {
            const uniqueList = [];
            const idOrdainedList = cameras.sort((a, b) => {
                return a.id - b.id;
            });

            idOrdainedList.forEach((current, index, list) => {
                if (uniqueList.length > 0) {
                    const last = uniqueList.length - 1;
                    if (uniqueList[last].id !== current.id) {
                        uniqueList.push(current);
                    }
                } else {
                    uniqueList.push(current);
                }
            });

            return uniqueList;
        }
        return [];
    }

    updateActive(cam, index) {
        cam.active = !cam.active;
        this.changedCam = cam;
        this.changedCamsIndex = index;

        if (index >= 0) {
            if (this.changedCam.active === true) {
                this.validationResponse.validationResponseHandler(200, this.pageName, 'update-active', 'cameras.update_active');
            } else {
                this.validationResponse.validationResponseHandler(200, this.pageName, 'update-inactive', 'cameras.update_inactive');
            }
        }
    }

    // Valida o retorno do Swal e o método do pai ao qual fez a chamada
    validateReturnMethodCalled(returnResultSwal: ResultAlert) {
        if (returnResultSwal.methodCalled === 'updateActive' || returnResultSwal.methodCalled === 'updateInactive') {
            if (returnResultSwal.isConfirmed) {
                this.store.dispatch(CameraActions.update_status_camera({
                    camera_id: this.changedCam.id,
                    status: this.changedCam.active,
                    user_sub: this.userSub
                }));
                this.loadingUpdateActive[this.changedCamsIndex] = true;
            } else if (returnResultSwal.isDismissed) {
                this.cameras[this.changedCamsIndex].active = !this.changedCam.active;
            }
        } else if (returnResultSwal.methodCalled === 'deleteCam') {
            if (returnResultSwal.isConfirmed) {
                this.loadingCameraList = true;
                this.cameraService.closeModals(true);
                this.store.dispatch(CameraActions.close_modal_update_camera_confirm());
                this.store.dispatch(CameraActions.delete_camera({ camera_id: this.delCamera.id, child_sub: localStorage.getItem('clientView') }));
                document?.getElementById('top-list').scrollIntoView();
            }
            else if (returnResultSwal.isDismissed) {
            }
        } else if (returnResultSwal.methodCalled === 'optionEnabled') {
            if (returnResultSwal.isConfirmed) {
                delete returnResultSwal.methodCalled;
                delete returnResultSwal.dismiss;
                this.confirm = returnResultSwal;
                this.router.navigateByUrl('/users/profile');
            }
            else if (returnResultSwal.isDismissed) {
            }
        } else if (returnResultSwal.methodCalled === 'userInventoryError') {
            if (returnResultSwal.isConfirmed) {
                this.confirm = returnResultSwal;
                this.router.navigateByUrl('/users/clients/list');
            }
        }
    }

    getPlans() {
        this.store.dispatch(CameraActions.get_plans({ associateId: this.associate_id }));
        this.getPlan = this.plans$.subscribe(resultPlans => {
            if (resultPlans) {
                this.plans = resultPlans;
            }
            else {
                this.plans = [];
            }
        });
    }

    getPlanProperty(camera_id: number, property: string): string {
        const plan = this.plans.find(pl => pl.id === camera_id);
        let aliasReformed;
        if (plan) {
            switch (property) {
                // Nome do plano
                case 'alias':
                    if (plan.alias === 'FREE') {
                        aliasReformed = 'FREE';
                    } else {
                        aliasReformed = plan.alias + ' dias';
                    }
                    return aliasReformed;

                case 'color':
                    return plan.color;

                case 'price':
                    return plan.price.toString();

                case 'name':
                    return 'Câmera ' + plan.resolution.alias;

                default:
                    return 'error';
            }
        }
        else {
            return '';
        }
    }

    updateNumberGuests() {
        this.modalClose = this.modal_open$.subscribe(modalStatus => {
            if (modalStatus == ModalState.Closed) {
                const query = this.searchInputControl.value;
                this.store.dispatch(CameraActions.get_cameras({
                    user_sub: this.userSub,
                    child_sub: this.client_sub,
                    str_filter: query,
                    cam_stat_filter: (this.placeholder == 1) ? true : (this.placeholder == 2) ? false : null,
                    limit: this.camLimit,
                    page: this.pageIndex,
                    clients_id: this.clientsID,
                    active: (this.placeholder == 3) ? false : (this.placeholder == 4) ? true : null
                }));
                this.loadingCameraList = true;
                this.store.dispatch(CameraActions.close_modal_invite_user_cancel());
            }
        });
    }
    updateNumberAnalytics() {
        this.modalClose = this.modal_open_analytic$.subscribe(modalStatus => {
            if (modalStatus == 3) {
                const query = this.searchInputControl.value;
                this.store.dispatch(CameraActions.get_cameras({
                    user_sub: this.userSub,
                    child_sub: this.client_sub,
                    str_filter: query,
                    cam_stat_filter: (this.placeholder == 1) ? true : (this.placeholder == 2) ? false : null,
                    limit: this.camLimit,
                    page: this.pageIndex,
                    clients_id: this.clientsID,
                    active: (this.placeholder == 3) ? false : (this.placeholder == 4) ? true : null
                }));
                this.loadingCameraList = true;
            }
        });
    }

    updateCameraEdit() {
        this.modalClose = this.modal_open_camera$.subscribe(modalStatus => {
            if (modalStatus == ModalState.Closed) {
                const query = this.searchInputControl.value;
                this.store.dispatch(CameraActions.get_cameras_change_return());
                this.store.dispatch(CameraActions.get_cameras({
                    user_sub: this.userSub,
                    child_sub: this.client_sub,
                    str_filter: query,
                    cam_stat_filter: (this.placeholder == 1) ? true : (this.placeholder == 2) ? false : null,
                    limit: this.camLimit,
                    page: 1,
                    clients_id: this.clientsID,
                    active: (this.placeholder == 3) ? false : (this.placeholder == 4) ? true : null
                }));
                this.loadingCameraList = true;
                this.store.dispatch(CameraActions.update_camera_infor_change_return(null));
            }
        });
    }

    confirmDeleteFeedback() {
        this.deleteCam = this.delCam$.subscribe(delStatus => {
            if (delStatus && delStatus.status == OperationStatus.Success) {
                // showAlert('Sua câmera foi deletada.', 'success');
                this.validationResponse.validationResponseHandler(200, this.pageName, 'success-delete-camera', delStatus.message);
                const query = this.searchInputControl.value;
                this.store.dispatch(CameraActions.get_cameras({
                    user_sub: this.userSub,
                    child_sub: this.client_sub,
                    str_filter: query,
                    cam_stat_filter: (this.placeholder == 1) ? true : (this.placeholder == 2) ? false : null,
                    limit: this.camLimit,
                    page: this.pageIndex,
                    clients_id: this.clientsID,
                    active: (this.placeholder == 3) ? false : (this.placeholder == 4) ? true : null
                }));
                this.callUserInventory();
                this.loadingCameraList = true;
            } else if (delStatus && delStatus.status == OperationStatus.Fail) {
                this.validationResponse.validationResponseHandler(400, this.pageName, 'failure-delete-camera', delStatus.message);
                this.loadingCameraList = false;
                // showAlert('Não foi possível excluir sua Câmera.', 'danger');
            }
            this.store.dispatch(CameraActions.delete_camera_change_feedback({ payload: OperationStatus.Unloaded }));
        });
    }

    confirmUpdateCamera() {
        this.updateCam = this.upCam$.subscribe(upStatus => {
            if (upStatus && upStatus.status == OperationStatus.Success) {
                this.store.dispatch(CameraActions.close_modal_update_camera_confirm());
                this.validationResponse.validationResponseHandler(200, this.pageName, 'success-update-camera', upStatus.message);
                // showAlert('Os dados da sua câmera foram alterados com sucesso.', 'success');
                const query = this.searchInputControl.value;
                this.store.dispatch(CameraActions.get_cameras({
                    user_sub: this.userSub,
                    child_sub: this.client_sub,
                    str_filter: query,
                    cam_stat_filter: (this.placeholder == 1) ? true : (this.placeholder == 2) ? false : null,
                    limit: this.camLimit,
                    page: this.pageIndex,
                    clients_id: this.clientsID,
                    active: (this.placeholder == 3) ? false : (this.placeholder == 4) ? true : null
                }));
                this.loadingCameraList = true;
            } else if (upStatus && upStatus.status == OperationStatus.Fail) {
                this.store.dispatch(CameraActions.close_modal_update_camera_confirm());
                this.validationResponse.validationResponseHandler(400, this.pageName, 'failure-update-camera', upStatus.message);
                const query = this.searchInputControl.value;
                this.store.dispatch(CameraActions.get_cameras({
                    user_sub: this.userSub,
                    child_sub: this.client_sub,
                    str_filter: query,
                    cam_stat_filter: (this.placeholder == 1) ? true : (this.placeholder == 2) ? false : null,
                    limit: this.camLimit,
                    page: this.pageIndex,
                    clients_id: this.clientsID,
                    active: (this.placeholder == 3) ? false : (this.placeholder == 4) ? true : null
                }));
                this.loadingCameraList = true;
                // showAlert('Não foi possível alterar os dados da sua câmera.', 'danger');
            }
            this.store.dispatch(CameraActions.update_camera_change_feedback({ payload: OperationStatus.Unloaded }));
        });
    }

    confirmUpdateBlockCamera() {
        this.updateBlockSub = this.blockCam$.subscribe(block => {
            this.loadingUpdateActive[this.changedCamsIndex] = false;
            if (block) {
                if (block.success == true) {
                    if (this.changedCam.active === false) {
                        this.validationResponse.validationResponseHandler(200, this.pageName, 'success-update-camera', 'cameras.success_block');
                    }
                    else {
                        this.validationResponse.validationResponseHandler(200, this.pageName, 'success-update-camera', 'cameras.success_unblock');
                    }
                    const query = this.searchInputControl.value;
                    this.store.dispatch(CameraActions.get_cameras({
                        user_sub: this.userSub,
                        child_sub: this.client_sub,
                        str_filter: query,
                        cam_stat_filter: (this.placeholder == 1) ? true : (this.placeholder == 2) ? false : null,
                        limit: this.camLimit,
                        page: this.pageIndex,
                        clients_id: this.clientsID,
                        sort_by: this.sortBy,
                        active: (this.placeholder == 3) ? false : (this.placeholder == 4) ? true : null
                    }));
                    this.loadingUpdateActive[this.changedCamsIndex] = false;
                }
                else {
                    this.validationResponse.validationResponseHandler(400, this.pageName, 'failure-update-camera', 'cameras.failure_update');
                    this.loadingUpdateActive[this.changedCamsIndex] = false;
                    this.cameras[this.changedCamsIndex].active = !this.cameras[this.changedCamsIndex].active;
                }
            }
        });
    }

    openEditCam(cam: Camera) {
        this.camIdinEdit = cam.id;
        this.editCamEnable = true;
    }

    cancelEditCam(cam: Camera) {
        this.camIdinEdit = -1;
        this.editCamEnable = false;
        this.newNameCam.reset();
    }

    updateInfoCam(cam: Camera) {
        const userSub = localStorage.getItem('sub');
        const camera: Camera = {
            id: cam.id,
            alias: cam.alias,
            id_plan: cam.id_plan,
            address: {
                rua: cam.address['rua'],
                numero: cam.address['numero'],
                complemento: cam.address['complemento'],
                cep: cam.address['cep'],
                bairro: cam.address['bairro'],
                estado: cam.address['estado'],
                cidade: cam.address['cidade'],
                latitude: cam.address['latitude'],
                longitude: cam.address['longitude']
            },
            link_rtsp: cam.link_rtsp,
        };
        this.store.dispatch(CameraActions.update_camera_infor({
            camera: camera,
            user_sub: userSub,
            child_sub: localStorage.getItem('clientView') ? localStorage.getItem('clientView') : null
        }));
        this.loadingCameraList = true;
    }

    saveCamera(cam: Camera) {
        if (this.newNameCam.valid) {
            cam.alias = this.newNameCam.value;
            this.updateInfoCam(cam);
            this.editCamEnable = false;
            this.newNameCam.reset();
        }
        else {
            this.validationResponse.validationResponseHandler(400, this.pageName, 'not-name-camera', 'cameras.not_name');
            // showAlert('Escreva um novo nome para sua câmera!', 'danger');
        }
    }

    openModalAnalytics(cam: Camera) {
        if (cam.created_off == false) {
            if (cam.active == true) {
                this.store.dispatch(CameraActions.put_analytic_camera({ payload: cam }));
                this.store.dispatch(AnalyticActions.update_modal_state({ payload: AnalModels.ModalState.List }));
            }
        }
    }

    openModalInviteUser(cam: Camera, index) {
        if (cam.created_off == false) {
            if (cam.active == true) {
                localStorage.setItem('cameraIdList', index);
                this.store.dispatch(CameraActions.put_id_invite_user({ payload: cam.id }));
                this.store.dispatch(CameraActions.put_plan_max_invite_user({ payload: cam.plan_details[0].max_invites }));
                localStorage.setItem('cameraIdInvite', String(cam.id));
                this.store.dispatch(CameraActions.open_modal_invite_user());
            }
        }
    }

    openModalCameraUpdate(cam: Camera) {
        this.delCamera = cam;
        this.store.dispatch(CameraActions.open_modal_update_camera({ payload: cam }));
    }

    viewLiveCamera(cam: Camera) {
        const dataModal = {
            data: {
                camera: cam
            },
            modalHeight: 'fit-content',
            modalMaxHeight: '90vh',
            // modalWidth: '60%',
            backdropClass: 'lightBackdrop',
            tipoModal: 'live_camera'
        };
        this.service.returnDataToModal(dataModal);
    }

    userInventoryClient() {
        this.userInventorySub = this.userInventory$.subscribe(inventory => {
            if (inventory) {
                if (inventory.model === 'feedback') {
                    if (!this.is_partner_client) {
                        this.validationResponse.validationResponseHandler(403, 'user-summary', 'userinventory-error', 'user.userinventory_error', inventory.message);
                    }
                } else {
                    this.inventories = inventory[0];

                    const cameraTypeList = Object.values(this.inventories.inventory.ci);
                    cameraTypeList.pop();
                    this.ciList = cameraTypeList;
                    this.loadingClientData = false;
                    this.analytics_available = this.inventories.inventory.ai.available;
                }
            }
        });
    }

    callUserInventory() {
        this.loadingClientData = true;
        if (this.client_sub !== null) {
            this.getUserData();
            this.store.dispatch(UserActions.get_user_inventory({
                user_sub: this.userSub,
                child_sub: this.client_sub
            }));
        } else {
            this.store.dispatch(UserActions.get_user_inventory({
                user_sub: this.userSub
            }));
        }
    }

    addCredit() {
        if (this.client && this.inventories) {
            const dataModal = {
                data: {
                    client: this.client,
                    clientInventory: this.inventories,
                },
                tipoModal: 'adicionar_camera_deteccoes',
                modalHeight: '85vh',
                modalWidth: '60vw'
            };
            this.service.returnDataToModal(dataModal);
        }
    }

    getUserData() {
        this.store.dispatch(UserActions.get_user_data({
            user_sub: this.userSub,
            child_sub: this.client_sub
        }));
        this.user_data_sub = this.userData$.subscribe(client_data => {
            if (client_data) {
                if (client_data.model === 'feedback') {
                    this.validationResponse.validationResponseHandler(400, 'user-summary', 'return-data-error', 'user.client_cognito_error', client_data.message);
                }
                else {
                    if (client_data.sub != this.userSub) {
                        this.fillClientInfo(client_data);
                        this.loadingClientData = false;
                    }
                }
            }
        });
    }

    fillClientInfo(client_attr: CognitoAttributes) {
        this.client = {
            'name': client_attr.name,
            'sub': client_attr.sub,
            'active': client_attr.active,
            'cameras': this.cameras
        };
    }

    closeToolTip(id) {
        const tooltipSteps = document.getElementById(id) as HTMLElement;
        tooltipSteps.style.visibility = 'hidden';
    }

    waitForCompletion() {
        this.awaitCompletion = setInterval(() => {
            if (!this.is_processing) {
                clearInterval(this.awaitCompletion);
                this.awaitCompletion = null;
            } else {
                const query = this.searchInputControl.value;
                this.store.dispatch(CameraActions.get_cameras({
                    user_sub: this.userSub,
                    child_sub: this.client_sub,
                    str_filter: query,
                    cam_stat_filter: (this.placeholder == 1) ? true : (this.placeholder == 2) ? false : null,
                    limit: this.camLimit,
                    page: this.pageIndex,
                    clients_id: this.clientsID,
                    sort_by: this.sortBy,
                    active: (this.placeholder == 3) ? false : (this.placeholder == 4) ? true : null
                }));
            }
        }, 120000);
    }

    changeSort(event) {
        this.sortBy = [this.sortOptions.find(x => x.id == event[0]).value] as SortColumnCamera[];
        const queryStr = this.searchInputControl.value;
        this.loadingDashCameras = true;
        this.store.dispatch(CameraActions.get_cameras({
            user_sub: this.userSub,
            child_sub: this.client_sub,
            str_filter: queryStr,
            cam_stat_filter: (this.placeholder == 1) ? true : (this.placeholder == 2) ? false : null,
            limit: this.camLimit,
            page: 1,
            clients_id: this.clientsID,
            sort_by: this.sortBy,
            active: (this.placeholder == 3) ? false : (this.placeholder == 4) ? true : null
        }));
    }

    changeStatusFilter(event) {
        this.changeSortedStatusColumn(event[0]);
    }

    toggleCameraList() {
        this.openExpandedCameraList = !this.openExpandedCameraList;
    }

    openTooltipHelp(id) {
        const tooltip = document.querySelector(`#tooltip-${id}`) as HTMLElement;
        if (tooltip.style.visibility == '' || tooltip.style.visibility == 'hidden') {
            tooltip.style.visibility = 'visible';
        } else if (tooltip.style.visibility == 'visible') {
            tooltip.style.visibility = 'hidden';
        }
    }

    closeTooltipHelp(id) {
        const tooltip = document.querySelector(`#tooltip-${id}`) as HTMLElement;
        tooltip.style.visibility = 'hidden';
    }

    openTooltipGroup(camera: Camera) {
        camera.show_groups = !camera.show_groups;
        const tooltip = document.querySelector(`#tooltip-group-${camera.id}`) as HTMLElement;
        if (tooltip.style.visibility == '' || tooltip.style.visibility == 'hidden') {
            tooltip.style.visibility = 'visible';
        } else if (tooltip.style.visibility == 'visible') {
            tooltip.style.visibility = 'hidden';
        }
    }

    updateThumbnail(index: number) {
        if (this.cameras[index].active && !this.cameras[index].refresh_thumb) {
            this.indexUpdatingThumb = index;
            this.cameras[index].refresh_thumb = true;
            const cameraThumbnail: CameraThumbnail = {
                hashname: this.cameras[index].hashname,
                sub: this.userSub,
                uri: this.cameras[index].protocol == 'RTSP' ? this.cameras[index].link_rtsp : this.cameras[index].link_rtmp,
                refresh: true
            };
            this.store.dispatch(CameraActions.create_camera_thumbnail({
                camera_thumbnail: cameraThumbnail
            }));
        }
    }

    // Retorna o feedback de cadastro da câmera
    getCreateThumbnailResult() {
        this.cameraThumbnail = this.camera_thumb$.subscribe(async result => {
            if (result && this.cameras[this.indexUpdatingThumb].refresh_thumb) {
                result = result as string;
                this.cameras[this.indexUpdatingThumb].refresh_thumb = false;
                await this.IS.updateImageToDatabase(result);
                this.cameras[this.indexUpdatingThumb].cache_thumb = of(
                    await this.IS.getCSSBackgroundImageURL(result)
                );
                this.validationResponse.validationResponseHandler(200, this.pageName, 'update-thumbnail', 'cameras.updateThumbSuccess');
            }
            CameraActions.create_camera_thumbnail_cleanup();
            this.indexUpdatingThumb = undefined;
        });
    }

    deleteCamera(camera: Camera) {
        this.delCamera = camera;
        if (camera.protocol == 'RTMP') {
            const dataModal = {
                data: {
                    camera: camera
                },
                tipoModal: 'delete_rtmp',
                modalWidth: '60vw'
            };
            this.service.returnDataToModal(dataModal);
        } else {
            this.validationResponse.validationResponseHandler(200, this.pageName, 'confirm-delete-camera', 'cameras.delete_camera');
        }
    }

    hasCameraDeleted(): boolean {
        if (this.loadingCameraList) {
            return false;
        }

        const index = this.cameras.findIndex(element => {
            return element.deleted;
        });

        return (index >= 0);
    }

    brokenImageHandler(element) {
        element.error = false;
        element.target.src = '../../../assets/img/broken_link.svg';
    }

    goToDashboard() {
        this.router.navigateByUrl('/home');
    }

    requestSearch() {
        clearTimeout(this.debounceSearch);
        const query = this.searchInputControl.value;
        this.debounceSearch = setTimeout(() => {
            this.store.dispatch(CameraActions.get_cameras({
                user_sub: this.userSub,
                child_sub: this.client_sub,
                str_filter: query,
                cam_stat_filter: (this.placeholder == 1) ? true : (this.placeholder == 2) ? false : null,
                limit: this.camLimit,
                page: 1,
                clients_id: this.clientsID,
                sort_by: this.sortBy,
                active: (this.placeholder == 3) ? false : (this.placeholder == 4) ? true : null
            }));
            this.loadingDashCameras = true;
        }, 300);
    }

    getClients() {
        this.store.dispatch(CameraActions.get_clients_group({
            user_sub: localStorage.getItem('sub')
        }));
        this.clientsSub = this.clientsTogroup$.subscribe(result => {
            if (result && result.length > 0) {
                this.clients = result;
                this.clientSimple = result.map(element => {
                    return {
                        id: element.client_id,
                        text: element.client_name
                    };
                });
            }
        });
    }

    selectClient(event) {
        this.clientsID = event;
        clearTimeout(this.debounceSearch);
        const queryStr = this.searchInputControl.value;
        this.debounceSearch = setTimeout(() => {
            this.store.dispatch(CameraActions.get_cameras({
                user_sub: this.userSub,
                child_sub: this.client_sub,
                str_filter: queryStr,
                limit: this.camLimit,
                cam_stat_filter: (this.placeholder == 1) ? true : (this.placeholder == 2) ? false : null,
                page: 1,
                clients_id: this.clientsID,
                sort_by: this.sortBy,
                active: (this.placeholder == 3) ? false : (this.placeholder == 4) ? true : null
            }));
            this.loadingDashCameras = true;
        }, 1000);
    }

    filtersOn() {
        return this.searchInputControl.value != '' || this.clientsID || [2, 3].includes(this.placeholder);
    }

    scrolltoTop() {
        if (this.partnerDashboard || !this.hasGroups || (!this.is_guest && !this.isClientAssociate)) {
            document?.getElementById('top-list').scrollIntoView();
        }
        else {
            document?.getElementById('cam-table').scrollIntoView();
        }
    }
}
