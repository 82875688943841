import { SharedService } from 'app/Shared/Services/shared.service';
import Swal from 'sweetalert2';
import { ResultAlert } from '../models';
import { showAlert } from '../Helpers/showAlert';

/**
 * Classe reponsável por validar as requisições e chamar o feedback do usuario
 */
export class ValidationResponseUsers {
    resultAlert: ResultAlert;
    constructor() {
    }

    swalDanger(title: string) {
        showAlert(title, 'danger');
    }

    swalWarning(title: string) {
        showAlert(title, 'warning');
    }

    swalSuccess(title: string) {
        showAlert(title, 'success');
    }


    swal_error_400(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            icon: 'error',
        });
    }

    return_data_error_400(title: string, swalParameters: any) {
        Swal.fire({
            title: title,
            text: swalParameters,
            icon: 'error',
        });
    }

    client_deletion_400(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: title['confirmButtonColor'],
            confirmButtonText: title['confirmButtonText'],
            cancelButtonColor: title['cancelButtonColor'],
            cancelButtonText: title['cancelButtonText']
        }).then((result) => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'deleteClient');
        });
    }

    return_edit_payment_success_200(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            icon: 'success',
        });
    }

    return_edit_payment_success_400(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            icon: 'error',
        });
    }

    data_update_success_200(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            icon: 'success',
            showConfirmButton: false
        });
    }

    photo_max_size_error_400(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            icon: title['icon'],
            confirmButtonColor: title['confirmButtonColor'],
            confirmButtonText: title['confirmButtonText'],
        });
    }

    photo_min_size_error_400(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            icon: title['icon'],
            confirmButtonColor: title['confirmButtonColor'],
            confirmButtonText: title['confirmButtonText'],
        });
    }

    disable_analytic_200(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            icon: title['icon'],
            showCancelButton: true,
            confirmButtonColor: title['confirmButtonColor'],
            cancelButtonColor: title['cancelButtonColor'],
            confirmButtonText: title['confirmButtonText'],
            cancelButtonText: title['cancelButtonText'],
        }).then((result) => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'openModalSub');
        });
    }

    client_view_confirm_delete_client_200(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            imageUrl: '../../../assets/img/delete_icon.svg',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            showCancelButton: true,
            confirmButtonColor: title['confirmButtonColor'],
            cancelButtonColor: title['cancelButtonColor'],
            confirmButtonText: title['confirmButtonText'],
            cancelButtonText: title['cancelButtonText'],
        }).then((result) => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'deleteClient');
        });
    }

    client_view_confirm_delete_partner_200(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            imageUrl: '../../../assets/img/delete_icon.svg',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            showCancelButton: true,
            confirmButtonColor: title['confirmButtonColor'],
            cancelButtonColor: title['cancelButtonColor'],
            confirmButtonText: title['confirmButtonText'],
            cancelButtonText: title['cancelButtonText'],
        }).then((result) => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'deletePartner');
        });
    }

    client_view_confirm_block_client_200(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            imageUrl: '../../../assets/img/info_icon.svg',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            showCancelButton: true,
            confirmButtonColor: title['confirmButtonColor'],
            cancelButtonColor: title['cancelButtonColor'],
            confirmButtonText: title['confirmButtonText'],
            cancelButtonText: title['cancelButtonText'],
        }).then((result) => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'blockClient');
        });
    }

    client_view_confirm_block_partner_200(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            imageUrl: '../../../assets/img/info_icon.svg',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            showCancelButton: true,
            confirmButtonColor: title['confirmButtonColor'],
            cancelButtonColor: title['cancelButtonColor'],
            confirmButtonText: title['confirmButtonText'],
            cancelButtonText: title['cancelButtonText'],
        }).then((result) => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'blockPartner');
        });
    }

    client_view_confirm_unblock_client_200(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            imageUrl: '../../../assets/img/info_icon.svg',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            showCancelButton: true,
            confirmButtonColor: title['confirmButtonColor'],
            cancelButtonColor: title['cancelButtonColor'],
            confirmButtonText: title['confirmButtonText'],
            cancelButtonText: title['cancelButtonText'],
        }).then((result) => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'unblockClient');
        });
    }

    user_associated_confirm_delete_guest_200(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            imageUrl: '../../../assets/img/delete_icon.svg',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            showCancelButton: true,
            confirmButtonColor: '#326ABC',
            cancelButtonColor: '#C5C9D1',
            confirmButtonText: title['confirmButton'],
            cancelButtonText: title['cancelButton']
        }).then(result => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'deleteAssociated');
        });

    }

    userInventory_403(message: string, swalParameters: any) {
        Swal.fire({
            title: message['title'],
            text: swalParameters,
            confirmButtonColor: message['confirmButtonColor'],
            cancelButtonColor: message['cancelButtonColor'],
            confirmButtonText: message['confirmButtonText'],
            cancelButtonText: message['cancelButtonText'],
            showCancelButton: true,
            imageUrl: '../../../assets/img/info_icon.svg',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image'
        }).then((result) => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'userInventoryError');
        });
    }

    user_confirm_delete_client_inventory_200(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            imageUrl: '../../../assets/img/delete_icon.svg',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            showCancelButton: true,
            confirmButtonColor: '#326ABC',
            cancelButtonColor: '#C5C9D1',
            confirmButtonText: title['confirmButton'],
            cancelButtonText: title['cancelButton']
        }).then(result => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'deleteClientInventory');
        });
    }

    user_kits_delete_kit_confirmation(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            imageUrl: '../../../assets/img/delete_icon.svg',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            showCancelButton: true,
            confirmButtonColor: title['confirmButtonColor'],
            cancelButtonColor: title['cancelButtonColor'],
            confirmButtonText: title['confirmButtonText'],
            cancelButtonText: title['cancelButtonText']
        }).then(result => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'deleteKit');
        });
    }

    calendar_update_active_200() {
        Swal.fire({
            title: 'Habilitar Feriado?',
            text: 'Essa ação poderá ser revertida a qualquer momento.',
            imageUrl: '../../../assets/img/info_icon.svg',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            showCancelButton: true,
            confirmButtonColor: '#326ABC',
            cancelButtonColor: '#C5C9D1',
            confirmButtonText: 'Habilitar',
            cancelButtonText: 'Cancelar',
        }).then(result => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'updateActive');
        });

    }

    calendar_update_inactive_200() {
        Swal.fire({
            title: 'Desabilitar Feriado?',
            text: 'Essa ação poderá ser revertida a qualquer momento.',
            imageUrl: '../../../assets/img/info_icon.svg',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            showCancelButton: true,
            confirmButtonColor: '#326ABC',
            cancelButtonColor: '#C5C9D1',
            confirmButtonText: 'Desabilitar',
            cancelButtonText: 'Cancelar',
        }).then(result => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'updateInactive');
        });
    }

    calendar_confirm_delete_200() {
        Swal.fire({
            title: 'Excluir Feriado?',
            text: 'Essa ação não poderá ser revertida.',
            imageUrl: '../../../assets/img/delete_icon.svg',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            showCancelButton: true,
            confirmButtonColor: '#326ABC',
            cancelButtonColor: '#C5C9D1',
            confirmButtonText: 'Excluir',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'deleteCalendar');
        });
    }

    user_kits_master_delete_kit_confirmation(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            imageUrl: '../../../assets/img/delete_icon.svg',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            showCancelButton: true,
            confirmButtonColor: title['confirmButtonColor'],
            cancelButtonColor: title['cancelButtonColor'],
            confirmButtonText: title['confirmButtonText'],
            cancelButtonText: title['cancelButtonText']
        }).then(result => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'deleteMasterKit');
        });
    }

}
