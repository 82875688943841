<div class="main-content background" *ngIf="!loading && !loadingCameras">
    <div class="header">
        <span class="title" *ngIf="childProfile != 'Convidado' && childProfile != 'Parceiro'">Dados do cliente</span>
        <span class="title" *ngIf="childProfile == 'Convidado'">Dados do convidado</span>
        <span class="title" *ngIf="childProfile == 'Parceiro'">Dados do parceiro</span>
        <span (click)="edit()" class="clickable edit" [ngClass]="{'active' : onEdit}" *ngIf="client.child_profile != 'Cliente Associado' && client.child_profile != 'Convidado' && (!isAssociate || adminAssociate)">Editar <span nz-icon nzType="edit" nzTheme="outline"></span></span>
    </div>

    <div class="form-container">
        <form nz-form [formGroup]="clientForm">
            <div class="form-row flex">
                <div class="label-container half-width">
                    <div class="label-email"><span>Nome</span></div>
                    <input nz-input [readonly]="!onEdit" type="text" placeholder="Digite aqui..." formControlName="name" class="input-container" />
                    <mat-error *ngIf="clientForm.get('name').hasError('required') && clientForm.get('name').touched">
                        Nome <strong>é requerido</strong>
                    </mat-error>
                </div>
                <div class="label-container half-width email-client" nz-tooltip nzTooltipTitle="E-mail não pode ser mudado"
                    nzTooltipOverlayClassName="cam-name" nzTooltipPlacement="topLeft" [nzTooltipOverlayStyle]="{'top': '12px'}">
                    <div class="label-email"><span>E-mail</span></div>
                    <input nz-input [readonly]="true" type="text" placeholder="Digite aqui..." formControlName="email" class="input-container" />
                    <mat-error *ngIf="clientForm.get('email').hasError('required') && clientForm.get('email').touched">
                        E-mail <strong>é requerido</strong>
                    </mat-error>
                </div>
                <span class="resend clickable" (click)="resendEmail()" *ngIf="!successEmail && !loadingMail && (!isAssociate || adminAssociate)">Reenviar convite</span>
                <span nz-icon *ngIf="loadingMail" nzType="loading" nzTheme="outline" style="font-size:15px"></span>
                <span class="resend" *ngIf="successEmail"><i class="material-icons">check_circle</i> Convite enviado</span>
            </div>

            <div class="form-row flex">
                <ng-container *ngIf="childProfile == 'Parceiro'">
                    <div class="label-container half-width">
                        <div class="label-email"><span>CPF/CNPJ</span></div>
                        <input nz-input mask="CPF_CNPJ" [readonly]="!onEdit" type="text" placeholder="Digite aqui..." formControlName="cpforcnpj" class="input-container" />
                        <mat-error *ngIf="clientForm.get('cpforcnpj').hasError('required') && clientForm.get('cpforcnpj').touched">
                            CPF/CNPJ <strong>é requerido</strong>
                        </mat-error>
                    </div>
                </ng-container>
                <ng-container *ngIf="childProfile != 'Parceiro'">
                    <div class="label-container quarter-width">
                        <div class="label-email"><span>Telefone</span></div>
                        <input nz-input [readonly]="!onEdit" mask="(00) 0000-0000||(00) 0 0000-0000" type="text" placeholder="Digite aqui..." formControlName="phone" class="input-container" />
                        <mat-error *ngIf="clientForm.get('phone').hasError('required') && clientForm.get('phone').touched">
                            Telefone <strong>é requerido</strong>
                        </mat-error>
                    </div>
                    <div class="label-container quarter-width">
                        <div class="label-email"><span>CPF/CNPJ</span></div>
                        <input nz-input mask="CPF_CNPJ" [readonly]="!onEdit" type="text" placeholder="Digite aqui..." formControlName="cpforcnpj" class="input-container" />
                        <mat-error *ngIf="clientForm.get('cpforcnpj').hasError('required') && clientForm.get('cpforcnpj').touched">
                            CPF/CNPJ <strong>é requerido</strong>
                        </mat-error>
                    </div>
                </ng-container>
                <div class="label-container quarter-width">
                    <div class="label-email"><span>Senha</span></div>
                    <input nz-input [readonly]="!onEdit" type="password" placeholder="Digite aqui..." formControlName="password"
                            class="input-container" (change)="checkPassword()" />
                </div>
                <span class="resend clickable" (click)="editPassword()" *ngIf="onEdit && !changePassword">Alterar senha</span>
                <div class="label-container quarter-width" *ngIf="changePassword">
                    <div class="label-email"><span>Confirmar senha</span></div>
                    <input nz-input [readonly]="!onEdit" type="password" placeholder="Digite aqui..." formControlName="confirm"
                            class="input-container" (change)="checkPassword()" onpaste="return false;"/>
                </div>
            </div>
            <div class="form-row" *ngIf="childProfile != 'Parceiro' && !costCenterMark && childProfile !== 'Convidado'">
                <ng-container>
                    <div class="label-container quarter-width">
                        <div class="label-email"><span>Centro de Custo</span></div>
                        <input nz-input [readonly]="!onEdit" type="text" placeholder="Digite aqui..." formControlName="costCenter" class="input-container"/>
                    </div>
                </ng-container>
            </div>

            <div class="form-row flex" *ngIf="childProfile == 'Parceiro'">
                <ng-container>
                    <div class="label-container half-width email-clien" nz-tooltip nzTooltipTitle="Só o Parceiro altera o contrato"
                    nzTooltipOverlayClassName="cam-name" nzTooltipPlacement="topLeft" [nzTooltipOverlayStyle]="{'top': '12px'}">
                        <div class="label-email"><span>Link do contrato</span></div>
                        <input nz-input [readonly]="true" type="text" placeholder="Digite aqui..." formControlName="contractLink" class="input-container"/>
                    </div>
                    <div class="label-container quarter-width" *ngIf="!costCenterMark || childProfile == 'Parceiro'">
                        <div class="label-email"><span>Centro de Custo</span></div>
                        <input nz-input [readonly]="!onEdit" type="text" placeholder="Digite aqui..." formControlName="costCenter" class="input-container"/>
                    </div>
                    <div class="label-container quarter-width" *ngIf="!costCenterMark || childProfile == 'Parceiro'">
                        <label class="cost-center-bond" nz-checkbox formControlName="costCenterBond" >Vincular Centro de Custo 
                          do Parceiro aos seus clientes</label>
                    </div>
                </ng-container>
            </div>

            <div class="form-row flex" *ngIf="childProfile != 'Convidado' && childProfile != 'Parceiro'">
                <ng-container>
                    <div class="label-container quarter-width">
                        <div class="holiday-calendar-button" (click)="openHolidayView()">
                            <span nz-icon nzType="calendar" nzTheme="outline" style="font-size: 20px; margin-right: 3px;"></span>
                            Calendário De Feriados
                        </div>
                    </div>
                </ng-container>
            </div>

            <div class="form-row" *ngIf="this.isMaster">
                <div class="button-container" *ngIf="childProfile == 'Parceiro'">
                    <div class="label-container half-width even-line">
                        <span class="permission-label slider-text">Permitir visualização de câmeras ao vivo e gravações de usuários cadastrados</span>
                        <!-- <mat-slide-toggle formControlName="permissionPartner" [disabled]="!onEdit"></mat-slide-toggle> -->
                        <nz-switch formControlName="permissionPartner" [nzDisabled]="!onEdit"></nz-switch>
                    </div>
                </div>
            </div>

            <div class="form-row" *ngIf="childProfile != 'Convidado' && childProfile != 'Parceiro'">
                <div class="button-container">
                    <div class="label-container even-line">
                        <span class="slider-text">Central de monitoramento</span>
                        <!-- <mat-slide-toggle formControlName="monitoringButton" [disabled]="!onEdit"></mat-slide-toggle> -->
                        <nz-switch formControlName="monitoringButton" [nzDisabled]="!onEdit" (ngModelChange)="changePanicButton()"></nz-switch>
                    </div>
                    <div class="label-container">
                        <span class="slider-text">Botão de pânico</span>
                        <!-- <mat-slide-toggle formControlName="panicButton" [disabled]="!onEdit"></mat-slide-toggle> -->
                        <nz-switch formControlName="panicButton" [nzDisabled]="!onEdit || !clientForm.get('monitoringButton').value"></nz-switch>
                    </div>
                </div>

                <div *ngIf="clientForm.get('monitoringButton').value" class="contact-container">
                    <span class="section-header">Contato de emergência</span>
                    <div class="form-row flex">
                        <div class="label-container half-width">
                            <div class="label-email"><span>Nome</span></div>
                            <input nz-input [readonly]="!onEdit" type="text" placeholder="Digite aqui..." formControlName="panicName" class="input-container"/>
                        </div>
                        <div class="label-container half-width">
                            <div class="label-email"><span>Telefone</span></div>
                            <input nz-input mask="(00) 0000-0000||(00) 0 0000-0000" [readonly]="!onEdit" type="text" placeholder="Digite aqui..." formControlName="panicPhone" class="input-container"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-row button-row flex" *ngIf="onEdit">
                <button class="btn btn-primary cancel-button" (click)="cancelEdit()">CANCELAR</button>
                <button class="btn btn-primary delete-button" (click)="save()" [attr.disabled]="loadingSave ? '' : null">
                    <span *ngIf="!loadingSave">SALVAR</span>
                    <span nz-icon nzType="loading" nzTheme="outline" *ngIf="loadingSave"></span>
                </button>
            </div>

            <div class="form-row" *ngIf="childProfile == 'Convidado'">
                <span class="label-client" *ngIf="client.child_profile != 'Cliente Associado'">Cliente vinculado</span>
                <span class="label-client" *ngIf="client.child_profile == 'Cliente Associado'">Cliente vinculado à conta</span>
            </div>

            <div class="container-client" *ngIf="childProfile == 'Convidado'">
                <ng-container *ngIf="client.child_profile != 'Cliente Associado'">
                    <div>
                        <span class="label-client-value">Cliente</span>
                        <div class="container-values-client" *ngFor="let invited of client.invites">
                            <div class="label-container">
                                <input readonly nz-input type="text" [value]="invited.user_name" class="input-container">
                            </div>
                        </div>
                    </div>
                    <div>
                        <span class="label-client-value">E-mail</span>
                        <div class="container-values-client" *ngFor="let invited of client.invites">
                            <div class="label-container">
                                <input readonly nz-input type="text" [value]="invited.user_email" class="input-container">
                            </div>
                        </div>
                    </div>
                    <div>
                        <span class="label-client-value">Câmera compartilhada</span>
                        <div class="container-values-client" *ngFor="let invited of client.invites">
                            <div class="label-container">
                                <input readonly nz-input type="text" [value]="invited.cam_name" class="input-container">
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="client.child_profile == 'Cliente Associado'">
                    <div>
                        <span class="label-client-value">Cliente</span>
                        <div class="container-values-client">
                            <div class="label-container">
                                <input readonly nz-input type="text" [value]="client.associated.name" class="input-container">
                            </div>
                        </div>
                    </div>
                    <div>
                        <span class="label-client-value">E-mail</span>
                        <div class="container-values-client">
                            <div class="label-container">
                                <input readonly nz-input type="text" [value]="client.associated.email" class="input-container">
                            </div>
                        </div>
                    </div>
                    <div>
                        <span class="label-client-value">Permissão do convidado</span>
                        <div class="container-values-client">
                            <div class="label-container">
                                <input readonly nz-input type="text" [value]="client.associated.permissions? client.associated.permissions[0].alias : ''" class="input-container">
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </form>


        <div class="grid-button-selecion" *ngIf="!onEdit && childProfile != 'Convidado' && (!isAssociate || adminAssociate)">
            <div class="flex">
                <div class="col-xs-6">
                    <button class="btn btn-primary block-button" (click)="blockClient()" [disabled]="loadingBlock" *ngIf="childProfile != 'Parceiro'">
                        <span *ngIf="!loadingBlock">{{blockClientActive ? 'Bloquear' : 'Desbloquear'}} Cliente </span>
                        <span nz-icon nzType="loading" nzTheme="outline" *ngIf="loadingBlock" class="load"></span>
                    </button>
                    <!-- <button class="btn btn-primary block-button" (click)="blockPartner()" [disabled]="loadingBlock" *ngIf="childProfile == 'Parceiro'">
                        <span *ngIf="!loadingBlock">{{blockPartnerActive ? 'Bloquear' : 'Desbloquear'}} Parceiro </span>
                        <span nz-icon nzType="loading" nzTheme="outline" *ngIf="loadingBlock" class="load"></span>
                    </button> -->
                </div>
                <div class="col-xs-6 delete-div">
                    <button *ngIf="cameras?.length >= 5 && childProfile != 'Parceiro'" [class.block-deletion]="cameras == undefined || cameras?.length >= 5 || hasRTMP"
                        class="btn btn-primary delete-button" (click)="deleteClient()" nz-popover [nzPopoverContent]="popOverDelete"
                        nzPopoverOverlayClassName="tooltip-delete-client"
                        ><span class="delete-icon" nz-icon nzType="delete" nzTheme="outline"></span> Excluir Cliente
                    </button>
                    <button *ngIf="cameras?.length >= 5 && childProfile == 'Parceiro'" [class.block-deletion]="cameras == undefined || cameras?.length >= 5 || hasRTMP"
                        class="btn btn-primary delete-button" (click)="deletePartner()" nz-popover [nzPopoverContent]="popOverDelete"
                        nzPopoverOverlayClassName="tooltip-delete-client"
                        ><span class="delete-icon" nz-icon nzType="delete" nzTheme="outline"></span> Excluir Parceiro
                    </button>
                    <button *ngIf="cameras?.length < 5 && !hasRTMP && childProfile != 'Parceiro'" class="btn btn-primary delete-button" 
                            (click)="deleteClient()" [disabled]="deleteLoading">
                        <span class="text-content"><span class="delete-icon" nz-icon nzType="delete" nzTheme="outline"></span> Excluir Cliente</span>
                        <span nz-icon nzType="loading" nzTheme="outline" *ngIf="deleteLoading" class="load"></span>
                    </button>
                    <button *ngIf="(cameras?.length < 5 || !hasRTMP) && childProfile == 'Parceiro'" class="btn btn-primary delete-button"
                            (click)="deletePartner()" [disabled]="deleteLoading">
                        <span class="text-content"><span class="delete-icon" nz-icon nzType="delete" nzTheme="outline"></span> Excluir Parceiro</span>
                        <span nz-icon nzType="loading" nzTheme="outline" *ngIf="deleteLoading" class="load"></span>
                    </button>
                    <ng-template id="tooltip-delete-client" #popOverDelete>
                        <span style="display:flex; flex-direction: column; align-items: center;"*ngIf="cameras?.length >= 5">
                            Esse cliente possui 5 ou mais câmeras cadastradas com detecção, exclua as câmeras para poder excluir o cliente.
                            <button class="btn btn-primary cam-list" (click)="goToList()">Ir para a listagem de câmeras</button>
                        </span>
                    </ng-template>
                </div>
            </div>
        </div>

        <div class="grid-button-selecion" *ngIf="!onEdit && childProfile == 'Convidado' && (!isAssociate || adminAssociate)">
            <button class="btn btn-primary delete-button" (click)="addClient()">CONVERTER EM CLIENTE</button>
        </div>
    </div>
</div>

<div class="center-spinner" *ngIf="loading || loadingCameras">
    <span nz-icon nzType="loading" nzTheme="outline" style="font-size:50px"></span>
</div>

<app-modal></app-modal>
