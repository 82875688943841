<div class="main-content">
  <div class="container-fluid card-view container_global" id="containerForm">
    <div *ngIf="!dataLoading" class="summary-content">
      <!--header-->
      <div #perfil id="header">
        <div class="row">
          <span [ngStyle]="{'margin-left.px': 15}" class="ml-4 md:ml-6 config-title">Configurações gerais da conta</span>
        </div>
      </div>
      <!--Editar foto e configurações de alarme-->
      <div id="form1">
        <div class="box-border mb-4 md:mb-6 flex sm:flex-row md:flex-col" [ngStyle]="{'margin-bottom.px': 25}">
          <div class="flex lt-md:w-36">
            <div #fileinput *ngIf="!profileLoading"  data-provides="fileinput"
            class="block lt-md:inline-block lt-md:mb-0 fileinput ml-auto mr-auto fileinput-new text-center container_inherit">
            <div class="lt-md:mb-0 fileinput-new thumbnail img-circle">
                <img [src]="getPlaceholder()" alt="...">
                <span class="btn-file overlay">
                  <div class="fileinput-new add_pic"><i class="material-icons">add_a_photo</i></div>
                  <input #inputfile type="file" (change)="updatePicture($event)" name="..." id="file" />
                </span>
              </div>
              <div #previewContainer class="fileinput-preview fileinput-exists thumbnail img-circle">
              </div>
            </div>
            <div *ngIf="profileLoading" class="fileinput ml-auto mr-auto text-center container_inherit">
              <span nz-icon class="thumbnail mat_spinner" nzType="loading" nzTheme="outline" style="font-size:50px"></span>
            </div>
          </div>
          <div class="block lt-md:w-72 lt-md:flex-initial lt-md:ml-4 name_margin">
            <span class="username">{{getField("name")}}</span>
          </div>
        </div>
        <div class="lt-md:hidden col">
          <span class="username username_color">______________________________</span>
        </div>
        <div class="lt-md:hidden col container_register">
          <div class="ml-auto mr-auto text-center flex_direction">
            <div class="flex sm:flex-row sm:gap-2.5 lt-md:items-center lt-md:justify-center cards_registers">
              <i (click)="scrollToElement(perfil, 0, 'perfil')" class="material-icons-outlined"
                [ngClass]="(currentScroll === 0) ? 'step-marked-style' : 'steps-style'">fiber_manual_record</i>
              <span [ngClass]="(currentScroll === 0) ? 'step-marked-title-style' : 'step-title-style'"
                (click)="scrollToElement(perfil, 0, 'perfil')">
                Perfil
              </span>
            </div>
            <div class="flex sm:flex-row sm:gap-2.5 lt-md:items-center lt-md:justify-center cards_registers" *ngIf="isClientAssociate || isAssociate">
              <i (click)="scrollToElement(cliente_vinculado, 1, 'cliente_vinculado')" class="material-icons-outlined"
                [ngClass]="(currentScroll === 1) ? 'step-marked-style' : 'steps-style'">fiber_manual_record</i>
              <span [ngClass]="(currentScroll === 1) ? 'step-marked-title-style' : 'step-title-style'"
                (click)="scrollToElement(cliente_vinculado, 1, 'cliente_vinculado')">
                Dados do cliente vinculado
              </span>
            </div>
            <div class="flex sm:flex-row sm:gap-2.5 lt-md:items-center lt-md:justify-center cards_registers"
                 *ngIf="!isClientAssociate && !isGuestCam && !isMaster">
              <i (click)="scrollToElement(convidados_conta, 2, 'convidados_conta')" class="material-icons-outlined"
                [ngClass]="(currentScroll === 2) ? 'step-marked-style' : 'steps-style'">fiber_manual_record</i>
              <span [ngClass]="(currentScroll === 2) ? 'step-marked-title-style' : 'step-title-style'"
                (click)="scrollToElement(convidados_conta, 2, 'convidados_conta')">
                Convidados da conta
              </span>
            </div>
          </div>
        </div>
      </div>
      <!--Formulário com dados gerais-->
      <form [formGroup]="editForm">
        <div id="form2">
          <div class="forms-areas">
            <div class="flex flex-row flex-wrap gap-1 perfil" *ngIf="viewProfile">
              <div class="flex w-full form-section">
                <span style="margin-left: 1.5%;">Perfil</span>
              </div>
              <div class="flex w-full">
                <p class="data-wrapper">Dados pessoais</p>
              </div>
              <div class="flex w-11/12 form-input">
                <div class="form-group">
                  <label class="bmd-label-floating">Nome</label>
                  <input formControlName="name" [placeholder]="validateField(currentUser.name)" type="text"
                    class="form-control">
                </div>
              </div>
              <div class="flex w-1/2 form-input">
                <div class="form-group">
                  <label *ngIf="isPartner" class="bdm-label-floating">CNPJ</label>
                  <label *ngIf="!isPartner" class="bdm-label-floating">CPF/CNPJ</label>
                  <input class="form-control" [placeholder]="validateField(currentUser.cpf_cnpj)"
                          mask="CPF_CNPJ" type="text" formControlName="cpf">
                </div>
              </div>
              <div class="flex w-2/5 form-input">
                <div class="form-group form-input">
                  <label class="bdm-label-floating">Celular</label>
                  <input class="form-control" [placeholder]="validateField(currentUser.telephone)"
                          mask="(00) 0000-0000||(00) 0 0000-0000" type="text" formControlName="cell">
                </div>
              </div>
              <div class="flex w-full">
                <p class="data-wrapper mind-the-gap">Dados de acesso</p>
              </div>
              <div class="flex w-1/2 form-input">
                <div class="form-group">
                  <label class="bmd-label-floating">Email</label>
                  <input formControlName="email" [placeholder]="validateField(currentUser.email)" type="email"
                    class="form-control">
                </div>
              </div>
              <div class="flex w-25">
                <div class="form-group">
                  <label class="bmd-label-floating">Senha</label>
                  <input disabled type="password" value="xxxxxxxx" class="form-control password_color">
                </div>
              </div>
              <div class="flex w-1/6 pass">
                <span class="change" (click)="openModalChangePassword()">Alterar senha</span>
              </div>
              <div class="flex w-1/2 form-input" *ngIf="isPartner">
                <div class="form-group">
                  <label class="bmd-label-floating">Email de suporte</label>
                  <input formControlName="emailSuport" [placeholder]="validateField(currentUser.email_suport)" 
                    type="email" class="form-control">
                </div>
              </div>
              <div class="flex w-1/2 form-input" *ngIf="isPartner">
                <div class="form-group">
                  <label class="bmd-label-floating">Link do contrato</label>
                  <input formControlName="contract_link" [placeholder]="validateField(currentUser.contract_link)" 
                      disabled type="text" class="form-control">
                </div>
              </div>
              <div class="flex w-3/12" class="pass" *ngIf="isPartner">
                <span class="change" (click)="openModalChangeLink()">Alterar link</span>
              </div>
              <div class="flex w-full">
                <p class="data-wrapper mind-the-gap">Dados residenciais</p>
              </div>
              <div class="flex w-9/12 form-input">
                <div class="form-group">
                  <label class="bmd-label-floating">Logradouro</label>
                  <input formControlName="street" [placeholder]="validateField(currentUser.address.street)" type="text"
                    class="form-control" maxlength="70">
                </div>
              </div>
              <div class="flex w-2/12">
                <div class="form-group input_number_remove_arrows">
                  <label class="bmd-label-floating">Número</label>
                  <input formControlName="num" [placeholder]="validateField(currentUser.address.number)"
                    type="text" class="form-control" onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="5">
                </div>
              </div>
              <div class="flex w-2/5 form-input">
                <div class="form-group">
                  <label class="bmd-label-floating">Complemento</label>
                  <input formControlName="complemento" [placeholder]="validateField(currentUser.address.comp)"
                    type="text" class="form-control" maxlength="70">
                </div>
              </div>
              <div class="flex w-1/2 form-input">
                <div class="form-group">
                  <label class="bmd-label-floating">CEP</label>
                  <input formControlName="CEP" [placeholder]="validateField(currentUser.address.CEP)" mask="00000-000"
                  showMaskTyped="true" shownMaskExpression="_____-___" type="text" class="form-control">
                  <mat-error *ngIf="editForm.get('CEP').hasError('CEPInvalid')">
                    CEP <strong>inválido</strong>
                  </mat-error>
                </div>
              </div>
              <div class="flex justify-between">
                <div class="flex w-1/4">
                  <div class="form-group">
                    <label class="bmd-label-floating">Bairro</label>
                    <input formControlName="bairro" [placeholder]="validateField(currentUser.address.bairro)" type="text"
                      class="form-control" maxlength="70">
                  </div>
                </div>
                <div class="flex w-1/4">
                  <div class="form-group">
                    <label class="bmd-label-floating">Cidade</label>
                    <input formControlName="cidade" [placeholder]="validateField(currentUser.address.city)" type="text"
                      class="form-control" maxlength="70">
                  </div>
                </div>
                <div class="flex w-1/4 alignRow">
                  <div class="form-group">
                    <label class="bmd-label-floating" style="margin-bottom: 0px;">Estado</label>
                    <mat-form-field>
                      <mat-select formControlName="estado">
                        <mat-option *ngIf="currentUser.address.state">{{currentUser.address.state}}</mat-option>
                        <mat-option *ngFor="let state of states" [value]="state">
                          {{ state }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div id="footer" class="flex-row-reverse gap-1" *ngIf="viewProfile">
              <div class="row end-button">
                <button *ngIf="!loading" (click)="getCepAddress()" mat-raised-button type="submit"
                  class="btn btn-primary btn-round pull-right" [disabled]="editForm.invalid || loading">
                  Salvar
                </button>
                <span nz-icon *ngIf="loading" nzType="loading" nzTheme="outline" style="font-size:40px"></span>
              </div>
            </div>
            <div #cliente_vinculado class="flex flex-row flex-wrap gap-1 cliente_vinculado">
              <ng-container *ngIf="linkedCustomer && (isClientAssociate || isAssociate)">
                <div class="flex w-full" class="form-section">
                  <span style="margin-left: 1.5%; width: 30em;">Dados do Cliente Vinculado</span>
                </div>
                <div class="flex w-full">
                  <p class="data-wrapper">Dados pessoais</p>
                </div>
                <div class="flex w-11/12 form-input">
                  <div class="form-group">
                    <label class="bmd-label-floating">Nome</label>
                    <input type="text" class="form-control" [value]="currentUser.associated.name" disabled>
                  </div>
                </div>
                <div class="flex w-1/2 form-input">
                  <div class="form-group">
                    <label class="bdm-label-floating">CPF/CNPJ</label>
                    <input class="form-control" disabled mask="CPF_CNPJ" type="text" [value]="currentUser.associated.cpf">
                  </div>
                </div>
                <div class="flex w-2/5 form-input">
                  <div class="form-group">
                    <label class="bdm-label-floating">Celular</label>
                    <input class="form-control" disabled mask="(00) 0000-0000||(00) 0 0000-0000" type="text" [value]="currentUser.associated.phone_number">
                  </div>
                </div>
                <div class="flex w-full">
                  <p class="data-wrapper mind-the-gap">Dados residenciais</p>
                </div>
                <div class="flex w-9/12 form-input">
                  <div class="form-group">
                    <label class="bmd-label-floating">Logradouro</label>
                    <input type="text" class="form-control" maxlength="70" disabled [value]="currentUser.associated.address.street ? currentUser.associated.address.street : ''">
                  </div>
                </div>
                <div class="flex w-2/12">
                  <div class="form-group input_number_remove_arrows">
                    <label class="bmd-label-floating">Número</label>
                    <input type="text" class="form-control" [value]="currentUser.associated.address.number ? currentUser.associated.address.number : ''" 
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="5" disabled>
                  </div>
                </div>
                <div class="flex w-2/5 form-input">
                  <div class="form-group">
                    <label class="bmd-label-floating">Complemento</label>
                    <input type="text" class="form-control" maxlength="70" disabled [value]="currentUser.associated.address.comp ? currentUser.associated.address.comp : ''">
                  </div>
                </div>
                <div class="flex w-1/2 form-input">
                  <div class="form-group">
                    <label class="bmd-label-floating">CEP</label>
                    <input mask="00000-000" showMaskTyped="true" shownMaskExpression="_____-___" type="text"
                    class="form-control" disabled [value]="currentUser.associated.address.CEP">
                    <mat-error *ngIf="editForm.get('CEP').hasError('CEPInvalid')">
                      CEP <strong>inválido</strong>
                    </mat-error>
                  </div>
                </div>
                <div class="flex justify-between">
                  <div class="flex w-1/4">
                    <div class="form-group">
                      <label class="bmd-label-floating">Bairro</label>
                      <input type="text" class="form-control" maxlength="70" disabled [value]="currentUser.associated.address.bairro ? currentUser.associated.address.bairro : ''">
                    </div>
                  </div>
                  <div class="flex w-1/4">
                    <div class="form-group">
                      <label class="bmd-label-floating">Cidade</label>
                      <input type="text" class="form-control" maxlength="70" disabled [value]="currentUser.associated.address.city ? currentUser.associated.address.city : ''">
                    </div>
                  </div>
                  <div class="flex w-1/4 alignRow">
                    <div class="form-group" style="margin-top: 20px;">
                      <label class="bmd-label-floating">Estado</label>
                      <input type="text" class="form-control" maxlength="50" disabled  [value]="currentUser.associated.address.state ? currentUser.associated.address.state : ''">
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div id="settings" #convidados_conta class="flex flex-row flex-wrap gap-1">
              <ng-container *ngIf="!isClientAssociate && !isGuestCam && accountGuests">
                <div class="flex w-full form-section">
                  <span style="margin-left: 1.5%;">Convidados da conta</span>
                </div>
                <app-user-client-guests context="summary" style="width: 95%;"></app-user-client-guests>
              </ng-container>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="dataLoading" class="loading-indicator">
      <span nz-icon nzType="loading" nzTheme="outline" style="font-size:50px"></span>
    </div>
  </div>

</div>
<app-user-config-alarm (toggleEmitter)="onCancelModal($event)" (loadingEmitter)="onUpdateConfig($event)">
</app-user-config-alarm>
<app-user-crop-image (imageEmitter)="onCropImage($event)"></app-user-crop-image>
<app-user-change-password (loadChangePassEmitter)="upUserChangePassword($event)"></app-user-change-password>
<app-user-change-link (loadChangeLinkEmitter)="upUserChangeLink($event)"></app-user-change-link>
