import { Injectable } from '@angular/core';
import { Feedback, OperationStatus } from 'app/Shared/models';
import { createAction, props } from '@ngrx/store';
import { FluxStandardAction } from 'flux-standard-action';
import {
    User, ConfigAlarm, CognitoAttributes, UserChangePassword, Client,
    TypePayment, UserBillings, UserBillingsHistory, UserChangeBillingAddress, UserBillingsDetails, Partners, UserInventory,
    CameraAmount, KitAmount, UpdateClient, Kit, UserNotification, AssociatedUsers, TicketKit, Holidays,
} from '../models';

type Payload = Client | Client[] | Kit[] | UserInventory | UserNotification | Partners | User | User[] | string | number | ConfigAlarm | CognitoAttributes | Feedback | any | OperationStatus;

export interface Metadata {
    id?: number;
    detail?: boolean;
    profile?: number;
    sub?: string;
    name?: string;
    email?: string;
    password?: string;
    img64?: string;
    year?: number;
    month?: number;
    configList?: ConfigAlarm;
    user_sub?: string;
    child_sub?: string;
    cognitoAttr?: CognitoAttributes;
    updatePassword?: UserChangePassword;
    upAddress?: UserChangeBillingAddress;
    updatePayment?: TypePayment;
    user_billings?: UserBillings;
    user_billings_history?: UserBillingsHistory;
    user_billings_details?: UserBillingsDetails;
    buyers_sub?: string;
    cameras?: CameraAmount[];
    cameras_amount?: number;
    analytics_amount?: number;
    guests_amount?: number;
    client?: Client;
    edit_client?: UpdateClient;
    kits?: KitAmount[];
    active?: boolean;
    kit?: any;
    hashNotification?: string[] | string;
    requesterId?: number;
    str_filter?: string;
    cnpj?: string;
    id_plan?: number;
    partner_permission?: boolean;
    contract?: string;
    resend?: boolean;
    associates?: AssociatedUsers[];
    associateUser?: AssociatedUsers;
    limit?: number;
    page?: number;
    bodyTicket?: TicketKit;
    costCenter?: string;
    costCenterMark?: boolean;
    holidays?: Holidays[];
    idCamera?: number;
    hash_holiday?: string;
    foreignhash?: string;
}

export type UserAction = FluxStandardAction<Payload, Metadata>;

@Injectable()
export class UserActions {
    static readonly GET_USERS = 'GET_USERS';
    static readonly GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
    static readonly GET_USERS_FAILURE = 'GET_USERS_FAILURE';

    static readonly GET_USER = 'GET_USER';
    static readonly GET_USER_SUCCESS = 'GET_USER_SUCCESS';
    static readonly GET_USER_FAILURE = 'GET_USER_FAILURE';

    static readonly GET_USER_DATA = 'GET_USER_DATA';
    static readonly GET_USER_DATA_RESULT = 'GET_USER_DATA_RESULT';

    static readonly GET_USER_BILLINGS = 'GET_USER_BILLINGS';
    static readonly GET_USER_BILLINGS_RESULT = 'GET_USER_BILLINGS_RESULT';

    static readonly GET_USER_BILLINGS_HISTORY = 'GET_USER_BILLINGS_HISTORY';
    static readonly GET_USER_BILLINGS_HISTORY_RESULT = 'GET_USER_BILLINGS_HISTORY_RESULT';

    static readonly GET_USER_BILLINGS_DETAILS = 'GET_USER_BILLINGS_DETAILS';
    static readonly GET_USER_BILLINGS_DETAILS_RESULT = 'GET_USER_BILLINGS_DETAILS_RESULT';

    static readonly GET_USER_PAYMENT_METHOD = 'GET_USER_PAYMENT_METHOD';
    static readonly GET_USER_PAYMENT_METHOD_RESULT = 'GET_USER_PAYMENT_METHOD_RESULT';

    static readonly UPDATE_USER = 'UPDATE_USER';
    static readonly UPDATE_USER_RESULT = 'UPDATE_USER_RESULT';

    static readonly OPEN_MODAL_CROP = 'OPEN_MODAL_CROP';
    static readonly CLOSE_MODAL_CROP = 'CLOSE_MODAL_CROP';

    static readonly CHANGE_PASSWORD = 'CHANGE_PASSWORD';
    static readonly CHANGE_PASSWORD_RESULT = 'CHANGE_PASSWORD_RESULT';
    static readonly CHANGE_PASSWORD_MODAL = 'CHANGE_PASSWORD_MODAL';

    static readonly CHANGE_LINK_MODAL = 'CHANGE_LINK_MODAL';

    static readonly ADD_GUEST_MODAL = 'ADD_GUEST_MODAL';
    static readonly GUEST_SETTINGS_MODAL = 'GUEST_SETTINGS_MODAL';

    static readonly CHANGE_BILLING_ADDRESS = 'CHANGE_BILLING_ADDRESS';
    static readonly CHANGE_BILLING_ADDRESS_RESULT = 'CHANGE_BILLING_ADDRESS_RESULT';
    static readonly CHANGE_BILLING_ADDRESS_RESULT_CLEAR = 'CHANGE_BILLING_ADDRESS_RESULT_CLEAR';

    static readonly PUBLISH_PICTURE = 'PUBLISH_PICTURE';
    static readonly PUBLISH_PICTURE_SUCCESS = 'PUBLISH_PICTURE_SUCCESS';

    static readonly CREATE_CONFIG_ALARM = 'CREATE_CONFIG_ALARM';
    static readonly CREATE_CONFIG_ALARM_RETURN = 'CREATE_CONFIG_ALARM_RETURN';

    static readonly GET_CONFIG_ALARM = 'GET_CONFIG_ALARM';
    static readonly GET_CONFIG_ALARM_RETURN = 'GET_CONFIG_ALARM_RETURN';

    static readonly SET_CONFIG_ALARM = 'SET_CONFIG_ALARM';

    static readonly UPDATE_MODAL_ALARM = 'UPADTE_MODAL_ALARM';

    static readonly UPDATE_CONFIG_ALARM = 'UPDATE_CONFIG_ALARM';
    static readonly UPDATE_CONFIG_ALARM_RETURN = 'UPDATE_CONFIG_ALARM_RETURN';
    static readonly UPDATE_CONFIG_ALARM_CHANGE_RETURN = 'UPDATE_CONFIG_ALARM_CHANGE_RETURN';

    static readonly DELETE_CONFIG_ALARM = 'DELETE_CONFIG_ALARM';
    static readonly DELETE_CONFIG_ALARM_RETURN = 'DELETE_CONFIG_ALARM_RETURN';

    static readonly CLEANUP = 'CLEANUP';

    static readonly UPDATE_PAYMENT = 'UPDATE_PAYMENT';
    static readonly UPDATE_PAYMENT_RESULT = 'UPDATE_PAYMENT_RESULT';
    static readonly UPDATE_PAYMENT_RESULT_CLEAR = 'UPDATE_PAYMENT_RESULT_CLEAR';

    static readonly GET_PARTNERS = 'GET_PARTNERS';
    static readonly GET_PARTNERS_RESULT = 'GET_PARTNERS_RESULT';

    static readonly CREATE_PARTNERS = 'CREATE_PARTNERS';
    static readonly CREATE_PARTNERS_STATUS = 'CREATE_PARTNERS_STATUS';
    static readonly UPDATE_PARTNERS = 'UPDATE_PARTNERS';
    static readonly UPDATE_PARTNERS_RETURN = 'UPDATE_PARTNERS_RETURN';

    static readonly GET_CLIENTS = 'GET_CLIENTS';
    static readonly GET_CLIENTS_CLEANUP = 'GET_CLIENTS_CLEANUP';
    static readonly GET_CLIENTS_RETURN = 'GET_CLIENTS_RETURN';
    static readonly GET_ALL_GUESTS = 'GET_ALL_GUESTS';
    static readonly GET_ALL_GUESTS_RETURN = 'GET_ALL_GUESTS_RETURN';

    static readonly RESEND_WELCOME_EMAIL = 'RESEND_WELCOME_EMAIL';
    static readonly RESEND_WELCOME_EMAIL_RETURN = 'RESEND_WELCOME_EMAIL_RETURN';

    static readonly GET_USER_INVENTORY = 'GET_USER_INVENTORY';
    static readonly GET_USER_INVENTORY_RESULT = 'GET_USER_INVENTORY_RESULT';

    static readonly TRANSFER_ITEMS = 'TRANSFER_ITEMS';
    static readonly TRANSFER_ITEMS_RESULT = 'GET_DETECTIONS_RESULT';

    static readonly ADD_CLIENT = 'ADD_CLIENT';
    static readonly ADD_CLIENT_RETURN = 'ADD_CLIENT_RETURN';

    static readonly EDIT_CLIENT = 'EDIT_CLIENT';
    static readonly EDIT_CLIENT_RETURN = 'EDIT_CLIENT_RETURN';

    static readonly DELETE_CLIENT = 'DELETE_CLIENT';
    static readonly DELETE_CLIENT_RESULT = 'DELETE_CLIENT_RESULT';

    static readonly BLOCK_CLIENT = 'BLOCK_CLIENT';
    static readonly BLOCK_CLIENT_RESULT = 'BLOCK_CLIENT_RESULT';

    static readonly GET_KITS = 'GET_KITS';
    static readonly GET_KITS_RETURN = 'GET_KITS_RETURN';

    static readonly UPDATE_KIT = 'UPDATE_KIT';
    static readonly UPDATE_KIT_RESULT = 'UPDATE_KIT_RESULT';
    static readonly UPDATE_KIT_CLEANUP = 'UPDATE_KIT_CLEANUP';

    static readonly DELETE_KIT = 'DELETE_KIT';
    static readonly DELETE_KIT_RESULT = 'DELETE_KIT_RESULT';
    static readonly DELETE_KIT_CLEANUP = 'DELETE_KIT_CLEANUP';

    static readonly CREATE_KIT = 'CREATE_KIT';
    static readonly CREATE_KIT_RETURN = 'CREATE_KIT_RETURN';
    static readonly CREATE_KIT_CLEANUP = 'CREATE_KIT_CLEANUP';

    static readonly GET_USER_NOTIFICATION = 'GET_USER_NOTIFICATION';
    static readonly GET_USER_NOTIFICATION_RESULT = 'GET_USER_NOTIFICATION_RESULT';
    static readonly NOTIFICATION_VISIBILITY = 'SET_NOTIFICATION_VISIBILIT';
    static readonly NOTIFICATION_VISIBILITY_SUCCESS = 'SET_NOTIFICATION_VISIBILIT_RESULT';
    static readonly CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';

    static readonly ADD_CLIENT_CONVERT = 'ADD_CLIENT_CONVERT';
    static readonly ADD_CLIENT_CONVERT_RETURN = 'ADD_CLIENT_CONVERT_RETURN';

    static readonly ADD_CLIENT_GUEST_CONVERT = 'ADD_CLIENT_GUEST_CONVERT';
    static readonly ADD_CLIENT_GUEST_CONVERT_RETURN = 'ADD_CLIENT_GUEST_CONVERT_RETURN';

    static readonly DELETE_CLIENT_INVENTORY = 'DELETE_CLIENT_INVENTORY';
    static readonly DELETE_CLIENT_INVENTORY_RETURN = 'DELETE_CLIENT_INVENTORY_RETURN';

    static readonly EDIT_KIT_MODAL_UPDATE = 'EDIT_KIT_MODAL_UPDATE';
    static readonly EDIT_KIT_MODAL_CLEANUP = 'EDIT_KIT_MODAL_CLEANUP';

    static readonly GET_ASSOCIATED_USERS = 'GET_ASSOCIATED_USERS';
    static readonly GET_ASSOCIATED_USERS_SUCCESS = 'GET_ASSOCIATED_USERS_SUCCESS';

    static readonly INVITE_ASSOCIATED = 'INVITE_ASSOCIATED';
    static readonly INVITE_ASSOCIATED_SUCCESS = 'INVITE_ASSOCIATED_SUCCESS';
    static readonly INVITE_ASSOCIATED_CLEANUP = 'INVITE_ASSOCIATED_CLEANUP';

    static readonly DELETE_ASSOCIATED = 'DELETE_ASSOCIATED';
    static readonly DELETE_ASSOCIATED_RETURN = 'DELETE_ASSOCIATED_RETURN';

    static readonly UPDATE_ASSOCIATED = 'UPDATE_ASSOCIATED';
    static readonly UPDATE_ASSOCIATED_RESULT = 'UPDATE_ASSOCIATED_RESULT';

    static readonly CREATE_TICKET_KIT = 'CREATE_TICKET_KIT';
    static readonly CREATE_TICKET_KIT_RETURN = 'CREATE_TICKET_KIT_RETURN';
    static readonly CREATE_TICKET_KIT_CLEANUP = 'CREATE_TICKET_KIT_CLEANUP';

    static readonly DELETE_PARTNER = 'DELETE_PARTNER';
    static readonly DELETE_PARTNER_RETURN = 'DELETE_PARTNER_RETURN';
    static readonly DELETE_PARTNER_CLEAR = 'DELETE_PARTNER_CLEAR';

    static readonly GET_NATIONAL_HOLIDAYS = 'GET_NATIONAL_HOLIDAYS';
    static readonly GET_NATIONAL_HOLIDAYS_RETURN = 'GET_NATIONAL_HOLIDAYS_RETURN';
    static readonly GET_NATIONAL_HOLIDAYS_CLEAR = 'GET_NATIONAL_HOLIDAYS_CLEAR';

    static readonly GET_CALENDAR_LIST = 'GET_CALENDAR_LIST';
    static readonly GET_CALENDAR_LIST_RETURN = 'GET_CALENDAR_LIST_RETURN';
    static readonly GET_CALENDAR_LIST_CLEAR = 'GET_CALENDAR_LIST_CLEAR';

    static readonly CREATE_CALENDAR = 'CREATE_CALENDAR';
    static readonly CREATE_CALENDAR_RETURN = 'CREATE_CALENDAR_RETURN';
    static readonly CREATE_CALENDAR_CLEAR = 'CREATE_CALENDAR_CLEAR';

    static readonly UPDATE_CALENDAR = 'UPDATE_CALENDAR';
    static readonly UPDATE_CALENDAR_RETURN = 'UPDATE_CALENDAR_RETURN';
    static readonly UPDATE_CALENDAR_CLEAR = 'UPDATE_CALENDAR_CLEAR';

    static readonly DELETE_CALENDAR = 'DELETE_CALENDAR';
    static readonly DELETE_CALENDAR_RETURN = 'DELETE_CALENDAR_RETURN';
    static readonly DELETE_CALENDAR_CLEAR = 'DELETE_CALENDAR_CLEAR';

    static readonly DELETE_MASTER_KIT = 'DELETE_MASTER_KIT';
    static readonly DELETE_MASTER_KIT_RESULT = 'DELETE_MASTER_KIT_RESULT';
    static readonly DELETE_MASTER_KIT_CLEANUP = 'DELETE_MASTER_KIT_CLEANUP';


    static get_users = createAction(
        UserActions.GET_USERS,
        props<Metadata>()
    );

    static get_users_success = createAction(
        UserActions.GET_USERS_SUCCESS,
        props<{ payload: Payload }>()
    );

    static get_user = createAction(
        UserActions.GET_USER,
        props<Metadata>()
    );

    static get_user_success = createAction(
        UserActions.GET_USER_SUCCESS,
        props<{ meta: Metadata; payload: Payload }>()
    );

    static get_user_data = createAction(
        UserActions.GET_USER_DATA,
        props<Metadata>()
    );

    static get_user_data_result = createAction(
        UserActions.GET_USER_DATA_RESULT,
        props<{ payload: Payload }>()
    );

    static get_user_billings = createAction(
        UserActions.GET_USER_BILLINGS,
        props<Metadata>()
    );

    static get_user_billings_result = createAction(
        UserActions.GET_USER_BILLINGS_RESULT,
        props<{ payload: Payload }>()
    );

    static get_user_billings_history = createAction(
        UserActions.GET_USER_BILLINGS_HISTORY,
        props<Metadata>()
    );

    static get_user_billings_history_result = createAction(
        UserActions.GET_USER_BILLINGS_HISTORY_RESULT,
        props<{ payload: Payload }>()
    );

    static get_user_billings_datails = createAction(
        UserActions.GET_USER_BILLINGS_DETAILS,
        props<Metadata>()
    );

    static get_user_billings_datails_result = createAction(
        UserActions.GET_USER_BILLINGS_DETAILS_RESULT,
        props<{ payload: Payload }>()
    );

    static get_user_payment_method = createAction(
        UserActions.GET_USER_PAYMENT_METHOD,
        props<Metadata>()
    );

    static get_user_payment_method_result = createAction(
        UserActions.GET_USER_PAYMENT_METHOD_RESULT,
        props<{ payload: Payload }>()
    );

    static get_partners = createAction(
        UserActions.GET_PARTNERS,
        props<Metadata>()
    );

    static get_partners_result = createAction(
        UserActions.GET_PARTNERS_RESULT,
        props<{ payload: Payload }>()
    );

    static create_partners = createAction(
        UserActions.CREATE_PARTNERS,
        props<Metadata>()
    );

    static create_partners_status = createAction(
        UserActions.CREATE_PARTNERS_STATUS,
        props<{ payload: Payload }>()
    );

    static update_partner = createAction(
        UserActions.UPDATE_PARTNERS,
        props<Metadata>()
    );

    static update_partner_return = createAction(
        UserActions.UPDATE_PARTNERS_RETURN,
        props<{ payload: Payload }>()
    );

    static update_user = createAction(
        UserActions.UPDATE_USER,
        props<Metadata>()
    );

    static update_user_result = createAction(
        UserActions.UPDATE_USER_RESULT,
        props<{ payload: Payload }>()
    );

    static update_associated = createAction(
        UserActions.UPDATE_ASSOCIATED,
        props<Metadata>()
    );

    static update_associated_result = createAction(
        UserActions.UPDATE_ASSOCIATED_RESULT,
        props<{ payload: Payload }>()
    );

    static update_payment = createAction(
        UserActions.UPDATE_PAYMENT,
        props<Metadata>()
    );

    static update_payment_result = createAction(
        UserActions.UPDATE_PAYMENT_RESULT,
        props<{ payload: Payload }>()
    );

    static update_payment_result_clear = createAction(
        UserActions.UPDATE_PAYMENT_RESULT_CLEAR
    );

    static open_modal_crop = createAction(
        UserActions.OPEN_MODAL_CROP,
        props<{ payload: Payload }>()
    );

    static close_modal_crop = createAction(
        UserActions.CLOSE_MODAL_CROP
    );

    static change_password = createAction(
        UserActions.CHANGE_PASSWORD,
        props<Metadata>()
    );

    static change_password_result = createAction(
        UserActions.CHANGE_PASSWORD_RESULT,
        props<{ payload: Payload }>()
    );

    static change_password_modal = createAction(
        UserActions.CHANGE_PASSWORD_MODAL,
        props<{ payload: Payload }>()
    );

    static change_link_modal = createAction(
        UserActions.CHANGE_LINK_MODAL,
        props<{ payload: Payload }>()
    );

    static add_guest_modal = createAction(
        UserActions.ADD_GUEST_MODAL,
        props<{ payload: Payload }>()
    );

    static guest_settings_modal = createAction(
        UserActions.GUEST_SETTINGS_MODAL,
        props<{ payload: Payload }>()
    );

    static delete_associated = createAction(
        UserActions.DELETE_ASSOCIATED,
        props<Metadata>()
    );

    static delete_associated_return = createAction(
        UserActions.DELETE_ASSOCIATED_RETURN,
        props<{ payload: Payload }>()
    );

    static change_billing_address = createAction(
        UserActions.CHANGE_BILLING_ADDRESS,
        props<Metadata>()
    );

    static change_billing_address_result = createAction(
        UserActions.CHANGE_BILLING_ADDRESS_RESULT,
        props<{ payload: Payload }>()
    );

    static change_billing_address_result_clear = createAction(
        UserActions.CHANGE_BILLING_ADDRESS_RESULT_CLEAR
    );

    static publish_picture = createAction(
        UserActions.PUBLISH_PICTURE,
        props<Metadata>()
    );

    static publish_picture_success = createAction(
        UserActions.PUBLISH_PICTURE_SUCCESS,
        props<{ payload: Payload }>()
    );

    static notification_visibility = createAction(
        UserActions.NOTIFICATION_VISIBILITY,
        props<Metadata>()
    );

    static notification_visibility_success = createAction(
        UserActions.NOTIFICATION_VISIBILITY_SUCCESS,
        props<{ payload: Payload }>()
    );

    static clear_notification = createAction(
        UserActions.CLEAR_NOTIFICATION
    );

    static create_config_alarm = createAction(
        UserActions.CREATE_CONFIG_ALARM,
        props<Metadata>()
    );

    static create_config_alarm_return = createAction(
        UserActions.CREATE_CONFIG_ALARM_RETURN,
        props<{ payload: Payload }>()
    );

    static get_configurations_alarm = createAction(
        UserActions.GET_CONFIG_ALARM,
        props<Metadata>()
    );

    static get_configurations_alarm_return = createAction(
        UserActions.GET_CONFIG_ALARM_RETURN,
        props<{ payload: Payload }>()
    );

    static set_configurations_alarm = createAction(
        UserActions.SET_CONFIG_ALARM,
        props<{ payload: Payload }>()
    );

    static update_modal_alarm = createAction(
        UserActions.UPDATE_MODAL_ALARM,
        props<{ payload: Payload }>()
    );

    static update_configurations_alarm = createAction(
        UserActions.UPDATE_CONFIG_ALARM,
        props<Metadata>()
    );

    static update_configurations_alarm_return = createAction(
        UserActions.UPDATE_CONFIG_ALARM_RETURN,
        props<{ payload: Payload }>()
    );

    static update_configurations_alarm_change_return = createAction(
        UserActions.UPDATE_CONFIG_ALARM_CHANGE_RETURN,
        props<{ payload: Payload }>()
    );

    static delete_config_alarm = createAction(
        UserActions.DELETE_CONFIG_ALARM,
        props<Metadata>()
    );

    static delete_config_alarm_return = createAction(
        UserActions.DELETE_CONFIG_ALARM_RETURN,
        props<{ payload: Payload }>()
    );

    static cleanup = createAction(
        UserActions.CLEANUP
    );

    static get_clients = createAction(
        UserActions.GET_CLIENTS,
        props<Metadata>()
    );

    static get_clients_return = createAction(
        UserActions.GET_CLIENTS_RETURN,
        props<{ payload: Payload; pageIndex?: number }>()
    );

    static get_all_guests = createAction(
        UserActions.GET_ALL_GUESTS,
        props<Metadata>()
    );

    static get_all_guests_return = createAction(
        UserActions.GET_ALL_GUESTS_RETURN,
        props<{ payload: Payload; pageIndex?: number }>()
    );

    static resend_welcome_email = createAction(
        UserActions.RESEND_WELCOME_EMAIL,
        props<Metadata>()
    );

    static resend_welcome_email_return = createAction(
        UserActions.RESEND_WELCOME_EMAIL_RETURN,
        props<{ payload: Payload }>()
    );

    static get_user_inventory = createAction(
        UserActions.GET_USER_INVENTORY,
        props<Metadata>()
    );

    static get_user_inventory_result = createAction(
        UserActions.GET_USER_INVENTORY_RESULT,
        props<{ payload: Payload }>()
    );

    static transfer_items = createAction(
        UserActions.TRANSFER_ITEMS,
        props<Metadata>()
    );

    static transfer_items_result = createAction(
        UserActions.TRANSFER_ITEMS_RESULT,
        props<{ payload: Payload }>()
    );

    static add_client = createAction(
        UserActions.ADD_CLIENT,
        props<Metadata>()
    );

    static add_client_return = createAction(
        UserActions.ADD_CLIENT_RETURN,
        props<{ payload: Payload }>()
    );

    static add_client_convert = createAction(
        UserActions.ADD_CLIENT_CONVERT,
        props<Metadata>()
    );

    static add_client_convert_return = createAction(
        UserActions.ADD_CLIENT_CONVERT_RETURN,
        props<{ payload: Payload }>()
    );

    static add_client_guest_convert = createAction(
        UserActions.ADD_CLIENT_GUEST_CONVERT,
        props<Metadata>()
    );

    static add_client_guest_convert_return = createAction(
        UserActions.ADD_CLIENT_GUEST_CONVERT_RETURN,
        props<{ payload: Payload }>()
    );

    static edit_client = createAction(
        UserActions.EDIT_CLIENT,
        props<Metadata>()
    );

    static edit_client_return = createAction(
        UserActions.EDIT_CLIENT_RETURN,
        props<{ payload: Payload }>()
    );

    static delete_client = createAction(
        UserActions.DELETE_CLIENT,
        props<Metadata>()
    );

    static delete_client_result = createAction(
        UserActions.DELETE_CLIENT_RESULT,
        props<{ payload: Payload }>()
    );

    static block_client = createAction(
        UserActions.BLOCK_CLIENT,
        props<Metadata>()
    );

    static block_client_result = createAction(
        UserActions.BLOCK_CLIENT_RESULT,
        props<{ payload: Payload }>()
    );

    static get_kits = createAction(
        UserActions.GET_KITS,
        props<Metadata>()
    );

    static get_kits_return = createAction(
        UserActions.GET_KITS_RETURN,
        props<{ payload: Payload }>()
    );

    static update_kit = createAction(
        UserActions.UPDATE_KIT,
        props<Metadata>()
    );

    static update_kit_result = createAction(
        UserActions.UPDATE_KIT_RESULT,
        props<{ payload: Payload }>()
    );

    static update_kit_cleanup = createAction(
        UserActions.UPDATE_KIT_CLEANUP
    );

    static delete_kit = createAction(
        UserActions.DELETE_KIT,
        props<Metadata>()
    );

    static delete_kit_result = createAction(
        UserActions.DELETE_KIT_RESULT,
        props<{ payload: Payload }>()
    );

    static delete_kit_cleanup = createAction(
        UserActions.DELETE_KIT_CLEANUP
    );

    static create_kit = createAction(
        UserActions.CREATE_KIT,
        props<Metadata>()
    );

    static create_kit_return = createAction(
        UserActions.CREATE_KIT_RETURN,
        props<{ payload: Payload }>()
    );

    static create_kit_cleanup = createAction(
        UserActions.CREATE_KIT_CLEANUP
    );

    static get_associated_users = createAction(
        UserActions.GET_ASSOCIATED_USERS,
        props<Metadata>()
    );

    static get_associated_users_success = createAction(
        UserActions.GET_ASSOCIATED_USERS_SUCCESS,
        props<{ payload: Payload }>()
    );

    static get_user_notification = createAction(
        UserActions.GET_USER_NOTIFICATION,
        props<Metadata>()
    );

    static get_user_notification_result = createAction(
        UserActions.GET_USER_NOTIFICATION_RESULT,
        props<{ payload: Payload }>()
    );

    static delete_client_inventory = createAction(
        UserActions.DELETE_CLIENT_INVENTORY,
        props<Metadata>()
    );

    static delete_client_inventory_return = createAction(
        UserActions.DELETE_CLIENT_INVENTORY_RETURN,
        props<{ payload: Payload }>()
    );

    static edit_kit_modal_update = createAction(
        UserActions.EDIT_KIT_MODAL_UPDATE
    );

    static edit_kit_modal_cleanup = createAction(
        UserActions.EDIT_KIT_MODAL_CLEANUP
    );

    static invite_associated = createAction(
        UserActions.INVITE_ASSOCIATED,
        props<Metadata>()
    );

    static invite_associated_return = createAction(
        UserActions.INVITE_ASSOCIATED_SUCCESS,
        props<{ payload: Payload }>()
    );

    static invite_associated_cleanup = createAction(
        UserActions.INVITE_ASSOCIATED_CLEANUP
    );

    static create_ticket_kit = createAction(
        UserActions.CREATE_TICKET_KIT,
        props<Metadata>()
    );

    static create_ticket_kit_return = createAction(
        UserActions.CREATE_TICKET_KIT_RETURN,
        props<{ payload: Payload }>()
    );

    static create_ticket_kit_cleanup = createAction(
        UserActions.CREATE_TICKET_KIT_CLEANUP
    );

    static delete_partner = createAction(
        UserActions.DELETE_PARTNER,
        props<Metadata>()
    );

    static delete_partner_return = createAction(
        UserActions.DELETE_PARTNER_RETURN,
        props<{ payload: Payload }>()
    );

    static delete_partner_clear = createAction(
        UserActions.DELETE_PARTNER_CLEAR
    );

    static get_national_holidays = createAction(
        UserActions.GET_NATIONAL_HOLIDAYS,
        props<Metadata>()
    );

    static get_national_holidays_return = createAction(
        UserActions.GET_NATIONAL_HOLIDAYS_RETURN,
        props<{ payload: Payload }>()
    );

    static get_national_holidays_clear = createAction(
        UserActions.GET_NATIONAL_HOLIDAYS_CLEAR
    );

    static get_calendar_list = createAction(
        UserActions.GET_CALENDAR_LIST,
        props<Metadata>()
    );

    static get_calendar_list_return = createAction(
        UserActions.GET_CALENDAR_LIST_RETURN,
        props<{ payload: Payload }>()
    );

    static get_calendar_list_clear = createAction(
        UserActions.GET_CALENDAR_LIST_CLEAR
    );

    static create_calendar = createAction(
        UserActions.CREATE_CALENDAR,
        props<Metadata>()
    );

    static create_calendar_return = createAction(
        UserActions.CREATE_CALENDAR_RETURN,
        props<{ payload: Payload }>()
    );

    static create_calendar_clear = createAction(
        UserActions.CREATE_CALENDAR_CLEAR
    );

    static update_calendar = createAction(
        UserActions.UPDATE_CALENDAR,
        props<Metadata>()
    );

    static update_calendar_return = createAction(
        UserActions.UPDATE_CALENDAR_RETURN,
        props<{ payload: Payload }>()
    );

    static update_calendar_clear = createAction(
        UserActions.UPDATE_CALENDAR_CLEAR
    );

    static delete_calendar = createAction(
        UserActions.DELETE_CALENDAR,
        props<Metadata>()
    );

    static delete_calendar_return = createAction(
        UserActions.DELETE_CALENDAR_RETURN,
        props<{ payload: Payload }>()
    );

    static delete_calendar_clear = createAction(
        UserActions.DELETE_CALENDAR_CLEAR
    );

    static delete_master_kit = createAction(
        UserActions.DELETE_MASTER_KIT,
        props<Metadata>()
    );

    static delete_master_kit_result = createAction(
        UserActions.DELETE_MASTER_KIT_RESULT,
        props<{ payload: Payload }>()
    );

    static delete_master_kit_cleanup = createAction(
        UserActions.DELETE_MASTER_KIT_CLEANUP
    );

}
