import Chart from 'chart.js/auto';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { AppState } from 'app/store/model';
import { CameraActions } from 'app/cameras/Services/actions';
import { CamerasReport, ClientsToGroup, BlockedCamerasReport, InfoBlockedCameras, GroupCamerasReport, GroupCamera, PaginationCameras } from 'app/cameras/models';
import { HasScrollService } from 'app/Shared/Services/has-scroll.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {
    readonly blockedCamerasReport$ = this.store.select((state: AppState) => state.camera.blockedCamerasReport);
    readonly groupCamerasReport$ = this.store.select((state: AppState) => state.camera.groupCamerasReport);
    readonly camerasReport$ = this.store.select((state: AppState) => state.camera.camerasReport);
    readonly clientsToGroup$ = this.store.select((state: AppState) => state.camera.clientsGroup);
    readonly groupList$ = this.store.select((state: AppState) => state.camera.groupCamera);

    clientsSub: Subscription;
    clients: ClientsToGroup[];

    camsReport: Subscription;
    camerasReport: CamerasReport[];

    blockedCamsReport: Subscription;
    blockedCamerasReport: BlockedCamerasReport[];

    groupCamsReport: Subscription;
    groupCamerasReport: GroupCamerasReport[];

    groupListSub: Subscription;

    loadingClients: boolean;
    loadingFilter = false;
    loadingCameras = true;
    loadingBlockedCameras = true;
    loadingBlockedList = false;
    loadingGroups: boolean;

    isPartner: boolean;
    isAssociate: boolean;
    commonAssociate: boolean;
    isclientAssociate: boolean;
    commomPrivGroup: boolean;

    userSub: string;
    childSub: string;
    clientsID: number[];
    page: number;
    limit = 20;
    totalBlockedCam: number;
    blockedCams: InfoBlockedCameras[];
    blockedData = [];

    sum = 1;
    allPages = 0;
    actual: number;

    chart: any;
    camOffline: number;
    camOnline: number;
    context: CanvasRenderingContext2D;
    totalCam: number;

    dashboardCam: boolean;

    clientSimple: {
        id: number | string;
        text: string;
    }[] = [];

    groupListSimple: {
        id: number;
        text: string;
    }[];

    groupCamOffline: number;
    groupCamOnline: number;
    totalGroupCam: number;

    searchInputControl = new UntypedFormControl('', []);
    selectedGroup: number;
    groupCamchart: any;
    groupContext: CanvasRenderingContext2D;

    resizeListener: any;

    @ViewChild('MyChart') canvas: ElementRef<HTMLCanvasElement>;
    @ViewChild('MyGroupChart') canvasGroup: ElementRef<HTMLCanvasElement>;

    constructor(private router: Router, private readonly store: Store<AppState>, private scrollService: HasScrollService) { }

    ngOnInit(): void {
        localStorage.setItem('context', 'home');
        this.userSub = localStorage.getItem('sub');
        this.childSub = localStorage.getItem('clientView');
        this.dashboardCam = true;
        this.clientsID = null;
        this.getClients();
        this.getCamerasReport();
        this.cardInit();

        if (localStorage.getItem('profile_name') == 'cd343bfc-17e8-11ec-9621-0242ac130002') {
            this.isPartner = true;
        }
        if (localStorage.getItem('profile_name') === 'f29868c7-b4c5-4963-9ae8-1dd95699d6c3') {
            this.isAssociate = true;
        }
        if (localStorage.getItem('profile_name') === '419bea06-5d4e-4a56-b8b5-04b3ad566d59') {
            this.isclientAssociate = true;
        }
        if (this.isclientAssociate && localStorage.getItem('associate_permission') === 'd3098a51-86f3-4494-b7b0-5bb0498558ee') {
            this.commonAssociate = true;
        }
        if (this.isPartner || this.isAssociate) {
            this.loadingClients = true;
            this.getBlockedCamerasReport();
        } else {
            this.loadingGroups = true;
            this.getGroupCameraList();
            this.getGroupCamerasReport();
        }

        this.resizeListener = this.checkScreenSize.bind(this);
        window.addEventListener('resize', this.resizeListener);

        this.checkScreenSize();
    }

    ngAfterViewInit(): void {
        this.context = this.canvas.nativeElement.getContext('2d');
        this.groupContext = this.canvasGroup.nativeElement.getContext('2d');
    }

    ngOnDestroy(): void {
        if (this.camsReport) {
            this.camsReport.unsubscribe();
        }
        if (this.blockedCamsReport) {
            this.blockedCamsReport.unsubscribe();
        }
        if (this.clientsSub) {
            this.clientsSub.unsubscribe();
        }
        if (this.groupCamsReport) {
            this.groupCamsReport.unsubscribe();
        }
        if (this.groupListSub) {
            this.groupListSub.unsubscribe();
        }
        this.store.dispatch(CameraActions.cleanup());
        this.scrollService.hasScrollEmitter.emit(false);
        this.scrollService.hasScrollXEmitter.emit(false);
        window.removeEventListener('resize', this.resizeListener);
        localStorage.removeItem('clientView');
    }

    getGroupCameraList() {
        this.store.dispatch(CameraActions.get_group_cameras({
            user_sub: this.userSub,
            type_request: 'list',
            active: true
        }));
        this.groupListSub = this.groupList$.subscribe(data => {
            this.loadingGroups = false;
            data = data as GroupCamera[];
            if (data && data.length) {
                data.forEach((group) => {
                    if (this.commonAssociate && group.is_public == false) {
                        this.commomPrivGroup = true;
                    }
                });
                this.groupListSimple = data.map(c => {
                    return {
                        id: c.id_camera_group,
                        text: c.alias
                    };
                });
            }
        });
    }

    selectGroup(id) {
        this.loadingCameras = true;
        this.selectedGroup = id;
        this.getGroupCamerasReport();
    }

    getClients() {
        this.store.dispatch(CameraActions.get_clients_group({
            user_sub: localStorage.getItem('sub')
        }));
        this.clientsSub = this.clientsToGroup$.subscribe(result => {
            this.loadingClients = false;
            if (result && result.length > 0) {
                this.clients = result;
                this.clientSimple = result.map(element => {
                    return {
                        id: element.client_id,
                        text: element.client_name
                    };
                });
            }
        });
    }

    checkScreenSize() {
        document?.getElementById('top-list').scrollIntoView();
        const screenWidth = window.innerWidth;

        if (screenWidth < 1366) {
            this.scrollService.hasScrollEmitter.emit(false);
            this.scrollService.hasScrollXEmitter.emit(true);
        } else {
            this.scrollService.hasScrollEmitter.emit(true);
            this.scrollService.hasScrollXEmitter.emit(false);
        }
    }

    cardInit() {
        if (this.totalCam != undefined && this.totalGroupCam != undefined) {
            if ((this.totalCam > this.totalGroupCam) && !this.commomPrivGroup) {
                this.dashboardCam = true;
            }
            else if (this.commomPrivGroup) {
                this.dashboardCam = false;
            }
            else {
                this.dashboardCam = false;
            }
        }
    }

    selectClient(event) {
        this.clientsID = event;
        localStorage.setItem('clientsID', event);
        this.loadingFilter = true;
        this.getCamerasReport();
    }

    getCamerasReport() {
        this.loadingCameras = true;
        this.store.dispatch(CameraActions.get_cameras_report({
            user_sub: this.userSub,
            clients_id: this.clientsID
        }));
        this.camerasReportResult();
    }

    camerasReportResult() {
        this.camsReport = this.camerasReport$.subscribe(result => {
            if (result) {
                this.camerasReport = result;
                this.camOffline = (this.camerasReport[0].cameras_off_72h + this.camerasReport[0].cameras_off_not_72h);
                this.camOnline = this.camerasReport[0].cameras_on;
                this.totalCam = this.camerasReport[0].total;
                if (this.chart != undefined) {
                    this.chart.destroy();
                }
                this.createChart();
                if (!(this.isPartner || this.isAssociate)) {
                    if (this.totalCam != undefined && this.totalGroupCam != undefined) {
                        this.loadingCameras = false;
                    }
                }
                else {
                    this.loadingCameras = false;
                }
                setTimeout(() => {
                    this.loadingFilter = false;
                }, 2000);
            }
        });
    }

    getGroupCamerasReport() {
        this.loadingCameras = true;
        this.store.dispatch(CameraActions.get_group_cameras_report({
            user_sub: this.userSub,
            group_id: this.selectedGroup
        }));
        this.groupCamerasReportResult();
    }

    groupCamerasReportResult() {
        this.groupCamsReport = this.groupCamerasReport$.subscribe(result => {
            if (result) {
                this.groupCamerasReport = result;
                this.groupCamOffline = (this.groupCamerasReport[0].cameras_off_72h + this.groupCamerasReport[0].cameras_off_not_72h);
                this.groupCamOnline = this.groupCamerasReport[0].cameras_on;
                this.totalGroupCam = this.groupCamerasReport[0].total;

                if (!this.selectedGroup && this.dashboardCam) {
                    this.cardInit();
                }

                if (this.groupCamchart != undefined) {
                    this.groupCamchart.destroy();
                }
                this.createChart();
                this.loadingCameras = (this.totalCam != undefined && this.totalGroupCam != undefined) ? false : true;
                setTimeout(() => {
                    this.loadingFilter = false;
                }, 2000);
            }
        });
    }

    selectClientBlockedCam(event) {
        this.initCameras();
        this.clientsID = event;
        localStorage.setItem('clientsID', event);
        this.loadingBlockedCameras = true;
        this.store.dispatch(CameraActions.get_blocked_cameras_report({
            user_sub: this.userSub,
            child_sub: null,
            clients_id: this.clientsID,
            page: this.sum,
            limit: this.limit
        }));
    }

    getBlockedCamerasReport() {
        this.store.dispatch(CameraActions.get_blocked_cameras_report({
            user_sub: this.userSub,
            child_sub: null,
            clients_id: this.clientsID,
            page: this.sum,
            limit: this.limit
        }));
        this.blockedCamsReportResult();
    }

    blockedCamsReportResult() {
        this.blockedCamsReport = this.blockedCamerasReport$.subscribe(result => {
            if (result) {
                const cams: any = result[0] as BlockedCamerasReport;
                this.blockedCams = cams.cameras;
                this.totalBlockedCam = cams.total;
                this.allPages = cams.pages;
                this.actual = cams.actual;
                this.limit = cams.limit;
                this.loadingBlockedCameras = false;
                this.blockedData.push(...this.blockedCams);
            }
        });
    }

    initCameras() {
        this.blockedData = [];
        this.sum = 1;
        this.totalBlockedCam = 0;
        this.actual = 1;
    }

    onScroll() {
        if (!this.loadingBlockedCameras) {
            if (this.sum < this.allPages) {
                this.loadingBlockedList = true;
                this.sum = this.actual <= this.allPages ? this.sum += 1 : this.sum;
                this.store.dispatch(CameraActions.get_blocked_cameras_report({
                    user_sub: this.userSub,
                    child_sub: null,
                    clients_id: this.clientsID,
                    page: this.sum,
                    limit: this.limit
                }));
            }

            setInterval(() => {
                this.loadingBlockedList = false;
            }, 8000);
        }
    }

    goToList() {
        this.router.navigateByUrl('/cameras/list');
    }

    goToBlockedList() {
        localStorage.setItem('list', 'blocked');
        this.router.navigateByUrl('/cameras/list');
    }

    goToOfflineList() {
        localStorage.setItem('list', 'offline');
        this.router.navigateByUrl('/cameras/list');
    }

    goToOnlineList() {
        localStorage.setItem('list', 'online');
        this.router.navigateByUrl('/cameras/list');
    }

    dashboardSwitch() {
        this.dashboardCam = !this.dashboardCam;
        if (this.dashboardCam) {
            this.getCamerasReport();
        }
        else {
            this.selectedGroup = null;
            this.getGroupCamerasReport();
        }
    }

    createChart() {
        const ctx = this.context;

        const gradientOn = ctx.createLinearGradient(0, 0, 0, 450);
        gradientOn.addColorStop(0, 'rgba(24, 144, 255, 1)');
        gradientOn.addColorStop(0.5, 'rgba(192, 224, 255, 1)');

        const gradientOff = ctx.createLinearGradient(0, 0, 0, 450);
        gradientOff.addColorStop(0, 'rgba(114, 46, 209, 1)');
        gradientOff.addColorStop(0.5, 'rgba(227, 184, 255, 1)');

        if (this.camerasReport) {
            const offline = Math.round(this.camerasReport[0].percent_off_not_72h + this.camerasReport[0].percent_off_72h);
            const online = Math.round(this.camerasReport[0].percent_on);
            const total = this.totalCam;

            const hoverPercentage = {
                id: 'hoverPercentage',
                afterDraw(chart, args, options) {
                    const { chartArea: { left, right, top, bottom, width, height } } = chart;
                    ctx.save();

                    let offlinePercentage = chart?.config.data.datasets[0].data[0] + '%';
                    let onlinePercentage = chart?.config.data.datasets[0].data[1] + '%';

                    if (total == 0) {
                        offlinePercentage = '0%';
                        onlinePercentage = '0%';
                    }

                    ctx.font = 'bold 32px Open Sans';
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';

                    if (chart._active.length > 0) {
                        const index = chart._active[0].index;
                        const style = chart.config.data.datasets[0].borderColor[index];
                        let percentage = chart.config.data.datasets[0].data[index] + '%';
                        if (total == 0) {
                            percentage = '0%';
                        }
                        ctx.fillStyle = style;
                        ctx.fillText(percentage, 10 + width / 2, 10 + height / 2);
                    }
                    else {
                        if (offlinePercentage > onlinePercentage) {
                            const style = chart.config.data.datasets[0].borderColor[0];
                            ctx.fillStyle = style;
                            ctx.fillText(offlinePercentage, 10 + width / 2, 10 + height / 2);
                        }
                        else {
                            const style = chart.config.data.datasets[0].borderColor[1];
                            ctx.fillStyle = style;
                            ctx.fillText(onlinePercentage, 10 + width / 2, 10 + height / 2);
                        }
                    }
                }
            };

            const hoverLabel = {
                id: 'hoverLabel',
                afterDatasetsDraw(chart, args, pluginOptions) {
                    const { data, options, active,
                        chartArea: { top, bottom, left, right, width, height } } = chart;
                    ctx.save();

                    if (active) {
                        const index = active[0].index;
                        const dataLabel = data.labels[index];

                        ctx.fillStyle = 'rgba(0, 45, 89, 1)';
                        ctx.font = '14px Open Sans';
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'middle';
                        ctx.fillText(dataLabel, width / 2, height + 40);
                    }
                }
            };

            const chartStatus = Chart.getChart('MyChart');
            if (chartStatus != undefined) {
                chartStatus.destroy();
            }

            this.chart = new Chart('MyChart', {
                type: 'doughnut',

                data: {
                    labels: ['Câmeras Offline', 'Câmeras Online'],
                    datasets: [{
                        data: [offline, online],
                        backgroundColor: [gradientOff, gradientOn],
                        hoverOffset: 15,
                        borderColor: ['rgba(119, 53, 211, 1)', 'rgba(48, 110, 191, 1)'],
                        borderWidth: 0
                    }],
                },
                options: {
                    aspectRatio: 2.5,
                    cutout: 75,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            bottom: 50,
                            top: 10
                        },
                    },
                    onHover: (event, chartElement) => {
                        const element = event.native.target as HTMLElement;
                        element.style.cursor = (chartElement != null && chartElement.length > 0) ? 'pointer' : 'default';
                    },
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            enabled: false
                        }
                    }
                },
                plugins: [hoverPercentage, hoverLabel]
            });
        }

        if (!(this.isPartner || this.isAssociate) && this.groupCamerasReport) {
            const groupOffline = Math.round(this.groupCamerasReport[0].percent_off_not_72h + this.groupCamerasReport[0].percent_off_72h);
            const groupOnline = Math.round(this.groupCamerasReport[0].percent_on);
            const groupTotal = this.totalGroupCam;

            const ctxGroup = this.groupContext;

            const hoverGroupPercentage = {
                id: 'hoverGroupPercentage',
                afterDraw(groupCamchart, args, options) {
                    const { chartArea: { left, right, top, bottom, width, height } } = groupCamchart;
                    ctxGroup.save();

                    let offlineGroupPercentage = groupCamchart?.config.data.datasets[0].data[0] + '%';
                    let onlineGroupPercentage = groupCamchart?.config.data.datasets[0].data[1] + '%';
                    if (groupTotal == 0) {
                        offlineGroupPercentage = '0%';
                        onlineGroupPercentage = '0%';
                    }
                    ctxGroup.font = 'bold 32px Open Sans';
                    ctxGroup.textAlign = 'center';
                    ctxGroup.textBaseline = 'middle';

                    if (groupCamchart._active.length > 0) {
                        const index = groupCamchart._active[0].index;
                        const style = groupCamchart.config.data.datasets[0].borderColor[index];
                        let percentage = groupCamchart.config.data.datasets[0].data[index] + '%';
                        if (groupTotal == 0) {
                            percentage = '0%';
                        }
                        ctxGroup.fillStyle = style;
                        ctxGroup.fillText(percentage, 10 + width / 2, 10 + height / 2);
                    }
                    else {
                        if (offlineGroupPercentage > onlineGroupPercentage) {
                            const style = groupCamchart.config.data.datasets[0].borderColor[0];
                            ctxGroup.fillStyle = style;
                            ctxGroup.fillText(offlineGroupPercentage, 10 + width / 2, 10 + height / 2);
                        }
                        else {
                            const style = groupCamchart.config.data.datasets[0].borderColor[1];
                            ctxGroup.fillStyle = style;
                            ctxGroup.fillText(onlineGroupPercentage, 10 + width / 2, 10 + height / 2);
                        }
                    }
                }
            };

            const hoverGroupLabel = {
                id: 'hoverGroupLabel',
                afterDatasetsDraw(groupCamchart, args, pluginOptions) {
                    const { data, options, active,
                        chartArea: { top, bottom, left, right, width, height } } = groupCamchart;
                    ctxGroup.save();
                    if (active) {
                        const index = active[0].index;
                        const dataLabel = data.labels[index];
                        ctxGroup.fillStyle = 'rgba(0, 45, 89, 1)';
                        ctxGroup.font = '14px Open Sans';
                        ctxGroup.textAlign = 'center';
                        ctxGroup.textBaseline = 'middle';
                        ctxGroup.fillText(dataLabel, width / 2, height + 40);
                    }
                }
            };

            const chartGroupStatus = Chart.getChart('MyGroupChart');
            if (chartGroupStatus != undefined) {
                chartGroupStatus.destroy();
            }


            this.groupCamchart = new Chart('MyGroupChart', {
                type: 'doughnut',

                data: {
                    labels: ['Câmeras Offline', 'Câmeras Online'],
                    datasets: [{
                        data: [groupOffline, groupOnline],
                        backgroundColor: [gradientOff, gradientOn],
                        hoverOffset: 15,
                        borderColor: ['rgba(119, 53, 211, 1)', 'rgba(48, 110, 191, 1)'],
                        borderWidth: 0
                    }],
                },
                options: {
                    aspectRatio: 2.5,
                    cutout: 75,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            bottom: 50,
                            top: 10
                        },
                    },
                    onHover: (event, chartElement) => {
                        const element = event.native.target as HTMLElement;
                        element.style.cursor = (chartElement != null && chartElement.length > 0) ? 'pointer' : 'default';
                    },
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            enabled: false
                        }
                    }
                },
                plugins: [hoverGroupPercentage, hoverGroupLabel]
            });
        }
    }

}
