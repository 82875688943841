import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from 'app/Shared/Services/shared.service';
import { UserInventory } from 'app/Users/models';
import { Plan } from '../models';

@Component({
    selector: 'app-camera-kits-stock',
    templateUrl: './camera-kits-stock.component.html',
    styleUrls: ['./camera-kits-stock.component.scss']
})
export class CameraKitsStockComponent implements OnInit {

    @Input() inventory: UserInventory;
    @Input() plans: Plan[];

    isAssociate: boolean;
    adminAssociate: boolean;
    filterKitsList: any = [];

    constructor(private service: SharedService) { }

    ngOnInit(): void {
        localStorage.setItem('activeTab', 'kits');
        this.filterKitsList = this.filterKits(this.inventory.kits);
        if (localStorage.getItem('profile_name') === 'f29868c7-b4c5-4963-9ae8-1dd95699d6c3') {
            this.isAssociate = true;
        }
        if (this.isAssociate && localStorage.getItem('associate_permission') === 'e816c560-812e-11ed-a1eb-0242ac120002') {
            this.adminAssociate = true;
        }
    }

    filterKits(kits) {
        return kits.filter((kit) => {
            return kit.showed;
        });
    }

    getPlanProperty(camera_id_plan: number, property: string): string {
        const plan = this.plans.find(pl => pl.id === camera_id_plan);
        if (plan) {
            switch (property) {
                case 'alias':
                    return plan.alias;

                case 'color':
                    return plan.color;

                case 'price':
                    return plan.price.toString();

                case 'days':
                    return plan.days_storage.toString();

                case 'resolution':
                    return plan.resolution.alias.toString();

                case 'width':
                    return plan.resolution.width.toString();

                case 'height':
                    return plan.resolution.height.toString();

                default:
                    return 'error';
            }
        }
        else {
            return '';
        }
    }

    getAvailable(kit) {
        const camerasNeeded = kit.cameras_amount;
        const plan = kit.id_plan;
        const analyticsNeeded = kit.analytics_amount;
        const camerasAvailable = this.inventory.inventory.ci[plan].available;
        const analyticsAvailable = this.inventory.inventory.ai.available;

        const possibleCameras = Math.floor(camerasAvailable / camerasNeeded);
        const possibleAnalytics = Math.floor(analyticsAvailable / analyticsNeeded);

        return possibleCameras < possibleAnalytics ? possibleCameras : possibleAnalytics;
    }

    requestKit(kit) {
        const dataModal = {
            data: {
                kit: kit,
                inventories: this.inventory,
                manyKits: false
            },
            tipoModal: 'solicitar_kit',
            modalHeight: '73vh',
            modalWidth: '50vw'
        };
        this.service.returnDataToModal(dataModal);
    }

}
