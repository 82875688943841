import { Feedback, OperationStatus } from 'app/Shared/models';
import { Action, createReducer, on } from '@ngrx/store';
import {
    CameraState, Camera, Plan, Mosaic, Video, ModalState, GuestsList, HealthCheck, PaginationCameras, HealthPanel,
    Address, AnalyticTypes, RTMPUri, GroupCamera, ClientsToGroup, CamerasReport, Cameras, BlockedCamerasReport,
    GroupCamerasReport, ProviderIntegratedCameras,
    CamerasByLocation
} from '../models';
import { CameraActions, CameraAction } from './actions';

const INITIAL_STATE: CameraState = {
    postCam: null,
    modal_open_invite_user: ModalState.Cancel,
    modal_open_update_camera: ModalState.Cancel,
    modal_open_list_address_camera: ModalState.Cancel,
    modal_create_mosaic: ModalState.Cancel,
    updateCam: null,
    deleteCam: null,
    deleteGuest: OperationStatus.Unloaded,
    inviteUsers: null,
    updateGuests: null,
    cameras: null,
    groupCameraID: null,
    groupCamera: null,
    updateCameraGroup: null,
    deleteCamGroup: null,
    addresses: null,
    check_resolution: null,
    camera_thumbnail: null,
    cameraShared: null,
    camera_view: null,
    previewCam: null,
    urlSignedVideo: null,
    playlist: null,
    currentMosaic: null,
    mosaics: null,
    connectionHistory: [],
    camera_address: null,
    typesAnalytic: [],
    RTMPUri: null,
    OpenViewGroup: null,
    clientsGroup: null,
    camerasReport: null,
    blockedCamerasReport: null,
    groupCamerasReport: null,
    providerIntegratedCameras: null,
    camerasByLocation: null,
};

export const cameraReducer = createReducer(
    INITIAL_STATE,
    on(
        CameraActions.create_camera, state => state
    ),
    on(
        CameraActions.create_camera_status, (state, { payload }) => ({
            ...state,
            postCam: payload as Feedback
        })
    ),
    on(
        CameraActions.cleanup_post, state => ({
            ...state,
            postCam: {
                model: 'feedback',
                status: OperationStatus.Unloaded,
                message: ''
            } as Feedback
        })
    ),
    on(
        CameraActions.put_id_invite_user, (state, { payload }) => ({
            ...state,
            camera_id: payload as number
        })
    ),
    on(
        CameraActions.put_plan_max_invite_user, (state, { payload }) => ({
            ...state,
            plan_max_invite: payload as number
        })
    ),
    on(
        CameraActions.put_camera_update, (state, { payload }) => ({
            ...state,
            camera_view: payload as Camera
        })
    ),
    on(
        CameraActions.put_view_camera, (state, { payload }) => ({
            ...state,
            camera_view: payload as Camera
        })
    ),
    on(
        CameraActions.put_analytic_camera, (state, { payload }) => ({
            ...state,
            camera_view_analytics: payload as Camera
        })
    ),
    on(
        CameraActions.clear_analytic_camera, state => ({
            ...state,
            camera_view_analytics: null
        })
    ),
    on(
        CameraActions.get_healthcheck, state => state
    ),
    on(
        CameraActions.get_healthcheck_return, (state, { payload }) => ({
            ...state,
            connectionHealth: payload as HealthCheck
        })
    ),
    on(
        CameraActions.get_healthcheck_cleanup, state => ({
            ...state,
            connectionHealth: null
        })
    ),
    on(
        CameraActions.get_health_history, state => ({
            ...state,
            historyLoading: true
        })
    ),
    on(
        CameraActions.get_health_history_return, (state, { payload }) => ({
            ...state,
            connectionHistory: payload === null ? state.connectionHistory : state.connectionHistory.concat(payload as HealthPanel[]),
            historyLoading: false,
            finalPage: payload === null
        })
    ),
    on(
        CameraActions.get_health_history_filter, state => ({
            ...state,
            historyLoading: true
        })
    ),
    on(
        CameraActions.get_health_history_filter_return, (state, { payload }) => ({
            ...state,
            connectionHistory: payload === null ? state.connectionHistory : state.connectionHistory.concat(payload as HealthPanel[]),
            historyLoading: false,
            finalPage: payload === null
        })
    ),
    on(
        CameraActions.clear_history, state => ({
            ...state,
            connectionHistory: []
        })
    ),
    on(
        CameraActions.open_modal_invite_user, state => ({
            ...state,
            modal_open_invite_user: ModalState.Open
        })
    ),
    on(
        CameraActions.close_modal_invite_user_cancel, state => ({
            ...state,
            modal_open_invite_user: ModalState.Cancel,
            guests: [],
            inviteUsers: null,
            updateGuests: null,
            deleteGuest: null,
            cameras: null,
            plans: null
        })
    ),
    on(
        CameraActions.close_modal_add_cam_guest, state => ({
            ...state,
            inviteUsers: null,
            cameras: null
        })
    ),
    on(
        CameraActions.close_modal_invite_user_confirm, state => ({
            ...state,
            modal_open_invite_user: ModalState.Closed,
            inviteUsers: null,
            updateGuests: null
        })
    ),
    on(
        CameraActions.close_modal_invite_user_update, state => ({
            ...state,
            modal_open_invite_user: ModalState.Closed,
            inviteUsers: null,
            updateGuests: null
        })
    ),
    on(
        CameraActions.open_modal_update_camera, (state, { payload }) => ({
            ...state,
            modal_open_update_camera: ModalState.Open,
            camera_view: payload as Camera
        })
    ),
    on(
        CameraActions.close_modal_update_camera_cancel, state => ({
            ...state,
            modal_open_update_camera: ModalState.Cancel,
            previewCam: null,
            check_resolution: null,
            plans: null,
            camera_view: null
        })
    ),
    on(
        CameraActions.close_modal_update_camera_confirm, state => ({
            ...state,
            modal_open_update_camera: ModalState.Closed,
            previewCam: null,
            check_resolution: null,
            camera_view: null
        })
    ),
    on(
        CameraActions.close_modal_update_camera_update, state => ({
            ...state,
            modal_open_update_camera: ModalState.Closed,
            previewCam: null,
            check_resolution: null,
            camera_view: null
        })
    ),
    on(
        CameraActions.open_modal_list_camera_address, state => ({
            ...state,
            modal_open_list_address_camera: ModalState.Open
        })
    ),
    on(
        CameraActions.close_modal_list_camera_address_cancel, state => ({
            ...state,
            modal_open_list_address_camera: ModalState.Cancel,
            previewCam: null,
        })
    ),
    on(
        CameraActions.close_modal_list_camera_address_confirm, state => ({
            ...state,
            modal_open_list_address_camera: ModalState.Closed,
        })
    ),
    on(
        CameraActions.close_modal_list_camera_address_update, state => ({
            ...state,
            modal_open_list_address_camera: ModalState.Closed,
        })
    ),
    on(
        CameraActions.get_cameras, state => state
    ),
    on(
        CameraActions.get_cameras_return, (state, { payload }) => ({
            ...state,
            cameras: payload as Cameras
        })
    ),
    on(
        CameraActions.get_list_address, state => state
    ),
    on(
        CameraActions.get_list_address_return, (state, { payload }) => ({
            ...state,
            addresses: payload as Address[]
        })
    ),
    on(
        CameraActions.check_camera_resolution, state => state
    ),
    on(
        CameraActions.check_camera_resolution_return, (state, { payload }) => ({
            ...state,
            check_resolution: payload as Feedback
        })
    ),
    on(
        CameraActions.clear_camera_resolution, state => ({
            ...state,
            check_resolution: null
        })
    ),
    on(
        CameraActions.create_camera_thumbnail, state => state
    ),
    on(
        CameraActions.create_camera_thumbnail_return, (state, { payload }) => ({
            ...state,
            camera_thumbnail: payload as Feedback
        })
    ),
    on(
        CameraActions.create_camera_thumbnail_cleanup, state => ({
            ...state,
            camera_thumbnail: null
        })
    ),
    on(
        CameraActions.clear_camera_create_thumbnail, state => ({
            ...state,
            camera_thumbnail: null
        })
    ),
    on(
        CameraActions.get_preview_camera, state => state
    ),
    on(
        CameraActions.get_preview_camera_return, (state, { payload }) => ({
            ...state,
            previewCam: payload as string
        })
    ),
    on(
        CameraActions.reset_preview, state => ({
            ...state,
            previewCam: null
        })
    ),
    on(
        CameraActions.get_url_signed_video, state => state
    ),
    on(
        CameraActions.get_url_signed_video_return, (state, { payload }) => ({
            ...state,
            urlSignedVideo: payload as string
        })
    ),
    on(
        CameraActions.get_url_signed_video_clear_return, state => ({
            ...state,
            urlSignedVideo: null
        })
    ),
    on(
        CameraActions.get_invited_cameras, state => state
    ),
    on(
        CameraActions.get_invited_cameras_return, (state, { payload }) => ({
            ...state,
            cameraShared: payload as Camera[]
        })
    ),
    on(
        CameraActions.get_cameras_change_return, state => ({
            ...state,
            cameras: null
        })
    ),
    on(
        CameraActions.get_plans, state => state
    ),
    on(
        CameraActions.get_plans_return, (state, { payload }) => ({
            ...state,
            plans: payload as Plan[]
        })
    ),
    on(
        CameraActions.get_plans_cleanup, state => ({
            ...state,
            plans: null
        })
    ),
    on(
        CameraActions.get_analytics_types, state => state
    ),
    on(
        CameraActions.get_analytics_types_return, (state, { payload }) => ({
            ...state,
            typesAnalytic: payload as AnalyticTypes[]
        })
    ),
    on(
        CameraActions.invite_user_camera, state => state
    ),
    on(
        CameraActions.invite_user_camera_return, (state, { payload }) => ({
            ...state,
            inviteUsers: payload as any
        })
    ),
    on(
        CameraActions.invite_user_camera_change_return, (state, { payload }) => ({
            ...state,
            inviteUsers: payload as any
        })
    ),
    on(
        CameraActions.update_invite_user_camera, state => state
    ),
    on(
        CameraActions.update_invite_user_camera_return, (state, { payload }) => ({
            ...state,
            updateGuests: payload as string
        })
    ),
    on(
        CameraActions.update_invite_user_camera_change_return, (state, { payload }) => ({
            ...state,
            updateGuests: payload as string
        })
    ),
    on(
        CameraActions.delete_camera, state => state
    ),
    on(
        CameraActions.delete_guest_camera, state => state
    ),
    on(
        CameraActions.delete_camera_feedback, (state, { payload }) => ({
            ...state,
            deleteCam: payload as Feedback
        })
    ),
    on(
        CameraActions.delete_camera_change_feedback, (state, { payload }) => ({
            ...state,
            deleteCam: payload as Feedback
        })
    ),
    on(
        CameraActions.get_group_cameras, state => state
    ),
    on(
        CameraActions.get_group_cameras_return, (state, { payload }) => ({
            ...state,
            groupCamera: payload as GroupCamera[] | PaginationCameras
        })
    ),
    on(
        CameraActions.get_group_camerasID_return, (state, { payload }) => ({
            ...state,
            groupCameraID: payload as GroupCamera[]
        })
    ),
    on(
        CameraActions.update_camera_group, state => state
    ),
    on(
        CameraActions.update_camera_group_result, (state, { payload }) => ({
            ...state,
            updateCameraGroup: payload as Feedback
        })
    ),
    on(
        CameraActions.update_camera_group_clear, state => ({
            ...state,
            updateCameraGroup: null,
        })
    ),
    on(
        CameraActions.clear_group_cameras, state => ({
            ...state,
            groupCamera: null,
            groupCameraID: null,
            createGroup: null,
            clientsGroup: null,
        })
    ),
    on(
        CameraActions.delete_group_cameras, state => state
    ),
    on(
        CameraActions.delete_group_cameras_return, (state, { payload }) => ({
            ...state,
            deleteCamGroup: payload as Feedback
        })
    ),
    on(
        CameraActions.delete_group_cameras_clear, state => ({
            ...state,
            deleteCamGroup: null
        })
    ),
    on(
        CameraActions.update_camera, state => state
    ),
    on(
        CameraActions.update_camera_feedback, (state, { payload }) => ({
            ...state,
            updateCam: payload as Feedback
        })
    ),
    on(
        CameraActions.update_camera_change_feedback, (state, { payload }) => ({
            ...state,
            updateCam: payload as Feedback
        })
    ),
    on(
        CameraActions.update_status_camera, state => state
    ),
    on(
        CameraActions.update_status_camera_feedback, (state, { payload }) => ({
            ...state,
            updateStatusCam: payload as Feedback
        })
    ),
    on(
        CameraActions.update_status_camera_change_feedback, (state, { payload }) => ({
            ...state,
            updateStatusCam: payload as Feedback
        })
    ),
    on(
        CameraActions.delete_guest, state => state
    ),
    on(
        CameraActions.delete_guest_feedback, (state, { payload }) => ({
            ...state,
            deleteGuest: payload as number
        })
    ),
    on(
        CameraActions.delete_guest_change_feedback, (state, { payload }) => ({
            ...state,
            deleteGuest: payload as number
        })
    ),
    on(
        CameraActions.put_guests, (state, { payload }) => ({
            ...state,
            guests: payload ? payload : undefined
        })
    ),
    on(
        CameraActions.get_guests, state => state
    ),
    on(
        CameraActions.get_guests_return, (state, { payload }) => ({
            ...state,
            guests: payload as GuestsList[]
        })
    ),
    on(
        CameraActions.cleanup, state => ({
            ...state,
            guests: null,
            camera_id: null,
            camera_view: null,
            cameras: null,
            cameraShared: null,
            playlist: null,
            currentMosaic: null,
            connectionHistory: [],
            connectionHealth: null,
            finalPage: false,
            historyLoading: undefined,
            mosaics: null,
            plans: null,
            groupCamera: null,
            groupCameraID: null,
            success: null,
            deleteStatus: null,
            editStatus: null,
            editMosaic: null,
            postCam: null,
            modal_open_invite_user: ModalState.Cancel,
            modal_open_update_camera: ModalState.Cancel,
            modal_open_list_address_camera: ModalState.Cancel,
            modal_create_mosaic: ModalState.Cancel,
            updateCam: null,
            deleteCam: null,
            deleteGuest: null,
            inviteUsers: null,
            updateGuests: null,
            updateCameraGroup: null,
            deleteCamGroup: null,
            addresses: null,
            check_resolution: null,
            camera_thumbnail: null,
            previewCam: null,
            urlSignedVideo: null,
            camera_address: null,
            typesAnalytic: [],
            camerasReport: null,
            blockedCamerasReport: null,
            groupCamerasReport: null,
            resettingPlaylist: undefined,
            updateStatusCam: null
        })
    ),
    on(
        CameraActions.get_videos, state => state
    ),
    on(
        CameraActions.get_videos_success, (state, { payload }) => ({
            ...state,
            playlist: !state.resettingPlaylist ? payload as Video[] : null
        })
    ),
    on(
        CameraActions.get_videos_cleanup, state => ({
            ...state,
            playlist: []
        })
    ),
    on(
        CameraActions.reseting_videos, (state, { reset }) => ({
            ...state,
            resettingPlaylist: reset
        })
    ),
    on(
        CameraActions.get_mosaics, state => state
    ),
    on(
        CameraActions.get_mosaics_return, (state, { payload }) => ({
            ...state,
            mosaics: payload as Mosaic[]
        })
    ),
    on(
        CameraActions.get_user_mosaics, state => state
    ),
    on(
        CameraActions.get_user_mosaics_return, (state, { payload }) => ({
            ...state,
            userMosaics: payload as Mosaic[]
        })
    ),
    on(
        CameraActions.save_mosaic, (state, { mosaic }) => {
            return {
                ...state,
                currentMosaic: mosaic
            };
        }
    ),
    on(
        CameraActions.save_mosaic_return, (state, { payload }) => ({
            ...state,
            success: payload as boolean
        })
    ),
    on(
        CameraActions.edit_mosaic, state => state
    ),
    on(
        CameraActions.edit_mosaic_return, (state, { payload }) => ({
            ...state,
            editStatus: payload as Feedback
        })
    ),
    on(
        CameraActions.delete_mosaic, state => state
    ),
    on(
        CameraActions.delete_mosaic_return, (state, { payload }) => ({
            ...state,
            deleteStatus: payload as Feedback
        })
    ),
    on(
        CameraActions.reset_return, state => ({
            ...state,
            success: null,
            deleteStatus: null,
            editStatus: null,
            editMosaic: null
        })
    ),
    on(
        CameraActions.open_modal_mosaic, state => ({
            ...state,
            modal_create_mosaic: ModalState.Open,
            editMosaic: {
                id_mosaic_template: 1,
                cameras: [{ id_camera: -1 }]
            }
        })
    ),
    on(
        CameraActions.open_edit_modal_mosaic, (state, { payload }) => ({
            ...state,
            editMosaic: payload as Mosaic,
            modal_create_mosaic: ModalState.Open
        })
    ),
    on(
        CameraActions.close_modal_mosaic, state => ({
            ...state,
            modal_create_mosaic: ModalState.Closed,
            editMosaic: null,
            success: undefined
        })
    ),
    on(
        CameraActions.cancel_modal_mosaic, state => ({
            ...state,
            modal_create_mosaic: ModalState.Cancel,
            editMosaic: null,
            success: undefined
        })
    ),
    on(
        CameraActions.get_rtmp_url, state => state
    ),
    on(
        CameraActions.get_rtmp_url_return, (state, { payload }) => ({
            ...state,
            RTMPUri: payload as any
        })
    ),
    on(
        CameraActions.open_view_group, state => ({
            ...state,
            OpenViewGroup: true
        })
    ),
    on(
        CameraActions.open_view_group_clear, state => ({
            ...state,
            OpenViewGroup: null,
            cameras: null
        })
    ),
    on(
        CameraActions.get_clients_group, state => state
    ),
    on(
        CameraActions.get_clients_group_return, (state, { payload }) => ({
            ...state,
            clientsGroup: payload as ClientsToGroup[]
        })
    ),
    on(
        CameraActions.get_clients_group_clear, state => ({
            ...state,
            clientsGroup: null
        })
    ),
    on(
        CameraActions.create_group, state => state
    ),
    on(
        CameraActions.create_group_return, (state, { payload }) => ({
            ...state,
            createGroup: payload as Feedback
        })
    ),
    on(
        CameraActions.create_group_clear, state => ({
            ...state,
            createGroup: null,
        })
    ),
    on(
        CameraActions.get_cameras_report, state => ({
            ...state,
            camerasReport: null
        })
    ),
    on(
        CameraActions.get_cameras_report_return, (state, { payload }) => ({
            ...state,
            camerasReport: payload as CamerasReport[]
        })
    ),
    on(
        CameraActions.get_cameras_report_clear, state => ({
            ...state,
            camerasReport: null
        })
    ),
    on(
        CameraActions.get_blocked_cameras_report, state => ({
            ...state,
            blockedCamerasReport: null
        })
    ),
    on(
        CameraActions.get_blocked_cameras_report_return, (state, { payload }) => ({
            ...state,
            blockedCamerasReport: payload as BlockedCamerasReport[]
        })
    ),
    on(
        CameraActions.get_blocked_cameras_report_clear, state => ({
            ...state,
            blockedCamerasReport: null
        })
    ),
    on(
        CameraActions.get_group_cameras_report, state => ({
            ...state,
            groupCamerasReport: null
        })
    ),
    on(
        CameraActions.get_group_cameras_report_return, (state, { payload }) => ({
            ...state,
            groupCamerasReport: payload as GroupCamerasReport[]
        })
    ),
    on(
        CameraActions.get_group_cameras_report_clear, state => ({
            ...state,
            groupCamerasReport: null
        })
    ),
    on(
        CameraActions.get_provider_integrated_cameras, state => ({
            ...state,
            providerIntegratedCameras: null
        })
    ),
    on(
        CameraActions.get_provider_integrated_cameras_return, (state, { payload }) => ({
            ...state,
            providerIntegratedCameras: payload as ProviderIntegratedCameras[]
        })
    ),
    on(
        CameraActions.get_provider_integrated_cameras_clear, state => ({
            ...state,
            providerIntegratedCameras: null
        })
    ),
    on(
        CameraActions.get_cameras_by_location, state => ({
            ...state,
            camerasByLocation: null
        })
    ),
    on(
        CameraActions.get_cameras_by_location_return, (state, { payload }) => ({
            ...state,
            camerasByLocation: payload as CamerasByLocation[]
        })
    ),
    on(
        CameraActions.get_cameras_by_location_clear, state => ({
            ...state,
            camerasByLocation: null
        })
    ),
);
