import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Analytics, ModalState, AnalyticMetadata, GroupAnalytics, TypeAnalytic, AnalyticsByCamera, DrawType } from '../models';
import { AnalyticActions } from '../Services/actions';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/model';
import { Subject, Subscription } from 'rxjs';
import { Camera } from 'app/cameras/models';
import { ConfigureAnalyticComponent } from '../configure-analytic/configure-analytic.component';
import { DrawAnalyticComponent } from '../draw-analytic/draw-analytic.component';
import { OperationStatus } from 'app/Shared/models';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { Router } from '@angular/router';

@Component({
    selector: 'app-analytic-create',
    templateUrl: './analytic-create.component.html',
    styleUrls: ['./analytic-create.component.scss', '../styles.scss'],
})
export class AnalyticCreateComponent implements OnInit, OnDestroy {
    readonly statusPostAnalytic$ = this.store.select((state: AppState) => state.analytic.statusPostAnalytic);
    readonly statusPutAnalytic$ = this.store.select((state: AppState) => state.analytic.statusPutAnalytic);
    readonly statusTotalCreatesAnalytics$ = this.store.select((state: AppState) => state.analytic.statusTotalCreatesAnalytics);
    readonly modal_data$ = this.store.select((state: AppState) => state.analytic.modalData);

    @ViewChild(ConfigureAnalyticComponent) configuration: ConfigureAnalyticComponent;
    @ViewChild(DrawAnalyticComponent) draw: DrawAnalyticComponent;
    cam: Camera;
    client_sub: string;
    currentStep = -1;
    nextMessage: string[] = ['', '', '', ''];
    analytic: Analytics;
    newAnalytic: Analytics = { smaller_object: 10, larger_object: 80 };
    group: GroupAnalytics;
    groups: GroupAnalytics[] = [];
    metadata: AnalyticMetadata = {
        points: [],
        extra_info: {
            verticesQuantity: 0
        }
    };
    //Título da página
    namePage: string;
    //Tipos de analíticos do grupo escolhido
    optionsAnalytics: TypeAnalytic[];
    //Enviar evento de escolha de analítico para DrawAnalyticComponent
    changedOptionEvent: Subject<DrawType> = new Subject<DrawType>();
    //Enviar evento de reset
    resetAnalyticEvent: Subject<any> = new Subject<any>();
    //Objeto que será enviado à api
    newAnalyticByCamera: AnalyticsByCamera;
    hasUpdate = false;
    heightModal: string;
    loadingPostDel = false;
    showHelp = false;
    pageName = 'analytic-create';
    statusCreateAnalytic: Subscription;
    statusPutAnalytic: Subscription;
    modalDataSub: Subscription;
    statusTotalCreatesAnalytics: Subscription;
    formInformation: Subject<boolean> = new Subject<boolean>();
    hasHolidaysInCamera: boolean;
    constructor(public dialogRef: MatDialogRef<AnalyticCreateComponent>, @Inject(MAT_DIALOG_DATA) public data: { camera: Camera },
        private readonly store: Store<AppState>, private validationResponse: ValidationResponseHandlerModule, private router: Router) {
    }

    ngOnInit(): void {
    // retorna as informações de quantidade de analiticos criados
        this.store.dispatch(AnalyticActions.get_analitycs_total_creates({}));
        // this.getStatusTotalCreatesAnalyticsReturn();
        // this.store.dispatch(this.actions.post_analytics_camera_change_feedback());
        //Inicializando variáveis
        this.client_sub = localStorage.getItem('clientView');
        this.heightModal = 'fit-content';
        this.cam = this.data.camera;
        this.newAnalyticByCamera = {
            alias: this.cam.alias,
            idCamera: this.cam.id
        };
        this.msgCreateAnalytic();
        this.msgPutAnalytic();
        this.getModalData();
    }

    ngOnDestroy() {
        if (this.statusCreateAnalytic) {
            this.statusCreateAnalytic.unsubscribe();
        }
        if (this.statusPutAnalytic) {
            this.statusPutAnalytic.unsubscribe();
        }
        if (this.modalDataSub) {
            this.modalDataSub.unsubscribe();
        }
        if (this.statusTotalCreatesAnalytics) {
            this.statusTotalCreatesAnalytics.unsubscribe();
        }

        this.store.dispatch(AnalyticActions.cleanup());
    }

    getAnaliticFormFull(statusForm: boolean) {
        this.formInformation.next(statusForm);
    }

    getChosenAnalytic(event: Analytics) {
        if (this.currentStep != -1) {
            this.firstTooltip();
        }
        this.nextMessage = [];

        switch (event.alias) {
            case 'Aglomeração':
                this.nextMessage.push('');
                this.nextMessage.push('Selecione o máximo de pessoas aglomeradas em que o alerta deve ser emitido.');
                this.nextMessage.push('Selecione o intervalo de tempo em que os alertas serão emitidos.');
                this.nextMessage.push('Selecione o nível de criticidade do alerta.');
                this.nextMessage.push('Selecione a cor e clique na imagem para desenhar a área de detecção.');
                break;
            case 'Detecção de Máscara':
                this.nextMessage.push('Selecione em qual a situação a câmera deve alertar.');
                this.nextMessage.push('Selecione o intervalo de tempo em que os alertas serão emitidos.');
                this.nextMessage.push('');
                this.nextMessage.push('Selecione o nível de criticidade do alerta.');
                this.nextMessage.push('Selecione a cor e clique na imagem para desenhar a área de detecção.');
                break;
            case 'Movimento':
                this.nextMessage.push('Selecione os objetos que deseja detectar na cena.');
                this.nextMessage.push('Selecione o intervalo de tempo em que os alertas serão emitidos.');
                this.nextMessage.push('');
                this.nextMessage.push('Selecione o nível de criticidade do alerta.');
                this.nextMessage.push('Selecione a cor e clique na imagem para desenhar a área de detecção.');
                break;
            case 'Cruzamento de Linha':
                this.nextMessage.push('Selecione os objetos que deseja detectar na cena.');
                this.nextMessage.push('');
                this.nextMessage.push('Selecione o nível de criticidade do alerta.');
                this.nextMessage.push('Selecione a cor e clique na imagem para desenhar a área de detecção.');
                break;
            case 'Abandono de Posto':
                this.nextMessage.push('');
                this.nextMessage.push('Selecione o intervalo de tempo em que os alertas serão emitidos.');
                this.nextMessage.push('');
                this.nextMessage.push('Selecione o nível de criticidade do alerta.');
                this.nextMessage.push('Selecione a cor e clique na imagem para desenhar a área de detecção.');
                break;
            case 'Ausência de Movimento':
                this.nextMessage.push('');
                this.nextMessage.push('Selecione a duração de ausência de movimento para que o alerta seja emitido.');
                this.nextMessage.push('Selecione o intervalo de tempo em que os alertas serão emitidos.');
                this.nextMessage.push('Selecione o nível de criticidade do alerta.');
                this.nextMessage.push('Selecione a cor e clique na imagem para desenhar a área de detecção.');
                break;
            case 'Reconhecimento de Placa Veicular':
                this.nextMessage.push('');
                this.nextMessage.push('Selecione o intervalo de tempo em que os alertas serão emitidos.');
                this.nextMessage.push('');
                this.nextMessage.push('Selecione o nível de criticidade do alerta.');
                this.nextMessage.push('Selecione a cor e clique na imagem para desenhar a área de detecção.');
                break;
            case 'Assalto com Moto':
                this.nextMessage.push('');
                this.nextMessage.push('Selecione o intervalo de tempo em que os alertas serão emitidos.');
                this.nextMessage.push('');
                this.nextMessage.push('Selecione o nível de criticidade do alerta.');
                this.nextMessage.push('Selecione a cor e clique na imagem para desenhar a área de detecção.');
                break;
            case 'Vadiagem':
                this.nextMessage.push('');
                this.nextMessage.push('Selecione em qual a categoria a câmera deve alertar.');
                this.nextMessage.push('Selecione o intervalo de tempo em que os alertas serão emitidos.');
                this.nextMessage.push('Selecione o nível de criticidade do alerta.');
                this.nextMessage.push('Selecione a cor e clique na imagem para desenhar a área de detecção.');
                break;
            case 'Detecção de Emboscada':
                this.nextMessage.push('');
                this.nextMessage.push('Selecione a duração de tempo de espera e de limite para que o alerta seja emitido.');
                this.nextMessage.push('Selecione o intervalo de tempo em que os alertas serão emitidos.');
                this.nextMessage.push('Selecione o nível de criticidade do alerta.');
                this.nextMessage.push('Selecione a cor e clique na imagem para desenhar a área de detecção.');
                break;
            case 'Abuso de Permanência':
                this.nextMessage.push('Selecione os objetos que deseja detectar na cena.');
                this.nextMessage.push('Selecione o tempo de tolerância para que o alerta seja emitido.');
                this.nextMessage.push('Selecione o intervalo de tempo em que os alertas serão emitidos.');
                this.nextMessage.push('Selecione o nível de criticidade do alerta.');
                this.nextMessage.push('Selecione a cor e clique na imagem para desenhar a área de detecção.');
                break;
            case 'Tampering':
                this.nextMessage.push('Selecione o tipo de analíse a ser detectado');
                this.nextMessage.push('Selecione o tempo de tolerância para que o alerta seja emitido.');
                this.nextMessage.push('Selecione o intervalo de tempo em que os alertas serão emitidos.');
                this.nextMessage.push('Selecione o nível de criticidade do alerta.');
                this.nextMessage.push('Selecione a cor e clique na imagem para desenhar a área de detecção.');
                break;
            default:
                this.nextMessage.push('');
                this.nextMessage.push('');
                this.nextMessage.push('');
                this.nextMessage.push('');
                this.nextMessage.push('');
                break;
        }

    }

    previousPage() {
        this.store.dispatch(AnalyticActions.update_modal_state({ payload: ModalState.List }));
        this.dialogRef.close();
    }

    changedOption(event: DrawType) {
        this.changedOptionEvent.next(event);
    }

    getModalData() {
        this.modalDataSub = this.modal_data$.subscribe(modalData => {
            this.analytic = modalData.analytic;
            this.group = modalData.groups.find(g => g.id === modalData.groupId);
            this.groups = modalData.groups;
            this.optionsAnalytics = this.group.analytic_types;
            this.namePage = this.group.alias;
            this.newAnalytic.id_group = this.group.id;
            this.hasHolidaysInCamera = modalData.hasHolidaysInCamera;
            if (this.analytic) {
                this.hasUpdate = true;
                this.newAnalytic = this.analytic;
                this.changedOptionEvent.next(this.optionsAnalytics.find(a => a.id_analytic_type === this.analytic.id_analytic_type).draw_type);
            }
        });
    }

    addAnalytic() {
        let erro = false;
        if (this.configuration.selectClassifiers.value) {
            this.newAnalytic.classifier = [] as string[];
            this.configuration.selectClassifiers.value.forEach(element => {
                const index = this.configuration.optionsClassifiers.findIndex(el => el.alias === element['alias']);
                if (index != -1) {
                    this.newAnalytic.classifier.push(this.configuration.optionsClassifiers[index].hashname);
                }
            });
        }
        else {
            this.newAnalytic.classifier = [] as string[];
        }

        this.newAnalytic.events = this.configuration.newAnalytic.events ? this.configuration.newAnalytic.events : [];

        if (this.configuration.selectAnalytics.value) {
            const index = this.optionsAnalytics.findIndex(el => el.alias === this.configuration.selectAnalytics.value.alias);
            if (index != -1) {
                this.newAnalytic.id_analytic_type = this.optionsAnalytics[index].id_analytic_type;
                this.newAnalytic.alias = this.optionsAnalytics[index].alias;
            }
        }
        else {
            erro = true;
            this.validationResponse.validationResponseHandler(400, this.pageName, 'select-type-analytic', 'analytics.select_type');
            // showAlert('Selecione um tipo de analítico!', 'danger');
        }

        if ((this.draw.newAnalyticDraw.value == 'line' && this.draw.newAnalytic.points.length != 2) ||
      (this.draw.newAnalyticDraw.value == 'polygon' && this.draw.newAnalytic.points.length < 3) ||
      (this.draw.newAnalyticDraw.value == 'rectangle' && this.draw.newAnalytic.points.length != 4)) {
            erro = true;
            this.validationResponse.validationResponseHandler(400, this.pageName, 'draw-analytic', 'analytics.draw_type');
            // showAlert('Desenhe um analítico conforme seu tipo!', 'danger');
        }

        if (this.configuration.scheduleList.length == 0) {
            erro = true;
            this.validationResponse.validationResponseHandler(400, this.pageName, 'scheduling-required', 'analytics.scheduling_required');
            // showAlert('Crie pelo menos um agendamento!', 'danger');
        }

        if (this.configuration.criticalitySelected == undefined) {
            erro = true;
            this.validationResponse.validationResponseHandler(400, this.pageName, 'criticality-required', 'analytics.criticality_required');
        }

        if (['Ausência de Movimento', 'Abandono de Posto', 'Aglomeração', 'Dispersão', 'Aglomeração e dispersão',
            'Abuso de Permanência', 'Tampering'].includes(this.newAnalytic.alias)) {
            if (!this.configuration.crowdingMaxTimeMin.valid || !this.configuration.crowdingMaxTimeSec.valid) {
                erro = true;
                this.validationResponse.validationResponseHandler(400, this.pageName, 'amount-objects-required', 'analytics.amount_objects');
                // showAlert('Insira valores corretos no campo do tempo de alerta!', 'danger');
            }

            if (this.newAnalytic.alias === 'Aglomeração e dispersão') {
                if (this.configuration.selectClassifiers.value.length == 0 ||
            !this.configuration.crowdingMaxPeople.valid || !this.configuration.crowdingMinPeople.valid) {
                    erro = true;
                    this.validationResponse.validationResponseHandler(400, this.pageName, 'amount-objects-required', 'analytics.amount_objects');
                }

            }

            if (this.newAnalytic.alias === 'Aglomeração') {
                if (this.configuration.selectClassifiers.value.length == 0 || !this.configuration.crowdingMaxPeople.valid) {
                    erro = true;
                    this.validationResponse.validationResponseHandler(400, this.pageName, 'amount-objects-required', 'analytics.amount_objects');
                }
            }

            if (this.newAnalytic.alias === 'Dispersão') {
                if (this.configuration.selectClassifiers.value.length == 0 || !this.configuration.crowdingMinPeople.valid) {
                    erro = true;
                    this.validationResponse.validationResponseHandler(400, this.pageName, 'amount-objects-required', 'analytics.amount_objects');
                }

            }

            if (this.newAnalytic.alias === 'Abuso de Permanência') {
                if (this.configuration.selectClassifiers.value.length == 0) {
                    erro = true;
                    this.validationResponse.validationResponseHandler(400, this.pageName, 'amount-objects-required', 'analytics.amount_objects');
                }
            }
        }

        if (['Vadiagem'].includes(this.newAnalytic.alias)) {
            if (!this.configuration.loiteringControl.valid || !this.configuration.crowdingMaxTimeMin.valid
          || !this.configuration.crowdingMaxTimeSec.valid) {
                erro = true;
                this.validationResponse.validationResponseHandler(400, this.pageName, 'amount-objects-required', 'analytics.amount_objects');
            }
        }

        if (['Detecção de Emboscada'].includes(this.newAnalytic.alias)) {
            if (!this.configuration.crowdingMaxTimeMin.valid || !this.configuration.toleranceTimeMin.valid) {
                erro = true;
                this.validationResponse.validationResponseHandler(400, this.pageName, 'amount-objects-required', 'analytics.amount_objects');
            }
        }

        if (['Movimento', 'Cruzamento de Linha'].includes(this.newAnalytic.alias)) {
            if (this.configuration.selectClassifiers.value.length == 0) {
                erro = true;
                this.validationResponse.validationResponseHandler(400, this.pageName, 'amount-objects-required', 'analytics.amount_objects');
                // showAlert('Insira um valor no campo de alerta para quantidade de objetos!', 'danger');
            }
        }

        if (!erro) {
            this.newAnalytic.border_color = this.draw.newAnalytic.color;
            this.newAnalytic.blur = this.draw.sensitivityDraw == 0 ? 1 : this.draw.sensitivityDraw;
            this.newAnalytic.toogle_light = this.draw.toogleLight.value;
            this.newAnalytic.smaller_object = this.draw.objectControl[0];
            this.newAnalytic.larger_object = this.draw.objectControl[1];
            this.newAnalytic.schedules = this.configuration.scheduleList;
            this.newAnalytic.criticality = this.configuration.criticalitySelected;
            if (this.configuration.testOption.value) {
                this.newAnalytic.category_id = this.configuration.testOption.value;
            }
            this.metadata = {
                points: this.draw.newAnalytic.points,
                extra_info: {
                    verticesQuantity: this.draw.newAnalytic.points.length,
                }
            };
            if (['Ausência de Movimento', 'Abandono de Posto', 'Aglomeração', 'Abuso de Permanência', 'Dispersão',
                'Aglomeração e dispersão'].includes(this.newAnalytic.alias)) {
                const maxTime = (Math.floor(this.configuration.crowdingMaxTimeMin.value) * 60) + Math.floor(this.configuration.crowdingMaxTimeSec.value);
                this.metadata.extra_info.maxTime = maxTime;
                if (this.newAnalytic.alias === 'Aglomeração') {
                    const maxPeople = Math.floor(this.configuration.crowdingMaxPeople.value);
                    this.metadata.extra_info.maxPeople = maxPeople;
                    this.metadata.extra_info.minPeople = 1;
                    this.metadata.extra_info.agglomeration = true;
                    this.metadata.extra_info.dispersion = false;
                } else if (this.newAnalytic.alias === 'Dispersão') {
                    const minPeople = Math.floor(this.configuration.crowdingMinPeople.value);
                    this.metadata.extra_info.minPeople = minPeople;
                    this.metadata.extra_info.maxPeople = minPeople + 1;
                    this.metadata.extra_info.agglomeration = false;
                    this.metadata.extra_info.dispersion = true;
                } else if (this.newAnalytic.alias === 'Aglomeração e dispersão') {
                    const maxPeople = Math.floor(this.configuration.crowdingMaxPeople.value);
                    const minPeople = Math.floor(this.configuration.crowdingMinPeople.value);
                    this.metadata.extra_info.minPeople = minPeople;
                    this.metadata.extra_info.maxPeople = maxPeople;
                    this.metadata.extra_info.agglomeration = true;
                    this.metadata.extra_info.dispersion = true;
                }
            } else if (this.newAnalytic.alias === 'Tampering') {
                const duration = (Math.floor(this.configuration.crowdingMaxTimeMin.value) * 60) + Math.floor(this.configuration.crowdingMaxTimeSec.value);
                this.metadata.extra_info.dissimilarity = (this.configuration.dissimilarity / 100);
                this.metadata.extra_info.analysistype = this.newAnalytic.events[0].replace('detection', '');
                this.metadata.extra_info.tampering_duration = duration;
            } else if (this.newAnalytic.alias === 'Detecção de Máscara') {
                this.metadata.extra_info.withoutmask = this.configuration.checkOption.value;
            } else if (this.newAnalytic.alias === 'Vadiagem') {
                const permanceTime = (Math.floor(this.configuration.crowdingMaxTimeMin.value) * 60) + Math.floor(this.configuration.crowdingMaxTimeSec.value);
                this.metadata.extra_info.permanceTime = permanceTime;
                this.metadata.extra_info.type = this.configuration.loiteringControl.value.value;
            } else if (this.newAnalytic.alias === 'Detecção de Emboscada') {
                const permanceTime = (Math.floor(this.configuration.crowdingMaxTimeMin.value) * 60) + Math.floor(this.configuration.crowdingMaxTimeSec.value);
                const toleranceTime = (Math.floor(this.configuration.toleranceTimeMin.value) * 60) + Math.floor(this.configuration.toleranceTimeSec.value);
                this.metadata.extra_info.permanceTime = Math.floor(permanceTime / 60);
                this.metadata.extra_info.toleranceTime = Math.floor(toleranceTime / 60);
            }
            this.newAnalytic.metadata = JSON.stringify(this.metadata);
            this.newAnalyticByCamera.analytics = [];
            this.newAnalyticByCamera.analytics.push(this.newAnalytic);
            this.loadingPostDel = true;
            this.heightModal = '92vh';
            if (this.hasUpdate) {
                this.store.dispatch(AnalyticActions.put_analytics_camera({ put_analytic: this.newAnalyticByCamera, child_sub: localStorage.getItem('clientView')}));
            }
            else {
                this.store.dispatch(AnalyticActions.post_analytics_camera({ new_analytic: this.newAnalyticByCamera, child_sub: localStorage.getItem('clientView')}));
            }
        }
    }

    // Retorna a mensagem de criação do analitico
    msgCreateAnalytic() {
        this.statusCreateAnalytic = this.statusPostAnalytic$.subscribe(msg => {
            if (msg.status != OperationStatus.Unloaded) {
                this.loadingPostDel = false;
                this.heightModal = 'fit-content';
                if (msg.status === OperationStatus.Success) {
                    // showAlert(msg.message, 'success');
                    this.validationResponse.validationResponseHandler(200, this.pageName, 'create-analytic-success', null, msg.message);
                    this.store.dispatch(AnalyticActions.post_analytics_camera_change_feedback());
                    this.store.dispatch(AnalyticActions.get_analytics_camera({ camera_id: this.cam.id }));
                    this.previousPage();
                }
                else {
                    // showAlert(msg.message, 'danger');
                    this.validationResponse.validationResponseHandler(400, this.pageName, 'create-analytic-failure', null, msg.message);
                }
            }
        });
    }

    msgPutAnalytic() {
        this.statusPutAnalytic = this.statusPutAnalytic$.subscribe(msg => {
            if (msg.status != OperationStatus.Unloaded) {
                this.loadingPostDel = false;
                this.heightModal = 'fit-content';
                if (msg.status === OperationStatus.Success) {
                    // showAlert(msg.message, 'success');
                    this.validationResponse.validationResponseHandler(200, this.pageName, 'update-analytic-success', null, msg.message);
                    this.store.dispatch(AnalyticActions.put_analytics_camera_change_feedback());
                    this.store.dispatch(AnalyticActions.get_analytics_camera({ camera_id: this.cam.id }));
                    this.previousPage();
                }
                else {
                    // showAlert(msg.message, 'danger');
                    this.validationResponse.validationResponseHandler(400, this.pageName, 'update-analytic-failure', null, msg.message);
                }
            }
        });
    }

    firstTooltip() {
        const tooltipStep = document.getElementById('tooltipOneStep');
        if (this.hasUpdate){
            tooltipStep.style.visibility = 'hidden';
        } else {
            if (this.currentStep != -1) {
                this.closeStep(this.currentStep + 1);
            }
            this.currentStep = -1;
            tooltipStep.style.visibility = 'visible';
        }
    }

    nextStepTooltip() {
        const tooltipSteps = document.getElementsByClassName('tooltipStep') as HTMLCollectionOf<HTMLElement>;
        tooltipSteps[this.currentStep + 1].style.visibility = 'hidden';

        const index = this.hasNextStep();
        if (index != -1) {
            tooltipSteps[index + 1].style.visibility = 'visible';
            this.currentStep = index;
        }
    }

    hasNextStep(): number {
        for (let index = this.currentStep + 1; index < 4; index++) {
            if (this.nextMessage[index] != '') {
                return index;
            }
        }
        return -1;
    }

    closeStep(id: number) {
        const tooltipSteps = document.getElementsByClassName('tooltipStep') as HTMLCollectionOf<HTMLElement>;
        tooltipSteps[id].style.visibility = 'hidden';
        this.currentStep = -1;
    }

    getStatusTotalCreatesAnalyticsReturn() {
        this.statusTotalCreatesAnalytics = this.statusTotalCreatesAnalytics$.subscribe(msg => {
            if (msg.status != OperationStatus.Unloaded) {
                if (msg.status === OperationStatus.Success) {
                    if (msg.data['total'] <= 5) {
                        this.firstTooltip();
                    }
                }
            }
        });
    }

    closeModal() {
        this.store.dispatch(AnalyticActions.update_modal_state({ payload: ModalState.Closed }));
        this.dialogRef.close();
    }

}
