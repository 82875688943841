import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import { Camera } from '../models';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { SharedService } from 'app/Shared/Services/shared.service';
import { Store } from '@ngrx/store';
import { Subject, Subscription} from 'rxjs';
import {CameraActions} from '../Services/actions';
import {AppState} from '../../store/model';

@Component({
    selector: 'app-camera-data',
    templateUrl: './camera-data.component.html',
    styleUrls: ['./camera-data.component.scss']
})
export class CameraDataComponent implements OnInit, OnDestroy {
    readonly providerIntegratedCameras$ = this.store.select((state: AppState) => state.camera.providerIntegratedCameras);
    @Input() camera: Camera;
    @Input() loading: boolean;
    @Output() integratedCameraData = new EventEmitter<Camera>();
    @Output() camRetryData = new EventEmitter<boolean>();
    @Output() camEditData = new EventEmitter<boolean>();
    @Output() SaveCamera = new EventEmitter<Camera>();
    @Output() cancelEdit = new EventEmitter<void>();
    cameraForm = new UntypedFormGroup({
        nome_camera: new UntypedFormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(250), Validators.pattern(/^(?!\s)((?!\s{2,}).)*$/)]),
        link_rtsp: new UntypedFormControl('', [Validators.required, Validators.pattern(/(rtsp:\/\/)[a-zA-Z0-9!#_.]+[:][a-zA-Z0-9!#_.@]+[@][a-zA-Z0-9.:\-_]+[\/]/)]),
        link_rtmp: new UntypedFormControl('', [Validators.pattern(/^(?!\s)((?!\s{2,}).)*$/)]),
        isCameraOnline: new UntypedFormControl('', []),
    });
    intregatedCamForm = new UntypedFormGroup({
        user: new UntypedFormControl('', [Validators.required]),
        model_hash: new UntypedFormControl('', [Validators.required]),
        model_name: new UntypedFormControl('', [Validators.required]),
        url_http: new UntypedFormControl('', [Validators.required]),
        password: new UntypedFormControl('', [Validators.required]),
    });
    link_rtsp;
    original_rtsp;
    cameraName: string;
    registerAtConvert: string;
    activedAtConvert: string;
    activedAtTwoHours: boolean;
    save_changes: boolean;
    pageName = 'camera-data';
    isRTMP: boolean;
    is_guest = false;
    isClientAssociate: boolean = localStorage.getItem('profile_name') === '419bea06-5d4e-4a56-b8b5-04b3ad566d59';
    adminClientAssociate: boolean;
    isAssociate: boolean;
    adminAssociate: boolean;
    isPartner: boolean;
    hide = true;
    user_profile: string = localStorage.getItem('profile_name');
    userSub: string;

    providerIntegratedCameras: Subscription;
    modelList: {
        id: number | string;
        text: string;
    }[];

    camModel: {
        hash: string;
        name: string;
    }[];

    processingTag = false;

    constructor(public dialogRef: MatDialogRef<CameraDataComponent>, private readonly store: Store<AppState>,
        private sharedService: SharedService, private validationResponse: ValidationResponseHandlerModule) { }

    ngOnInit(): void {
        this.isRTMP = this.camera.protocol === 'RTMP';
        this.save_changes = false;
        this.userSub = localStorage.getItem('sub');
        if (this.camera.integrated_camera != null) {
            this.store.dispatch(CameraActions.get_provider_integrated_cameras({
                user_sub: this.userSub,
            }));
        }
        this.getProviderIntegratedCameras();
        this.camInformations();

        if (localStorage.getItem('profile_name') == 'cd343bfc-17e8-11ec-9621-0242ac130002') {
            this.isPartner = true;
        }
        if (localStorage.getItem('profile_name') === 'f29868c7-b4c5-4963-9ae8-1dd95699d6c3') {
            this.isAssociate = true;
        }
        if (this.isAssociate && localStorage.getItem('associate_permission') === 'e816c560-812e-11ed-a1eb-0242ac120002') {
            this.adminAssociate = true;
        }

        this.adminClientAssociate = this.isClientAssociate && localStorage.getItem('associate_permission') === 'e816c560-812e-11ed-a1eb-0242ac120002';
        this.is_guest = (this.user_profile == 'bb653b3a-fdb3-4438-bce6-012585b5268f' || (this.isClientAssociate && !this.adminClientAssociate));

        this.cameraForm.valueChanges.subscribe(value => {
            if (this.cameraForm.valid) {
                this.camera.alias = this.cameraForm.get('nome_camera').value;
                this.camera.link_rtsp = this.cameraForm.get('link_rtsp').value;
                this.camera.link_rtmp = this.cameraForm.get('link_rtmp').value;
                this.camera.is_camera_online = this.cameraForm.get('isCameraOnline').value;
                this.save_changes = true;
                this.SaveCamera.emit(this.camera);
            } else {
                if ((localStorage.getItem('profile_name') == 'cd343bfc-17e8-11ec-9621-0242ac130002' || this.adminAssociate || this.adminClientAssociate || this.isPartner) &&
            (this.cameraForm.get('nome_camera').valid))
                {
                    this.camera.alias = this.cameraForm.get('nome_camera').value;
                    this.camera.link_rtsp = this.cameraForm.get('link_rtsp').value;
                    this.camera.link_rtmp = this.cameraForm.get('link_rtmp').value;
                    this.camera.is_camera_online = this.cameraForm.get('isCameraOnline').value;
                    this.save_changes = true;
                    this.SaveCamera.emit(this.camera);
                }
                else {
                    this.save_changes = false;
                }
            }
        });

        if (this.camera.integrated_camera != null) {
            this.intregatedCamForm.valueChanges.subscribe(value => {
                if (this.intregatedCamForm.valid) {
                    this.save_changes = true;
                } else {
                    this.save_changes = false;
                }
            });
        }
    }

    ngOnDestroy() {
        if (this.providerIntegratedCameras) {
            this.providerIntegratedCameras.unsubscribe();
        }

        this.store.dispatch(CameraActions.clear_camera_create_thumbnail());
    }

    changeCameraOnline() {
        this.cameraForm.get('isCameraOnline').setValue(!this.cameraForm.get('isCameraOnline').value);
    }

    camInformations() {
        this.original_rtsp = this.camera.link_rtsp;
        this.cameraName = this.camera.alias;
        this.cameraForm.get('nome_camera').setValue(this.camera.alias);
        this.cameraForm.get('link_rtsp').setValue(this.camera.link_rtsp);
        this.cameraForm.get('link_rtmp').setValue(this.camera.link_rtmp);
        this.registerAtConvert = this.convertDate(this.camera.registration_date);
        this.cameraForm.get('isCameraOnline').setValue(!!this.camera.status);
        this.activedAtConvert = this.camera.date_active ? this.convertDate(this.camera.date_active) : this.registerAtConvert;
        this.activedAtTwoHours = Math.floor(Math.floor((Math.abs(new Date().getTime() - new Date(String(this.camera.date_active)).getTime())) / 1000) / 60) >= 120;

        if (this.camera.integrated_camera != null) {
            this.intregatedCamForm.get('url_http').setValue(this.camera.integrated_camera.url_http);
            this.intregatedCamForm.get('user').setValue(this.camera.integrated_camera.login);
            this.intregatedCamForm.get('password').setValue(this.camera.integrated_camera.password);
            this.intregatedCamForm.get('model_hash').setValue(this.camera.integrated_camera.camera_model_hash);
            this.intregatedCamForm.get('model_name').setValue(this.camera.integrated_camera.camera_model_name);
        }
    }

    convertDate(date_to_convert) {
        const data = new Date(String(date_to_convert));
        const dia = data.getDate().toString().padStart(2, '0');
        const mes = (data.getMonth() + 1).toString().padStart(2, '0');
        const ano = data.getFullYear();
        const hora = data.getHours().toString().padStart(2, '0');
        const minutes = data.getMinutes().toString().padStart(2, '0');
        return dia + '/' + mes + '/' + ano + ' às ' + hora + ':' + minutes;
    }

    closeModal() {
        this.camera.alias = this.cameraName;
        this.cancelEdit.emit();
    }

    getProviderIntegratedCameras() {
        this.providerIntegratedCameras = this.providerIntegratedCameras$.subscribe(result => {
            if (result) {
                this.modelList = result.map(element => {
                    return {
                        id: element.hashProvider,
                        text: element.models
                    };
                });
            }
        });
    }


    getModel(event) {
        this.camModel = [event].map(element => {
            return {
                hash: element.id,
                name: element.text
            };
        });
        this.intregatedCamForm.get('model_hash').setValue(this.camModel[0].hash);
        this.intregatedCamForm.get('model_name').setValue(this.camModel[0].name);
    }

    retry() {
        this.processingTag = true;
        if (this.intregatedCamForm.valid) {
            this.camRetryData.emit(true);
        }
    }

    saveChanges() {
        if (this.camera.integrated_camera != null) {
            if (this.cameraForm.valid && this.intregatedCamForm.valid) {
                this.camera.integrated_camera.url_http = this.intregatedCamForm.get('url_http').value;
                this.camera.integrated_camera.login = this.intregatedCamForm.get('user').value;
                this.camera.integrated_camera.password = this.intregatedCamForm.get('password').value;
                this.camera.integrated_camera.camera_model_hash = this.intregatedCamForm.get('model_hash').value;
                this.camera.integrated_camera.camera_model_name =  this.intregatedCamForm.get('model_name').value;
                this.integratedCameraData.emit(this.camera);
                this.camEditData.emit(true);
            }
        }
        else if (this.cameraForm.valid && this.camera.integrated_camera == null) {
            this.camEditData.emit(true);
        }
        else {
            if (this.cameraForm.get('nome_camera').invalid){
                this.validationResponse.validationResponseHandler(400, 'camera-form', 'camera-name-null', 'cameras.camera_name_null');
            }
            if ((this.cameraForm.get('link_rtsp').invalid) && (localStorage.getItem('profile_name') != 'cd343bfc-17e8-11ec-9621-0242ac130002')){
                this.validationResponse.validationResponseHandler(400, 'camera-form', 'invalid-rtsp', 'cameras.invalid_rtsp');
            }
            if ((localStorage.getItem('profile_name') == 'cd343bfc-17e8-11ec-9621-0242ac130002' || this.adminAssociate || this.adminClientAssociate || this.isPartner) &&
          (this.cameraForm.get('nome_camera').valid)){
                this.camEditData.emit(true);
            }
        }
    }

    // Chama a deleção da câmera
    deleteCam(camera: Camera) {
        if (this.isRTMP) {
            const dataModal = {
                tipoModal: 'delete_rtmp',
                modalWidth: '60vw'
            };
            this.sharedService.returnDataToModal(dataModal);
        } else {
            this.validationResponse.validationResponseHandler(200, this.pageName, 'confirm-delete-camera', 'cameras.delete_camera');
        }
    }

    openManege() {
        const dataModal = {
            data: {camera: this.camera},
            tipoModal: 'gerenciar_cameras',
            modalHeight: '60vh',
            modalWidth: '60vw'
        };
        this.sharedService.returnDataToModal(dataModal);
    }

}

