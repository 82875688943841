import { Component, Input, OnInit, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';

interface Simple {
    id: number | string;
    text: string;
}

interface MultiWeight {
    id: number | string;
    title: string;
    description: string;
}

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})

export class FilterComponent implements OnInit {

    //Lista de opções
    @Input() list: Simple[] | string[] | number[] | MultiWeight[];
    //Indica se há seleção de todos, juntamente com o texto que será usado
    @Input() selectAll: boolean;
    //Indica se todos estão selecionados
    @Input() hasSelectedAll: boolean;
    //Opções pre-selecionadas, funciona apenas no caso de não ser multi-select
    @Input() selected: number | string;
    //Opções pre-selecionadas, funciona apenas para multi-select
    @Input() selectedList: number[] | string[];
    //Tipo de listagem
    @Input() type: 'simple' | 'multiselect' | 'multiweight';
    //Lista simples com estilo multiselect
    @Input() simpleMulti: boolean;
    //(Funciona apenas com permissões), indica a caixa do select apresentará quantas opções foram selecionadas
    @Input() feedback: boolean;
    //Texto placeholder do select
    @Input() placeholder: string;
    //Indica se deve ser mostrado qual opção foi selecionada
    @Input() showSelected: boolean;
    //Esconde os styles de hover
    @Input() hideHover: boolean;
    //Esconde os styles de quando o select está ativo
    @Input() hideActiveFill: boolean;
    //Indica se há um icone de prefixo
    @Input() hasIconPrefix: boolean;
    //Caso haja o icone, indica qual icone será utilizado
    @Input() iconType: string;
    //(Funciona apenas com type = simple) Indica se será permitido remover uma seleção, deixando o select vazio novamente
    @Input() hasToggle: boolean;
    //Indica se haverá botões de submit/cancel
    @Input() hasSubmit: boolean;
    //Indica se o filtro está bloqueado
    @Input() blocked: boolean;
    //Indica se o filtro está no modal de analítico
    @Input() analytic: boolean;
    //Indica alterações no estilo
    @Input() styles: string[];
    //Indica se o filtro precisa de pesquisa
    @Input() search: boolean;
    //Indica o posicionamento do filtro
    @Input() position: 'bottom' | 'left' | 'right' | 'top';
    //Indica se está no dashboard
    @Input() dashboard: boolean;
    //Indica se tem mais itens para carregar
    @Input() loading: boolean;
    //Indica se está no formulário de adição de câmera integrada
    @Input() modelCam: boolean;
    //Indica se está no formulário de adição de câmera integrada no modal
    @Input() modelCamData: boolean;
    //Indica se o feriado é nacional
    @Input() national: boolean;
    //Indica se está no modal de calendários
    @Input() calendar: boolean;
    //Callback para scroll
    @Output() scrollItems = new EventEmitter<boolean>();
    //Callback para quando a listagem muda
    @Output() filterStatusChange = new EventEmitter<number[]>();
    //Callback para quando há um submit/cancel
    @Output() submitChanges = new EventEmitter<number[]>();

    processedList: Simple[];
    firstList: Simple[];
    activeList: any;
    selectStatus: boolean;
    selectedText: string;
    selectedDescription: string;
    placeholderText: string;
    searchFilter: string;
    placeholderTextDashboard: string;
    hasSelectAll: boolean;

    constructor(private element: ElementRef) {}

    ngOnInit(): void {
        this.activeList = [];
        this.selectStatus = false;
        this.placeholderText = this.placeholder;
        this.placeholderTextDashboard = this.placeholder;
        this.searchFilter = '';

        //NORMALIZAR ENTRADA DE OPÇÕES
        if (!this.isMultiWeight(this.list)) {
            if (!this.isSimple(this.list)) {
                this.processedList = new Array();
                this.list.forEach(element => {
                    this.processedList.push({
                        id: element,
                        text: element
                    });
                });
            } else {
                this.processedList = this.list as Simple[];
            }
        }
        this.firstList = this.processedList;
        this.fillData();
        if (!this.hasSelectedAll && this.calendar) {
            this.activeList = this.selectedList ? this.selectedList.map(element => element.id) : this.activeList;
        }
    }

    //SELECIONA OPÇÕES MARCADAS POR DEFAULT
    fillData() {
        switch (this.type) {
            case 'multiselect':
                if (this.selectedList) {
                    if (this.selectedList.length > 0) {
                        this.selectedList.forEach(element => {
                            this.change(element);
                        });
                        if ((this.national == true && this.hasSelectedAll) || this.hasSelectedAll) {
                            this.selectedText = 'Todas as câmeras';
                            this.hasSelectAll = true;
                            this.markAll();
                        }
                    } else if (this.feedback) {
                        this.placeholderText = this.placeholder;
                        this.placeholderTextDashboard = this.placeholder;
                        this.selectedText = this.dashboard && (!this.national && !this.selectAll) ? this.placeholderTextDashboard : '';
                    }
                }
                break;
            case 'multiweight':
            case 'simple':
            default:
                if (this.selected) {
                    this.toggleSelect();
                    this.change(this.selected);
                } else if (this.feedback) {
                    this.placeholderText = this.placeholder;
                    this.placeholderTextDashboard = this.placeholder;
                }
                break;
        }
    }

    //CHECA SE O TIPO DA ENTRADA É "SIMPLE"
    isSimple(element: any) {
        return element ? typeof element[0] !== 'string' : false;
    }

    //CHECA SE O TIPO DA ENTRADA É "MULTIWEIGHT"
    isMultiWeight(element: any) {
        return element ? 'description' in element : false;
    }

    //SELECIONA ITEM
    change(id: any) {
        switch (this.type) {
            case 'multiselect':
                const index = this.activeList.findIndex(element => element == id);
                if (index >= 0) {
                    this.activeList.splice(index, 1);
                } else {
                    this.activeList.push(id);
                }
                break;
            case 'simple':
                const indexSimple = this.activeList.findIndex(element => element == id);
                if (this.activeList.findIndex(element => element == id) >= 0 && this.hasToggle) {
                    this.activeList = [];
                    this.selectedText = null;
                    this.toggleSelect();
                } else {
                    this.activeList = [id];
                    this.selectedText = this.modelCam ? this.activeList[0].text :
                        this.processedList.find(element => element.id == this.activeList[0]) ? this.processedList.find(element => element.id == this.activeList[0]).text : null;
                    this.toggleSelect();
                    if (indexSimple >= 0) {
                        this.activeList.splice(indexSimple, 1);
                        this.selectedText = null;
                    }
                }
                this.searchFilter = '';
                break;
            case 'multiweight':
                this.activeList = [id];
                this.list.forEach(element => {
                    if (element.id === id) {
                        this.selectedText = element.title;
                        this.selectedDescription = element.description;
                    }
                });
                this.toggleSelect();
                break;
        }

        if (this.feedback) {
            if (this.activeList.length > 0) {
                this.placeholderText = this.activeList.length.toString() + (this.activeList.length > 1 ? ' permissões' : ' permissão');
                this.placeholderTextDashboard =  this.displayName((this.processedList.find(element => element.id == this.activeList[0]) ?
                    this.processedList.find(element => element.id == this.activeList[0]).text : this.placeholder)) +
                                    (this.activeList.length > 1 ? ' (+' + (this.activeList.length.toString() - 1) + ')' : '' );
                this.selectedText = this.dashboard ? this.placeholderTextDashboard : '';
            } else {
                this.placeholderText = this.placeholder;
                this.placeholderTextDashboard = this.placeholder;
                this.selectedText = null;
            }
        }

        this.filterStatusChange.emit(this.modelCam ? this.activeList[0] : this.activeList);
    }

    //ABRE OU FECHA O FILTRO
    toggleSelect() {
        if (!this.blocked) {
            this.selectStatus = !this.selectStatus;
        }
    }

    cancel() {
        this.activeList = [];
        this.fillData();
        this.selectStatus = false;
    }

    submit() {
        this.submitChanges.emit(this.activeList);
        this.selectStatus = false;
    }

    @HostListener('document:click', ['$event'])
    clickOutside(event: Event) {
        if (!this.element.nativeElement.contains(event.target) && this.selectStatus) {
            if (this.hasSubmit) {
                this.cancel();
            } else {
                this.selectStatus = false;
            }
        }
    }

    clearActiveList() {
        this.activeList = [];
        this.selectStatus = false;
        document.querySelector('.parent-text').innerHTML = this.placeholder;
        this.placeholderText = this.placeholder;
    }

    remove(id) {
        const index = this.activeList.findIndex(element => element === id);
        if (index >= 0) {
            this.activeList.splice(index, 1);
        }
        this.filterStatusChange.emit(this.activeList);
    }

    markAll() {
        this.activeList = this.processedList.map(element => element.id);
        this.filterStatusChange.emit(this.activeList);
    }

    unmarkAll() {
        this.activeList = [];
        this.filterStatusChange.emit(this.activeList);
    }

    allCameras() {
        this.hasSelectAll = !this.hasSelectAll;
        if (this.hasSelectAll) {
            this.selectedText = 'Todas as câmeras';
            this.markAll();
        }
        else {
            this.selectedText = null;
            this.placeholderTextDashboard = 'Selecionar câmera(s)';
            this.unmarkAll();
        }
    }

    filterSearch(event: any) {
        const value = event.target.value.toLowerCase();
        this.processedList = this.firstList.filter(x => x.text.toLowerCase().includes(value));
    }

    onScroll() {
        if (this.loading !== undefined) {
            this.scrollItems.emit(!this.loading);
        }
    }

    displayName(name: string): any {
        if (name != null) {
            let displayName = name;
            if (displayName.length > 19) {
                const formatString = displayName.substr(0, 19);
                const stringFormated = formatString + '...';
                displayName = stringFormated;
            }
            return displayName;
        }
        else {
            return '';
        }
    }

}
