<div class="main-content">
  <div class="header">
    <a (click)="viewPartnerList()">Lista de Parceiros</a>
    <i class="material-icons">arrow_forward_ios</i>
    <span>{{client_name}}</span>
  </div>

  <div class="tabs">
    <div class="tabs-header">
      <button class="btn btn-tab" [class.active]="activeTab === 'info'" (click)="changeTab($event)" id="info">
        Dados do parceiro
      </button>
      <button class="btn btn-tab" [class.active]="activeTab === 'kits'" (click)="changeTab($event)" id="kits">
        Kits
      </button>
    </div>
    <div class="tabs-content">
      <app-client-info *ngIf="activeTab === 'info'"></app-client-info>
      <app-user-kit-list *ngIf="activeTab === 'kits'"></app-user-kit-list>
    </div>
  </div>
</div>