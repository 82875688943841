<div class="airing">
    <div class="infinite-scroll-container" infinite-scroll [scrollWindow]="false" [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [infiniteScrollDisabled]="finalPage$ | async">
        <div class="first-row">
            <span class="card-title">Histórico de conexão</span>
            <input (dateInput)="onDateInput($event)"
                [formControl]="dateFilter" matInput [matDatepicker]="picker"
                [matDatepickerFilter]="onDateInputFilter" class="calendar-color"
                style="visibility: hidden;flex: 1 1 100%;box-sizing: border-box;max-width: 20%;margin-left: 35%;">
                <mat-datepicker-toggle fxFlex matSuffix [for]="picker" class="calendar-color"></mat-datepicker-toggle>
                <mat-datepicker fxFlex #picker></mat-datepicker>
        </div>
        <div>
            <div class="go-back" (click)="goBack()" fxFlex="30" *ngIf="filtering">
                <span class="arrow-back">&#x3c;</span>
                <span class="text-back">Voltar</span>
            </div>
        </div>
        <mat-accordion multi>
            <mat-expansion-panel [expanded]="true" *ngFor="let panel of history$ | async">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <ng-container *ngIf="equalDate(panel)">
                            {{panel.startDate | date: 'mediumDate' : undefined : 'pt-BR'}}
                        </ng-container>
                        <ng-container *ngIf="!equalDate(panel)">
                            {{panel.startDate | date: 'mediumDate' : undefined : 'pt-BR'}} a
                            {{panel.endDate | date: 'mediumDate' : undefined : 'pt-BR'}}
                        </ng-container>
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div *ngFor="let event of panel.events; let i = index" class="flex flex-col"
                    [ngClass]="hasGap(panel.events, i)" class="column-list">
                    <div *ngIf="event.event_end" class="flex">
                        <i class="flex w-1/12 material-icons green">stop_circle</i>
                        <span class="flex w-2/6 connection-text">Retorno de conexão</span>
                        <span class="flex w-2/6 connection-text">{{event.event_end | date: 'HH:mm:ss - dd/MM/yyyy'}}</span>
                        <span class="flex w-2/6 connection-text">{{formatDownTime(event.down_time)}} sem gravar</span>
                    </div>
                    <div class="event-line"></div>
                    <div class="flex">
                        <i class="flex w-1/12 material-icons red">error</i>
                        <span class="flex w-2/6 lost-connection-text">Conexão perdida</span>
                        <span class="flex w-4/6 lost-connection-text">{{event.event_begin | date: 'HH:mm:ss - dd/MM/yyyy'}}</span>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div *ngIf="loading$ | async" class="row spinner_row">
            <span nz-icon class="center" nzType="loading" nzTheme="outline" style="font-size: 40px"></span>
        </div>
    </div>
</div>

<ng-template #popover>
    <div class="flex flex-col gap-5 popover-container">
        <div class="flex">
            <p class="title">Escala de Timeout WeCloud</p>
        </div>
        <div class="flex">
            <p class="downtime-data big">{{getHours(healthStatus.downtime)}} horas</p>
            <p class="description">perdidas nos últimos 7 dias</p>
        </div>
        <div class="flex flex-row gap-6">
            <div class="flex w-[calc(33.333333%-1.5rem)]" *ngFor="let status of healthStatus.status">
                <p class="status-health mini" [ngClass]="getTextClass(status.alias)">{{status.alias}}</p>
                <hr class="big-line" [ngClass]="getTextClass(status.alias)">
                <p class="subtitle">&#37; {{status.range[0]}}-{{status.range[1]}}</p>
            </div>
        </div>
    </div>
</ng-template>
