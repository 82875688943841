<div class="flex justify-content-between title-header-kits">
  <span class="title-modal">Kits atribuídos</span>
  <button class="btn btn-primary enter-button" (click)="addKitToUser()"
          *ngIf="!isAssociate || adminAssociate" [disabled]="plans.length == 0">
    <i class="material-icons icon-add">add</i> ADICIONAR KIT
  </button>
</div>

<div *ngIf="loading" class="loading-kits">
  <span nz-icon class="center" nzType="loading" nzTheme="outline" style="font-size:30px"></span>
</div>

<div class="background" *ngIf="!loading && inventories">
  <div class="kit" *ngFor="let kit of filterOwnedKits(inventories.kits); let id = index">
    <div class="kit-info">
      <ng-container *ngFor="let plan of plans">
        <ng-container *ngIf="plan.id == kit.id_plan">
          <span class="plan"><strong>CÂMERA {{plan.resolution.alias}}</strong> {{plan.days_storage}} {{plan.days_storage == 1 ? ' dia' : ' dias'}}</span>
        </ng-container>
      </ng-container>
      <span class="description">{{kit.cameras_amount}} {{kit.cameras_amount > 1 ? ' câmeras' : ' câmera'}} <br> {{kit.analytics_amount}} {{kit.analytics_amount > 1 ? ' detecções inteligentes' : ' detecção inteligente'}}</span>
    </div>
    <div class="kit-request">
      <span *ngIf="(inventories.inventory.ci[kit.id_plan].available === 0) && (!isAssociate || adminAssociate)"
            [class.disabled]="inventories.inventory.ci[kit.id_plan].available === 0"
            nz-tooltip nzTooltipOverlayClassName="camera_playlist_processing rtsp dark-blue-tooltip"
            nzTooltipTitle="Todas as câmeras e detecções estão em uso"
            (click)="openDeleteModal(kit)" class="delete-kit">
            <i nz-icon nzType="delete" nzTheme="outline"></i>Excluir Kit
      </span>
      <span *ngIf="(inventories.inventory.ci[kit.id_plan].available !== 0) && (!isAssociate || adminAssociate)"
            (click)="openDeleteModal(kit)" class="delete-kit"><i nz-icon nzType="delete" nzTheme="outline"></i>Excluir Kit
</span>
    </div>
  </div>
</div>

<div class="no-kits" *ngIf="inventories && filterOwnedKits(inventories.kits).length === 0 && !loading">
  <span class="title">Parceiro sem kits</span>
</div>

<app-modal></app-modal>
