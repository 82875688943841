import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GroupAnalytics, TypeAnalytic, TypeClass, AnalyticsByCamera, ScheduleAnalytic } from '../models';
import { environment } from 'environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { getToken } from 'app/Shared/Helpers/getToken';
import { map } from 'rxjs/operators';
import { showAlert } from 'app/Shared/Helpers/showAlert';
import { Feedback, OperationStatus } from 'app/Shared/models';

@Injectable({
    providedIn: 'root'
})
export class AnalyticService {
    private backend_url = environment.apiUrl;

    constructor(private http: HttpClient) { }
    //Serviço que retorna uma lista agrupada de analíticos
    getGroupAnalytics(): Observable<GroupAnalytics[]> {
        const url = `${this.backend_url}/api/resources/analytic/allanalyticsbygroup`;
        const headers = {
            headers: new HttpHeaders({
                'token': getToken()
            })
        };
        return this.http.get(url, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    showAlert(`Código ${event['statusCode']} :${event['results'][0].message}`, 'danger');
                    return [];
                }
                else {
                    const groupAnalytics: GroupAnalytics[] = Array.isArray(event['results'][0].data) ? event['results'][0].data : [];
                    return groupAnalytics;
                }
            })
        );
    }

    //Serviço que retorna uma lista de class pelo tipo de analítico
    getClassTypeAnalytic(idAnalyticType: number): Observable<TypeClass[]> {
        const url = `${this.backend_url}/api/resources/analytic/analyticclass`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            idAnalyticType: idAnalyticType
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    //showAlert(`Código ${event['statusCode']} :${event['results'][0].message}`, 'danger');
                    return [];
                }
                else {
                    const classAnalyticType: TypeClass[] = Array.isArray(event['results'][0].data) ? event['results'][0].data : [];
                    return classAnalyticType;
                }
            })
        );
    }

    //Serviço que retorna os analíticos associado a uma determinada câmera
    getAnalyticsCam(camera_id: number): Observable<AnalyticsByCamera[]> {
        const url = `${this.backend_url}/api/resources/analytic/allanalyticscamera`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            idCamera: camera_id
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    showAlert(`Código ${event['statusCode']} :${event['results'][0].message}`, 'danger');
                    return [];
                }
                else {
                    const analyticsCam: AnalyticsByCamera[] = Array.isArray(event['results'][0].data) ? event['results'][0].data : [];
                    analyticsCam[0].analytics = analyticsCam[0].analytics === null ? [] : analyticsCam[0].analytics;
                    return analyticsCam;
                }
            })
        );
    }

    //Serviço que cadastra um analítico a uma câmera
    postAnalyticCam(analyticByCamera: AnalyticsByCamera, child_sub: string): Observable<Feedback> {
        const sub = localStorage.getItem('sub');
        const data = {
            sub: sub,
            id_group: analyticByCamera.analytics[0].id_group,
            id_camera: analyticByCamera.idCamera,
            id_type_analytic: analyticByCamera.analytics[0].id_analytic_type,
            alias: analyticByCamera.analytics[0].alias,
            border_color: analyticByCamera.analytics[0].border_color,
            smaller_object: analyticByCamera.analytics[0].smaller_object,
            larger_object: analyticByCamera.analytics[0].larger_object,
            blur: analyticByCamera.analytics[0].blur,
            toogle_light: analyticByCamera.analytics[0].toogle_light,
            metadata: analyticByCamera.analytics[0].metadata,
            classifier: analyticByCamera.analytics[0].classifier,
            criticality: analyticByCamera.analytics[0].criticality,
            schedules: analyticByCamera.analytics[0].schedules,
            category_id: analyticByCamera.analytics[0].category_id,
            child_sub: child_sub,
            events: analyticByCamera.analytics[0].events ? analyticByCamera.analytics[0].events : []
        };
        const url = `${this.backend_url}/api/resources/analytic/analyticcreate`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode']) {
                    if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                        const feed: Feedback = {
                            model: 'feedback',
                            message: `Código ${event['statusCode']} :${event['results'][0].message}`,
                            status: OperationStatus.Fail
                        };
                        return feed;
                    }
                    else {
                        const feed: Feedback = {
                            model: 'feedback',
                            message: 'Analítico será configurado e estará disponível em breve',
                            status: OperationStatus.Success
                        };
                        return feed;
                    }
                }
                else {
                    const feed: Feedback = {
                        model: 'feedback',
                        message: 'Sistema temporariamente fora do ar, tente novamente mais tarde',
                        status: OperationStatus.Fail
                    };
                    return feed;
                }
            })
        );
    }

    //Serviço que cadastra um analítico a uma câmera
    putAnalyticCam(analyticByCamera: AnalyticsByCamera, child_sub: string): Observable<Feedback> {
        const sub = localStorage.getItem('sub');
        const data = {
            sub: sub,
            id_group: analyticByCamera.analytics[0].id_group,
            id_camera: analyticByCamera.idCamera,
            id_type_analytic: analyticByCamera.analytics[0].id_analytic_type,
            id_analytic: analyticByCamera.analytics[0].id_analytic,
            alias: analyticByCamera.analytics[0].alias,
            border_color: analyticByCamera.analytics[0].border_color,
            smaller_object: analyticByCamera.analytics[0].smaller_object,
            larger_object: analyticByCamera.analytics[0].larger_object,
            blur: analyticByCamera.analytics[0].blur,
            toogle_light: analyticByCamera.analytics[0].toogle_light,
            metadata: analyticByCamera.analytics[0].metadata,
            classifier: analyticByCamera.analytics[0].classifier,
            criticality: analyticByCamera.analytics[0].criticality,
            schedules: analyticByCamera.analytics[0].schedules,
            child_sub: child_sub,
            category_id: analyticByCamera.analytics[0].category_id,
            events: analyticByCamera.analytics[0].events ? analyticByCamera.analytics[0].events : []
        };
        const url = `${this.backend_url}/api/resources/analytic/analyticupdate`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        return this.http.put(url, data, headers).pipe(
            map(event => {
                if (event['statusCode']) {
                    if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                        const feed: Feedback = {
                            model: 'feedback',
                            message: `Código ${event['statusCode']} :${event['results'][0].message}`,
                            status: OperationStatus.Fail
                        };
                        return feed;
                    }
                    else {
                        const feed: Feedback = {
                            model: 'feedback',
                            message: 'Suas novas configurações foram salvas e sua detecção estará disponível em breve',
                            status: OperationStatus.Success
                        };
                        return feed;
                    }
                }
                else {
                    const feed: Feedback = {
                        model: 'feedback',
                        message: 'Sistema temporariamente fora do ar, tente novamente mais tarde',
                        status: OperationStatus.Fail
                    };
                    return feed;
                }
            })
        );
    }

    //Serviço que exclui um analítico a uma câmera
    deleteAnalyticCam(id_analytic: number, sub: string): Observable<Feedback> {
        const data = {
            id: id_analytic,
            sub: sub
        };
        const url = `${this.backend_url}/api/resources/analytic/analyticdelete`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode']) {
                    if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                        const feed: Feedback = {
                            model: 'feedback',
                            message: `Código ${event['statusCode']} :${event['results'][0].message}`,
                            status: OperationStatus.Fail
                        };
                        return feed;
                    }
                    else {
                        const feed: Feedback = {
                            model: 'feedback',
                            message: 'Analítico deletado com sucesso',
                            status: OperationStatus.Success
                        };
                        return feed;
                    }
                }
                else {
                    const feed: Feedback = {
                        model: 'feedback',
                        message: 'Não foi possível deletar o analítico',
                        status: OperationStatus.Fail
                    };
                    return feed;
                }
            })
        );
    }

    //Serviço que cadastra um analítico a uma câmera
    getAnalitycsTotalCreates(): Observable<Feedback> {
        const sub = localStorage.getItem('sub');
        const data = {
            sub: sub
        };
        const url = `${this.backend_url}/api/resources/analytic/accessanalyticstutorial`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode']) {
                    if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                        const feed: Feedback = {
                            model: 'feedback',
                            message: event['statusCode'],
                            status: OperationStatus.Fail,
                            data: null
                        };
                        return feed;
                    }
                    else {
                        const feed: Feedback = {
                            model: 'feedback',
                            message: null,
                            status: OperationStatus.Success,
                            data: event['results'][0].data
                        };
                        return feed;
                    }
                }
                else {
                    return null;
                }
            })
        );
    }

    //Serviço que retorna uma lista de class pelo tipo de analítico
    getLastAnalyticSchedule(): Observable<ScheduleAnalytic[]> {
        const userSub = localStorage.getItem('sub');
        const url = `${this.backend_url}/api/resources/analytic/getlastschedule`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: userSub
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    return [];
                }
                else {
                    const lastScheduleData: ScheduleAnalytic[] = Array.isArray(event['results'][0].data) ? event['results'][0].data : [];
                    return lastScheduleData;
                }
            })
        );
    }

    //Serviço que tenta cadastrar um analítico novamente
    retryAnalytic(id_analytic: number, sub: string, child_sub?: string): Observable<Feedback> {
        const data = {
            id_analytic: id_analytic,
            sub: sub,
            child_sub: child_sub
        };
        const url = `${this.backend_url}/api/resources/analytic/retryanalytic`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                let feedback: Feedback;
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message,
                        statusCode: event['statusCode'],
                    };
                    return feedback;
                }
                else {
                    feedback = {
                        model: 'feedback',
                        success: true,
                        status: OperationStatus.Success,
                        message: event['results'][0].message,
                        data: event['results'][0].data
                    };
                    return feedback;
                }
            })
        );
    }

}
