<div class="main-content main-content-cam">

    <div class="container-fluid">
        <div class="flex add_cam">
            <span class="style-title2" (click)="toPreviousPath()">{{previousRouter}}</span>
            <span class="material-icons style-icon-title" nz-icon nzType="right" nzTheme="outline"></span>
            <span class="style-title">Adicionar Câmera</span>
        </div>
    </div>

    <nz-steps id="stepsDiv" [nzCurrent]="currentStep">
        <nz-step class="step-container" *ngFor="let step of activeSteps; index as stepNumber" [ngStyle]="{'width': activeSteps.length > 2 ? '' : '900px'}"
        [ngClass]="{'active': stepNumber == currentStep, 'visited': stepNumber < currentStep}" [nzTitle]="step"></nz-step>
    </nz-steps>

    <div id="pressEnter" class="stepsContent">
        <!-- Protocolo -->
        <div id="first-step" *ngIf="currentStep === 0">
            <span class="step-title">Escolha o protocolo de configuração da sua câmera</span>
            <div class="options">
                <div class="protocol-card clickable" (click)="selectProtocol('rtsp')">
                    <div class="first-row">
                        <i nz-icon nzType="video-camera" nzTheme="outline"></i>
                        <span class="protocol-name">RTSP</span>
                    </div>
                </div>
                <div class="protocol-card clickable" (click)="selectProtocol('rtmp')" #rtmpCard>
                    <div class="first-row">
                        <i nz-icon nzType="wifi" nzTheme="outline"></i>
                        <span class="protocol-name">RTMP</span>
                    </div>
                    <div class="recomended">
                        <i nz-icon nzType="star" nzTheme="outline"></i>
                        <span>Recomendado</span>
                    </div>
                    <div class="tooltip_icon">
                        <i
                            nz-icon nzType="info-circle"
                            nzTheme="outline"
                            nz-popover
                            [nzPopoverContent]="rtmpTooltip"
                            nzPopoverTrigger="hover"
                            [(nzPopoverVisible)]="isRTMPPopoverVisible"
                            (nzPopoverVisibleChange)="toggleExpandedCameraList(true)"
                            nzPopoverPlacement="right"
                            [nzPopoverOrigin]="rtmpCard"
                            nzPopoverMouseLeaveDelay="0.5"
                            nzPopoverOverlayClassName="popover-container"
                        ></i>
                    </div>
                </div>
            </div>
        </div>
        <!-- Link -->
        <div id="second-step"  *ngIf="currentStep === 1">
            <!-- RTSP -->
            <form [formGroup]="camForm">
                <div class="rtsp-container" *ngIf="protocol == 'rtsp'">
                    <span class="step-title">Cole o link RTSP</span>
                    <span class="step-subtitle">Cole abaixo o link RTSP da sua câmera</span>
                    <div class="rtsp-field-container">
                        <div class="link-rtsp">
                            <div class="link-container">
                                <span class="link-name">Link RTSP:</span>
                                <input type="text" class="outline-input" formControlName="camSrc" placeholder="Cole aqui o link da sua câmera">
                                <div class="flex margin_information">
                                    <div class="col-md-10 mr-auto container_information">
                                        <p class="inform-link" id="slideout" (click)="animationHelp()">
                                            Mostrar exemplo
                                        </p>
                                        <p class="inform-link" id="slidein" (click)="animationHelp()">
                                             <b>rtsp://usuario:</b>senha<b>&#64;</b>ip:<b
                                                >porta/cam/realmonitor?channel=1&subtype=0</b
                                        >
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <span class="loading-rtsp" *ngIf="isLoadingRTSP">
                                <i nz-icon nzType="loading" style="margin-right: 10px;" nzTheme="outline"></i> Aguardando imagem da câmera
                            </span>
                            <span class="loading-rtsp" *ngIf="errorRTSP">
                                <i nz-icon nzType="close-circle" class="error-rtsp" nzTheme="fill"></i> Não conseguimos achar sua câmera, tente novamente
                            </span>
                            <span class="loading-rtsp" *ngIf="camForm.get('camSrc').hasError('required') && camForm.get('camSrc').touched">
                                <i nz-icon nzType="close-circle" class="error-rtsp" nzTheme="fill"></i>Link RTSP é obrigatório.
                            </span>
                            <span class="loading-rtsp" *ngIf="camForm.get('camSrc').hasError('pattern') && camForm.get('camSrc').touched">
                                <i nz-icon nzType="close-circle" class="error-rtsp" nzTheme="fill"></i> Formato de link inválido
                            </span>
                            <button class="btn next-button btn-primary" *ngIf="protocol == 'rtsp'" (click)="goToNextStep()" style="margin-top: 160px;"
                                    [class.disabled]="camForm.get('camSrc').hasError('required') || camForm.get('camSrc').hasError('pattern') ||
                                    (openExpandedForm && intregatedCamForm.get('user').hasError('required')) || (openExpandedForm && intregatedCamForm.get('password').hasError('required')) ||
                                    (openExpandedForm && intregatedCamForm.get('urlHttp').hasError('required')) || (openExpandedForm && camModel == undefined) || isLoadingRTSP">Próximo</button>
                        </div>
                        <form [formGroup]="intregatedCamForm">
                            <div class="link-container">
                                <div class="switch-container">
                                    <span class="link-name">Câmera com analítico integrado<i class="info-icon clickable" nz-icon nzType="question-circle"
                                        nzTheme="outline" nz-tooltip nzTooltipTitle="A câmera com analítico integrado usará as detecções inteligente da própria câmera,
                                        com isso não será possível adicionar analíticos dentro do WeCloud para essa câmera" nzTooltipOverlayClassName="camera_playlist_processing rtsp"
                                        nzTooltipPlacement="top"></i> :</span>
                                        <nz-switch [nzLoading]="modelList == undefined" [class.disabled]="modelList == undefined" (click)="toggleForm()"></nz-switch>
                                </div>
                                <div *ngIf="openExpandedForm">
                                    <div class="form-group">
                                        <span class="link-name">Link HTTP <i class="info-icon clickable" nz-icon nzType="question-circle"
                                            nzTheme="outline" nz-tooltip nzTooltipTitle="Link de comunicação com a câmera. Preencha os campos abaixo que compõe o formato do link HTTP"
                                            nzTooltipOverlayClassName="camera_playlist_processing" nzTooltipPlacement="top"></i> :</span>
                                        <input type="link" formControlName="urlHttp" class="outline-input" placeholder="Cole aqui o link">
                                    </div>
                                    <div class="form-group">
                                        <span class="link-name" style="margin-bottom: 8px;">Modelo da câmera: </span>
                                        <app-filter 
                                            [styles]="['simple-alternate-style']"
                                            [list]="modelList"
                                            type="simple"
                                            placeholder="Selecionar modelo da câmera"
                                            [simpleMulti]="true"
                                            [hideActiveFill]="true"
                                            [showSelected]="true"
                                            [feedback]="true"
                                            [modelCam]="true"
                                            (filterStatusChange)="getModel($event)">
                                        </app-filter>
                                    </div>
                                    <div class="form-group" style="display: flex;">
                                          <div class="input-value">
                                            <div class="link-name"><span>Usuário: </span></div>
                                            <input formControlName="user" class="outline-input" type="text" nz-input placeholder="Digite aqui..." onpaste="return false;"/>
                                          </div>
                                          <div class="input-value password">
                                              <span class="link-name">Senha: </span>
                                              <div class="input-field">
                                                <input formControlName="password" [type]="hide ? 'password' : 'text'" placeholder="Digite sua senha" onpaste="return false;">
                                                <i nz-icon [nzType]="hide ? 'eye-invisible' : 'eye'" nzTheme="outline" (click)="hide = !hide" class="clickable input-icon"></i>
                                              </div>
                                        </div>
                                      </div>
                                </div>
                            </div>
                            <span class="loading-rtsp" [hidden]="!openExpandedForm" *ngIf="(intregatedCamForm.get('urlHttp').hasError('required') && intregatedCamForm.get('urlHttp').touched) ||
                                (intregatedCamForm.get('user').hasError('required') && intregatedCamForm.get('user').touched) || (intregatedCamForm.get('urlHttp').hasError('password') && intregatedCamForm.get('password').touched) ||
                                (openExpandedForm && camModel == undefined)">
                                <i nz-icon nzType="close-circle" class="error-rtsp" nzTheme="fill"></i> Preencha os campos obrigatórios
                            </span>
                        </form>
                    </div>
                </div>
            <!-- RTMP -->
                <div class="rtmp-container" *ngIf="protocol == 'rtmp'">
                    <span class="step-title">Copie o link RTMP</span>
                    <span class="step-subtitle">Copie o link abaixo e conecte ao servidor da sua câmera</span>

                    <div class="flex flex-row justify-between items-start content-container">
                        <div class="link-container">
                            <span class="input-name">Link RTMP:</span>
                            <div class="row-rtmp">
                                <div class="input-container">
                                    <input type="text" class="outline-input" formControlName="camSrc" placeholder="Gerando o link para sua câmera..." readonly>
                                </div>
                                <div class="refresh-container" [ngClass]="{clickable: !isLoadingRTMP}" (click)="refreshRTMPUri()">
                                    <i nz-icon nzType="sync" nzTheme="outline" *ngIf="!isLoadingRTMP"></i>
                                    <i nz-icon nzType="sync" nzTheme="outline" nzSpin *ngIf="isLoadingRTMP"></i>
                                </div>
                            </div>
                            <div class="copy-container" [ngClass]="{'clickable': !copied}" (click)="copyUri()">
                                <i nz-icon nzType="copy" nzTheme="outline"></i>
                                <span class="copy" *ngIf="!copied">Copiar link</span>
                                <span class="copy" *ngIf="copied">Link copiado. Configure no sistema de sua câmera.</span>
                            </div>
                        </div>
                        
                        <div class="preview-container" *ngIf="!successRTMP && !errorRTMP">
                            <div class="icon-round">
                                <i nz-icon nzType="video-camera" nzTheme="outline"></i>
                            </div>
                            <div class="loading-bar">
                                <div class="loading-piece-1"></div>
                                <div class="loading-piece-2"></div>
                            </div>
                            <div class="preview-text">Aguardando imagem da câmera<br>Isso pode levar alguns minutos</div>
                        </div>

                        <div class="preview-container" *ngIf="!successRTMP && errorRTMP">
                            <img style="height: 120px; margin-bottom: 36px;" src="../../../assets/img/unconected_video.svg" alt="">
                            <div class="preview-text erro">Erro</div>
                            <div class="preview-subtext">Falha na conexão, tente novamente</div>

                            <div class="accordeon" (click)="toggleAccordeon()">
                                <i nz-icon nzType="info-circle" nzTheme="outline"></i>
                                <span>Mostrar detalhes</span>
                                <i nz-icon nzType="down" nzTheme="outline" *ngIf="!openFailDropdown"></i>
                                <i nz-icon nzType="up" nzTheme="outline" *ngIf="openFailDropdown"></i>
                            </div>

                            <div class="dropdown_accordeon" *ngIf="openFailDropdown">
                                <ul>
                                    <li>
                                        <i nz-icon nzType="close" nzTheme="outline"></i>
                                        <span>Verifique se o link copiado corresponde ao mesmo link fornecido pelo WeCloud;</span>
                                    </li>
                                    <li>
                                        <i nz-icon nzType="close" nzTheme="outline"></i>
                                        <span>Verifique se sua câmera está com as permissões de transmissão RTMP;</span>
                                    </li>
                                    <li>
                                        <i nz-icon nzType="close" nzTheme="outline"></i>
                                        <span>Verifique se sua câmera está conectada a uma rede de internet;</span>
                                    </li>
                                    <li>
                                        <i nz-icon nzType="close" nzTheme="outline"></i>
                                        <span>Verifique se sua câmera não apresenta nenhum defeito de energia.</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <img [src]="plotImage()" class="preview-container" *ngIf="successRTMP">

                    </div>
                </div>
            </form>

            <button class="btn next-button btn-primary" *ngIf="protocol == 'rtmp'" (click)="goToNextStep()">Próximo</button>
        </div>
        <!-- Confirma Imagem RTSP -->
        <div id="confirm-camera" *ngIf="protocol == 'rtsp' && currentStep === 2">
            <span class="step-title">Confira a imagem da câmera</span>
            <span class="step-subtitle">Confira se a imagem abaixo é da sua câmera</span>

            <img [src]="plotImage()" class="preview-cam">

            <button class="next-button" (click)="goToNextStep()">Próximo</button>
        </div>
        <!-- Camera Data -->
        <div id="camera-data" *ngIf="(protocol == 'rtsp' && currentStep === 3) || (protocol == 'rtmp' && currentStep === 2)">
            <div class="form-container">
                <span class="step-title">Dê um nome à sua câmera</span>
                <div class="input-container">
                    <form [formGroup]="camForm">
                        <span class="input-name">Nome da câmera:</span>
                        <input type="text" [ngClass]="{'invalid': camForm.get('camName').invalid}" class="outline-input" formControlName="camName" placeholder="Digite aqui o nome da sua câmera">
                        <mat-error *ngIf="camForm.get('camName').hasError('required') && camForm.get('camName').touched">
                            Preencha o nome da câmera para continuar
                        </mat-error>
                    </form>
                </div>
                
                <div class="address-row">
                    <span class="step-title">Cadastre o endereço da sua câmera</span>
                    <button class="outline-button" (click)="addressEmpty()" [ngClass]="{'active': noAddress}">Não Cadastrar Endereço</button>
                </div>
                <div class="input-container" [ngClass]="{'disabled': noAddress}">
                    <form [formGroup]="addressForm">
                        <div class="flex flex-row items-start input-row">
                            <div class="flex w-2/5 input-group">
                                <div class="input-name cep">CEP:</div>
                                <input type="text" [ngClass]="{'invalid': addressForm.get('cep').invalid}" mask="00.000-000" class="outline-input" 
                                    formControlName="cep" placeholder="Digite aqui o CEP da sua câmera" showMaskTyped="true" shownMaskExpression="__.___-___">
                                <mat-error *ngIf="addressForm.get('cep').hasError('required') && addressForm.get('cep').touched">
                                    Preencha o cep para continuar
                                </mat-error>
                            </div>
                            <span (click)="getCepAddress()" class="check-cep clickable">Buscar</span>
                        </div>
                        <div class="flex flex-row items-start input-row">
                            <div class="flex w-3/5 input-group rua">
                                <div class="input-name">Logradouro:</div>
                                <input type="text" [ngClass]="{'invalid': addressForm.get('rua').invalid}" class="outline-input" formControlName="rua" placeholder="Digite aqui...">
                                <mat-error *ngIf="addressForm.get('rua').hasError('required') && addressForm.get('rua').touched">
                                    Preencha um logradouro para continuar
                                </mat-error>
                            </div>
                            <div class="flex w-1/5 input-group numero">
                                <div class="input-name">Número:</div>
                                <input type="text" [ngClass]="{'invalid': addressForm.get('numero').invalid}" class="outline-input" formControlName="numero" placeholder="Digite aqui...">
                                <mat-error *ngIf="addressForm.get('numero').invalid && addressForm.get('numero').touched && !addressForm.get('noNum').value">
                                    Preencha um numero válido para continuar
                                </mat-error>
                            </div>
                            <div class="flex w-1/5 input-group no-number" [formGroup]="noNumberForm">
                                <label nz-checkbox formControlName="noNum">Sem número</label>
                            </div>
                        </div>
                        <div class="flex flex-row items-start input-row">
                            <div class="flex w-2/5 input-group bairro">
                                <div class="input-name">Bairro:</div>
                                <input type="text" [ngClass]="{'invalid': addressForm.get('bairro').invalid}" class="outline-input" formControlName="bairro" placeholder="Digite aqui...">
                                <mat-error *ngIf="addressForm.get('bairro').hasError('required') && addressForm.get('bairro').touched">
                                    Preencha um bairro para continuar
                                </mat-error>
                            </div>
                            <div class="flex w-4/12 input-group cidade">
                                <div class="input-name">Cidade:</div>
                                <input type="text" [ngClass]="{'invalid': addressForm.get('cidade').invalid}" class="outline-input" formControlName="cidade" placeholder="Digite aqui...">
                                <mat-error *ngIf="addressForm.get('cidade').hasError('required') && addressForm.get('cidade').touched">
                                    Preencha uma cidade para continuar
                                </mat-error>
                            </div>
                            <div class="flex w-3/12 input-group estado">
                                <div class="input-name">Estado:</div>
                                <nz-select formControlName="estado" nzPlaceHolder="Selecionar" [ngClass]="{'invalid': addressForm.get('estado').invalid}">
                                    <nz-option *ngFor="let state of states" [nzValue]="state" [nzLabel]="state"></nz-option>
                                </nz-select>
                                <mat-error *ngIf="addressForm.get('estado').hasError('required') && addressForm.get('estado').touched">
                                    Selecione um estado para continuar
                                </mat-error>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="submit-container">
                <button class="next-button" (click)="goToNextStep()" [class.disabled]="camForm.invalid || (addressForm.invalid && !this.noAddress)">Próximo</button>
            </div>
        </div>
        <!-- Plan -->
        <div id="plan"  *ngIf="(protocol == 'rtsp' && currentStep === 4) || (protocol == 'rtmp' && currentStep === 3)">
            <span class="step-title">Escolha o plano de gravação</span>
            <span class="step-subtitle">Todos os planos tem acesso ilimitado no aplicativo,
                e permitem resoluções de imagem até a FULL HD (1920x1080)</span>

            <div class="ant-row">
                <div class="plan-list ant-col-12" [ngStyle]="{'flex-wrap': planList?.length > 3 ? 'wrap' : ''}">
                    <div class="plan-card clickable" [ngClass]="{'plan-disabled': loading}" *ngFor="let plan of planList"
                     (click)="getIdPlan(plan.id)">
                        <div class="plan-icon">
                            <img src="./../../../assets/img/Icons/camera_svg.svg" alt="" *ngIf="plan.resolution.alias === 'SVG'">
                            <img src="./../../../assets/img/Icons/camera_svg.svg" alt="" *ngIf="plan.resolution.alias === 'VGA'">
                            <img src="./../../../assets/img/Icons/camera_svg.svg" alt="" *ngIf="plan.resolution.alias === 'D1'">
                            <img src="./../../../assets/img/Icons/camera_hd.svg" alt="" *ngIf="plan.resolution.alias === 'HD'">
                            <img src="./../../../assets/img/Icons/camera_full_hd.svg" alt="" *ngIf="plan.resolution.alias === 'FULL HD'">
                        </div>
                        <div class="plan-description">
                            <span class="plan-card-title">Câmera {{plan.resolution.alias}}</span>
                            <div class="plan-card-subtitle">{{plan.days_storage}} {{plan.days_storage == 1 ? ' dia' : ' dias'}}</div>
                        </div>
                        <div class="selected_icon" *ngIf="this.planForm.get('plan').value === plan.id">
                            <i nz-icon nzType="check" nzTheme="outline"></i>
                        </div>
                    </div>
                </div>
                <div class="ant-col-12" *ngIf="loading">
                    <div class="feedback-list">
                        <div class="feedback-resquest" *ngIf="showResolution">
                            <i nz-icon nzType="loading" nzTheme="outline" *ngIf="loadingCheckResolution"></i>
                            <i nz-icon nzType="check-circle" nzTheme="outline" *ngIf="!loadingCheckResolution"></i>
                            <span class="text">Validando resolução e FPS da câmera</span>
                        </div>
                        <div class="feedback-resquest" *ngIf="showCreateCamera">
                            <i nz-icon nzType="loading" nzTheme="outline" *ngIf="loadingCreateCamera"></i>
                            <i nz-icon nzType="check-circle" nzTheme="outline" *ngIf="!loadingCreateCamera"></i>
                            <span class="text">Preparando configuração de streaming</span>
                        </div>
                        <div class="feedback-resquest" *ngIf="showGetThumb">
                            <i nz-icon nzType="loading" nzTheme="outline" *ngIf="loadingGetThumb"></i>
                            <i nz-icon nzType="check-circle" nzTheme="outline" *ngIf="!loadingGetThumb"></i>
                            <span class="text">Estabelecendo conexão com sua câmera</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="submit-container">
                <button class="btn next-button btn-primary register-cam" (click)="goToNextStep()" [attr.disabled]="loading || this.planForm.get('plan').invalid ? '' : null">
                    Cadastrar câmera <i nz-icon nzType="loading" nzTheme="outline" *ngIf="loading"></i>
                </button>
            </div>
        </div>
    </div>
</div>

<ng-template #rtmpTooltip>
    <div class="" (mouseleave)="toggleExpandedCameraList(true)">
        <p class="tooltip-text tooltip-style">
            Para verificar se o seu dispositivo possui tecnologia RTMP, vá ao painel de configurações,
            vá ao menu "SERVIÇOS" e procure a opção "RTMP".
        </p>
        <p class="tooltip-text tooltip-style">
            Segue uma lista extensiva mas não-exclusiva de Câmeras IP com RTMP.
        </p>
    
        <span class="tooltip-expand" (click)="toggleExpandedCameraList()">
            Visualizar lista de câmeras
            <i nz-icon nzType="down" nzTheme="outline" *ngIf="!isRTMPCameraListExpanded"></i>
            <i nz-icon nzType="up" nzTheme="outline" *ngIf="isRTMPCameraListExpanded"></i>
        </span>
    
        <ul class="tooltip-style available-camera-list" *ngIf="isRTMPCameraListExpanded">
            <li>VIP 3230 B SL</li>
            <li>VIP 3230 D SL</li>
            <li>VIP.1430 B G2</li>
            <li>VIP 1430 D G2</li>
            <li>VIP 3260 Z G2</li>
            <li>VIP 5500 FIA</li>
            <li>VIP. 1230 B G2</li>
            <li>VIP 1230 D G2</li>
            <li>VIP 1130 B G2</li>
            <li>VIP 1130D G2</li>
            <li>VIP 1220 D Full Color G2</li>
            <li>VIP 1220 B Full Color G2</li>
            <li>Bullet VIP 1230W</li>
            <li>VIP 1230D W</li>
            <li>VIP 3240 D IA</li>
            <li>VIP 3240 IA</li>
            <li>VIP 5280 B IA</li>
            <li>VIP 3240 Z G3</li>
            <li>VIP 3240 DZ G3</li>
        </ul>
    </div>
</ng-template>
