<div class="background" [hidden]="!filterKitsList.length">
    <div class="kit" *ngFor="let kit of filterKitsList">
        <div class="kit-info">
            <span class="plan"><strong>CÂMERA {{getPlanProperty(kit.id_plan, 'resolution')}}</strong> {{getPlanProperty(kit.id_plan, 'days')}} dias</span>
            <span class="description">{{kit.cameras_amount}} {{kit.cameras_amount > 1 ? ' câmeras' : ' câmera'}} <br> {{kit.analytics_amount}} {{kit.analytics_amount > 1 ? ' detecções inteligentes' : ' detecção inteligente'}}</span>
        </div>
        <div class="kit-request">
            <span class="available">Quantidade disponível: <strong>{{getAvailable(kit)}}</strong></span>
            <span class="add-kit" (click)="requestKit(kit)" *ngIf="!isAssociate || adminAssociate">
                <span class="plus-icon" nz-icon nzType="plus" nzTheme="outline"></span> Solicitar Kit
            </span>
        </div>
    </div>
</div>

<div class="no-kits" [hidden]="filterKitsList.length">
    <span class="title">Lista de Kits Vazia</span>
    <div class="description">Se já solicitou kits com seu fornecedor,<br> aguarde que eles serão exibidos nessa tela.</div>
</div>