import { Store } from '@ngrx/store';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppState } from 'app/store/model';
import { ResultAlert } from 'app/Shared/models';
import { Subscription } from 'rxjs';
import { UserActions } from '../Services/actions';
import { SharedService } from 'app/Shared/Services/shared.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-user-kit-delete',
    templateUrl: './user-kit-delete.component.html',
    styleUrl: './user-kit-delete.component.scss'
})
export class UserKitDeleteComponent implements OnInit, OnDestroy {
    readonly deleteKitSub$ = this.store.select((state: AppState) => state.Users.deleteMasterKit);

    @Output() modalClose = new EventEmitter<string>();

    editForm = new UntypedFormGroup({
        cameraAmount: new UntypedFormControl('', [Validators.required]),
        analyticAmount: new UntypedFormControl('', [Validators.required]),
    });

    deleteKitSub: Subscription;
    swalDecisionSub: Subscription;

    partnerName: string;
    kitInfo: any;
    inventoryInfo: any;
    camResolution: string;
    camDays: number;
    qtdCam: number;
    qtdAnalytic: number;
    analyticAvailable: number;
    calcAnalytic: number;
    id_plan: number;
    sub: string;
    client_sub: string;
    loading: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private readonly store: Store<AppState>,
        private validationResponse: ValidationResponseHandlerModule, private service: SharedService, private router: Router) { }


    ngOnInit(): void {
        this.sub = localStorage.getItem('sub');
        this.client_sub = localStorage.getItem('clientView');
        this.loading = false;

        this.inventoryInfo = this.data.data.inventory_info;
        this.partnerName = this.inventoryInfo.inventory.partner_name;
        this.kitInfo = this.data.data.kit_info;
        this.camResolution = this.kitInfo.plan.resolution.alias;
        this.camDays = this.kitInfo.plan.days_storage;
        this.id_plan = this.kitInfo.id_plan;
        this.qtdCam = this.inventoryInfo.inventory.ci[this.id_plan].available;
        this.analyticAvailable = this.inventoryInfo.inventory.ai.available;
        this.calcAnalytic = Math.floor((this.qtdCam / this.kitInfo.cameras_amount) * this.kitInfo.analytics_amount);
        this.qtdAnalytic = Math.min(this.calcAnalytic, this.analyticAvailable);
        this.editForm.get('cameraAmount').setValue(this.qtdCam);
        this.editForm.get('analyticAmount').setValue(this.qtdAnalytic);

        this.swalDecisionSub = this.service.swalDecision.subscribe(returnSwallObject => {
            if (returnSwallObject) {
                this.validateReturnMethodCalled(returnSwallObject as ResultAlert);
            }
        });
    }

    checkLimit() {
        if (this.editForm.get('cameraAmount').value == '') {
            this.editForm.get('cameraAmount').setErrors({'required': true});
        } else {
            this.editForm.get('cameraAmount').setErrors(null);
        }

        if (this.editForm.get('analyticAmount').value == '') {
            this.editForm.get('analyticAmount').setErrors({'required': true});
        } else {
            this.editForm.get('analyticAmount').setErrors(null);
        }
    }

    ngOnDestroy() {
        this.closeModal();

        if (this.deleteKitSub) {
            this.deleteKitSub.unsubscribe();
        }
        if (this.swalDecisionSub) {
            this.swalDecisionSub.unsubscribe();
        }
        this.store.dispatch(UserActions.delete_master_kit_cleanup());
    }


    closeModal() {
        this.modalClose.emit();
        this.store.dispatch(UserActions.delete_master_kit_cleanup());
    }

    deleteKit() {
        this.validationResponse.validationResponseHandler(200, 'user-kits', 'master-confirm-delete-kit', 'user.delete_master_kit');
    }

    validateReturnMethodCalled(returnSwal: ResultAlert) {
        if (returnSwal.methodCalled === 'deleteMasterKit') {
            if (returnSwal.isConfirmed) {
                this.loading = true;
                this.store.dispatch(UserActions.delete_master_kit({
                    sub: this.sub,
                    child_sub: this.client_sub,
                    id_plan: this.id_plan,
                    cameras_amount: this.editForm.get('cameraAmount').value,
                    analytics_amount: this.editForm.get('analyticAmount').value
                }));
                this.deleteKitResult();
            }
            else if (returnSwal.isDismissed) {
            }
        }
    }

    deleteKitResult() {
        this.deleteKitSub = this.deleteKitSub$.subscribe(result => {
            if (result) {
                this.loading = false;
                if (result.status === 2) {
                    this.validationResponse.validationResponseHandler(200, 'user-kits', 'delete-kit', result.message);
                    this.closeModal();
                    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                    this.router.onSameUrlNavigation = 'reload';
                    localStorage.setItem('activeTab', 'kits');
                    this.router.navigate([this.router.url]);
                } else {
                    this.validationResponse.validationResponseHandler(400, 'user-kits', 'delete-kit-fail', result.message);
                }
                this.store.dispatch(UserActions.delete_master_kit_cleanup());
            }
        });
    }
}
