import { Feedback, OperationStatus } from 'app/Shared/models';
import { Action, createReducer, on } from '@ngrx/store';
import { AnalyticsByCamera, GroupAnalytics, TypeClass, AnalyticState, ModalState } from '../models';
import { AnalyticActions } from './actions';

const INITIAL_STATE: AnalyticState = {
    modal_open_analytics: ModalState.Closed,
    statusPutAnalytic: {
        model: 'feedback',
        message: '',
        status: OperationStatus.Unloaded
    },
    statusPostAnalytic: {
        model: 'feedback',
        message: '',
        status: OperationStatus.Unloaded
    },
    statusTotalCreatesAnalytics: {
        model: 'feedback',
        message: '',
        status: OperationStatus.Unloaded
    },
    statusDeleteAnalytic: {
        model: 'feedback',
        message: '',
        status: OperationStatus.Unloaded
    }
};

export const analyticReducer = createReducer(
    INITIAL_STATE,
    on(
        AnalyticActions.get_analytics_camera, state => state
    ),
    on(
        AnalyticActions.get_analytics_camera_return, (state, { payload }) => ({
            ...state,
            camera_analytics: payload as AnalyticsByCamera[]
        })
    ),
    on(
        AnalyticActions.get_analytics_group, state => state
    ),
    on(
        AnalyticActions.get_analytics_group_return, (state, { payload }) => ({
            ...state,
            groupAnalytics: payload as GroupAnalytics[]
        })
    ),
    on(
        AnalyticActions.get_analytics_class, state => state
    ),
    on(
        AnalyticActions.get_analytics_class_return, (state, { payload }) => ({
            ...state,
            class_type_analytic: payload as TypeClass[]
        })
    ),
    on(
        AnalyticActions.get_analitycs_total_creates, state => state
    ),
    on(
        AnalyticActions.get_analitycs_total_creates_return, (state, { payload }) => ({
            ...state,
            statusTotalCreatesAnalytics: payload as Feedback
        })
    ),
    on(
        AnalyticActions.post_analytics_camera, state => state
    ),
    on(
        AnalyticActions.post_analytics_camera_return, (state, { payload }) => ({
            ...state,
            statusPostAnalytic: payload as Feedback
        })
    ),
    on(
        AnalyticActions.post_analytics_camera_change_feedback, state => ({
            ...state,
            statusPostAnalytic: {
                model: 'feedback',
                status: OperationStatus.Unloaded,
                message: ''
            } as Feedback
        })
    ),
    on(
        AnalyticActions.put_analytics_camera, state => state
    ),
    on(
        AnalyticActions.put_analytics_camera_return, (state, { payload }) => ({
            ...state,
            statusPutAnalytic: payload as Feedback
        })
    ),
    on(
        AnalyticActions.put_analytics_camera_change_feedback, state => ({
            ...state,
            statusPutAnalytic: {
                model: 'feedback',
                status: OperationStatus.Unloaded,
                message: ''
            } as Feedback
        })
    ),
    on(
        AnalyticActions.delete_analytics_camera, state => state
    ),
    on(
        AnalyticActions.delete_analytics_camera_return, (state, { payload }) => ({
            ...state,
            statusDeleteAnalytic: payload as Feedback
        })
    ),
    on(
        AnalyticActions.delete_analytics_camera_change_feedback, state => ({
            ...state,
            statusDeleteAnalytic: {
                model: 'feedback',
                status: OperationStatus.Unloaded,
                message: ''
            } as Feedback
        })
    ),
    on(
        AnalyticActions.update_modal_state, (state, { payload }) => ({
            ...state,
            modal_open_analytics: payload as ModalState
        })
    ),
    on(
        AnalyticActions.put_modal_data, (state, { payload }) => ({
            ...state,
            modalData: payload
        })
    ),
    on(
        AnalyticActions.cleanup, state => ({
            ...state,
            modal_open_analytics: null,
            modalData: null,
            groupAnalytics: null,
            camera_analytics: null,
            statusRetryAnalytic: null,
            statusDeleteAnalytic: {
                model: 'feedback',
                message: '',
                status: OperationStatus.Unloaded
            } as Feedback,
            statusPutAnalytic: {
                model: 'feedback',
                message: '',
                status: OperationStatus.Unloaded
            } as Feedback,
            statusPostAnalytic: {
                model: 'feedback',
                message: '',
                status: OperationStatus.Unloaded
            } as Feedback,
            statusTotalCreatesAnalytics: {
                model: 'feedback',
                message: '',
                status: OperationStatus.Unloaded
            } as Feedback,
        })
    ),
    on(
        AnalyticActions.cleanup_classes_analytics, state => ({
            ...state,
            class_type_analytic: null
        })
    ),
    on(
        AnalyticActions.cleanup_camera_analytics, state => ({
            ...state,
            camera_analytics: null,
            statusDeleteAnalytic: {
                model: 'feedback',
                message: '',
                status: OperationStatus.Unloaded
            } as Feedback
        })
    ),
    on(
        AnalyticActions.cleanup_group_analytics, state => ({
            ...state,
            groupAnalytics: null
        })
    ),
    on(
        AnalyticActions.cleanup_controller_analytics, state => ({
            ...state,
            modal_open_analytics: ModalState.Closed,
            camera_view_analytics: null
        })
    ),
    on(
        AnalyticActions.retry_analytics, state => state
    ),
    on(
        AnalyticActions.retry_analytics_return, (state, { payload }) => ({
            ...state,
            statusRetryAnalytic: payload as Feedback
        })
    ),
    on(
        AnalyticActions.retry_analytics_cleanup, state => ({
            ...state,
            statusRetryAnalytic: null
        })
    ),
);
