import { Store } from '@ngrx/store';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Camera, HealthCheck, HealthHistory, HealthPanel } from '../models';
import { AnimationOptions } from 'ngx-lottie';
import { AppState } from 'app/store/model';
import { CameraActions } from '../Services/actions';
import { MatAccordion } from '@angular/material/expansion';
import { UntypedFormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { formatDate } from '@angular/common';


@Component({
    selector: 'app-camera-health',
    templateUrl: './camera-health.component.html',
    styleUrls: ['./camera-health.component.scss']
})
export class CameraHealthComponent implements OnInit, OnDestroy {
    readonly health$ = this.store.select((state: AppState) => state.camera.connectionHealth);
    readonly history$ = this.store.select((state: AppState) => state.camera.connectionHistory);
    readonly finalPage$ = this.store.select((state: AppState) => state.camera.finalPage);
    readonly loading$ = this.store.select((state: AppState) => state.camera.historyLoading);
    @Input() camera: Camera;
    @ViewChild(MatAccordion) accordion: MatAccordion;
    dateFilter = new UntypedFormControl('');
    healthStatus: HealthCheck;
    sub: Subscription;
    options: AnimationOptions = {
        path: '',
    };
    styles: Partial<CSSStyleDeclaration> = {
        width: '10rem',
        height: '10rem',
        marginLeft: 'auto',
        marginRight: 'auto'
    };
    page = 1;
    filtering = false;
    loading = false;

    constructor(private readonly store: Store<AppState>) { }

    ngOnInit(): void {
        this.store.dispatch(CameraActions.get_healthcheck({
            camera_id: this.camera.id,
            user_sub: localStorage.getItem('sub')
        }));
        this.loading = true;
        this.getHealth();
        this.store.dispatch(CameraActions.get_health_history({
            camera_id: this.camera.id,
            user_sub: localStorage.getItem('sub'),
            page: this.page
        }));
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        this.store.dispatch(CameraActions.clear_history());
        this.store.dispatch(CameraActions.get_healthcheck_cleanup());
    }

    getAnimationPath(): string {
        if (this.healthStatus) {
            const p = document.getElementById('status-text');
            if (p) {
                switch (this.healthStatus.current_status.toLowerCase()) {
                    case 'ruim':
                        p.classList.add('bad');
                        return '/assets/animations/weather-thunder.json';
                    case 'instável':
                        p.classList.add('unstable');
                        return '/assets/animations/weather-mist.json';
                    case 'estável':
                        p.classList.add('stable');
                        return '/assets/animations/weather-partly-cloudy.json';
                }
            }
        }
        else {
            return null;
        }
    }

    getHealth() {
        this.sub = this.health$.subscribe(result => {
            if (result) {
                this.loading = false;
                this.healthStatus = result;
                setTimeout(() => {
                    this.options = {
                        ...this.options,
                        path: this.getAnimationPath()
                    };
                }, 1500);
            }
        });
    }

    //Pega o tempo em segundos e retorna uma string no formato "HH:MM:ss"
    formatDownTime(time: number): string {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor(time % 3600 / 60);
        const seconds = Math.floor(time % 3600 % 60);

        return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);
    }

    getHours(time: number): string {
        const hours = Math.floor(time / 3600);
        return hours.toString();
    }

    getTextClass(status?: string) {
        let statusText;
        if (!status && this.healthStatus) {
            statusText = this.healthStatus.current_status;
        }
        else if (status) {
            statusText = status;
        }
        if (statusText) {
            switch (statusText.toLowerCase()) {
                case 'ruim':
                    return 'bad';
                case 'instável':
                    return 'unstable';
                case 'estável':
                    return 'stable';
            }
        }
    }

    onScroll() {
        if (this.filtering) {
            const date = this.dateFilter.value;
            const beginDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
            const endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 59);
            this.store.dispatch(CameraActions.get_health_history_filter({
                camera_id: this.camera.id,
                user_sub: localStorage.getItem('sub'),
                page: ++this.page,
                timeBegin: formatDate(beginDate, 'yyyy-MM-dd HH:mm:ss', 'pt'),
                timeEnd: formatDate(endDate, 'yyyy-MM-dd HH:mm:ss', 'pt')
            }));
        }
        else {
            this.store.dispatch(CameraActions.get_health_history({
                camera_id: this.camera.id,
                user_sub: localStorage.getItem('sub'),
                page: ++this.page
            }));
        }
    }

    itemList(panel: HealthPanel): string {
        const len = panel.events.length;
        if (len > 1) {
            return `${len} itens`;
        }
        else {
            return `${len} item`;
        }
    }

    equalDate(panel: HealthPanel): boolean {
        if (panel.endDate === null) {
            return true;
        }
        else {
            const timeDiff = panel.endDate.valueOf() - panel.startDate.valueOf();
            return Math.floor(timeDiff / 1000 / 3600) < 24;
        }
    }

    hasGap(events: HealthHistory[], i: number): string {
        return i + 1 < events.length ? 'bottom-gap' : '';
    }

    onDateInputFilter = (d: Date | null): boolean => {
        const date = (d || new Date());
        const today = new Date();
        return today >= date;
    };

    onDateInput(event: MatDatepickerInputEvent<Date>) {
        this.store.dispatch(CameraActions.clear_history());
        this.page = 0;
        this.filtering = true;
        const date = event.value;
        const today = new Date();
        if (today >= new Date(date.getFullYear(), date.getMonth(), date.getDate())) {
            this.dateFilter.setValue(event.value);
            const beginDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
            const endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 59);
            this.store.dispatch(CameraActions.get_health_history_filter({
                camera_id: this.camera.id,
                user_sub: localStorage.getItem('sub'),
                page: ++this.page,
                timeBegin: formatDate(beginDate, 'yyyy-MM-dd HH:mm:ss', 'pt'),
                timeEnd: formatDate(endDate, 'yyyy-MM-dd HH:mm:ss', 'pt')
            }));
        }
    }

    goBack() {
        this.store.dispatch(CameraActions.clear_history());
        this.page = 0;
        this.filtering = false;
        this.dateFilter.setValue('');
        this.store.dispatch(CameraActions.get_health_history({
            camera_id: this.camera.id,
            user_sub: localStorage.getItem('sub'),
            page: ++this.page
        }));
    }

}
