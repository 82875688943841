<div class="container">
    <div class="container-title">
        <span style="width: 97%;">{{camName}}</span>
        <span class="close-modal" (click)="closeModal()">
            <i nz-icon nzType="close" nzTheme="outline"></i>
        </span>
    </div>
    <div class="player">
        <div class="freeze-overlay" *ngIf="freezeStreaming">
            <span class="overlay-question">Você ainda está assistindo?</span>
            <span class="overlay-button" (click)="this.loadPlayer(camera)">
                <span nz-icon nzType="caret-right" style="margin-right: 4px;" nzTheme="outline"></span>Clique para continuar
            </span>
        </div>
        <div class="camera-status" [ngClass]="{
            'camera_status_true': camera.status === true,
            'camera_status_false': camera.status === false}">
            <i *ngIf="!camera.status" nz-icon nzType="loading" nzTheme="outline" style="color: #FFFFFF;"></i>
        </div>
        <video #video autoplay="true" class="videoplayer" style="width: 100%; height: 100%; border-radius: 7px;"></video>
    </div>
</div>