<div class="modal-container">
    <div class="flex header">
        <div class="title-button">
            <i (click)="closeModal()" class="back-button" nz-icon nzType="arrow-left" nzTheme="outline"></i>
            <span class="title-modal">Convites e permissões de acessos</span>
        </div>
        <div (click)="closeModal()" class="close-modal">
            <span nz-icon nzType="close" nzTheme="outline"></span>
        </div>
    </div>
    <div class="flex">
        <span class="email-title"> {{guestEmail}} </span>
    </div>
    <div *ngIf="tableGuestsCam.length > 0">
        <nz-table #guestsTable [nzData]="tableGuestsCam" class="table style-table antd-table" [nzFrontPagination]="false">
            <thead>
              <tr>
                <th>Câmera</th>
                <th>Autorizar acesso a</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of guestsTable.data">
                <!-- Câmera -->
                <td>
                    <div class="form-group permissions_container">
                        <app-filter
                        [styles]="['simple-alternate-style']"
                        type="simple"
                        [list]="cameraList"
                        placeholder="Selecionar câmera"
                        [search]="true"
                        [simpleMulti]="true"
                        [hideActiveFill]="true"
                        [hasIconPrefix]="true"
                        iconType="video-camera"
                        [showSelected]="true"
                        [selected]="parseGuestCam(data.id)"
                        (filterStatusChange)="changeCam($event, data.id)"></app-filter>
                    </div>
                </td>
                <!-- Permissões -->
                <td [formGroup]="permissionForm">
                    <div class="form-group permissions_container">
                        <app-filter
                        [styles]="['multiselect-alternate-style']"
                        [showSelected]="true"
                        type="multiselect"
                        [list]="permissionLabels"
                        placeholder="Selecione aqui..."
                        [feedback]="true"
                        [hideActiveFill]="true"
                        (filterStatusChange)="changePerm($event, data.id)"
                        [selectedList]="parseGuestPermissions(data.id)"></app-filter>
                    </div>
                </td>
                 <!-- Botão de excluir -->
                 <td>
                    <div [hidden]="data.id == 0" class="pop-guest">
                        <i nz-icon nzType="close" nzTheme="outline" (click)="popGuest(data.id)" class="pop-icon"></i>
                    </div>
                </td>
              </tr>
            </tbody>
          </nz-table>
    </div>
    <span class="more-cameras-container">
        <span (click)="newGuests()" class="more-cameras-button"><i class="add-cam-icon" nz-icon nzType="plus" nzTheme="outline"></i> Convidar para outra câmera</span>
    </span>

    <div class="row footer">
        <button class="btn btn-md btn-primary tamButton" (click)="inviteUser()" [disabled]="loadingSubmit">
            Convidar
            <i nz-icon nzType="loading" nzTheme="outline" class="posfix" *ngIf="loadingSubmit"></i>
          </button>
    </div>

</div>