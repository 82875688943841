import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Camera } from 'app/cameras/models';
import { AppState } from 'app/store/model';
import { Subscription, filter } from 'rxjs';
import { UserActions } from '../Services/actions';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { Holidays} from '../models';
import { formatDate } from '@angular/common';


@Component({
    selector: 'app-user-calendar-create',
    templateUrl: './user-calendar-create.component.html',
    styleUrl: './user-calendar-create.component.scss'
})
export class UserCalendarCreateComponent implements OnInit, OnDestroy {
    @Output() modalClose = new EventEmitter<string>();

    readonly cameras$ = this.store.select((state: AppState) => state.camera.cameras);
    readonly nationalHolidays$ = this.store.select((state: AppState) => state.Users.nationalHolidays);
    readonly createCalendar$ = this.store.select((state: AppState) => state.Users.holidays);
    readonly updateCalendar$ = this.store.select((state: AppState) => state.Users.holidays);

    nationalHolidaysSub: Subscription;
    subSelectWholeDay: Subscription;
    createCalendarSub: Subscription;
    updateCalendarSub: Subscription;
    calendarSub: Subscription;

    clientName: string;
    user_sub: string;
    child_sub: string;
    holidays: Holidays[];
    allHolidaysData: Holidays[];

    dateRange = [];
    rangeForm = new UntypedFormGroup({
        start: new UntypedFormControl(''),
        end: new UntypedFormControl('')
    });
    selectWholeDay = new UntypedFormControl('', [Validators.required]);

    elementsHoliday: Holidays[] = [
        {alias: '', start_time: '', end_time: '', beginTime: '', endTime: '', foreignhash: '', id_cameras: [], national_holiday: false, to_all_cameras_user: undefined, active: true, id: 1},
    ];

    newHolidaysElement: Holidays = {
        alias: '',
        start_time: '',
        end_time: '',
        beginTime: '',
        endTime: '',
        foreignhash: '',
        id_cameras: [],
        national_holiday: false,
        to_all_cameras_user: undefined,
        active: true,
        id: 2
    };

    holidayName: any;
    beginTime: string;
    endTime: string;
    cameras: Camera[];

    cameraList: {
        id: number | string;
        text: string;
    }[];

    currentTime = new Date();
    showTime: boolean[] = new Array(50).map(x => false);
    uniqueTime = false;
    loadingHolidayList: boolean;
    loadingCameras: boolean;
    restoreHolidays: boolean;
    cleanHolidays: boolean;
    creatingCalendar: boolean;
    updatingCalendar: boolean;
    errorPass: boolean;
    allDayChecked: boolean;
    holidayCams: any;
    modalEdit: boolean;
    modalCreate: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private readonly store: Store<AppState>, private validationResponse: ValidationResponseHandlerModule) { }

    ngOnInit(): void {
        this.loadingHolidayList = true;
        this.loadingCameras = true;
        this.clientName = this.data.data.client_name;
        this.cameras = this.data.data.cameras;
        this.user_sub = localStorage.getItem('sub');
        this.child_sub = localStorage.getItem('clientView');
        this.modalEdit = localStorage.getItem('tipoModal') == 'edit';
        this.modalCreate = localStorage.getItem('tipoModal') == 'create';
        this.errorPass = true;
        this.getAllHolidays();
        this.getAllHolidaysResult();
        this.selectWholeDays();
        this.getCameras();
        this.cleanHolidays = true;
        if (this.allHolidaysData == undefined || this.allHolidaysData.length == 0) {
            this.getNationalHolidays();
            this.getNationalHolidaysResult(false);
            this.restoreHolidays = false;
        }
    }

    closeModal() {
        this.modalClose.emit();
        this.store.dispatch(UserActions.cleanup());
        this.getAllHolidays();
    }

    ngOnDestroy() {
        if (this.nationalHolidaysSub) {
            this.nationalHolidaysSub.unsubscribe();
        }
        if (this.subSelectWholeDay) {
            this.subSelectWholeDay.unsubscribe();
        }
        if (this.createCalendarSub) {
            this.createCalendarSub.unsubscribe();
        }
        if (this.calendarSub) {
            this.calendarSub.unsubscribe();
        }
        if (this.updateCalendarSub) {
            this.updateCalendarSub.unsubscribe();
        }

        this.elementsHoliday = [{alias: '', start_time: '', end_time: '', beginTime: '', endTime: '', foreignhash: '', id_cameras: [], national_holiday: false, to_all_cameras_user: undefined, active: true, id: 1}];
        this.closeModal();
        this.store.dispatch(UserActions.cleanup());
        this.store.dispatch(UserActions.get_calendar_list_clear());
        this.store.dispatch(UserActions.get_national_holidays_clear());
    }

    getCameras() {
        if (this.cameras.length > 0) {
            this.loadingCameras = false;
            this.cameraList = this.cameras.map(element => {
                return {
                    id: element.id,
                    text: element.alias
                };
            });
        }
    }


    setTime(newItem: [string, string], id?: number) {
        const list = this.allHolidaysData.length > 0 ? this.allHolidaysData : this.elementsHoliday;
        let [newStartTime, newEndTime] = [`${newItem[0]}:00`, `${newItem[1]}:59`];
        const updateTimes = (holiday) => {
            if (newItem[0] === '' && newItem[1] === '') {
                [newStartTime, newEndTime] = [null, null];
                this.beginTime = null;
                this.endTime = null;
                holiday.beginTime = null;
                holiday.endTime = null;
            } else {
                holiday.beginTime = newStartTime;
                holiday.endTime = newEndTime;
            }
        };
        if (id !== undefined && id >= 0) {
            this.uniqueTime = true;
            updateTimes(list[id]);
        } else {
            this.uniqueTime = false;
            list.forEach(updateTimes);
            this.beginTime = newStartTime;
            this.endTime = newEndTime;
        }
    }

    getAllHolidays() {
        this.store.dispatch(UserActions.get_calendar_list({
            user_sub: this.user_sub,
            child_sub: this.child_sub,
        }));
    }

    getAllHolidaysResult() {
        this.calendarSub =  this.store.select((state: AppState) => state.Users.calendarList).pipe(filter((value) => !!value)).subscribe(result => {
            if (result) {
                if (result.status === 2) {
                    this.allHolidaysData = result.data;
                    if (this.allHolidaysData.length > 0) {
                        this.allHolidaysData = this.transformHolidays(this.allHolidaysData);
                        this.loadingHolidayList = false;
                    }
                }
                else {
                    this.validationResponse.validationResponseHandler(400, 'user', 'calendar-result-fail', 'user.calendar-result-fail');
                }
                this.store.dispatch(UserActions.get_calendar_list_clear());
            }
        });
    }

    getNationalHolidays() {
        this.store.dispatch(UserActions.get_national_holidays({
            user_sub: this.user_sub,
        }));
    }

    getNationalHolidaysResult(restore?: boolean) {
        this.nationalHolidaysSub = this.nationalHolidays$.subscribe(result => {
            if (result) {
                if (result.status === 2) {
                    this.loadingHolidayList = false;
                    this.elementsHoliday = result.data;
                    this.elementsHoliday = this.transformHolidays(this.elementsHoliday, 'national', restore);
                    if (this.allHolidaysData.length > 0){
                        if (restore == true) {
                            this.allHolidaysData = this.allHolidaysData.concat(this.elementsHoliday);
                        }
                    }
                }
                else {
                    this.validationResponse.validationResponseHandler(400, 'user', 'calendar-result-fail', 'user.calendar-result-fail');
                    this.store.dispatch(UserActions.get_national_holidays_clear());
                }
            }
        });
    }

    adjustDate(dateString) {
        const dateHour = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
        if (dateHour.test(dateString)) {
            if (!dateString) {
                return null;
            }
            const [datePart, timePart] = dateString.split(' ');
            const [year, month, day] = datePart.split('-').map(Number);
            const [hour, minute, second] = timePart.split(':').map(Number);

            const date = new Date(year, month - 1, day, hour, minute, second);

            return date;
        }
        else {
            if (!dateString) {
                return null;
            }
            const date = new Date(dateString);
            const userTimezoneOffset = date.getTimezoneOffset() * 60000;
            const correctedDate = new Date(date.getTime() + userTimezoneOffset);
            return correctedDate;
        }
    }

    dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
        this.dateRange[0] = dateRangeStart.value;
        this.dateRange[1] = dateRangeEnd.value;
    }

    openTimePicker(id) {
        this.showTime[id] = true;
        const tooltip = document.querySelector(`#tooltip-time-${id}`) as HTMLElement;
        const tooltipIcon = document.querySelector(`#tooltip-icon-${id}`) as HTMLElement;
        if (tooltip.style.visibility == '' || tooltip.style.visibility == 'hidden') {
            tooltip.style.visibility = 'visible';
            tooltipIcon.style.backgroundColor = '#6CACFF';
            tooltipIcon.style.color = '#FFFF';
        }
    }

    closeTimePicker(id) {
        this.showTime[id] = false;
        const tooltip = document.querySelector(`#tooltip-time-${id}`) as HTMLElement;
        const tooltipIcon = document.querySelector(`#tooltip-icon-${id}`) as HTMLElement;

        if (tooltip && tooltipIcon && tooltip.style.visibility === 'visible') {
            tooltip.style.visibility = 'hidden';
            tooltipIcon.style.backgroundColor = '#FFFF';
            tooltipIcon.style.color = '#306EBF';
        }
    }

    cleanNationalHolidays() {
        this.restoreHolidays = true;
        this.cleanHolidays = false;
        this.elementsHoliday = this.elementsHoliday.filter(element => element.national_holiday !== true);
        if (this.allHolidaysData.length > 0){
            this.allHolidaysData = this.allHolidaysData.filter(element => element.national_holiday !== true);
        }
        if (this.elementsHoliday.length == 0 && this.allHolidaysData.length == 0) {
            this.newHolidays();
        }
    }

    restoreNationalHolidays() {
        this.getNationalHolidays();
        this.getNationalHolidaysResult(true);
        this.loadingHolidayList = true;
        this.restoreHolidays = false;
        this.cleanHolidays = true;
    }

    newHolidays() {
        const newHoliday = { alias: this.newHolidaysElement.alias, start_time: this.newHolidaysElement.start_time, end_time: this.newHolidaysElement.end_time,
            beginTime: this.beginTime != undefined ? this.beginTime : this.newHolidaysElement.beginTime, endTime: this.endTime != undefined ? this.endTime : this.newHolidaysElement.endTime, foreignhash: this.newHolidaysElement.foreignhash, id_cameras: this.newHolidaysElement.id_cameras,
            national_holiday: this.newHolidaysElement.national_holiday, to_all_cameras_user: this.newHolidaysElement.to_all_cameras_user, active: this.newHolidaysElement.active,
            id: this.newHolidaysElement.id};

        if (this.allHolidaysData.length > 0) {
            this.allHolidaysData = [...this.allHolidaysData, newHoliday];
            this.newHolidaysElement.id = this.allHolidaysData.length + 1;
        } else {
            this.elementsHoliday = [...this.elementsHoliday, newHoliday];
            this.newHolidaysElement.id = this.elementsHoliday.length + 1;
        }
    }

    removeHoliday(id) {
        if (this.allHolidaysData.length > 0) {
            this.allHolidaysData.splice(id, 1);
            this.allHolidaysData = [...this.allHolidaysData];
            if (this.allHolidaysData[id]?.national_holiday === true) {
                this.restoreHolidays = true;
            }
        }
        else {
            this.elementsHoliday.splice(id, 1);
            this.elementsHoliday = [...this.elementsHoliday];
            if (this.elementsHoliday[id]?.national_holiday === true) {
                this.restoreHolidays = true;
            }
        }
    }

    selectWholeDays() {
        if (this.beginTime == '00:00:00' && this.endTime == '23:59:59') {
            this.allDayChecked = true;
            this.selectWholeDay.setValue(this.allDayChecked);
        }
        else {
            this.allDayChecked = false;
            this.selectWholeDay.setValue(this.allDayChecked);
        }
        this.subSelectWholeDay = this.selectWholeDay.valueChanges.subscribe(monitoring => {
            if (monitoring) {
                this.beginTime = '00:00';
                this.endTime = '23:59';
            } else {
                this.beginTime = '';
                this.endTime = '';
            }
            this.setTime([this.beginTime, this.endTime]);
        });
    }

    personalizedHourButton(id: number) {
        const list = this.allHolidaysData?.length > 0 ? this.allHolidaysData : this.elementsHoliday;
        if (id < 0 || id >= list.length) {
            return false;
        }
        const holiday = list[id];
        const hasCustomTime = (holiday.beginTime && holiday.beginTime !== '00:00:00' && holiday.beginTime !== this.beginTime) ||
                              (holiday.endTime && holiday.endTime !== '23:59:59' && holiday.endTime !== this.endTime);
        return this.allHolidaysData?.length > 0 ? hasCustomTime : hasCustomTime && this.uniqueTime;
    }


    getHolidayHour(id: number) {
        const list = this.allHolidaysData && this.allHolidaysData.length > 0 ? this.allHolidaysData : this.elementsHoliday;
        if (id >= 0 && id < list.length) {
            const holiday = list[id];

            if (holiday.beginTime == null && holiday.endTime == null) {
                return '';
            }
            if (holiday.beginTime == '00:00:00' && holiday.endTime == '23:59:59') {
                return '24 horas';
            }
            if (this.uniqueTime) {
                return holiday.beginTime ? `${holiday.beginTime} - ${holiday.endTime}` : '';
            }
            if (holiday.beginTime == '' && holiday.endTime == '') {
                return '';
            }
            return `${holiday.beginTime} - ${holiday.endTime}`;
        }
    }

    getHolidayName(event, i) {
        if (this.allHolidaysData.length > 0) {
            this.allHolidaysData[i].alias = event;
        }
        else {
            if (this.elementsHoliday[i] != undefined) {
                this.elementsHoliday[i].alias = event;
            }
        }
    }

    changeCams(event, i) {
        if (this.allHolidaysData.length > 0) {
            this.allHolidaysData[i].id_cameras = [];
            this.allHolidaysData[i].id_cameras = event;
        }
        else {
            if (this.elementsHoliday[i] != undefined) {
                this.elementsHoliday[i].id_cameras = event;
            }
        }
    }

    isEmpty(holidays) {
        for (const holiday of holidays) {
            if (holiday == '' || holiday == undefined) {
                this.errorPass = false;
                this.updatingCalendar = false;
                return;
            }
        }
        this.errorPass = true;
        this.updatingCalendar = true;
    }

    cameraPlaceholder(holiday) {
        if (this.allHolidaysData?.length === 0) {
            if (holiday.national_holiday === true || holiday.type === 'national') {
                return 'Todas as câmeras';
            } else {
                return 'Selecionar câmera(s)';
            }
        } else if (this.allHolidaysData?.length > 0) {
            if (holiday.to_all_cameras_user === true) {
                return 'Todas as câmeras';
            }
            if (!holiday.id_cameras) {
                if (holiday.cameras_info && holiday.to_all_cameras_user === false && holiday.cameras_info.length > 0) {
                    return holiday.cameras_info[0]?.alias;
                }
                else {
                    return 'Selecionar câmera(s)';
                }
            }
            else {
                return 'Selecionar câmera(s)';
            }
        } else {
            return 'Selecionar câmera(s)';
        }
    }

    cameraSelect(holiday) {
        if (this.allHolidaysData?.length === 0) {
            if (holiday.national_holiday === true || holiday.type === 'national') {
                return this.cameraList;
            } else {
                return '';
            }
        } else if (this.allHolidaysData?.length > 0) {
            if (holiday.to_all_cameras_user === true) {
                return this.cameraList;
            } else if (holiday && holiday.to_all_cameras_user === false && holiday.cameras_info) {
                return holiday.cameras_info;
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    formateData(date) {
        if (date != undefined) {
            const [day, month, year] = date.split('/');
            return `${year}-${month}-${day}`;
        } else {
            return null;
        }
    }

    transformHolidays(holidays, type?: string, restore?: boolean) {
        return holidays.map(holiday => {
            if (type == 'national') {
                holiday.date_begin = holiday.date_begin ? holiday.date_begin : this.dateRange[0];
                holiday.date_end = holiday.date_end ? holiday.date_end : this.dateRange[1];
                const holidayCams = holiday.id_cameras;
                if (restore == true) {
                    const startDate = formatDate((holiday.date_begin), 'yyyy-MM-dd', 'pt') + (holiday.beginTime ? ' ' + holiday.beginTime : this.beginTime ? 'T' + this.beginTime : '');
                    const endDate = holiday.date_end ? formatDate(holiday.date_end, 'yyyy-MM-dd', 'pt') + (holiday.end_time ? ' ' + holiday.end_time : this.endTime ? 'T' + this.endTime : '') : null;

                    return {
                        alias: holiday.name,
                        date_begin: this.adjustDate(startDate),
                        date_end: this.adjustDate(endDate),
                        beginTime: this.beginTime,
                        endTime: this.endTime,
                        national_holiday: holiday.type == 'national',
                        active: true,
                        all_day: holiday.beginTime == '00:00:00' && holiday.endTime == '23:59:59',
                        to_all_cameras_user: true,
                        id_cameras: holidayCams,
                        start_time: startDate,
                        end_time: endDate,
                    };
                }
                else {
                    const startDate = formatDate((holiday.date_begin), 'yyyy-MM-dd', 'pt') + (holiday.beginTime ? ' ' + holiday.beginTime : '');
                    const endDate = holiday.date_end ? formatDate(holiday.date_end, 'yyyy-MM-dd', 'pt') + (holiday.end_time ? ' ' + holiday.end_time : '') : null;

                    return {
                        alias: holiday.name == undefined ? 'Confraternização Universal' : holiday.name,
                        date_begin: this.adjustDate(startDate),
                        date_end: this.adjustDate(endDate),
                        beginTime: this.beginTime,
                        endTime: this.endTime,
                        national_holiday: holiday.type == 'national',
                        active: true,
                        all_day: holiday.beginTime == '00:00:00' && holiday.endTime == '23:59:59',
                        to_all_cameras_user: true,
                        id_cameras: holidayCams,
                    };
                }
            }
            else if (type == 'update') {
                holiday.start_time = holiday.date_begin ? holiday.date_begin : this.formateData(this.dateRange[0]);
                if (holiday.national_holiday != true) {
                    holiday.end_time = holiday.end_time ? formatDate((holiday.end_time), 'yyyy-MM-dd', 'pt') : this.formateData(this.dateRange[1]);
                } else {
                    holiday.end_time = holiday.date_end ? formatDate((holiday.date_end), 'yyyy-MM-dd', 'pt') : holiday.start_time;
                }

                const startDate = holiday.start_time != undefined ? formatDate((holiday.start_time), 'yyyy-MM-dd', 'pt') + 'T' + holiday.beginTime : '';
                const endDate = holiday.start_time != undefined ? (holiday.end_time ? formatDate((holiday.end_time), 'yyyy-MM-dd', 'pt') : formatDate((holiday.start_time), 'yyyy-MM-dd', 'pt')) + 'T' + holiday.endTime : '';

                if (holiday.cameras_info != undefined) {
                    holiday.cameras_info = holiday.cameras_info.map(cams => ({ id: cams.id, hash_holiday: holiday.hash_holiday }));
                    holiday.id_cameras = holiday.id_cameras.map(id => ({
                        id: (typeof id === 'object' && id !== null ? id.id : id),
                        hash_holiday: ''
                    }));
                    const camerasInfoIds = new Set(holiday.cameras_info.map(cams => cams.id));
                    const hasDuplicateIds = holiday.id_cameras.some(idCam => camerasInfoIds.has(idCam.id));

                    if (hasDuplicateIds) {
                        if (holiday.cameras_info.length != holiday.id_cameras.length) {
                            holiday.cameras_info = holiday.id_cameras.filter(idCam => !camerasInfoIds.has(idCam.id));
                            holiday.cameras_info = holiday.id_cameras.map(cams => ({
                                id: cams.id,
                                hash_holiday: camerasInfoIds.has(cams.id) ? holiday.hash_holiday : ''
                            }));
                            holiday.to_all_cameras_user = undefined;
                        } else {
                            const uniqueArray = [...holiday.cameras_info, ...holiday.id_cameras].filter((item, index, self) =>
                                index === self.findIndex((t) => t.id === item.id));
                            holiday.cameras_info = uniqueArray;
                        }
                    } else {
                        holiday.cameras_info = holiday.id_cameras.filter(idCam => !camerasInfoIds.has(idCam.id));
                        holiday.cameras_info = holiday.id_cameras.map(cams => ({
                            id: cams.id,
                            hash_holiday: camerasInfoIds.has(cams.id) ? holiday.hash_holiday : ''
                        }));
                        holiday.to_all_cameras_user = undefined;
                    }
                    this.holidayCams = holiday.cameras_info;
                } else {
                    this.holidayCams = holiday.id_cameras != undefined ? holiday.id_cameras.map(cams => ({ id: cams, hash_holiday: '' })) : null;
                }

                this.isEmpty.call(this, [
                    holiday.alias,
                    this.beginTime,
                    this.endTime,
                    holiday.date_begin,
                    holiday.beginTime,
                    holiday.endTime,
                    holiday.start_time,
                    this.holidayCams.length === 0 ? '' : 'notEmpty'
                ]);

                return {
                    alias: holiday.alias,
                    start_time: startDate,
                    end_time: endDate,
                    national_holiday: holiday.national_holiday == true,
                    active: holiday.active,
                    all_day: startDate.split('T')[1] == '00:00:00' && endDate.split('T')[1] == '23:59:59',
                    to_all_cameras_user: holiday.to_all_cameras_user != undefined ? holiday.to_all_cameras_user : this.cameras.length == (holiday.cameras_info == undefined ? holiday.id_cameras?.length : holiday.cameras_info?.length),
                    cameras_info: this.holidayCams
                };
            }
            else if (type == 'create') {
                const holidayCams = holiday.id_cameras;
                holiday.start_time = holiday.date_begin ? holiday.date_begin : this.formateData(this.dateRange[0]);
                if (holiday.national_holiday != true) {
                    holiday.end_time = holiday.end_time ? formatDate((holiday.end_time), 'yyyy-MM-dd', 'pt') : this.formateData(this.dateRange[1]);
                } else {
                    holiday.end_time = holiday.date_end ? formatDate((holiday.date_end), 'yyyy-MM-dd', 'pt') : holiday.start_time;
                }
                const startDate = holiday.start_time != undefined ? formatDate((holiday.start_time), 'yyyy-MM-dd', 'pt') + 'T' + holiday.beginTime : '';
                const endDate = holiday.start_time != undefined ?  (holiday.end_time ? formatDate((holiday.end_time), 'yyyy-MM-dd', 'pt') : formatDate((holiday.start_time), 'yyyy-MM-dd', 'pt')) + 'T' + holiday.endTime : '';

                this.isEmpty.call(this, [
                    holiday.alias,
                    this.beginTime,
                    this.endTime,
                    holiday.date_begin,
                    holiday.beginTime,
                    holiday.endTime,
                    holiday.start_time,
                    holidayCams.length == 0 ? '' : 'notEmpty'
                ]);

                return {
                    alias: holiday.alias,
                    start_time: startDate,
                    end_time: endDate,
                    national_holiday: holiday.national_holiday == true,
                    active: true,
                    all_day: startDate.split('T')[1] == '00:00:00' && endDate.split('T')[1] == '23:59:59',
                    to_all_cameras_user: this.cameras.length == holiday.id_cameras?.length,
                    id_cameras: holidayCams
                };
            }
            else {
                holiday.date_begin = holiday.start_time ? holiday.start_time.split('T')[0] : this.dateRange[0];
                holiday.date_end = holiday.end_time ? holiday.end_time.split('T')[0] : this.dateRange[1];
                holiday.beginTime = holiday.start_time.split('T')[1];
                holiday.endTime = holiday.end_time.split('T')[1];

                this.beginTime = holiday.start_time.split('T')[1];
                this.endTime = holiday.end_time.split('T')[1];


                const startDate = formatDate((holiday.date_begin), 'yyyy-MM-dd', 'pt') + (holiday.beginTime ? ' ' + holiday.beginTime : '');
                const endDate = holiday.date_end ? formatDate(holiday.date_end, 'yyyy-MM-dd', 'pt') + (holiday.endTime ? ' ' + holiday.endTime : '') : null;

                return {
                    active: holiday.active,
                    alias: holiday.alias,
                    all_day: holiday.all_day,
                    cameras_info: holiday.cameras_info,
                    date_begin: this.adjustDate(startDate),
                    date_end: this.adjustDate(endDate),
                    beginTime: holiday.beginTime,
                    endTime: holiday.endTime,
                    foreignhash: holiday.foreignhash,
                    hash_holiday: holiday.hash_holiday,
                    national_holiday: holiday.national_holiday,
                    to_all_cameras_user: holiday.to_all_cameras_user,
                };
            }
        });

    }

    createCalendar() {
        this.creatingCalendar = true;
        const transformedHolidays: Holidays[]  = this.transformHolidays(this.elementsHoliday, 'create');
        if (this.errorPass) {
            this.store.dispatch(UserActions.create_calendar({
                user_sub: this.user_sub,
                child_sub: this.child_sub,
                holidays: transformedHolidays
            }));
            this.createCalendarResult();
        }
        else {
            this.creatingCalendar = false;
        }
    }

    createCalendarResult() {
        this.createCalendarSub = this.createCalendar$.subscribe(result => {
            if (result) {
                if (result.status === 3) {
                    this.validationResponse.validationResponseHandler(400, 'user', 'calendar-create-fail', 'user.calendar-create-fail');
                }
                if (result.status === 0) {
                    this.validationResponse.validationResponseHandler(204, 'user', 'calendar-create-warning', result.message);
                }
                if (result.status === 2) {
                    this.validationResponse.validationResponseHandler(200, 'user', 'calendar-create-success', 'user.calendar-create-success');
                    this.closeModal();
                }
                this.store.dispatch(UserActions.create_calendar_clear());
                this.creatingCalendar = false;
            }
        });
    }

    updateCalendar() {
        this.updatingCalendar = true;
        const transformedHolidays: Holidays[]  = this.transformHolidays(this.allHolidaysData, 'update');
        if (this.errorPass) {
            this.store.dispatch(UserActions.update_calendar({
                user_sub: this.user_sub,
                child_sub: this.child_sub,
                holidays: transformedHolidays
            }));
            this.updateCalendarResult();
        } else {
            this.updatingCalendar = false;
        }
    }

    updateCalendarResult() {
        this.updateCalendarSub = this.updateCalendar$.subscribe(result => {
            if (result) {
                if (result.status === 2) {
                    this.validationResponse.validationResponseHandler(200, 'user', 'calendar-update-success', 'user.calendar-update-success');
                    this.closeModal();
                }
                else {
                    if (result.statusCode == 403) {
                        this.validationResponse.validationResponseHandler(403, 'user', 'calendar-update-hashname-fail', result.message);
                    }
                    else {
                        this.validationResponse.validationResponseHandler(400, 'user', 'calendar-update-fail', 'user.calendar-update-fail');
                    }
                }
                this.store.dispatch(UserActions.update_calendar_clear());
                this.updatingCalendar = false;
            }
        });
    }

}
