import { Store } from '@ngrx/store';
import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { OperationStatus } from 'app/Shared/models';
import { SharedService } from 'app/Shared/Services/shared.service';
import { AppState } from 'app/store/model';
import { Subscription } from 'rxjs';
import { GroupCamera } from '../models';
import { CameraActions } from '../Services/actions';

@Component({
    selector: 'app-camera-group',
    templateUrl: './camera-group.component.html',
    styleUrls: ['./camera-group.component.scss']
})
export class CameraGroupComponent implements OnInit, OnDestroy {
    readonly groupCamera$ = this.store.select((state: AppState) => state.camera.groupCamera);
    readonly createGroup$ = this.store.select((state: AppState) => state.camera.createGroup);

    @Input() insertedGroup: GroupCamera[];

    displayedColumns: string[] = ['camera', 'group', 'type', 'client', 'users', 'details'];
    dataSource: MatTableDataSource<GroupCamera> = new MatTableDataSource([] as GroupCamera[]);
    @Input() context: string;

    isAssociate: boolean;
    commonAssociate: boolean;
    isClient: boolean;
    clientView: boolean;
    hasGroups: boolean;

    getGroupCams: Subscription;
    createGroupSub: Subscription;

    loading: boolean;
    allowSettings: boolean;
    sortingTable = false;

    filterOptions = [
        {
            id: 1,
            text: 'Recentes',
        },
        {
            id: 2,
            text: 'Usuários',
        },
        {
            id: 3,
            text: 'Ordem Alfabética',
        }
    ];

    constructor(private router: Router, private readonly store: Store<AppState>, private sharedService: SharedService) { }

    ngOnInit(): void {
        this.loading = true;
        if (localStorage.getItem('profile_name') === 'f29868c7-b4c5-4963-9ae8-1dd95699d6c3') {
            this.isAssociate = true;
        }

        if (this.isAssociate && localStorage.getItem('associate_permission') === 'd3098a51-86f3-4494-b7b0-5bb0498558ee') {
            this.commonAssociate = true;
        }

        if ((this.context == 'camera-list' || localStorage.getItem('context') == 'clientview') &&
        ((localStorage.getItem('profile_name') === 'cd343bfc-17e8-11ec-9621-0242ac130002') || this.isAssociate))
        {
            this.clientView = true;
        }

        if (localStorage.getItem('profile_name') === '61902d2b-3ada-49f3-b42a-1775bc064bb0' ||
        localStorage.getItem('profile_name') === '419bea06-5d4e-4a56-b8b5-04b3ad566d59') {
            this.isClient = true;
        }

        this.allowSettings = false;
        this.groupCamerasResult();
        this.store.dispatch(CameraActions.open_view_group_clear());
    }

    ngOnDestroy(): void {
        if (this.getGroupCams) {
            this.getGroupCams.unsubscribe();
        }
        if (this.createGroupSub) {
            this.createGroupSub.unsubscribe();
        }
        this.store.dispatch(CameraActions.update_camera_group_clear());
    // this.store.dispatch(CameraActions.clear_group_cameras());
    }

    changeFilter(event) {
        this.changeSortedColumn(event[0]);
    }

    // Compara o nome ou status e ordena corretamente
    regularCompare(a, b, isAsc) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    // Compara a data e retorna a ordenação corretamente
    dateCompare(a, b, isAsc) {
        const dateA = new Date(a).valueOf();
        const dateB = new Date(b).valueOf();
        return (dateA < dateB ? -1 : 1) * (isAsc ? 1 : -1);
    }

    changeSortedColumn(id = 1) {
        this.sortingTable = true;
        let sortType;
        switch (id) {
            case 1:
                sortType = 'timestamp';
                break;
            case 2:
                sortType = 'users';
                break;
            case 3:
                sortType = 'nome';
                break;
        }
        const sortState: Sort = { active: sortType, direction: 'asc' };
        const isAsc = sortState.direction === 'asc';
        const isDesc = sortState.direction === 'desc';

        this.dataSource.data = this.dataSource.data.sort((a, b) => {
            if (sortState.active == 'users') {
                return this.regularCompare((a.clients_info_group.length + a.associateds_info_group.length), (b.clients_info_group.length + b.associateds_info_group.length), isDesc);

            } else if (sortState.active == 'nome') {
                return this.regularCompare(a.alias.toUpperCase(), b.alias.toUpperCase(), isAsc);

            } else {
                return this.dateCompare(a.registered_at, b.registered_at, isDesc);
            }
        });
        this.sortingTable = false;
    }

    groupCameras() {
        this.store.dispatch(CameraActions.get_group_cameras({
            user_sub: localStorage.getItem('sub'),
            child_sub: this.clientView ? localStorage.getItem('clientView') : null,
            type_request: this.clientView ? 'data' : 'list'
        }));
    }

    groupCamerasResult() {
        this.getGroupCams = this.groupCamera$.subscribe(result => {
            this.allowSettings = false;
            if (result) {
                this.dataSource.data = result as GroupCamera[];
                this.allowSettings = true;
                this.changeSortedColumn();
                if (this.dataSource.data?.length > 0) {
                    this.hasGroups = true;
                }
                this.loading = false;
            } else {
                this.loading = true;
                this.hasGroups = false;
                this.dataSource.data = [];
                this.groupCameras();
            }
        });
    }

    openGroupView(element: GroupCamera): void {
        localStorage.setItem('groupId', element.id_camera_group.toString());
        localStorage.setItem('groupName', element.alias);
        localStorage.setItem('previousRouter', this.router.url);
        localStorage.setItem('previousRouterName', this.router.url === '/cameras/groups' ? 'Grupo de Câmeras' : 'Minhas Câmeras');
        if (this.router.url === '/cameras/groups' || this.router.url === '/cameras/list') {
            this.router.navigateByUrl('/cameras/groups/view');
        } else {
            this.store.dispatch(CameraActions.open_view_group());
        }
    }

    brokenImageHandler(element) {
        element.error = false;
        element.target.src = '../../../assets/img/broken_link.svg';
    }

    addGroupCamera(isPublic = false) {
        this.createGroupSub = this.createGroup$.subscribe(result => {
            if (result != null) {
                this.loading = true;
                if (result.status === OperationStatus.Success) {
                    this.groupCameras();
                }
            }
        });

        const dataModal = {
            data: isPublic,
            modalHeight: '90vh',
            modalWidth: '70vw',
            backdropClass: 'lightBackdrop',
            tipoModal: 'adicionar_grupo'
        };
        this.sharedService.returnDataToModal(dataModal);
    }

    configGroupCamera(element: GroupCamera) {
        localStorage.setItem('context', this.context);
        const dataModal = {
            data: {
                cameraGroup: element
            },
            modalHeight: '90vh',
            modalWidth: '85vw',
            backdropClass: 'lightBackdrop',
            tipoModal: 'configurar_grupo'
        };
        this.sharedService.returnDataToModal(dataModal);
    }

}
