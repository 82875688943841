<div class="map-container" [class.loading-map]="loadingMap">
  <span class="loading-map-icon" *ngIf="loadingMap" nz-icon nzType="loading" nzTheme="outline"></span>
    <div class="map-frame">
      <div id="map">
        <div class="flex-line between second-row">
          <div class="filter">
            <div class="search-input">
              <input type="text" placeholder="Pesquisar"
              [formControl]="searchInputControl" (keyup)="requestSearch()">
              <i nz-icon nzType="search" nzTheme="outline" class="clickable" (click)="requestSearch()"></i>
            </div>
            <div class="select-client-container" *ngIf="is_partner">
              <app-filter *ngIf="clientSimple.length != 0"
                    [styles]="['multiselect-alternate-style']"
                    [list]="clientSimple"
                    type="multiselect"
                    [search]="true"
                    placeholder="Selecionar Cliente"
                    (filterStatusChange)="selectClient($event)"
                    [showSelected]="true"
                    [selectedList]="clientPlaceholder"
                    feedback="true"
                    [hideActiveFill]="true"
                    [hasIconPrefix]="true"
                    [dashboard]="true"
                    iconType="team"
              ></app-filter>
            </div>
            <div class="status-container" *ngIf="!is_partner">
              <app-filter
              [styles]="['simple-alternate-style']"
              [list]="statusOptions"
              [selected]="placeholder"
              placeholder="Status da câmera"
              [type]="'simple'"
              [showSelected]="true"
              (filterStatusChange)="changeStatusFilter($event)"></app-filter>
            </div>
            <div class="status-container" *ngIf="is_partner">
              <app-filter
              [styles]="['simple-alternate-style']"
              [list]="statusOptionsPartner"
              [selected]="placeholder"
              placeholder="Status da câmera"
              [type]="'simple'"
              [showSelected]="true"
              (filterStatusChange)="changeStatusFilter($event)"></app-filter>
            </div>
            <span class="info-map" nz-icon nzType="info-circle" [nzTheme]="'twotone'" [nzTwotoneColor]="'#306EBF'" nz-tooltip 
            nzTooltipTitle="Só são mostradas no mapa câmeras com o endereço cadastrado"
            nzTooltipOverlayClassName="camera_playlist_processing integrated" nzTooltipPlacement="top"></span>
          </div>
        </div>
      </div>
  </div>
  <app-modal></app-modal>
</div>