<div class="main-content">

  <div class="row">
    <div class="mr-auto xs:mr-0 xs:title_style_1 sm:title_style_1 md:title_style_1 lg:title_style_1 xl:title_style_1 col-6">
      <span class="style-title">Lista de Kits</span>
    </div>
    <div class="ml-auto xs:ml-0 xs:title_style_2 sm:title_style_2 md:title_style_2 lg:title_style_2 xl:title_style_2 col-4">
      <button class="btn lg:btn-sm xl:btn-sm btn-primary enter-button" (click)="addKit()">
        <i class="material-icons add-icon">add</i>ADICIONAR KIT
      </button>
    </div>
  </div>
  <table mat-table [dataSource]="dataSource" class="style-table">

    <ng-container matColumnDef="kit">
      <th mat-header-cell *matHeaderCellDef> Resolução </th>
      <td mat-cell *matCellDef="let kit">
        <div *ngFor="let plan of plans">
          <span *ngIf="plan.id == kit.plan_id">CÂMERA {{plan.resolution.alias}}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="product">
      <th mat-header-cell *matHeaderCellDef> Armazenamento </th>
      <td mat-cell *matCellDef="let kit" class="value-partner">
        <div *ngFor="let plan of plans">
          <span *ngIf="plan.id == kit.plan_id">{{plan.days_storage}} {{plan.days_storage == 1 ? ' dia' : ' dias'}}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="cameras">
      <th mat-header-cell *matHeaderCellDef> Câmeras </th>
      <td mat-cell *matCellDef="let kit" class="value-partner"> {{kit.cameras_amount}} </td>
    </ng-container>

    <ng-container matColumnDef="analytics">
      <th mat-header-cell *matHeaderCellDef> Detecções inteligentes </th>
      <td mat-cell *matCellDef="let kit" class="value-partner"> {{kit.analytics_amount}} </td>
    </ng-container>

    <ng-container matColumnDef="cancel">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let kit; let index = index" class="value-partner">
        <button mat-raised-button class="btn btn-sm btn-just-icon btn-round btn-settings"
                *ngIf="plans.length > 0" (click)="editKit(kit)">
          <i nz-icon nzType="setting" nzTheme="outline"></i>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div *ngIf="loadingKits" class="page-header">
    <div class="loading">
      <span nz-icon class="center" nzType="loading" nzTheme="outline" style="font-size:30px"></span>
    </div>
  </div>
</div>

<app-modal></app-modal>