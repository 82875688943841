import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { CamerasByLocation, ClientsToGroup, SortColumnCamera } from '../models';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/model';
import { Subscription } from 'rxjs';
import { CameraActions } from '../Services/actions';
import { ImageService } from 'app/Shared/Services/image.service';
import { UntypedFormControl } from '@angular/forms';
import { SharedService } from 'app/Shared/Services/shared.service';
import * as L from 'leaflet';
import 'leaflet.locatecontrol';
import 'leaflet.markercluster';
import { HasScrollService } from 'app/Shared/Services/has-scroll.service';

@Component({
    selector: 'app-camera-map',
    templateUrl: './camera-map.component.html',
    styleUrl: './camera-map.component.scss'
})
export class CameraMapComponent implements OnInit, OnDestroy {
    readonly getCamerasByLocation$ = this.store.select((state: AppState) => state.camera.camerasByLocation);
    readonly clientsTogroup$ = this.store.select((state: AppState) => state.camera.clientsGroup);

    getCamerasByLocationSub: Subscription;
    clientsSub: Subscription;

    map: L.Map;
    centroid: L.LatLngExpression = [-15.7942, -47.8822];
    markers: any;

    debounceSearch: ReturnType<typeof setTimeout>;
    searchInputControl = new UntypedFormControl('', []);
    statusOptions = [
        {
            id: 1,
            text: 'Online',
        },
        {
            id: 2,
            text: 'Offline',
        }
    ];

    statusOptionsPartner = [
        {
            id: 1,
            text: 'Online',
        },
        {
            id: 2,
            text: 'Offline',
        },
        {
            id: 3,
            text: 'Bloqueadas',
        },
        {
            id: 4,
            text: 'Desbloqueadas',
        },
    ];

    clients: ClientsToGroup[];
    clientSimple: {
        id: number | string;
        text: string;
    }[] = [];
    clientPlaceholder: number[];


    is_partner = false;
    userProfile = localStorage.getItem('profile_name');
    userSub: string;
    clientSub: string;
    camLimit: number;
    clientsID: number[];
    sortBy: SortColumnCamera[];
    placeholder: number = null;
    lat: number;
    lng: number;
    radius: number;
    loadingMap: boolean;
    imgHover: boolean;
    camMap: CamerasByLocation[];
    showGroups = false;

    constructor(private readonly store: Store<AppState>,
        private service: SharedService,
        private scrollService: HasScrollService,
        private IS: ImageService) { }

    ngOnInit(): void {
        if (this.userProfile == 'cd343bfc-17e8-11ec-9621-0242ac130002') {
            this.is_partner = true;
        }
        this.userSub = localStorage.getItem('sub');
        this.lat = -14.2350;
        this.lng = -51.9253;
        this.radius = 20;
        this.loadingMap = true;
        this.getClients();
        this.getCamerasByLocation();
        this.initMap();
        this.scrollService.hasScrollEmitter.emit(true);
    }

    ngOnDestroy() {
        if (this.getCamerasByLocationSub) {
            this.getCamerasByLocationSub.unsubscribe();
        }
        if (this.clientsSub) {
            this.clientsSub.unsubscribe();
        }
        this.store.dispatch(CameraActions.cleanup());
        this.scrollService.hasScrollEmitter.emit(false);
    }

    initMap(): void {
        this.map = L.map('map', {
            center: this.centroid,
            zoom: 5
        });

        const tiles = L.tileLayer('http://basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
            maxZoom: 18,
            minZoom: 5,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        });

        tiles.addTo(this.map);
        this.map.zoomControl.remove();
        L.control.zoom({
            position: 'bottomleft'
        }).addTo(this.map);
        L.control.locate({
            position: 'bottomleft'
        }).addTo(this.map);
    }

    getCamerasByLocation() {
        this.store.dispatch(CameraActions.get_cameras_by_location({
            user_sub: this.userSub,
            lat: this.lat,
            lng: this.lng,
            radius: this.radius
        }));
        this.getCamerasByLocationResult();
    }

    getClients() {
        this.store.dispatch(CameraActions.get_clients_group({
            user_sub: this.userSub
        }));
        this.getClientsResult();
    }

    getClientsResult() {
        this.clientsSub = this.clientsTogroup$.subscribe(result => {
            if (result && result.length > 0) {
                this.clients = result;
                this.clientSimple = result.map(element => {
                    return {
                        id: element.client_id,
                        text: element.client_name
                    };
                });
            }
        });
    }

    requestSearch() {
        clearTimeout(this.debounceSearch);
        const query = this.searchInputControl.value.toLowerCase();
        this.clearMap();
        this.debounceSearch = setTimeout(() => {
            this.map.remove();
            this.initMap();
            this.store.dispatch(CameraActions.get_cameras_by_location({
                user_sub: this.userSub,
                lat: this.lat,
                lng: this.lng,
                radius: this.radius,
                text: query,
                id_user: this.clientsID,
                status: this.placeholder ? this.placeholder == 1 ? true : this.placeholder == 2 ? false : null : null,
                active: this.placeholder ? this.placeholder == 4 ? true : this.placeholder == 3 ? false : null : null
            }));
            this.loadingMap = true;
        }, 600);
    }

    selectClient(event) {
        this.clientsID = event;
        clearTimeout(this.debounceSearch);
        const queryStr = this.searchInputControl.value.toLowerCase();
        this.clearMap();
        this.debounceSearch = setTimeout(() => {
            this.map.remove();
            this.initMap();
            this.store.dispatch(CameraActions.get_cameras_by_location({
                user_sub: this.userSub,
                lat: this.lat,
                lng: this.lng,
                radius: this.radius,
                text: queryStr,
                id_user: this.clientsID,
                status: this.placeholder ? this.placeholder == 1 ? true : this.placeholder == 2 ? false : null : null,
                active: this.placeholder ? this.placeholder == 4 ? true : this.placeholder == 3 ? false : null : null

            }));
            this.loadingMap = true;
        }, 600);
    }

    changeStatusFilter(event) {
        this.changeSortedStatusColumn(event[0]);
    }

    changeSortedStatusColumn(id) {
        clearTimeout(this.debounceSearch);
        this.clearMap();
        const query = this.searchInputControl.value.toLowerCase();
        switch (id) {
            case 1:
                this.placeholder = 1;
                this.debounceSearch = setTimeout(() => {
                    this.map.remove();
                    this.initMap();
                    this.store.dispatch(CameraActions.get_cameras_by_location({
                        user_sub: this.userSub,
                        lat: this.lat,
                        lng: this.lng,
                        radius: this.radius,
                        status: true,
                        text: query,
                        id_user: this.clientsID
                    }));
                    this.loadingMap = true;
                }, 300);
                break;
            case 2:
                this.placeholder = 2;
                this.debounceSearch = setTimeout(() => {
                    this.map.remove();
                    this.initMap();
                    this.store.dispatch(CameraActions.get_cameras_by_location({
                        user_sub: this.userSub,
                        lat: this.lat,
                        lng: this.lng,
                        radius: this.radius,
                        status: false,
                        text: query,
                        id_user: this.clientsID
                    }));
                    this.loadingMap = true;
                }, 300);
                break;
            case 3:
                this.placeholder = 3;
                this.debounceSearch = setTimeout(() => {
                    this.map.remove();
                    this.initMap();
                    this.store.dispatch(CameraActions.get_cameras_by_location({
                        user_sub: this.userSub,
                        lat: this.lat,
                        lng: this.lng,
                        radius: this.radius,
                        text: query,
                        id_user: this.clientsID,
                        active: false
                    }));
                    this.loadingMap = true;
                }, 300);
                break;
            case 4:
                this.placeholder = 4;
                this.debounceSearch = setTimeout(() => {
                    this.map.remove();
                    this.initMap();
                    this.store.dispatch(CameraActions.get_cameras_by_location({
                        user_sub: this.userSub,
                        lat: this.lat,
                        lng: this.lng,
                        radius: this.radius,
                        text: query,
                        id_user: this.clientsID,
                        active: true
                    }));
                    this.loadingMap = true;
                }, 300);
                break;
            default:
                this.placeholder = null;
                this.debounceSearch = setTimeout(() => {
                    this.map.remove();
                    this.initMap();
                    this.store.dispatch(CameraActions.get_cameras_by_location({
                        user_sub: this.userSub,
                        lat: this.lat,
                        lng: this.lng,
                        radius: this.radius,
                        text: query,
                        id_user: this.clientsID,
                    }));
                    this.loadingMap = true;
                }, 300);
        }
    }

    @HostListener('document:click', ['$event'])
    handleDocumentClick(event: MouseEvent) {
        const target = event.target as HTMLElement;

        if (target.classList.contains('thumbnail')) {
            this.handleThumbnailClick(target);
        }
        if (target.classList.contains('showGroup')) {
            this.handleShowGroupClick(target);
        }
    }

    handleThumbnailClick(target: HTMLElement) {
        if (target.classList.contains('thumbnail')) {
            const img = target as HTMLImageElement;
            const src = img.src;
            const matchedCamera = this.camMap.find(camera => camera.thumbnail === src);
            if (matchedCamera) {
                this.viewLiveCamera(matchedCamera);
            }
        }
    }

    handleShowGroupClick(target: HTMLElement) {
        const parentDiv = target.closest('div');
        const moreGroupsDiv = parentDiv?.querySelector('.moreGroups') as HTMLElement;
        if (moreGroupsDiv) {
            if (moreGroupsDiv.style.display === 'none' || moreGroupsDiv.style.display === '') {
                moreGroupsDiv.style.display = 'flex';
                target.textContent = '^';
            } else {
                moreGroupsDiv.style.display = 'none';
                target.textContent = 'v';
            }
        }
    }

    viewLiveCamera(cam: CamerasByLocation) {
        const dataModal = {
            data: {
                camera: cam
            },
            modalHeight: 'fit-content',
            modalMaxHeight: '90vh',
            backdropClass: 'lightBackdrop',
            tipoModal: 'live_camera'
        };
        this.service.returnDataToModal(dataModal);
    }

    getCamerasByLocationResult() {
        this.getCamerasByLocationSub = this.getCamerasByLocation$.subscribe(result => {
            if (result) {
                this.loadingMap = false;
                this.camMap = result;
                this.markers = L.markerClusterGroup({
                    showCoverageOnHover: false
                });
                result.forEach(camera => {
                    this.cameraPopOver(camera);
                });
            }
        });
    }

    clearMap() {
        this.map.removeLayer(this.markers);
        this.markers.clearLayers();
    }

    cameraPopOver(camera) {
        const mapIcon = new L.Icon({
            iconUrl: camera.active != true ? 'assets/img/Icons/map-pin-blocked.png' :
                camera.status != true ? 'assets/img/Icons/map-pin-off.png' : 'assets/img/Icons/pin-online.svg',
            iconSize: [45, 50],
            iconAnchor: [23.5, 47],
            popupAnchor: [1, -40],
            shadowAnchor: [4, 62]
        });
        let opacity = 1;

        // Tag de off/block
        let camStatus = '';
        // Câmera offline
        if (camera['active'] !== true && camera['status'] == false) {
            opacity = 0.75;
            camStatus = `
             <div style='border-radius:10px; background-color: rgba(0, 45, 89, 1); 
                display: flex; align-items: center; padding: 4px 14px 4px 14px; width: 118px;
                margin: 3px;'>
                    <div>
                        <img src='assets/img/Icons/blocked.svg' style='width: 18px; height: 18px' />
                    </div>
                    <div style='font-size:14px; color: white; padding-left: 6px; padding-top:2px;'>
                        Bloqueada
                    </div>
                </div>
                <div style='border-radius:10px; background-color: rgba(192, 224, 255, 1); 
                display: flex; align-items: center; padding: 4px 14px 4px 14px; width: 100px;
                margin: 3px;'>
                    <div>
                        <img src='assets/img/Icons/ExclamationCircle.svg' style='width: 18px; height: 18px' />
                    </div>
                    <div style='font-size:14px; color: rgba(0, 45, 89, 1); padding-left: 6px; padding-top:2px;'>
                        Off-line
                    </div>
                </div>`;
        }
        else if (camera['active'] == true && camera['status'] == false) {
            opacity = 0.75;
            camStatus = `
                <div style='border-radius:10px; background-color: rgba(192, 224, 255, 1); 
                display: flex; align-items: center; padding: 4px 14px 4px 14px; width: 100px;
                margin: 3px;'>
                    <div>
                        <img src='assets/img/Icons/ExclamationCircle.svg' style='width: 18px; height: 18px' />
                    </div>
                    <div style='font-size:14px; color: rgba(0, 45, 89, 1); padding-left: 6px; padding-top:2px;'>
                        Off-line
                    </div>
                </div>`;
        } else if (camera['active'] != true) {
            opacity = 0.75;
            camStatus = `
                <div style='border-radius:10px; background-color: rgba(0, 45, 89, 1); 
                display: flex; align-items: center; padding: 4px 14px 4px 14px; width: 118px;
                margin: 3px;'>
                    <div>
                        <img src='assets/img/Icons/blocked.svg' style='width: 18px; height: 18px' />
                    </div>
                    <div style='font-size:14px; color: white; padding-left: 6px; padding-top:2px;'>
                        Bloqueada
                    </div>
                </div>`;
        }

        let hasLive = '';
        // Variável para evitar que abra o modal de live da cam. offline
        let hasThumb = 'offline';
        // Apenas as câmeras com status 'online' vão poder abrir a live
        if (camera['active'] == true && camera['status'] == true) {
            hasThumb = 'thumbnail';
            hasLive = `
            <div style='display: flex; justify-content: center; width: 100%; margin-top: 6px'>
            Clique para ver ao vivo
            </div>
            `;
        }
        // Câmeras que possuem grupo
        let groupsHtml = '';
        if (camera['groups'] && camera['groups'].length > 0) {
            const displayedGroups = camera['groups'].slice(0, 3);
            const remainingCount = camera['groups'].length - 3;
            let displayMoreGroups = [];

            if (remainingCount > 0) {
                displayMoreGroups = camera['groups'].slice(3);
            }
            groupsHtml = `
            <div style='margin-top:8px;'> 
                <span style='font-size:12px; color:#002D59;margin-left: 3px'> Grupos: <br /> </span>
                <div style='display: flex; flex-wrap:wrap; margin-top:4px;'>
                    ${displayedGroups.map(group => {
        if (group.is_public) {
            return `
                <div class='row align-items-start' 
                style='border-radius:10px; background-color: rgba(48, 110, 191, 1);
                padding: 8px; 
                margin: 3px;'>
                    <div style='padding:0px; width: 20px;'>
                        <img src='assets/img/Icons/Global.svg' style='width: 16px; height: 16px;' />
                    </div>
                    <div style='font-size:12px; color: white; margin-left: 6px; flex:1; padding: 0px'>
                        ${group.name}
                    </div>
        </div>`;
        } else {
            return `
                <div class='row align-items-start' 
                style='border-radius:10px; background-color: rgba(108, 172, 255, 1); 
                padding: 8px;
                margin: 3px;'>
                    <div style='padding:0px; width: 20px;'>
                        <img src='assets/img/Uservector.svg' style='width: 16px; height: 16px' />
                    </div>
                    <div style='font-size:12px; color: white; margin-left: 6px; flex:1; padding: 0px'>
                        ${group.name}
                    </div>
                    </div>`;
        }
    }).join('')}
                    ${remainingCount > 0 ? `
                    <div style='display: flex; align-items: center; flex: 1; margin-left: 2px; 
                    margin-top:5px; position: relative;'>
                        <div style='font-size:14px; color: rgba(48, 110, 191, 1);'>
                            +${remainingCount}
                        </div>
                            <span class='showGroup toggleIcon' 
                            style='font-size: 14px; color: rgba(48, 110, 191, 1); margin-left: 4px; cursor: pointer;'> 
                                v 
                            </span>
                        <div class='moreGroups' 
                        style='position: absolute; 
                        top: 27px; border-radius: 8px;
                        padding: 6px; width: 240px;
                        display: none; flex-wrap:wrap;
                        background-color: white; box-shadow: 0 3px 14px #0006;
                        '>
                    ${displayMoreGroups.map(group => {
        if (group.is_public) {
            return `
                        <div class='row align-items-start' 
                        style='border-radius:10px; background-color: rgba(48, 110, 191, 1);
                        padding: 8px; 
                        margin: 3px;'>
                            <div style='padding:0px; width: 20px;'>
                                <img src='assets/img/Icons/Global.svg' style='width: 16px; height: 16px;' />
                            </div>
                            <div style='font-size:12px; color: white; margin-left: 6px; flex:1; padding: 0px'>
                                ${group.name}
                            </div>
                        </div>`;
        } else {
            return `
                    <div class='row align-items-start' 
                    style='border-radius:10px; background-color: rgba(108, 172, 255, 1); 
                    padding: 8px;
                    margin: 3px;'>
                        <div style='padding:0px; width: 20px;'>
                            <img src='assets/img/Uservector.svg' style='width: 16px; height: 16px' />
                        </div>
                        <div style='font-size:12px; color: white; margin-left: 6px; flex:1; padding: 0px'>
                            ${group.name}
                        </div>
        </div>`;
        }
    }).join('')}
                </div>
                    </div>` : ''}
                </div>                
            </div>
            `;
        }

        const modal =
            `<div style='width: 221px'; font: Open Sans;>
            <div style=border-radius: 10px; width:216px; height:120px'>
                <img id='thumbnail' class=${hasThumb} 
                style='border-radius: 10px; width:216px; 
                height:120px; opacity: ${opacity}; cursor: pointer;'
                src="${camera.status == true ? camera.thumbnail : '/assets/img/new_broken_link.png'}">
                ${hasLive}
            </div>
            <div style='display: flex; align-items: start;
            color: #002D59; font-size: 16px; margin-top: 8px;'>
                <img src='assets/img/Icons/VideoCamera.svg'>
                <div>
                    <div style='font-weight: bold; 
                    max-height: 40px;
                    max-width: 190px;
                    margin-left: 6px;
                    display:-webkit-box;
                    font-size:16px;
                    overflow: hidden;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    '>
                        ${camera.alias} 
                    </div>
                    <div style='color:#306EBF; font-size:11px; margin-left: 7px;'> 
                        #${camera.id_camera} 
                    </div>
                </div>
            </div>
            ${camStatus}
            <div style='display: flex; align-items: center; margin-top: 8px'>
                <div>
                    <img src='assets/img/Icons/User.svg'>
                </div>
                <div style='font-size: 16px; padding-left:4px;'> 
                    ${camera['owner_name']} 
                </div>
            </div>
            ${groupsHtml}
            <div style='color: #002D59; font-size: 12px; margin-top: 8px;'> 
                <span style='font-weight: bold; '> CÂMERA </span>
                ${camera.plan}   
            </div>
            
            <div style='max-width:214px; margin-top: 8px;'> ${camera['address']} </div>
        </div>`;

        this.markers.addLayer(L.marker([camera.lat, camera.lng], { icon: mapIcon }).bindPopup(modal));
        this.map.addLayer(this.markers);
    }

}
