import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, AfterContentInit, OnChanges } from '@angular/core';
import { TypeAnalytic, ScheduleAnalytic, Analytics, DrawType, TypeClass, GroupAnalytics, Loitering, WeekList, ScheduleHours } from '../models';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AnalyticActions } from '../Services/actions';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/model';
import { Subscription, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { NzMarks } from 'ng-zorro-antd/slider';
import { SharedService } from 'app/Shared/Services/shared.service';

declare const $: any;

@Component({
    selector: 'app-configure-analytic',
    templateUrl: './configure-analytic.component.html',
    styleUrls: ['./configure-analytic.component.scss', '../styles.scss']
})
export class ConfigureAnalyticComponent implements OnInit, OnDestroy, AfterContentInit, OnChanges {
    readonly classTypeAnalytic$ = this.store.select((state: AppState) => state.analytic.class_type_analytic);
    //Novo analitico
    newAnalytic: Analytics = {};
    @Input() isHolidayActive: boolean;
    //Opções de analítico de determinado grupo
    @Input() optionsAnalytics: TypeAnalytic[];
    //Analítico a ser editado
    @Input() analytic: Analytics;
    //Evento de reset
    @Input() resetAnalytic: Observable<any>;
    //Grupos de analíticos
    @Input() groupAnalytic: GroupAnalytics[];

    @Input({required: true}) cameraData: {
        id: number;
        name: string;
    };

    @Output() analyticDraw: EventEmitter<DrawType> = new EventEmitter<DrawType>();
    //Analítico que foi escolhido:
    @Output() chosenAnalytic: EventEmitter<Analytics> = new EventEmitter<Analytics>();

    // Envia a informação de formulário completo para o config
    @Output() setFullForm = new EventEmitter<boolean>();
    @Output() cancelEmit = new EventEmitter<void>();
    @Output() saveEmit = new EventEmitter<void>();

    destroy$ = new Subject<void>();

    // Classificadores selecionados
    upClassifiers: String[] = [];

    //Hora de início e término do agendamento
    timeBegin = new UntypedFormControl('00:00:00', [Validators.required, Validators.pattern(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/)]);
    timeEnd = new UntypedFormControl('00:00:00', [Validators.required, Validators.pattern(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/)]);
    //Seleção dos classificadores e de tipo de analítico
    selectClassifiers = new UntypedFormControl('', [Validators.required]);
    selectAnalytics = new UntypedFormControl('', [Validators.required]);
    //Selecionar agendamento 24/7
    selectAllDays = new UntypedFormControl('', [Validators.required]);
    //Opções de aglomeração
    crowdingMaxPeople = new UntypedFormControl('', [Validators.required, Validators.min(1)]);
    crowdingMinPeople = new UntypedFormControl('', [Validators.required, Validators.min(1)]);
    toleranceTimeMin = new UntypedFormControl('', [Validators.required, Validators.pattern(/^[0-5]?[0-9]$/)]);
    toleranceTimeSec = new UntypedFormControl('', [Validators.required, Validators.pattern(/^[0-5]?[0-9]$/)]);
    crowdingMaxTimeMin = new UntypedFormControl('', [Validators.required, Validators.pattern(/^[0-5]?[0-9]$/)]);
    crowdingMaxTimeSec = new UntypedFormControl('', [Validators.required, Validators.pattern(/^[0-5]?[0-9]$/)]);
    //Opções de vadiagem
    loiteringControl = new UntypedFormControl('', [Validators.required]);
    loiterings: Loitering[] = [
        {value: 'moving', viewValue: 'Movimento'},
        {value: 'guard', viewValue: 'Parado'}
    ];
    // Analítico de teste
    testOption = new UntypedFormControl('', [Validators.required]);
    client_sub: string;

    //Opção com/sem máscara
    checkOption = new UntypedFormControl('', [Validators.required]);
    pageName = 'configure-analytic';
    selectedDays = new UntypedFormControl('', [Validators.required]);
    //Máscara de hora
    public hourMask = [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];
    //Máscara de minutos/segundos
    public crowdingMask = [/[0-9]/, /[0-9]/];
    //Variáveis de controle do agendamento
    weekList: WeekList[];
    scheduleList: ScheduleAnalytic[] = [];
    subAnalyticType: Subscription;
    selectedComercial = false;
    selectedNight = false;
    selectedOthers = false;
    //Loader dos classificadores
    loadingClass = true;
    disableOptionsClass = false;
    //Tamanho do componente de agendamento
    sizeWindowSchedule: string;
    subCrowdingMaxTimeMin: Subscription;
    subCrowdingMaxTimeSec: Subscription;
    subtoleranceTimeMin: Subscription;
    subtoleranceTimeSec: Subscription;
    getClassAnalytic: Subscription;
    getLastAnalyticSchedule: Subscription;
    subSelectAllDays: Subscription;
    editSub: Subscription;
    resetSub: Subscription;
    //Classificadores
    optionsClassifiers: TypeClass[] = [];
    //Tabela de agendamento
    tableHeaders = ['day', 'receiveAlert'];
    tableRows: MatTableDataSource<WeekList> = new MatTableDataSource([] as WeekList[]);
    idInterim = 0;
    seeClassificationCard = false;
    hideButton: boolean;
    classicationsList = [];
    probabilityOfAnalytic = [
        'Movimento', 'Cruzamento de Linha', 'Aglomeração', 'Ausência de Movimento', 'Reconhecimento de Placa Veicular',
        'Abandono de Posto', 'Detecção de Máscara', 'Detecção de Emboscada', 'Vadiagem', 'Assalto com Moto',
        'Abuso de Permanência', 'Dispersão', 'Aglomeração e dispersão', 'Detecção de Queda', 'Detecção de Arma',
        'Detecção de Violência', 'Tampering', 'Detecção de Capacete'
    ];
    idSchedule = 0;
    loadingLastSchedules = false;
    selectedEventsAnalytics = null;
    selectedTypeAnalytic = null;
    typeListSimple: {
        id: string;
        text: string;
    }[];
    dissimilarity = 0;
    marks: NzMarks = {
        0: {
            style: {
                color: '#002D59'
            },
            label: '<span style="left: 1%; position: relative;">0%</span>'
        },
        100: {
            style: {
                color: '#002D59'
            },
            label: '<span style="left: 95%; position: relative;">100%</span>'
        }
    };

    criticalityOptions = [
        {
            id: 1,
            text: 'Baixa',
        },
        {
            id: 2,
            text: 'Média',
        },
        {
            id: 3,
            text: 'Alta',
        }
    ];

    criticalitySelected: number;

    constructor(private validationResponse: ValidationResponseHandlerModule, private readonly store: Store<AppState>, private service: SharedService) { }

    ngOnInit(): void {
        if (this.analytic) {
            this.scheduleList = this.analytic.schedules;
            this.selectedTypeAnalytic = this.analytic.alias;
            this.criticalitySelected = this.analytic.criticality;
        }
        this.sizeWindowSchedule = '260px';
        this.getClassAnalyticType();
        this.monitoringAnalyticType();
        this.monitoringSelectAllDays();
        this.resetSub = this.resetAnalytic.subscribe(() => {
            this.resetInfo();
        });
        this.clearScheduleTable();
        this.hideButton = false;
        this.client_sub = localStorage.getItem('clientView');
        this.typeListSimple = this.optionsAnalytics.map(a => {
            return {
                id: a.alias,
                text: a.alias
            };
        });

        this.selectedRadioButton();
    }

    parseCurrentSchedule() {
        if (this.scheduleList.length > 0) {
            let value;
            const checkWeekend = this.scheduleList[0].saturday || this.scheduleList[0].sunday;
            const checkWorkday = this.scheduleList[0].monday && this.scheduleList[0].thursday && this.scheduleList[0] &&
        this.scheduleList[0].wednesday && this.scheduleList[0].tuesday && this.scheduleList[0].friday;
            if (!checkWeekend && checkWorkday) {
                if (this.scheduleList[0].start_time == '08:00:00' && this.scheduleList[0].end_time == '18:00:00') {
                    value = 1;
                }
                if (this.scheduleList[0].start_time == '18:00:00' && this.scheduleList[0].end_time == '23:59:59') {
                    value = 2;
                }
            }
            else {
                value = 3;
            }
            switch (value) {
                case 1:
                    this.selectedComercial = true;
                    break;
                case 2:
                    this.selectedNight = true;
                    break;
                case 3:
                    this.selectedOthers = true;
                    break;
            }

            this.getTimeSchedule(value);
        }
    }

    ngOnChanges(changes: any) {
        this.validateFormFullInformation(changes);
    }

    validateFormFullInformation(event = null) {
        // Movimento
        if (this.selectAnalytics.value['alias'] == this.probabilityOfAnalytic[0]) {
            if (this.selectClassifiers.value != '') {
                if (this.selectedDays.value != false && this.selectedDays.value != '' || this.selectAllDays.value != false && this.selectAllDays.value != '') {
                    if (this.timeBegin.valid && this.timeEnd.valid || this.selectAllDays.valid) {
                        if (this.scheduleList.length > 0) {
                            if (this.criticalitySelected != undefined) {
                                this.setFullForm.emit(false);
                            }
                        }
                    }
                }
            }
        }
        // Cruzamento de Linha
        else if (this.selectAnalytics.value['alias'] == this.probabilityOfAnalytic[1]) {
            if (this.selectClassifiers.value != '') {
                if (this.newAnalytic.events != undefined && this.newAnalytic.events.length > 0) {
                    if (this.selectedDays.value != false && this.selectedDays.value != '' || this.selectAllDays.value != false && this.selectAllDays.value != '') {
                        if (this.timeBegin.valid && this.timeEnd.valid || this.selectAllDays.valid) {
                            if (this.scheduleList.length > 0) {
                                if (this.criticalitySelected != undefined) {
                                    this.setFullForm.emit(false);
                                }
                            }
                        }
                    }
                }
            }
        }
        // Aglomeração
        else if (this.selectAnalytics.value['alias'] == this.probabilityOfAnalytic[2]) {
            if (this.selectClassifiers.value != '') {
                if (this.crowdingMaxPeople.valid) {
                    if (this.crowdingMaxTimeMin.valid && this.crowdingMaxTimeSec.valid) {
                        if (this.selectedDays.value != false && this.selectedDays.value != '' || this.selectAllDays.value != false && this.selectAllDays.value != '') {
                            if (this.timeBegin.valid && this.timeEnd.valid || this.selectAllDays.valid) {
                                if (this.scheduleList.length > 0) {
                                    if (this.criticalitySelected != undefined) {
                                        this.setFullForm.emit(false);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        // Ausência de Movimento e Abandono de Posto e Tampering
        else if (this.selectAnalytics.value['alias'] == this.probabilityOfAnalytic[3] || this.selectAnalytics.value['alias'] == this.probabilityOfAnalytic[5] ||
        this.selectAnalytics.value['alias'] == this.probabilityOfAnalytic[16]) {
            if (this.crowdingMaxTimeMin.valid && this.crowdingMaxTimeSec.valid) {
                if (this.selectedDays.value != false && this.selectedDays.value != '' || this.selectAllDays.value != false && this.selectAllDays.value != '') {
                    if (this.timeBegin.valid && this.timeEnd.valid || this.selectAllDays.valid) {
                        if (this.scheduleList.length > 0) {
                            if (this.criticalitySelected != undefined) {
                                this.setFullForm.emit(false);
                            }
                        }
                    }
                }
            }
        }
        // Reconhecimento Veicular
        else if (this.selectAnalytics.value['alias'] == this.probabilityOfAnalytic[4]) {
            if (this.selectedDays.value != false && this.selectedDays.value != '' || this.selectAllDays.value != false && this.selectAllDays.value != '') {
                if (this.timeBegin.valid && this.timeEnd.valid || this.selectAllDays.valid) {
                    if (this.scheduleList.length > 0) {
                        if (this.criticalitySelected != undefined) {
                            this.setFullForm.emit(false);
                        }
                    }
                }
            }
        }
        // Detecção de Máscara
        else if ([this.probabilityOfAnalytic[6], this.probabilityOfAnalytic[17]].includes(this.selectAnalytics.value['alias'])) {
            if (this.checkOption.valid) {
                if (this.selectedDays.value != false && this.selectedDays.value != '' || this.selectAllDays.value != false && this.selectAllDays.value != '') {
                    if (this.timeBegin.valid && this.timeEnd.valid || this.selectAllDays.valid) {
                        if (this.scheduleList.length > 0) {
                            if (this.criticalitySelected != undefined) {
                                this.setFullForm.emit(false);
                            }
                        }
                    }
                }
            }
        }
        // Detecção de Emboscada
        else if (this.selectAnalytics.value['alias'] == this.probabilityOfAnalytic[7]) {
            if (this.crowdingMaxTimeMin.valid) {
                if (this.toleranceTimeMin.valid) {
                    if (this.selectedDays.value != false && this.selectedDays.value != '' || this.selectAllDays.value != false && this.selectAllDays.value != '') {
                        if (this.timeBegin.valid && this.timeEnd.valid || this.selectAllDays.valid) {
                            if (this.scheduleList.length > 0) {
                                if (this.criticalitySelected != undefined) {
                                    this.setFullForm.emit(false);
                                }
                            }
                        }
                    }
                }
            }
        }
        // Vadiagem
        else if (this.selectAnalytics.value['alias'] == this.probabilityOfAnalytic[8]) {
            if (this.crowdingMaxTimeMin.valid && this.crowdingMaxTimeSec.valid) {
                if (this.loiteringControl.valid) {
                    if (this.selectedDays.value != false && this.selectedDays.value != '' || this.selectAllDays.value != false && this.selectAllDays.value != '') {
                        if (this.timeBegin.valid && this.timeEnd.valid || this.selectAllDays.valid) {
                            if (this.scheduleList.length > 0) {
                                if (this.criticalitySelected != undefined) {
                                    this.setFullForm.emit(false);
                                }
                            }
                        }
                    }
                }
            }
        }
        // Assalto com Moto e Detecção de Queda/Arma/Violência
        else if (this.selectAnalytics.value['alias'] == this.probabilityOfAnalytic[9] || this.selectAnalytics.value['alias'] == this.probabilityOfAnalytic[13] ||
        this.selectAnalytics.value['alias'] == this.probabilityOfAnalytic[14] || this.selectAnalytics.value['alias'] == this.probabilityOfAnalytic[15]) {
            if (this.selectedDays.value != false && this.selectedDays.value != '' || this.selectAllDays.value != false && this.selectAllDays.value != '') {
                if (this.timeBegin.valid && this.timeEnd.valid || this.selectAllDays.valid) {
                    if (this.scheduleList.length > 0) {
                        if (this.criticalitySelected != undefined) {
                            this.setFullForm.emit(false);
                        }
                    }
                }
            }
        }
        // Abuso de Permanência
        else if (this.selectAnalytics.value['alias'] == this.probabilityOfAnalytic[10]) {
            if (this.selectClassifiers.value != '') {
                if (this.crowdingMaxTimeMin.valid && this.crowdingMaxTimeSec.valid) {
                    if (this.selectedDays.value != false && this.selectedDays.value != '' || this.selectAllDays.value != false && this.selectAllDays.value != '') {
                        if (this.timeBegin.valid && this.timeEnd.valid || this.selectAllDays.valid) {
                            if (this.scheduleList.length > 0) {
                                if (this.criticalitySelected != undefined) {
                                    this.setFullForm.emit(false);
                                }
                            }
                        }
                    }
                }
            }
        }
        // Dispersão
        else if (this.selectAnalytics.value['alias'] == this.probabilityOfAnalytic[11]) {
            if (this.selectClassifiers.value != '') {
                if (this.crowdingMinPeople.valid) {
                    if (this.crowdingMaxTimeMin.valid && this.crowdingMaxTimeSec.valid) {
                        if (this.selectedDays.value != false && this.selectedDays.value != '' || this.selectAllDays.value != false && this.selectAllDays.value != '') {
                            if (this.timeBegin.valid && this.timeEnd.valid || this.selectAllDays.valid) {
                                if (this.scheduleList.length > 0) {
                                    if (this.criticalitySelected != undefined) {
                                        this.setFullForm.emit(false);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        // Aglomeração e dispersão
        else if (this.selectAnalytics.value['alias'] == this.probabilityOfAnalytic[12]) {
            if (this.selectClassifiers.value != '') {
                if (this.crowdingMinPeople.valid && this.crowdingMaxPeople.valid) {
                    if (this.crowdingMaxTimeMin.valid && this.crowdingMaxTimeSec.valid) {
                        if (this.selectedDays.value != false && this.selectedDays.value != '' || this.selectAllDays.value != false && this.selectAllDays.value != '') {
                            if (this.timeBegin.valid && this.timeEnd.valid || this.selectAllDays.valid) {
                                if (this.scheduleList.length > 0) {
                                    if (this.criticalitySelected != undefined) {
                                        this.setFullForm.emit(false);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    ngAfterContentInit() {
        if (this.analytic) {
            this.addAnalyticInfo();
        }
    }

    ngOnDestroy() {
        if (this.subAnalyticType) {
            this.subAnalyticType.unsubscribe();
        }
        if (this.subCrowdingMaxTimeMin && this.subCrowdingMaxTimeSec) {
            this.subCrowdingMaxTimeSec.unsubscribe();
            this.subCrowdingMaxTimeMin.unsubscribe();
        }
        if (this.subtoleranceTimeMin && this.subtoleranceTimeSec) {
            this.subtoleranceTimeMin.unsubscribe();
            this.subtoleranceTimeSec.unsubscribe();
        }
        if (this.getClassAnalytic) {
            this.getClassAnalytic.unsubscribe();
        }
        if (this.subSelectAllDays) {
            this.subSelectAllDays.unsubscribe();
        }
        if (this.editSub) {
            this.editSub.unsubscribe();
        }
        if (this.resetSub) {
            this.resetSub.unsubscribe();
        }
        if (this.getLastAnalyticSchedule) {
            this.getLastAnalyticSchedule.unsubscribe();
        }
        this.destroy$.next();
        this.destroy$.complete();
        this.store.dispatch(AnalyticActions.cleanup_classes_analytics());
    }

    //Captura modificação no tipo de analítico
    monitoringAnalyticType() {
        this.subAnalyticType = this.selectAnalytics.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
            .subscribe(monitoring => {
                if (monitoring) {
                    const index = this.optionsAnalytics.findIndex(element => element.alias === monitoring.alias);
                    this.newAnalytic.id_analytic_type = this.optionsAnalytics[index].id_analytic_type;
                    this.analyticDraw.emit(this.optionsAnalytics[index].draw_type);
                    this.newAnalytic.alias = this.optionsAnalytics[index].alias;
                    // this.newAnalytic.events = monitoring.events.map(a => { return a.value });
                    this.loadingClass = true;
                    this.disableOptionsClass = true;
                    this.store.dispatch(AnalyticActions.get_analytics_class({ idAnalyticType: this.newAnalytic.id_analytic_type }));
                    this.onChangeAnalyticType();
                    this.chosenAnalytic.emit(this.newAnalytic);
                    this.parseCurrentSchedule();
                }
            });
    }

    getSchedule() {
        this.hideButton = true;
        this.scheduleList = [];
        this.clearScheduleTable();
        this.loadingLastSchedules = true;
        const editLastSchadule = true;
        this.analytic.schedules.forEach(element => {
            this.scheduleList.push(element);
            this.addFormlastSchedule(element);
            this.addSchedule(editLastSchadule);
        });
    }

    clearScheduleTable() {
        this.weekList = [{ day: 'SEG', active: false, schedules: [] },
            { day: 'TER', active: false, schedules: [] },
            { day: 'QUA', active: false, schedules: [] },
            { day: 'QUI', active: false, schedules: [] },
            { day: 'SEX', active: false, schedules: [] },
            { day: 'SÁB', active: false, schedules: [] },
            { day: 'DOM', active: false, schedules: [] }];
        this.tableRows.data = null;
        this.tableRows.data = this.weekList;
    }

    addFormlastSchedule(element: ScheduleAnalytic) {
        if (this.scheduleList) {
            this.timeBegin.setValue(element.start_time);
            this.timeEnd.setValue(element.end_time);
            const schedulesDays = [element.monday, element.tuesday, element.wednesday,
                element.thursday, element.friday, element.saturday, element.sunday];
            this.weekList.forEach((w, i, a) => {
                w.active = schedulesDays[i];
            });
            this.loadingLastSchedules = false;
        } else {
            this.loadingLastSchedules = false;
        }
        this.loadingLastSchedules = false;
    }

    //Altera alguns elementos visuais da página em caso de mudança de tipo de analítico
    onChangeAnalyticType() {
        if (['Ausência de Movimento', 'Abandono de Posto', 'Dispersão', 'Aglomeração e dispersão',
            'Aglomeração', 'Abuso de Permanência'].includes(this.newAnalytic.alias)) {
            this.sizeWindowSchedule = '173px';
            this.changeValueCrowdingTimeMin();
            this.changeValueCrowdingTimeSec();
        } else if ((['Detecção de Emboscada'].includes(this.newAnalytic.alias))) {
            this.sizeWindowSchedule = '173px';
            this.changeValueToleranceTimeMin();
            this.changeValueToleranceTimeSec();
        }
        else {
            this.sizeWindowSchedule = '260px';
        }
        //Caso seja analítico de capacete
        if (['Detecção de Máscara', 'Detecção de Capacete'].includes(this.newAnalytic.alias) && !this.analytic) {
            this.checkOption.setValue(true);
        }
    }

    getContainerWidth(): string {
        return this.newAnalytic.alias === 'Reconhecimento de Placa Veicular' ? '100' : '42';
    }

    selectAnalytic(event: any, edit = false): void {
        this.selectedEventsAnalytics = edit ? undefined : this.selectedEventsAnalytics;
        const analytic = this.optionsAnalytics.find(a => a.alias == event[0] || a.id_analytic_type == event[0]);
        this.selectAnalytics.setValue(analytic);
        if (analytic.alias == this.probabilityOfAnalytic[6]) {
            const withoutMask = this.analytic ? JSON.parse(this.analytic.metadata).extra_info.withoutmask : ['notmask'];
            this.newAnalytic.events = withoutMask;
        } else if (analytic.alias == this.probabilityOfAnalytic[17]) {
            const eventsHelmet = this.analytic ? this.analytic.events : ['nothelmet'];
            this.newAnalytic.events = eventsHelmet;
        } else if (analytic.alias == this.probabilityOfAnalytic[11]) {
            this.newAnalytic.events = ['dispersePolygon'];
        } else if (analytic.alias == this.probabilityOfAnalytic[2]) {
            this.newAnalytic.events = ['crowdingPolygon'];
        } else {
            if (analytic.alias == this.probabilityOfAnalytic[16]) {
                const tamperingEvent = this.selectAnalytics.value.events.find(e => e.value == 'tamperingdetection');
                this.selectAnalyticEvents([tamperingEvent.id]);
                if (!this.selectedEventsAnalytics) {
                    this.selectedEventsAnalytics = tamperingEvent.id;
                }
            } else {
                const hasAllOptions = this.selectAnalytics.value.events.find(x => x.id == -1);
                if (!hasAllOptions) {
                    this.selectAnalytics.value.events.push({
                        id: -1,
                        value: '',
                        text: 'Todas as opções'
                    });
                }
                this.selectAnalyticEvents([-1]);
            }
        }
    }

    selectAnalyticEvents(event: any): void {
        if (event[0] == -1) {
            this.newAnalytic.events = this.selectAnalytics.value.events.map(a => { return a.value; });
        } else {
            this.newAnalytic.events = [this.selectAnalytics.value.events.find(a => a.id == event[0]).value];
        }
        this.validateFormFullInformation();
    }

    showClasses(): boolean {
        return !['Assalto com Moto', 'Vadiagem', 'Detecção de Emboscada', 'Detecção de Queda', 'Detecção de Capacete',
            'Detecção de Máscara', 'Reconhecimento de Placa Veicular', 'Detecção de Arma', 'Detecção de Violência',
            'Abandono de Posto', 'Ausência de Movimento', 'Tampering', ''].includes(this.newAnalytic.alias);
    }

    extraInformation(): boolean {
        return ['Ausência de Movimento', 'Abandono de Posto',  'Cruzamento de Linha',
            'Aglomeração', 'Dispersão', 'Aglomeração e dispersão', 'Tampering',
            'Abuso de Permanência', 'Vadiagem', 'Detecção de Emboscada'].includes(this.newAnalytic.alias);
    }

    //Caso usuário seleciona agendamento 24x7
    monitoringSelectAllDays() {
        this.subSelectAllDays = this.selectAllDays.valueChanges.subscribe(monitoring => {
            if (monitoring) {
                if (this.weekList.length) {
                    for (let i = 0; i < this.weekList.length; i++) {
                        this.weekList[i].active = true;
                    }
                    this.timeBegin.setValue('00:00:00');
                    this.timeEnd.setValue('23:59:59');
                }
            }
            else {
                if (this.weekList.length) {
                    for (let i = 0; i < this.weekList.length; i++) {
                        this.weekList[i].active = false;
                    }
                    this.timeBegin.setValue('00:00:00');
                    this.timeEnd.setValue('00:00:00');
                }
            }
        });
    }

    //Ajusta valor da hora
    changeValueCrowdingTimeMin() {
        this.subCrowdingMaxTimeMin = this.crowdingMaxTimeMin.valueChanges.subscribe(element => {
            if (element > 59) {
                this.crowdingMaxTimeMin.setValue(59);
            }
            if (element < 0) {
                this.crowdingMaxTimeMin.setValue(0);
            }
        });
    }

    //Ajusta valor do segundo
    changeValueCrowdingTimeSec() {
        this.subCrowdingMaxTimeSec = this.crowdingMaxTimeSec.valueChanges.subscribe(element => {
            if (element > 59) {
                this.crowdingMaxTimeSec.setValue(59);
            }
            if (element < 0) {
                this.crowdingMaxTimeSec.setValue(0);
            }
        });
    }

    changeValueToleranceTimeMin() {
        this.subtoleranceTimeMin = this.toleranceTimeMin.valueChanges.subscribe(element => {
            if (element > 59) {
                this.toleranceTimeMin.setValue(59);
            }
            if (element < 0) {
                this.toleranceTimeMin.setValue(0);
            }
        });
    }

    changeValueToleranceTimeSec() {
        this.subtoleranceTimeSec = this.toleranceTimeSec.valueChanges.subscribe(element => {
            if (element > 59) {
                this.toleranceTimeSec.setValue(59);
            }
            if (element < 0) {
                this.toleranceTimeSec.setValue(0);
            }
        });
    }

    //Recebe classificadores de acordo com o tipo de analítico
    getClassAnalyticType() {
        this.loadingClass = true;
        this.getClassAnalytic = this.classTypeAnalytic$.subscribe(classifiers => {
            if (classifiers) {
                this.disableOptionsClass = classifiers.length > 0 ? false : true;
                this.optionsClassifiers = classifiers;
                if (this.analytic && this.newAnalytic.classifier) {
                    this.upClassifiers = this.newAnalytic.classifier.map(classifier => classifier.alias);
                    this.classicationsList = this.newAnalytic.classifier.map(classifier => classifier);
                    if (this.upClassifiers) {
                        this.selectClassifiers.setValue(this.classicationsList);
                    }

                    this.disableOptionsClass = false;
                }
                this.loadingClass = false;
            }
            else {
                this.optionsClassifiers = [];
            }
        });
    }

    changeCheckHtml(day: string) {
        const index = this.weekList.findIndex(selectDay => selectDay.day === day);
        if (index != -1) {
            this.weekList[index].active = !this.weekList[index].active;
        }
    }

    changeCheckAllDaysHtml() {
        if (this.selectAllDays.value) {
            for (let i = 0; i < this.weekList.length; i++) {
                this.weekList[i].active = true;
            }
        }
        else {
            for (let i = 0; i < this.weekList.length; i++) {
                this.weekList[i].active = false;
            }
        }
    }

    changeColorHtml(dayActive: boolean) {
        return dayActive ? '#002D59' : '#326ABC';
    }

    changeWeightHtml(dayActive: boolean) {
        return dayActive ? 600 : 300;
    }

    eventsAnalyticSelect() {
        this.newAnalytic.events = this.analytic.events;
        const selectedEvents = [];
        this.selectAnalytics.value.events.forEach(a => {
            if (this.newAnalytic.events.includes(a.value)) {
                selectedEvents.push(a);
            }
        });

        if (selectedEvents.length > 1) {
            this.selectedEventsAnalytics = -1;
        } else {
            this.selectedEventsAnalytics = selectedEvents[0].id;
        }
    }

    addAnalyticInfo() {
        this.selectAnalytic([this.analytic.alias], true);
        this.eventsAnalyticSelect();
        const index = this.groupAnalytic.findIndex(result => result.id === this.analytic.id_group);
        if (['Ausência de Movimento', 'Abandono de Posto', 'Aglomeração', 'Dispersão', 'Aglomeração e dispersão',
            'Abuso de Permanência', 'Tampering'].includes(this.analytic.alias)) {
            let maxTime = 0;
            if (this.analytic.alias == this.probabilityOfAnalytic[16]) {
                this.dissimilarity = JSON.parse(this.analytic.metadata).extra_info.dissimilarity * 100;
                maxTime = JSON.parse(this.analytic.metadata).extra_info.tampering_duration;
            } else {
                maxTime = JSON.parse(this.analytic.metadata).extra_info.maxTime;
            }
            let maxTimeMin = String(Math.floor(maxTime / 60));
            maxTimeMin = maxTimeMin.length == 1 ? '0' + maxTimeMin : maxTimeMin;
            let maxTimeSec = String(Math.floor(maxTime % 60));
            maxTimeSec = maxTimeSec.length == 1 ? '0' + maxTimeSec : maxTimeSec;
            this.crowdingMaxTimeMin.setValue(maxTimeMin);
            this.crowdingMaxTimeSec.setValue(maxTimeSec);
            this.crowdingMaxPeople.setValue(Math.floor(JSON.parse(this.analytic.metadata).extra_info.maxPeople));
            this.crowdingMinPeople.setValue(Math.floor(JSON.parse(this.analytic.metadata).extra_info.minPeople));
        }
        if (this.analytic.alias == 'Detecção de Emboscada') {
            const perman = JSON.parse(this.analytic.metadata).extra_info.permanceTime;
            let permanMin = String(perman);
            permanMin = permanMin.length == 1 ? '0' + permanMin : permanMin;
            let permanSec = String(Math.floor(perman % 60));
            permanSec = permanSec.length == 1 ? '0' + permanSec : permanSec;
            const tolera = JSON.parse(this.analytic.metadata).extra_info.toleranceTime;
            let toleraMin = String(tolera);
            toleraMin = toleraMin.length == 1 ? '0' + toleraMin : toleraMin;
            let toleraSec = String(Math.floor(tolera % 60));
            toleraSec = toleraSec.length == 1 ? '0' + toleraSec : toleraSec;
            this.crowdingMaxTimeMin.setValue(permanMin);
            this.crowdingMaxTimeSec.setValue('00');
            this.toleranceTimeMin.setValue(toleraMin);
            this.toleranceTimeSec.setValue('00');
        }
        if (this.analytic.alias == 'Vadiagem') {
            const perman = JSON.parse(this.analytic.metadata).extra_info.permanceTime;
            let loit = JSON.parse(this.analytic.metadata).extra_info.type;
            this.loiterings.forEach((e, i, a) => {
                if (e.value == loit) {
                    loit = e;
                }
            });
            let permanMin = String(Math.floor(perman / 60));
            permanMin = permanMin.length == 1 ? '0' + permanMin : permanMin;
            let permanSec = String(Math.floor(perman % 60));
            permanSec = permanSec.length == 1 ? '0' + permanSec : permanSec;
            this.crowdingMaxTimeMin.setValue(permanMin);
            this.crowdingMaxTimeSec.setValue(permanSec);
            this.loiteringControl.setValue(loit);
        }
        if (this.analytic.alias == 'Detecção de Máscara') {
            const withoutMask = JSON.parse(this.analytic.metadata).extra_info.withoutmask;
            this.checkOption.setValue(withoutMask);
        }
        if (this.analytic.alias == 'Detecção de Capacete') {
            this.checkOption.setValue(this.analytic.events.includes('nothelmet'));
        }
        this.newAnalytic.id_analytic_type = this.analytic.id_analytic_type;
        const index2 = this.groupAnalytic[index].analytic_types.findIndex(result => result.alias === this.analytic.alias);
        if (index != -1) {
            this.selectAnalytics.setValue(this.groupAnalytic[index].analytic_types[index2]);
        }
        this.optionsAnalytics = this.optionsAnalytics;
        if (this.analytic.analytic_category == 'default') {
            this.testOption.setValue(1);
        } else if (this.analytic.analytic_category == 'validation') {
            this.testOption.setValue(2);
        }
        this.newAnalytic.classifier = this.analytic.classifier as TypeClass[];
        this.newAnalytic.border_color = this.analytic.border_color;
        this.newAnalytic.blur = this.analytic.blur;
        this.newAnalytic.smaller_object = this.analytic.smaller_object;
        this.newAnalytic.larger_object = this.analytic.larger_object;
        this.newAnalytic.criticality = this.analytic.criticality;
        if (this.analytic == null) {
            this.scheduleList = [];
        }
        this.timeBegin.setValue('00:00:00');
        this.timeEnd.setValue('00:00:00');
        this.weekList = [{ day: 'SEG', active: false, schedules: [] },
            { day: 'TER', active: false, schedules: [] },
            { day: 'QUA', active: false, schedules: [] },
            { day: 'QUI', active: false, schedules: [] },
            { day: 'SEX', active: false, schedules: [] },
            { day: 'SÁB', active: false, schedules: [] },
            { day: 'DOM', active: false, schedules: [] }];
        for (let i = 0; i < this.scheduleList.length; i++) {
            if (this.scheduleList[i].monday) {
                this.weekList[0].schedules.push({
                    id: this.scheduleList[i].id,
                    concatTime: this.scheduleList[i].start_time + ' - ' + this.scheduleList[i].end_time,
                    day: 'SEG',
                    close: false
                });
            }
            if (this.scheduleList[i].tuesday) {
                this.weekList[1].schedules.push({
                    id: this.scheduleList[i].id,
                    concatTime: this.scheduleList[i].start_time + ' - ' + this.scheduleList[i].end_time,
                    day: 'TER',
                    close: false
                });
            }
            if (this.scheduleList[i].wednesday) {
                this.weekList[2].schedules.push({
                    id: this.scheduleList[i].id,
                    concatTime: this.scheduleList[i].start_time + ' - ' + this.scheduleList[i].end_time,
                    day: 'QUA',
                    close: false
                });
            }
            if (this.scheduleList[i].thursday) {
                this.weekList[3].schedules.push({
                    id: this.scheduleList[i].id,
                    concatTime: this.scheduleList[i].start_time + ' - ' + this.scheduleList[i].end_time,
                    day: 'QUI',
                    close: false
                });
            }
            if (this.scheduleList[i].friday) {
                this.weekList[4].schedules.push({
                    id: this.scheduleList[i].id,
                    concatTime: this.scheduleList[i].start_time + ' - ' + this.scheduleList[i].end_time,
                    day: 'SEX',
                    close: false
                });
            }
            if (this.scheduleList[i].saturday) {
                this.weekList[5].schedules.push({
                    id: this.scheduleList[i].id,
                    concatTime: this.scheduleList[i].start_time + ' - ' + this.scheduleList[i].end_time,
                    day: 'SÁB',
                    close: false
                });
            }
            if (this.scheduleList[i].sunday) {
                this.weekList[6].schedules.push({
                    id: this.scheduleList[i].id,
                    concatTime: this.scheduleList[i].start_time + ' - ' + this.scheduleList[i].end_time,
                    day: 'DOM',
                    close: false
                });
            }
        }
        this.tableRows.data = null;
        this.tableRows.data = this.weekList;
    }

    resetInfo() {
        if (this.subCrowdingMaxTimeMin && this.subCrowdingMaxTimeSec) {
            this.subCrowdingMaxTimeMin.unsubscribe();
            this.subCrowdingMaxTimeSec.unsubscribe();
        }
        if (this.subtoleranceTimeMin && this.subtoleranceTimeSec) {
            this.subtoleranceTimeMin.unsubscribe();
            this.subtoleranceTimeSec.unsubscribe();
        }
        this.crowdingMaxPeople.setValue(10);
        this.crowdingMinPeople.setValue(0);
        this.crowdingMaxTimeMin.setValue(0);
        this.crowdingMaxTimeSec.setValue(1);
        this.toleranceTimeMin.setValue(0);
        this.toleranceTimeSec.setValue(1);
        this.sizeWindowSchedule = '260px';
        this.analytic = null;
        this.selectAnalytics.reset();
        this.selectClassifiers.reset();
        this.loadingClass = false;
        this.disableOptionsClass = true;
        this.weekList = [{ day: 'SEG', active: false, schedules: [] },
            { day: 'TER', active: false, schedules: [] },
            { day: 'QUA', active: false, schedules: [] },
            { day: 'QUI', active: false, schedules: [] },
            { day: 'SEX', active: false, schedules: [] },
            { day: 'SÁB', active: false, schedules: [] },
            { day: 'DOM', active: false, schedules: [] }];
        this.tableRows.data = this.weekList;
        this.scheduleList = [];
        this.selectAllDays.setValue(false);
        this.timeBegin.setValue('00:00:00');
        this.timeEnd.setValue('00:00:00');
        if (this.newAnalytic.classifier) {
            this.newAnalytic.classifier = [];
        }
        if (this.newAnalytic.schedules) {
            this.newAnalytic.schedules = [];
        }
    }

    isScheduleEmpty(schedule: ScheduleAnalytic): boolean {
        return !(schedule.monday || schedule.tuesday || schedule.wednesday || schedule.thursday || schedule.friday || schedule.saturday || schedule.sunday);
    }

    verifyIndex(tag: ScheduleHours) {
        return this.scheduleList.findIndex(schedule => schedule.start_time + ' - ' + schedule.end_time === tag.concatTime);
    }

    removeSchedule(tag: ScheduleHours) {
        const index = this.verifyIndex(tag);
        const i = this.weekList.findIndex(week => week.day === tag.day);
        const day = this.weekList[i];
        const weekListIndex = day.schedules.findIndex(schedule => schedule.concatTime === tag.concatTime);
        if (index >= 0) {
            switch (tag.day) {
                case 'SEG':
                    this.scheduleList[index].monday = false;
                    break;
                case 'TER':
                    this.scheduleList[index].tuesday = false;
                    break;
                case 'QUA':
                    this.scheduleList[index].wednesday = false;
                    break;
                case 'QUI':
                    this.scheduleList[index].thursday = false;
                    break;
                case 'SEX':
                    this.scheduleList[index].friday = false;
                    break;
                case 'SÁB':
                    this.scheduleList[index].saturday = false;
                    break;
                case 'DOM':
                    this.scheduleList[index].sunday = false;
            }
            day.schedules.splice(weekListIndex, 1);
            if (this.isScheduleEmpty(this.scheduleList[index])) {
                this.scheduleList.splice(index, 1);
            }
        }
        this.tableRows.data = null;
        this.tableRows.data = this.weekList;
    }

    addSchedule(editLastSchadule = false) {
        const timeStart: string[] = this.timeBegin.value.split(':');
        const timeFinish: string[] = this.timeEnd.value.split(':');
        const startDate = new Date();
        const endDate = new Date();
        startDate.setHours(parseInt(timeStart[0], 10));
        startDate.setMinutes(parseInt(timeStart[1], 10));
        if (timeStart.length == 2) {
            startDate.setSeconds(0, 10);
            this.timeBegin.setValue(this.timeBegin.value + ':00');
        }
        else {
            startDate.setSeconds(parseInt(timeStart[2], 10));
        }
        endDate.setHours(parseInt(timeFinish[0], 10));
        endDate.setMinutes(parseInt(timeFinish[1], 10));
        if (timeFinish.length == 2) {
            endDate.setSeconds(0, 10);
            this.timeEnd.setValue(this.timeEnd.value + ':00');
        }
        else {
            endDate.setSeconds(parseInt(timeFinish[2], 10));
        }
        let erro = false;
        if (!this.weekList[0].active &&
      !this.weekList[1].active &&
      !this.weekList[2].active &&
      !this.weekList[3].active &&
      !this.weekList[4].active &&
      !this.weekList[5].active &&
      !this.weekList[6].active) {
            erro = true;
        }

        if (!endDate.valueOf() || !startDate.valueOf() || endDate.valueOf() < startDate.valueOf() || endDate.valueOf() == startDate.valueOf()) {
            // showAlert('Intervalo de horário inválido!', 'danger');
            this.validationResponse.validationResponseHandler(400, 'camera-playlist', 'date-invalid', 'cameras.date_invalid');
            return;
        }
        else if (erro) {
            this.validationResponse.validationResponseHandler(400, this.pageName, 'scheduling-invalid', 'analytics.scheduling_invalid');
            // showAlert('Insira um agendamento válido!', 'danger');
            return;
        }
        else {
            const schedule: ScheduleAnalytic = {
                id: this.idInterim,
                start_time: this.timeBegin.value,
                end_time: this.timeEnd.value,
                monday: this.weekList[0].active,
                tuesday: this.weekList[1].active,
                wednesday: this.weekList[2].active,
                thursday: this.weekList[3].active,
                friday: this.weekList[4].active,
                saturday: this.weekList[5].active,
                sunday: this.weekList[6].active,
            };
            let allNew = true;
            this.scheduleList.forEach(a => {
                const equals = a.start_time === schedule.start_time && a.end_time === schedule.end_time && a.monday === schedule.monday &&
          a.tuesday === schedule.tuesday && a.wednesday === schedule.wednesday && a.thursday === schedule.thursday && a.friday === schedule.friday &&
          a.saturday === schedule.saturday && a.sunday === schedule.sunday;
                allNew = !equals;
            });
            if (allNew || editLastSchadule) {
                if (!editLastSchadule) {
                    this.scheduleList.push(schedule);
                }
                this.weekList.forEach(dayTags => {
                    if (dayTags.active) {
                        dayTags.schedules.push({
                            id: this.idInterim,
                            concatTime: this.timeBegin.value + ' - ' + this.timeEnd.value,
                            day: dayTags.day,
                            close: false
                        });
                    }
                });
                this.tableRows.data = null;
                this.tableRows.data = this.weekList;
                this.idInterim++;
            }
        }
        this.selectedDays.reset();
        this.timeBegin.setValue('00:00:00');
        this.timeEnd.setValue('00:00:00');
        this.selectAllDays.reset();
        this.validateFormFullInformation();
    }

    choiceObjetcs(object) {
        const tag = document.getElementById(String(object['alias']));
        this.optionsClassifiers.map((classification) => {
            if (tag) {
                if (classification == object) {
                    if (tag.classList.contains('border_style_active')) {
                        tag.classList.remove('border_style_active');
                        this.classicationsList.forEach((classific, index) => {
                            if (classific.alias == object.alias) {
                                this.classicationsList.splice(index, 1);
                                this.selectClassifiers.setValue(this.classicationsList);
                            }
                        });
                    } else {
                        tag.classList.add('border_style_active');
                        this.classicationsList.push(object);
                        this.selectClassifiers.setValue(this.classicationsList);
                    }
                }
                this.validateFormFullInformation();
            }
        });
    }

    // Reseta as informações caso o usuário troque de radiobutton
    resetInformation() {
        this.timeBegin.setValue('00:00:00');
        this.timeEnd.setValue('00:00:00');
        for (let a = 0; a < this.weekList.length; a++) {
            this.weekList[a].active = false;
            this.weekList[a].schedules = [];
        }
        this.tableRows.data = null;
        this.tableRows.data = this.weekList;
    }

    // Cria todo o fluxo de novos agendamentos
    getTimeSchedule(idSchedule) {
        this.idSchedule = idSchedule;
        this.resetInformation();
        switch (idSchedule) {
            case 1:
                this.scheduleList = [];
                this.clearScheduleTable();
                this.timeBegin.setValue('08:00:00');
                this.timeEnd.setValue('18:00:00');
                const workingDaysComercial = this.weekList.map(weekLists => weekLists.day != 'SÁB' && weekLists.day != 'DOM');
                for (let a = 0; a < this.weekList.length; a++) {
                    this.weekList[a].active = workingDaysComercial[a];
                }
                this.selectedDays.setValue(true);
                this.addSchedule();
                break;
            case 2:
                this.scheduleList = [];
                this.clearScheduleTable();
                this.timeBegin.setValue('18:00:00');
                this.timeEnd.setValue('23:59:59');
                const workingDaysNight = this.weekList.map(weekLists => weekLists.day != 'SÁB' && weekLists.day != 'DOM');
                for (let a = 0; a < this.weekList.length; a++) {
                    this.weekList[a].active = workingDaysNight[a];
                }
                this.addSchedule();
                this.timeBegin.setValue('00:00:00');
                this.timeEnd.setValue('06:00:00');
                const workingDaysFree = this.weekList.map(weekLists => weekLists.day != 'SÁB' && weekLists.day != 'DOM');
                for (let a = 0; a < this.weekList.length; a++) {
                    this.weekList[a].active = workingDaysFree[a];
                }
                this.selectedDays.setValue(true);
                this.addSchedule();
                break;
            case 3:
                this.scheduleList = [];
                this.clearScheduleTable();
                if (this.analytic) {
                    this.hideButton = false;
                    this.getSchedule();
                } else {
                    this.hideButton = true;
                }
                this.selectedDays.setValue(false);
                break;
        }
    }

    selectedRadioButton() {
        if (this.analytic) {
            if (this.analytic.schedules_flag == 'comercial') {
                this.selectedComercial = true;
                this.idSchedule = 1;
            } else if (this.analytic.schedules_flag == 'night') {
                this.selectedNight = true;
                this.idSchedule = 2;
            } else {
                this.loadingLastSchedules = true;
                this.selectedOthers = true;
                this.idSchedule = 3;
                setTimeout(() => {
                    this.getSchedule();
                }, 500);
            }
        }
    }

    formatterCorruptionTampergin(): string {
        return `${this.dissimilarity}%`;
    }

    openHolidayView() {
        const dataModal = {
            data : {
                id: this.cameraData.id,
                name: this.cameraData.name
            },
            modalHeight: '90vh',
            modalWidth: '80vw',
            backdropClass: 'lightBackdrop',
            tipoModal: 'listar_calendarios'
        };
        this.service.returnDataToModal(dataModal);
    }

    changeCriticalityFilter(event) {
        this.changeSortedStatusColumn(event[0]);
    }

    changeSortedStatusColumn(id) {
        this.criticalitySelected = id;
    }
}
