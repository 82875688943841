<div class="main-content">
    <div class="first-row">
        <div class="modal-title">
            Calendário de Feriados
            <span class="client-name">{{clientName}}</span>
        </div>
        <div class="close-modal">
            <i nz-icon nzType="close" (click)="closeModal()" nzTheme="outline"></i>
        </div>
    </div>
    <div class="scroll-modal">
        <div class="second-row">
            <span class="list-title">Lista de feriados do calendário do cliente</span>
            <div *ngIf="calendarListData?.length === 0 && !loadingCalendarList" (click)="openCreateCalendar(true)" class="create-button">
                <span nz-icon nzType="plus" nzTheme="outline"></span>Criar Calendário
            </div>
            <div class="edit clickable" (click)="openCreateCalendar(false)"
                *ngIf="calendarListData?.length > 0 && !loadingCalendarList">
                <span nz-icon nzType="edit" nzTheme="outline"></span>
                <span style="margin-left: 10px">Editar</span>
            </div>
        </div>
        <div class="calendar-table">
            <nz-table #holidayTable [nzData]="calendarListData"
            [nzShowPagination]="false"
            [nzFrontPagination]="false"
            [nzSize]="'middle'"
            [nzTableLayout]="'auto'"
            [nzData]="calendarListData"
            [nzLoading]="loadingCalendarList"
            [nzLoadingIndicator]="loadingListTemplate"
            class="table style-table">
                <thead>
                    <tr>
                        <th nzWidth="40%" class="table-title">Feriado</th>
                        <th class="table-date">Data</th>
                        <th class="table-hour">Horário de Funcionamento</th>
                    </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let calendar of holidayTable.data; let i = index">
                    <tr [ngClass]="i % 2 === 0 ? 'odd-row' : 'even-row'">
                        <td class="flex flex-col justify-evenly" [class.expanded-first]="calendar.expand">
                           <span class="holiday-name">{{calendar.alias}}</span>
                            <div class="container" (click)="calendar.expand = !calendar.expand">
                                <div class="icon-text">
                                    <i nz-icon nzType="video-camera" nzTheme="outline" class="mr-1" style="font-size: 20px; color: #306EBF; margin-right: 8px;"></i>
                                    <span *ngIf="!calendar.expand">Ver câmeras habilitadas</span>
                                    <span *ngIf="calendar.expand">Esconder câmeras habilitadas</span>
                                </div>
                                <div class="clickable">
                                    <span nz-icon *ngIf="!calendar.expand" nzType="down" nzTheme="outline"></span>
                                    <span nz-icon *ngIf="calendar.expand" nzType="up" nzTheme="outline"></span>
                                </div>
                            </div>
                        </td>
                        <td>{{formatDateRange(calendar.start_time, calendar.end_time)}}</td>
                        <td>{{formatHourRange(calendar.start_time, calendar.end_time)}}</td>
                        <td class="w-1/2" [class.expanded-last]="calendar.expand">
                            <div class="switch-container mr-1">
                                <nz-switch [nzControl]="true" [nzLoading]="disableLoading[i]"
                                [(ngModel)]="calendar.active" (click)="disableHoliday(calendar, i)"
                                class="gt-xs:onoffswitch xs:onoffswitch-small status_style"></nz-switch>
                                <i [class.disabled]="deleteDisabled" *ngIf="!deleteLoading[i]"class="delete-icon" nz-icon nzType="delete" nzTheme="outline" style="padding-top: 2px;" (click)="deleteHoliday(calendar, i)"></i>
                                <i [class.disabled]="deleteDisabled" *ngIf="deleteLoading[i]" class="delete-icon" nz-icon nzType="loading" style="cursor: none" style="padding-top: 2px;" nzTheme="outline"></i>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="calendar.expand" [ngClass]="i % 2 === 0 ? 'odd-row' : 'even-row'">
                      <td colspan="4" class="expanded-cell">
                        <div class="expanded-content">
                            {{calendar.cameras_info.length }} câmera(s)
                          <div class="flex-1 flex-row mt-2">
                            <span class="camera-name" *ngFor="let camera of calendar.cameras_info">{{ camera.alias }}</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </nz-table>
        </div>
        <div *ngIf="calendarListData?.length === 0 && !loadingCalendarList" style="padding: 60px 24px;">
            <app-feedback [noCalendar]="true" imageUrl="noCalendar.svg" [transparent]="true"></app-feedback>
        </div>
        <ng-template #loadingListTemplate>
            <div class="loadingCalendarList" style="margin-top: 60px;">
                <span nz-icon nzType="loading" nzTheme="outline"></span>
            </div>
        </ng-template>
    </div>
</div>
