import { Component, OnInit, Inject, AfterViewInit, OnDestroy, ViewChild, ElementRef, Input, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Camera } from 'app/cameras/models';
import { AnalyticActions } from '../Services/actions';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/model';
import { ModalState, AnalyticsByCamera, GroupAnalytics, Analytics } from '../models';
import { Subscription } from 'rxjs';
import { drawOnCanvas } from '../../Shared/Helpers/canvas';
import { showAlert } from 'app/Shared/Helpers/showAlert';
import { OperationStatus, ResultAlert } from 'app/Shared/models';
import { Router } from '@angular/router';
import { SharedService } from 'app/Shared/Services/shared.service';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';

@Component({
    selector: 'app-analytics-list',
    templateUrl: './analytics-list.component.html',
    styleUrls: ['./analytics-list.component.scss', '../styles.scss']
})
export class AnalyticsListComponent implements OnInit, AfterViewInit, OnDestroy {
    readonly cameraAnalytics$ = this.store.select((state: AppState) => state.analytic.camera_analytics);
    readonly statusDeleteAnalytic$ = this.store.select((state: AppState) => state.analytic.statusDeleteAnalytic);
    readonly retryAnalytic$ = this.store.select((state: AppState) => state.analytic.statusRetryAnalytic);

    swalDecisionSub: Subscription;
    analyticSub: Subscription;
    delSub: Subscription;
    retryAnalyticSub: Subscription;

    loading = false;
    is_guest = false;
    groupView = false;
    loadingAnalytics = true;
    limitAnalitic: boolean;
    pageName = 'analytics-list';
    cameraAnalytics: AnalyticsByCamera;
    analyticGroups: GroupAnalytics[];
    canvasView: HTMLCanvasElement;
    canvasViewPlotImage: HTMLCanvasElement;
    ctxView: CanvasRenderingContext2D;
    ctxViewPlotImage: CanvasRenderingContext2D;
    camThumbnail: HTMLImageElement;
    currentAnalyticSelection = -1;
    heightModal: string;
    userSub: string;
    client_sub: string;
    idAll: number;
    outsideBoolean = false;
    awaitAnalytics = null;
    auxHtmlSchedulesDay = { comercial: false, night: false, free: false };
    pathPreviousRouter = localStorage.getItem('previousRouter');
    processing: boolean[] = new Array(50).map(x => false);

    isAssociate: boolean;
    adminAssociate: boolean;
    isPartner: boolean;
    isClientAssociate: boolean = localStorage.getItem('profile_name') === '419bea06-5d4e-4a56-b8b5-04b3ad566d59';
    adminClientAssociate: boolean;
    isClient: boolean = localStorage.getItem('profile_name') === '61902d2b-3ada-49f3-b42a-1775bc064bb0';

    @ViewChild('viewAnalytic') viewAnalytic: ElementRef;
    @ViewChild('plotImageView') plogImageView: ElementRef;

    constructor(public dialogRef: MatDialogRef<AnalyticsListComponent>, @Inject(MAT_DIALOG_DATA) public camera: Camera,
        private readonly store: Store<AppState>, private router: Router, private service: SharedService,
        private validationResponse: ValidationResponseHandlerModule, private element: ElementRef) { }

    ngOnInit(): void {
        this.loading = false;
        this.adminClientAssociate = this.isClientAssociate && localStorage.getItem('associate_permission') === 'e816c560-812e-11ed-a1eb-0242ac120002';
        this.processing[this.idAll] = false;

        if (localStorage.getItem('profile_name') === 'f29868c7-b4c5-4963-9ae8-1dd95699d6c3') {
            this.isAssociate = true;
        }
        if (this.isAssociate && localStorage.getItem('associate_permission') === 'e816c560-812e-11ed-a1eb-0242ac120002') {
            this.adminAssociate = true;
        }
        if (localStorage.getItem('profile_name') == 'cd343bfc-17e8-11ec-9621-0242ac130002') {
            this.isPartner = true;
        }

        this.heightModal = 'fit-content';
        this.client_sub = localStorage.getItem('clientView');
        this.userSub = localStorage.getItem('sub');
        this.store.dispatch(AnalyticActions.get_analytics_camera({
            camera_id: this.camera.id
        }));
        this.limitAnalitic = false;
        this.getCameraAnalytics();
        this.deleteAnalyticResponse();
        // Recebe o retorno das informações do swal
        this.swalDecisionSub = this.service.swalDecision.subscribe(returnSwallObject => {
            if (returnSwallObject) {
                this.validateReturnMethodCalled(returnSwallObject as ResultAlert);
            }
        });

        if (localStorage.getItem('profile_name') == 'bb653b3a-fdb3-4438-bce6-012585b5268f' || (this.isClientAssociate && !this.adminClientAssociate)) {
            this.is_guest = true;
        }

        if (localStorage.getItem('contextAnalytics') == 'groupView') {
            this.groupView = true;
        }
    }

    ngAfterViewInit() {
        //Inicializando canvas
        //Aqui são dois canvas sobrepostos, um com o thumbnail da câmera e o outro
        //com o desenho do analítico (polígono ou linha colorida)
        this.canvasView = this.viewAnalytic.nativeElement;
        this.ctxView = this.canvasView.getContext('2d');
        this.canvasViewPlotImage = this.plogImageView.nativeElement;
        this.ctxViewPlotImage = this.canvasViewPlotImage.getContext('2d');

        //Desenhando thumbnail da câmera no canvas
        this.camThumbnail = new Image();
        this.camThumbnail.src = this.camera.thumbnail;
        this.camThumbnail.onload = () => {
            this.ctxViewPlotImage.drawImage(this.camThumbnail, 0, 0, this.camThumbnail.width, this.camThumbnail.height, 0, 0, 640, 480);
        };
        this.ctxView.fillStyle = 'rgb(255,255,255)';
    }

    ngOnDestroy() {
        if (this.analyticSub) {
            this.analyticSub.unsubscribe();
        }
        if (this.delSub) {
            this.delSub.unsubscribe();
        }
        if (this.swalDecisionSub) {
            this.swalDecisionSub.unsubscribe();
        }
        if (this.awaitAnalytics != null) {
            clearInterval(this.awaitAnalytics);
        }
        if (this.retryAnalyticSub) {
            this.retryAnalyticSub.unsubscribe();
        }

        this.store.dispatch(AnalyticActions.cleanup_camera_analytics());
    }

    //Recebe a lista de analíticos da câmera do State
    getCameraAnalytics() {
        this.analyticSub = this.cameraAnalytics$.subscribe(analytics => {
            if (analytics) {
                this.cameraAnalytics = analytics[0];
                if (this.cameraAnalytics.analytics.length > 0) {
                    this.loadingAnalytics = false;
                    this.loading = false;
                    if (this.cameraAnalytics.analytics.length >= 3) {
                        this.limitAnalitic = true;
                    } else {
                        this.limitAnalitic = false;
                    }

                    if (this.awaitAnalytics == null) {
                        this.asyncAwaitConfiguration();
                    }
                }
                else {
                    this.cameraAnalytics = null;
                    this.loadingAnalytics = false;
                }
            }
        });
    }

    asyncAwaitConfiguration() {
        let isUpdating = false;
        this.cameraAnalytics.analytics.forEach(analytic => {
            if (analytic.status == 'updating' || analytic.status == 'pending') {
                isUpdating = true;
            }
        });
        if (isUpdating) {
            this.awaitAnalytics = setInterval( () => {
                this.store.dispatch(AnalyticActions.get_analytics_camera({
                    camera_id: this.camera.id
                }));
            }, 120000);
        } else {
            clearInterval(this.awaitAnalytics);
            if (this.cameraAnalytics.analytics.length > 0) {
                this.awaitAnalytics = null;
            }
            else {
                this.cameraAnalytics = null;
            }
        }
    }

    //Recebe a lista de grupo de analíticos
    setAnalyticGroup(event: GroupAnalytics[]) {
        this.loading = false;
        this.analyticGroups = event;
    }

    //Retorna o 'alias' do grupo de analíticos com id 'id'
    getGroupName(id: number): string {
        if (this.analyticGroups) {
            const index = this.analyticGroups.findIndex(element => element.id === id);
            return index >= 0 ? this.analyticGroups[index].alias : 'Grupo do Analítico';
        } else {
            return 'Grupo do Analítico';
        }
    }

    @HostListener('document:click', ['$event'])
    clickOutside(event: Event) {
        if (!this.element.nativeElement.contains(event.target)) {
            if (this.outsideBoolean) {
                this.closeCancel();
            } else {
                this.outsideBoolean = true;
            }
        }
    }

    //Fecha o modal
    closeCancel() {
        this.store.dispatch(AnalyticActions.update_modal_state({ payload: ModalState.Closed }));
        this.dialogRef.close();
        if (!localStorage.getItem('idCameraChoice')) {
            this.redirect();
        }
    }

    redirect() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        if (this.isPartner) {
            if (this.pathPreviousRouter == '/cameras/groups') {
                this.router.navigateByUrl('cameras/groups/view');
            }
            else {
                this.router.navigateByUrl('/users/client/view');
            }
        }
        else if (this.client_sub != null) {
            if (this.pathPreviousRouter == '/cameras/groups') {
                this.router.navigateByUrl('cameras/groups/view');
            }
            else {
                this.router.navigateByUrl('/users/client/view');
            }
        }
        else if (this.isClient && this.groupView) {
            this.router.navigateByUrl('cameras/groups/view');
        }
        else {
            this.router.navigate(['/cameras/list']);
        }
    }

    //Abre o modal para adicionar novo analítico
    goToCreate(event: number) {
        this.store.dispatch(AnalyticActions.update_modal_state({ payload: ModalState.Create }));
        this.store.dispatch(AnalyticActions.put_modal_data({
            payload: {
                analytic: null,
                groupId: event,
                groups: this.analyticGroups,
                hasHolidaysInCamera: this.cameraAnalytics ? this.cameraAnalytics.hasHolidaysInCamera : null
            }
        }));
        this.dialogRef.close(true);
    }

    //Abre o modal para atualizar novo analítico
    goToUpdate(event: Analytics) {
        this.store.dispatch(AnalyticActions.update_modal_state({ payload: ModalState.Edit }));
        this.store.dispatch(AnalyticActions.put_modal_data({
            payload: {
                analytic: event,
                groupId: event.id_group,
                groups: this.analyticGroups,
                hasHolidaysInCamera: this.cameraAnalytics ? this.cameraAnalytics.hasHolidaysInCamera : null
            }
        }));
        this.dialogRef.close(true);
    }

    //Coloca em destaque o analítico selecionado
    selectAnalytic(analytic: Analytics) {
        const type = this.analyticGroups.find(group => group.id === analytic.id_group).analytic_types.find(a => a.id_analytic_type === analytic.id_analytic_type).draw_type;
        const elem: HTMLElement = document.getElementById(analytic.id_analytic.toString());
        if ((this.currentAnalyticSelection > 0) && (analytic.id_analytic != this.currentAnalyticSelection)) {
            const elem2 = document.getElementById(this.currentAnalyticSelection.toString());
            elem2.setAttribute('style', 'border: solid 0px red;');
        }

        elem.setAttribute('style', 'border: solid 1px #87C4FF;');

        if (analytic.id_analytic != this.currentAnalyticSelection) {
            this.ctxViewPlotImage.filter = 'blur(' + analytic.blur + 'px)';
            this.ctxViewPlotImage.drawImage(this.camThumbnail, 0, 0, this.camThumbnail.width, this.camThumbnail.height, 0, 0, 640, 480);
            // caso o contexto não esteja definido
            if (!this.ctxView) {
                return;
            }

            const pointsListView = JSON.parse(analytic.metadata).points;

            // this.drawObjectInCanvas(this.ctxCircleView, analytic.smaller_object, analytic.larger_object);
            drawOnCanvas(this.ctxView, analytic.border_color, pointsListView, type.value);
        }
        this.currentAnalyticSelection = analytic.id_analytic;
    }

    //Deleta analítico
    deleteAnalytic(id_analytic: number) {
        this.idAll = id_analytic;
        this.validationResponse.validationResponseHandler(200, this.pageName, 'confirm-delete-analytic');
    }

    deleteAnalyticResponse() {
        this.delSub = this.statusDeleteAnalytic$.subscribe(msg => {
            if (msg.status != OperationStatus.Unloaded) {
                this.loading = false;
                this.heightModal = 'fit-content';
                if (msg.status === OperationStatus.Success) {
                    showAlert(msg.message, 'success');
                    this.store.dispatch(AnalyticActions.delete_analytics_camera_change_feedback());
                    this.store.dispatch(AnalyticActions.get_analytics_camera({ camera_id: this.camera.id }));
                    this.loadingAnalytics = true;
                }
                else {
                    showAlert(msg.message, 'danger');
                }
                setTimeout(() => { this.reloadImageFromCanvas(); }, 1000);
            }
        });
    }

    reloadImageFromCanvas() {
        const currentDate = new Date();

        this.canvasView = this.viewAnalytic.nativeElement;
        this.ctxView = this.canvasView.getContext('2d');
        this.canvasViewPlotImage = this.plogImageView.nativeElement;
        this.ctxViewPlotImage = this.canvasViewPlotImage.getContext('2d');

        this.currentAnalyticSelection = -1;

        this.ctxViewPlotImage.putImageData(this.ctxViewPlotImage.createImageData(640, 480), 0, 0);
        this.ctxViewPlotImage.drawImage(this.camThumbnail, 0, 0, this.camThumbnail.width, this.camThumbnail.height, 0, 0, 640, 480);
    }

    validateReturnMethodCalled(returnResultSwal: ResultAlert) {
        if (returnResultSwal.methodCalled === 'deleteAnalytic') {
            this.outsideBoolean = false;
            if (returnResultSwal.isConfirmed) {
                if (this.idAll) {
                    if (this.client_sub != null) {
                        this.store.dispatch(AnalyticActions.delete_analytics_camera({
                            id_analytic: this.idAll,
                            sub: this.client_sub
                        }));
                    } else {
                        this.store.dispatch(AnalyticActions.delete_analytics_camera({
                            id_analytic: this.idAll,
                            sub: localStorage.getItem('sub')
                        }));
                    }
                }
                this.loading = true;
                this.heightModal = 'fit-content';
            } else if (returnResultSwal.isDismissed) {
            }
        }
    }

    retryAnalytic(id_analytic) {
        this.idAll = id_analytic;
        this.processing[id_analytic] = true;
        this.store.dispatch(AnalyticActions.retry_analytics({
            id_analytic: this.idAll,
            sub: this.userSub,
            child_sub: this.client_sub
        }));
        this.retryAnalyticResult();
    }

    retryAnalyticResult() {
        this.retryAnalyticSub = this.retryAnalytic$.subscribe(result => {
            if (result.status === 2) {
                this.validationResponse.validationResponseHandler(200, this.pageName, 'retry-analytic-success', result.message);
            }
            else {
                this.validationResponse.validationResponseHandler(400, this.pageName, 'retry-analytic-failure', result.message);
            }
            this.store.dispatch(AnalyticActions.retry_analytics_cleanup());
        });
    }

}
