<div class="main-content">
  <div class="flex justify-between items-center">
    <span class="title-modal">Editar limite de câmeras e detecções</span>
    <div (click)="closeModal()" class="close-modal">
      <i nz-icon nzType="close" nzTheme="outline"></i>
    </div>
  </div>
  
  <div class="flex justify-start">
    <span class="subtitle-modal">{{client.name}}</span>
  </div>
  
  <div class="form-container">
    <form [formGroup]="editForm">
      <div class="flex justify-end" style="margin-bottom: 1rem;">
        <span class="amount_type">Quantidade</span>
      </div>
  
      <div class="flex justify-between items-center container_block">
        <span class="name_type">Detecções inteligentes</span>
        <div class="label-container">
          <nz-input-number class="input-container-number" formControlName="detectionsAmount" [nzMin]="0" [nzStep]="1"></nz-input-number>
        </div>
      </div>
  
      <div class="flex justify-between items-center" style="padding: 12px;">
        <span class="name_type">Convidados</span>
        <div class="label-container">
          <nz-input-number class="input-container-number" formControlName="guestsAmount" [nzMin]="0" [nzStep]="1"></nz-input-number>
        </div>
      </div>
  
      <table mat-table [dataSource]="dataSource" class="style-table" *ngIf="!loadingProducts">
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef class="product_type"> Produto </th>
          <td mat-cell *matCellDef="let element" class="name_type"> CÂMERA {{element.name}} 
            <span class="day_element">{{element.days}} dias
             <i 
                *ngIf="element.partnerAmount == 0"
                class="tooltip-icon"
                nz-icon 
                nzType="exclamation-circle" 
                nzTheme="outline"
                nz-popover
                [nzPopoverContent]="tooltipPlan"
                nzPopoverTrigger="hover"
                nzPopoverPlacement="top"
                nzPopoverOverlayClassName="popover-container"
              ></i>
            </span>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef class="product_type"> Quantidade </th>
          <td mat-cell *matCellDef="let element" formArrayName="plan"> 
            <div class="label-container" style="width: 100%;" formArrayName="plan">
              <nz-input-number class="input-container" [(ngModel)]="planValues[element.plan_id]" [ngModelOptions]="{standalone: true}" [nzMin]="0" [nzStep]="1"></nz-input-number>
            </div>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button *ngIf="validateLimitFive(element)" class="delete-button" (click)="deleteInventory(element)" [class.void_deletion]="validateLimitFive(element)">
              <i [id]="'delete' + element.plan_id" nz-icon nzType="delete" nzTheme="outline" class="delete-icon" nz-popover
              [nzPopoverContent]="fiveLimit"
              nzPopoverTrigger="hover"
              nzPopoverPlacement="top"
              nzPopoverOverlayClassName="popover-container"></i>
              <i [id]="'loading' + element.plan_id" nz-icon nzType="loading" nzTheme="outline" class="delete-icon display"></i>
            </button>
            <button *ngIf="!validateLimitFive(element)" class="delete-button" (click)="deleteInventory(element)" [class.void_deletion]="validateLimitRTMP(element)">
              <i *ngIf="validateLimitRTMP(element)" [id]="'delete' + element.plan_id" nz-icon nzType="delete" nzTheme="outline" class="delete-icon" nz-popover
              [nzPopoverContent]="rtmpLimit"
              nzPopoverTrigger="hover"
              nzPopoverPlacement="top"
              nzPopoverOverlayClassName="popover-container"></i>
              <i *ngIf="!validateLimitRTMP(element)" [id]="'delete' + element.plan_id" nz-icon nzType="delete" nzTheme="outline" class="delete-icon"></i>
              <i [id]="'loading' + element.plan_id" nz-icon nzType="loading" nzTheme="outline" class="delete-icon display"></i>
            </button>
          </td>
        </ng-container> 
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
  
      <div *ngIf="!loadingProducts && !addProduct && partnerInventory?.plans.length > 0">
        <button type="button" class="btn btn-light add-button" (click)="submitProduct()">
          <i class="material-icons">add</i> 
          Adicionar outro produto
        </button> 
      </div>
  
      <div *ngIf="addProduct" class="flex justify-between items-center container_block">
        <div class="label-container product_selection" *ngIf="availableInventory">
          <nz-select nzBorderless [nzCustomTemplate]="defaultTemplate" nzPlaceHolder="Selecionar produto" class="product_type" formControlName="plan" (change)="updateAmountInfo()">
            <ng-container *ngFor="let plan of availableInventory">
              <nz-option [nzLabel]="'CÂMERA' + ' ' + plan.resolution.alias + ' ' + plan.days_storage + ' ' + (plan.days_storage == 1 ? ' dia': ' dias')" [nzValue]="plan"></nz-option>
            </ng-container>
          </nz-select>
          <ng-template #defaultTemplate let-selected> <span class="plan_select">CÂMERA {{selected.nzValue.resolution.alias}} </span><span class="day_element">{{selected.nzValue.days_storage}}  dias</span></ng-template>
        </div>
      
        <div class="label-container">
          <nz-input-number class="input-container" formControlName="cameraAmount" [nzMin]="1" [nzStep]="1"></nz-input-number>
        </div>
      </div>
    </form>

    <div *ngIf="!!loadingProducts" style="width: 100%; display: flex; align-items: center; justify-content: center;">
      <span nz-icon nzType="loading" nzTheme="outline" style="font-size:35px"></span>
    </div>
  </div>
  
  <div class="flex justify-end items-center" *ngIf="!loadingProducts">
    <button class="btn btn-secondary cancel-button" (click)="closeModal()">Cancelar</button>
    <button class="btn btn-primary enter-button" *ngIf="!loadingSubmit" (click)="submitForm()">
      Salvar
    </button>
    <div *ngIf="loadingSubmit">
      <span nz-icon nzType="loading" nzTheme="outline" style="font-size:35px"></span>
    </div>
  </div>
  
  <ng-template #tooltipPlan>
    <div class="tooltiptext">
      <span>Você não possui câmeras disponíveis nesse plano,<br>verifique seu estoque.</span>
      <button (click)="toStock()" class="btn btn-primary stock-button">Ir para estoque</button>
    </div>
  </ng-template>

  <ng-template #fiveLimit>
    <div class="tooltiptext">
      <span class="tooltiptextsoftTwo">Esse plano possui 5 ou mais câmeras cadastradas, exclua as câmeras para poder excluir o plano.</span>
      <button (click)="closeModal()" class="btn btn-primary stock-button">Ir para listagem de câmeras</button>
    </div>
  </ng-template>

  <ng-template #rtmpLimit>
    <div class="tooltiptext">
      <span class="tooltiptextsoftTwo">Esse plano possui pelo menos uma câmera RTMP ativa, exclua as câmeras para poder excluir o plano.</span>
      <button (click)="closeModal()" class="btn btn-primary stock-button">Ir para listagem de câmeras</button>
    </div>
  </ng-template>
</div>
