import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class HasScrollService {

    hasScrollEmitter = new EventEmitter<boolean>();
    hasScrollXEmitter = new EventEmitter<boolean>();
    constructor() { }
}
