import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { CameraFormComponent } from './camera-form/camera-form.component';
import { camerasRoutes } from './cameras.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// tslint:disable-next-line: max-line-length
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { CameraViewComponent } from './camera-view/camera-view.component';
import { CameraPlaylistComponent } from './camera-playlist/camera-playlist.component';
import { CameraInviteComponent, InviteModalComponent } from './camera-invite/camera-invite.component';
import { CameraListInviteComponent } from './camera-list-invite/camera-list-invite.component';
import ptBr from '@angular/common/locales/pt';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AnalyticsModule } from 'app/analytics/analytics.module';
import { MatPaginatorIntlBr } from './CustomPaginatorLabels';
import { CameraEditComponent, CameraEditModalComponent } from './camera-edit/camera-edit.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { CameraModalMosaicComponent, ModalMosaicComponent } from './camera-modal-mosaic/camera-modal-mosaic.component';
//Dropdown
import { CameraHealthComponent } from './camera-health/camera-health.component';
import { LottieComponent, provideLottieOptions } from 'ngx-lottie';
import player from 'lottie-web';
import { SharedModulesModule } from '../Shared/shared-modules/shared-modules.module';
import { CameraDataComponent } from './camera-data/camera-data.component';
import { CameraAddressComponent } from './camera-address/camera-address.component';
import { CameraPlansComponent } from './camera-plans/camera-plans.component';
import { ListCameraAddressComponent, ListCameraAddressModalComponent } from './list-camera-address/list-camera-address.component';
import { CameraListComponent } from './camera-list/camera-list.component';
import { RouterModule } from '@angular/router';
import { CameraStockComponent } from './camera-stock/camera-stock.component';
import { CameraDetectionsStockComponent } from './camera-detections-stock/camera-detections-stock.component';
import { ComponentsModule } from 'app/components/components.module';
import { CameraKitsStockComponent } from './camera-kits-stock/camera-kits-stock.component';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { FilterPipe } from 'app/pipes/filter.pipe';
import { SafeHtmlPipe } from 'app/pipes/safehtml.pipe';
import { CameraGroupComponent } from './camera-group/camera-group.component';
import { CameraViewGroupComponent } from './camera-view-group/camera-view-group.component';
import { CameraFormCsvComponent } from './camera-form-csv/camera-form-csv.component';
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { MAT_DATE_LOCALE } from '@angular/material/core';

registerLocaleData(ptBr);

export const MY_DATE_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    },
};

@NgModule({
    declarations: [
        CameraFormComponent,
        CameraViewComponent,
        CameraPlaylistComponent,
        CameraInviteComponent,
        InviteModalComponent,
        CameraListInviteComponent,
        CameraEditComponent,
        CameraEditModalComponent,
        CameraModalMosaicComponent,
        ModalMosaicComponent,
        CameraHealthComponent,
        CameraDataComponent,
        CameraAddressComponent,
        CameraPlansComponent,
        ListCameraAddressComponent,
        ListCameraAddressModalComponent,
        CameraListComponent,
        CameraStockComponent,
        CameraDetectionsStockComponent,
        CameraKitsStockComponent,
        FilterPipe,
        SafeHtmlPipe,
        CameraGroupComponent,
        CameraViewGroupComponent,
        CameraFormCsvComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        RouterModule.forChild(camerasRoutes),
        MatRadioModule,
        MatTableModule,
        MatPaginatorModule,
        MatCardModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatTooltipModule,
        MatExpansionModule,
        NzStepsModule,
        FlexLayoutModule,
        AnalyticsModule,
        SharedModulesModule,
        ComponentsModule,
        NzCheckboxModule,
        NzSelectModule,
        NzIconModule,
        NzPopoverModule,
        NzInputModule,
        NzToolTipModule,
        NzDatePickerModule,
        NzDropDownModule,
        NzTableModule,
        NzSpinModule,
        NzPaginationModule,
        NzGridModule,
        NzSwitchModule,
        LottieComponent,
        NgxMaskDirective,
        NgxMaskPipe
    ],
    exports: [
        CameraListComponent,
        MatSelectModule,
        InviteModalComponent,
        CameraInviteComponent,
        CameraEditComponent,
        CameraEditModalComponent,
        CameraViewComponent,
        CameraGroupComponent,
        CameraViewGroupComponent
    ],
    providers: [
        {
            provide: MatPaginatorIntl, useClass: MatPaginatorIntlBr
        },
        {
            provide: LOCALE_ID,
            useValue: 'pt'
        },
        {
            provide: OverlayContainer, useClass: FullscreenOverlayContainer
        },
        {
            provide: MAT_DATE_LOCALE, useValue: MY_DATE_FORMATS
        },
        provideLottieOptions({
            player: () => player,
        }),
        provideNgxMask()
    ]
})
export class CamerasModule { }
