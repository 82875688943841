<div class="main-content">
  <div class="header-modal">
    <span class="title-modal">Adicionar cliente</span>
    <div (click)="closeModal()" class="close-modal">
      <i nz-icon nzType="close" nzTheme="outline"></i>
    </div>
  </div>
    
  <div class="form-container">
  <form [formGroup]="registerForm">
    <div id="pressEnter" *ngIf="childProfile != 'Convidado'">
      <div *ngIf="currentStep === 0">
        <div class="row">
          <span class="title-style">Sua senha deve ser composta por pelo menos 1 letra maiúscula, 1 número e um caractere especial (ex: !&#64;#$%&*).</span>
        </div>
        <div class="row">
          <div class="label-container" style="display: flex;">
            <div class="input-value">
              <div class="label-email">
                <span>Nome</span>
              </div>
              <input nz-input type="text" placeholder="Digite aqui..." formControlName="name"
               class="input-container" />
              <mat-error *ngIf="registerForm.get('name').hasError('required') && registerForm.get('name').touched">
                Nome <strong>é requerido</strong>
              </mat-error>
            </div>
            <div class="input-value-three" *ngIf="!costCenterMark">
              <div class="label-email">
                <span>Centro de Custo</span>
              </div>
              <input nz-input type="text" placeholder="Digite aqui..." formControlName="costCenter"
               class="input-container" />
              <mat-error *ngIf="registerForm.get('costCenter').hasError('required') && registerForm.get('costCenter').touched">
                Centro de Custo <strong>é requerido</strong>
              </mat-error>
            </div>
          </div>
        </div>
  
        <div class="row">
          <div class="label-container" style="display: flex;">
            <div class="input-value">
              <div class="label-email">
                <span>E-mail</span>
              </div>
              <input nz-input type="email" placeholder="Digite aqui..." id="email" formControlName="email"
               class="input-container" />
              <mat-error
                *ngIf="registerForm.get('email').hasError('email') && !registerForm.get('email').hasError('required')">
                Por favor digite um formato de e-mail válido
              </mat-error>
              <mat-error *ngIf="registerForm.get('email').hasError('required') && registerForm.get('email').touched">
                Email <strong>é requerido</strong>
              </mat-error>
            </div>
            <div class="input-value-three">
              <div class="label-email">
                <span>CPF/CNPJ</span>
              </div>
              <input nz-input type="text" mask="CPF_CNPJ" placeholder="Digite aqui..." 
                formControlName="cpf_cnpj" class="input-container" />
              <mat-error *ngIf="registerForm.get('cpf_cnpj').hasError('required') && registerForm.get('cpf_cnpj').touched">
                CPF/CNPJ <strong>é requerido<br></strong>
              </mat-error>
              <mat-error *ngIf="registerForm.get('cpf_cnpj').hasError('CPFInvalido')">
                CPF <strong>inválido</strong>
              </mat-error>
              <mat-error *ngIf="registerForm.get('cpf_cnpj').hasError('CNPJInvalido')">
                CNPJ <strong>inválido</strong>
              </mat-error>
            </div>
            <div class="input-value-three">
              <div class="label-email">
                <span>Celular</span>
              </div>
              <input nz-input type="text" mask="(00) 0000-0000||(00) 0 0000-0000" placeholder="Digite aqui..." 
                formControlName="cell" class="input-container" />
              <mat-error *ngIf="registerForm.get('cell').hasError('required') && registerForm.get('cell').touched">
                Celular <strong>é requerido</strong>
              </mat-error>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="label-container" style="display: flex;">
            <div  class="input-value">
              <div class="label-email">
                <span>Senha</span>
              </div>
              <input class="input-container" type="password" nz-input placeholder="Digite aqui..." formControlName="password" />
              <mat-error *ngIf="registerForm.get('password').hasError('required') && registerForm.get('password').touched">
                Senha <strong>é requerido</strong>
              </mat-error>
            </div>
            <div class="input-value-two" style="width: 50% !important;">
              <div class="label-email">
                <span>Confirmar senha</span>
              </div>
              <input class="input-container" type="password" nz-input placeholder="Digite aqui..." formControlName="confirm"
                    onpaste="return false;" (change)="validateFirstStep()"/>
              <mat-error *ngIf="registerForm.get('confirm').hasError('required') && registerForm.get('confirm').touched">
                Confirmar senha <strong>é requerido<br></strong>
              </mat-error>
              <mat-error *ngIf="confirmation">
                Senhas <strong>não conferem!</strong>
              </mat-error>
            </div>
          </div>
          <div style="color: #F0896F" class="label-container error-code" *ngIf="registerForm.get('password').invalid && (registerForm.get('password').dirty || registerForm.get('password').touched)">
              <span>*Sua senha deve conter ao menos
                <span *ngIf="registerForm.get('password').errors.minlength">8 caracteres, </span>
                <span *ngIf="registerForm.get('password').errors.lowerCaseError;">1 letra minúscula, </span>
                <span *ngIf="registerForm.get('password').errors.upperCaseError;">1 letra maiúscula, </span>
                <span *ngIf="registerForm.get('password').errors.numberError;">1 número, </span>
                <span *ngIf="registerForm.get('password').errors.symbolError;">1 caractere especial (ex: !&#64;#$%&*)</span>.
              </span>
          </div>
        </div>
  
        <div class="row next-buttons">
          <div class="steps-icon">
            <i class="material-icons steps-left-style">fiber_manual_record</i>
            <i (click)="goToNext()"
              class="material-icons-outlined steps-right-style">fiber_manual_record</i>
          </div>
          <button class="btn btn-primary enter-button" (click)="goToNext()">
            Próximo
          </button>
        </div>
      </div>
      <div *ngIf="currentStep === 1 && validateFirstStep()">
        <div class="row">
          <div class="label-container" style="margin-left: 50%; margin-bottom: -1em;">
            <span class="qtd-text">Quantidade disponível</span>
          </div>
          <div class="label-container" style="display: flex" *ngIf="!loadingPlans">
            <div class="input-value">
              <div class="label-email">
                <span>Produto</span>
              </div>
              <nz-select nzBorderless formControlName="plan" nzPlaceHolder="Selecione o produto" class="input-select">
                <nz-option *ngFor="let plan of planList" [nzValue]="plan" 
                  [nzLabel]="plan.resolution.alias + ' - ' + plan.days_storage + (plan.days_storage == 1 ? ' dia' : ' dias')"></nz-option>
              </nz-select>
              <mat-error *ngIf="registerForm.get('plan').hasError('required') && registerForm.get('plan').touched">
                Você deve selecionar um tipo de plano para efetuar o cadastro.
              </mat-error>
            </div>
            <div *ngIf="loadingPlans">
              <span nz-icon class="center" nzType="loading" nzTheme="outline" style="font-size:30px"></span>
            </div>
            <div class="input-value assets">
              <div style="display: flex">
                <div class="input-value-four">
                  <div  class="new-input-value">
                    <div class="label-email">
                      <span>Câmeras</span>
                    </div>
                    <nz-input-number class="input-container-number" (change)="compareCamera()" formControlName="cameraAmount" [nzMin]="0" [nzStep]="1"></nz-input-number>
                    <mat-error *ngIf="registerForm.get('cameraAmount').hasError('required') && registerForm.get('cameraAmount').touched">
                      Quantidade de câmeras <strong>é requerido</strong>
                    </mat-error>
                    <mat-error *ngIf="registerForm.get('cameraAmount').hasError('invalid')">
                      Você ultrapassou o limite permitido <strong>({{this.data.data.cameras_avaiable}} câmeras)</strong>
                    </mat-error>
                  </div>
                </div>
                <div class="input-value-four">
                  <div class="new-input-value-mid">
                    <div class="label-email">
                      <span>Detecções</span>
                    </div>
                    <nz-input-number class="input-container-number" (change)="compareDetections()" formControlName="detectionsAmount" [nzMin]="0" [nzStep]="1"></nz-input-number>
                    <mat-error *ngIf="registerForm.get('detectionsAmount').hasError('required') && registerForm.get('detectionsAmount').touched">
                      Quantidade de detecções <strong>é requerido</strong>
                    </mat-error>
                    <mat-error *ngIf="registerForm.get('detectionsAmount').hasError('invalid')">
                      Você ultrapassou o limite permitido <strong>({{this.data.data.analytics_avaiable}} detecções)</strong>
                    </mat-error>
                  </div>
                </div>
                <div class="input-value-four">
                  <div class="new-input-value-two">
                    <div class="label-email">
                      <span>Convidados</span>
                    </div>
                    <nz-input-number class="input-container-number" formControlName="guestsAmount" [nzMin]="0" [nzStep]="1"></nz-input-number>
                    <mat-error *ngIf="registerForm.get('guestsAmount').hasError('required') && registerForm.get('guestsAmount').touched">
                      Quantidade de detecções <strong>é requerido</strong>
                    </mat-error>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="row row-lable" style="margin-top: 1.2rem;">
          <div class="label-container slider background-filled">
            <span class="slider-text">Central de monitoramento</span>
            <nz-switch formControlName="centralMonitoramento"></nz-switch>
          </div>
        </div>
  
        <div class="row row-lable" [hidden]="!registerForm.get('centralMonitoramento').value">
          <div class="label-container slider">
            <span class="slider-text">Botão de pânico</span>
            <nz-switch formControlName="botaoPanico"></nz-switch>
          </div>
        </div>
  
        <div *ngIf="registerForm.get('centralMonitoramento').value" class="row row-lable justify-content-start">
          <div class="label-container slider background-filled">
            <span class="slider-text">Contato de emergência</span>
          </div>
        </div> 
        <div [hidden]="!registerForm.get('centralMonitoramento').value" class="row">
          <div class="label-container" style="display: flex;">
            <div  class="input-value">
              <div class="label-email">
                <span>Nome</span>
              </div>
              <input class="input-container" type="text" nz-input placeholder="Digite aqui..." formControlName="contactEmergency" />
              <mat-error *ngIf="registerForm.get('contactEmergency').hasError('required') && registerForm.get('contactEmergency').touched">
                Nome <strong>é requerido</strong>
              </mat-error>
            </div>
            <div class="input-value-two">
              <div class="label-email">
                <span>Celular</span>
              </div>
              <input class="input-container" type="text" nz-input placeholder="Digite aqui..." 
                formControlName="phoneEmergency" mask="(00) 0000-0000||(00) 0 0000-0000" (change)="validateEmergencyNumber()" />
              <mat-error *ngIf="registerForm.get('phoneEmergency').hasError('required') && registerForm.get('phoneEmergency').touched">
                Celular <strong>é requerido</strong>
              </mat-error>
            </div>
          </div>
        </div>
        <div class="row next-buttons">
          <div class="steps-icon">
            <i (click)="goToPrevious()"
            class="material-icons-outlined steps-left-style">fiber_manual_record</i>
            <i class="material-icons steps-right-style">fiber_manual_record</i>
          </div>
          <button class="btn btn-primary btn-ant enter-button" (click)="onRegister()" *ngIf="!loading" 
                  [disabled]="registerForm.get('plan').hasError('required') ||
                  registerForm.get('cameraAmount').hasError('required') ||
                  registerForm.get('detectionsAmount').hasError('required') ||
                  registerForm.get('guestsAmount').hasError('required')">
            Adicionar cliente
          </button>
          <div *ngIf="loading">
            <span nz-icon class="center" nzType="loading" nzTheme="outline" style="font-size:35px"></span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="childProfile == 'Convidado'">
      <div class="row">
        <div class="label-container" style="display: flex;">
          <div  class="input-value">
            <div class="label-email">
              <span>Quantidade de câmeras</span>
            </div>
            <nz-input-number class="input-container-number" (change)="compareCamera()" formControlName="cameraAmount" [nzMin]="0" [nzStep]="1"></nz-input-number>
            <mat-error *ngIf="registerForm.get('cameraAmount').hasError('required') && registerForm.get('cameraAmount').touched">
              Quantidade de câmeras <strong>é requerido</strong>
            </mat-error>
            <mat-error *ngIf="registerForm.get('cameraAmount').hasError('invalid')">
              Você ultrapassou o limite permitido <strong>({{this.data.data.cameras_avaiable}} câmeras)</strong>
            </mat-error>
          </div>
          <div class="input-value-two">
            <div class="label-email">
              <span>Quantidade de detecções</span>
            </div>
            <nz-input-number class="input-container-number" (change)="compareDetections()" formControlName="detectionsAmount" [nzMin]="0" [nzStep]="1"></nz-input-number>
            <mat-error *ngIf="registerForm.get('detectionsAmount').hasError('required') && registerForm.get('detectionsAmount').touched">
              Quantidade de detecções <strong>é requerido</strong>
            </mat-error>
            <mat-error *ngIf="registerForm.get('detectionsAmount').hasError('invalid')">
              Você ultrapassou o limite permitido <strong>({{this.data.data.analytics_avaiable}} detecções)</strong>
            </mat-error>
          </div>
        </div>
      </div>
  
      <div class="row justify-content-center">
        <div class="label-container" *ngIf="!loadingPlans">
          <div class="label-email">
            <span>Produto</span>
          </div>
          <nz-select nzBorderless formControlName="plan" nzPlaceHolder="Selecione o produto" class="input-select">
            <nz-option *ngFor="let plan of planList" [nzValue]="plan" 
              [nzLabel]="plan.resolution.alias + ' - ' + plan.days_storage + (plan.days_storage == 1 ? ' dia' : ' dias')"></nz-option>
          </nz-select>
          <mat-error *ngIf="registerForm.get('plan').hasError('required') && registerForm.get('plan').touched">
            Você deve selecionar um tipo de plano para efetuar o cadastro.
          </mat-error>
        </div>
        <div *ngIf="loadingPlans" class="row">
          <span nz-icon class="center" nzType="loading" nzTheme="outline" style="font-size:30px"></span>
        </div>
      </div>
  
      <div class="row row-lable" style="margin-top: 1.2rem;">
        <div class="label-container slider background-filled">
          <span class="slider-text">Central de monitoramento</span>
          <nz-switch formControlName="centralMonitoramento"></nz-switch>
        </div>
      </div>
  
      <div class="row row-lable" [hidden]="!registerForm.get('centralMonitoramento').value">
        <div class="label-container slider">
          <span class="slider-text">Botão de pânico</span>
          <nz-switch formControlName="botaoPanico"></nz-switch>
        </div>
      </div>
  
      <div *ngIf="registerForm.get('centralMonitoramento').value" class="row row-lable justify-content-start">
        <div class="label-container slider background-filled">
          <span class="slider-text">Contato de emergência</span>
        </div>
      </div> 
      <div [hidden]="!registerForm.get('centralMonitoramento').value" class="row">
        <div class="label-container" style="display: flex;">
          <div  class="input-value">
            <div class="label-email">
              <span>Nome</span>
            </div>
            <input class="input-container" type="text" nz-input placeholder="Digite aqui..." formControlName="contactEmergency" />
            <mat-error *ngIf="registerForm.get('contactEmergency').hasError('required') && registerForm.get('contactEmergency').touched">
              Nome <strong>é requerido</strong>
            </mat-error>
          </div>
          <div class="input-value-two">
            <div class="label-email">
              <span>Celular</span>
            </div>
            <input class="input-container" type="text" nz-input placeholder="Digite aqui..." 
              formControlName="phoneEmergency" mask="(00) 0000-0000||(00) 0 0000-0000" (change)="validateEmergencyNumber()" />
            <mat-error *ngIf="registerForm.get('phoneEmergency').hasError('required') && registerForm.get('phoneEmergency').touched">
              Celular <strong>é requerido</strong>
            </mat-error>
          </div>
        </div>
      </div>
      <div class="flex create-button">
        <button class="btn btn-primary enter-button" (click)="convertClient()" *ngIf="!loading">
          Adicionar cliente
        </button>
        <div *ngIf="loading">
          <span nz-icon class="center" nzType="loading" nzTheme="outline" style="font-size:35px"></span>
        </div>
      </div>
    </div>
  </form>
  </div>
</div>
