import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import { combineLatest, distinctUntilChanged, Subject, Subscription, takeUntil, zip } from 'rxjs';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CameraActions} from '../Services/actions';
import {Store} from '@ngrx/store';
import {Camera, CheckResolution, ModalState, Plan} from '../models';
import {AppState} from 'app/store/model';
import {SharedService} from 'app/Shared/Services/shared.service';
import {Style} from 'ol/style';
import {Translate} from 'ol/interaction';
import Map from 'ol/Map';
import VectorSource from 'ol/source/Vector';
import OSM from 'ol/source/OSM';
import View from 'ol/View';
import Feature from 'ol/Feature';
import {ValidationResponseHandlerModule} from 'app/Shared/ValidationResponse/validation-response-handler';
import {ImageService} from 'app/Shared/Services/image.service';
import {OperationStatus} from 'app/Shared/models';

@Component({
    selector: 'app-camera-edit-modal',
    templateUrl: './modal-edit.component.html',
    styleUrls: ['./camera-edit.component.scss']
})
export class CameraEditModalComponent implements OnInit, OnDestroy {
    readonly plans$ = this.store.select((state: AppState) => state.camera.plans);
    readonly checkResolu$ = this.store.select((state: AppState) => state.camera.check_resolution);
    getCams: Subscription;
    getPlans: Subscription;
    prevCam: Subscription;
    checkResolution: Subscription;
    cameraName: string;
    cameraForm = new UntypedFormGroup({
        nome_camera: new UntypedFormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(250), Validators.pattern(/^(?!\s{1})((?!\s{2,}).)*$/)]),
        link_rtsp: new UntypedFormControl('', [Validators.required, Validators.pattern(/^(?!\s{1})((?!\s{2,}).)*$/)]),
        isCameraOnline: new UntypedFormControl(),
        data_cadastro: new UntypedFormControl(),
        CEP: new UntypedFormControl(''),
        street: new UntypedFormControl(''),
        num: new UntypedFormControl(''),
        complemento: new UntypedFormControl(''),
        cidade: new UntypedFormControl(''),
        estado: new UntypedFormControl(''),
        bairro: new UntypedFormControl(''),
        plan: new UntypedFormControl()
    });
    intregatedCamForm = new UntypedFormGroup({
        user: new UntypedFormControl('', [Validators.required]),
        model_hash: new UntypedFormControl('', [Validators.required]),
        model_name: new UntypedFormControl('', [Validators.required]),
        url_http: new UntypedFormControl('', [Validators.required]),
        password: new UntypedFormControl('', [Validators.required]),
    });
    plansList: Plan[] = [];
    plano_id: number;
    plano_id_actual: number;
    save_changes: boolean;
    editBoolean: boolean;
    retryBoolean: boolean;
    camModel: {
        hash: string;
        name: string;
    }[];
    loadingCep = false;
    loadingGeo = false;
    longitude: number = null;
    latitude: number = null;
    hasMarker = false;
    public map: Map;
    public source: OSM;
    public view: View;
    iconFeature: Feature;
    iconStyle: Style;
    vectorSource: VectorSource;
    translate: Translate;
    mapShow = false;
    scrollbarCam: any;
    sub;
    loading = false;
    is_guest = false;
    id_camera: number;
    link_rtsp;
    selectNewPlan = true;
    addressData: Subject<Camera> = new Subject<Camera>();
    currentCamera: Camera;
    cameras: Camera;
    pageName = 'camera-edit';
    partnerClient: Boolean;
    partner: Boolean;
    isClientAssociate: boolean = localStorage.getItem('profile_name') === '419bea06-5d4e-4a56-b8b5-04b3ad566d59';
    adminClientAssociate: boolean;
    activeTab = 'dados';
    constructor(public dialogRef: MatDialogRef<CameraEditModalComponent>, @Inject(MAT_DIALOG_DATA) public camera: Camera,
        private readonly store: Store<AppState>, private builder: UntypedFormBuilder, private service: SharedService,
        private validationResponse: ValidationResponseHandlerModule, private imgService: ImageService) {
    }

    ngOnInit() {
        const containerCameras = document.querySelector('#containerEdit') as HTMLElement;
        if (localStorage.getItem('profile_name') == '61902d2b-3ada-49f3-b42a-1775bc064bb0') {
            this.partnerClient = true;
        } else if (localStorage.getItem('profile_name') == 'cd343bfc-17e8-11ec-9621-0242ac130002'){
            this.partner = true;
        } else {
            this.partnerClient = false;
            this.partner = false;
        }

        this.adminClientAssociate = this.isClientAssociate && localStorage.getItem('associate_permission') === 'e816c560-812e-11ed-a1eb-0242ac120002';

        if (localStorage.getItem('profile_name') == 'bb653b3a-fdb3-4438-bce6-012585b5268f' || (this.isClientAssociate && !this.adminClientAssociate)) {
            this.is_guest = true;
        }

        this.currentCamera = this.camera;
        // this.scrollbarCam = new PerfectScrollbar(containerCameras);
        this.save_changes = false;
        this.camInformations();
        this.plans();
        this.cameraForm.valueChanges.subscribe(value => {
            this.save_changes = true;
        });
        this.intregatedCamForm.valueChanges.subscribe(value => {
            this.save_changes = true;
        });
        this.sub = localStorage.getItem('sub');
        this.getCheckResolutionResult();
        this.changeTabs('dados');
    }

    ngOnDestroy() {
        if (this.getCams) {
            this.getCams.unsubscribe();
        }
        if (this.getPlans) {
            this.getPlans.unsubscribe();
        }
        if (this.prevCam) {
            this.prevCam.unsubscribe();
        }
        if (this.checkResolution) {
            this.checkResolution.unsubscribe();
        }
        this.plano_id = undefined;
        this.store.dispatch(CameraActions.close_modal_update_camera_cancel());
    }

    getIdPlan(id_plan: number) {
        if (this.cameraForm.get('plan').value !== id_plan) {
            this.selectNewPlan = false;
            this.plano_id = id_plan;
        }
        else {
            this.selectNewPlan = true;
        }
    }

    // Valida a seleção de mudança de plano e guarda o id do novo plano
    onSelectPlanChange() {
        this.save_changes = true;
        this.cameraForm.get('plan').setValue(this.plano_id);
    }

    // Retorna e carrega no modal as informações da câmera
    camInformations() {
        this.cameras = this.camera;
        this.id_camera = this.camera.id;
        this.cameraName = this.camera.alias;
        this.cameraForm.get('nome_camera').setValue(this.camera.alias);
        this.cameraForm.get('link_rtsp').setValue(this.camera.link_rtsp);
        this.cameraForm.get('isCameraOnline').setValue(this.camera.is_camera_online);
        this.link_rtsp = this.camera.link_rtsp;
        this.cameraForm.get('plan').setValue(this.camera.id_plan);
        this.plano_id = this.camera.id_plan;
        this.plano_id_actual = this.camera.id_plan;
        const data = new Date(String(this.camera.registration_date));
        const dia = data.getDate().toString().padStart(2, '0');
        const mes = (data.getMonth() + 1).toString().padStart(2, '0');
        const ano = data.getFullYear();
        const hora = data.getHours();
        const minutes = data.getMinutes();
        const dataFormatada = dia + '/' + mes + '/' + ano + ' às ' + hora + ':' + minutes;
        const num = Number(this.camera.address.numero) ? Number(this.camera.address.numero) : null;
        this.cameraForm.get('data_cadastro').setValue(dataFormatada);
        this.cameraForm.get('CEP').setValue(this.camera.address.cep);
        this.cameraForm.get('num').setValue(num);
        this.cameraForm.get('bairro').setValue(this.camera.address.bairro);
        this.cameraForm.get('estado').setValue(this.camera.address.estado);
        this.cameraForm.get('cidade').setValue(this.camera.address.cidade);
        this.cameraForm.get('street').setValue(this.camera.address.rua);
        this.latitude = this.camera.address.latitude ? Number(this.camera.address.latitude) : null;
        this.longitude = this.camera.address.longitude ? Number(this.camera.address.longitude) : null;

        if (this.camera.integrated_camera != null) {
            this.intregatedCamForm.get('url_http').setValue(this.camera.integrated_camera.url_http);
            this.intregatedCamForm.get('user').setValue(this.camera.integrated_camera.login);
            this.intregatedCamForm.get('password').setValue(this.camera.integrated_camera.password);
            this.intregatedCamForm.get('model_hash').setValue(this.camera.integrated_camera.camera_model_hash);
            this.intregatedCamForm.get('model_name').setValue(this.camera.integrated_camera.camera_model_name);
        }
    }

    // Retorna as informações da lista de planos
    plans() {
        this.getPlans = this.plans$.subscribe(result => {
            if (result) {
                this.plansList = result;
                const returnPlan = ['alias', 'color', 'price', 'days', 'resolution'];
                for (let index = 0; index < returnPlan.length; index++) {
                    this.getPlanProperty(this.plano_id, returnPlan[index]);
                }
            }
        });
    }

    // Retorna as propriedades do plano e seta os estilos no front
    getPlanProperty(camera_id_plan: number, property: string): string {
        const plan = this.plansList.find(pl => pl.id === camera_id_plan);
        if (plan) {
            switch (property) {
                case 'alias':
                    return plan.alias;

                case 'color':
                    return plan.color;

                case 'price':
                    return plan.price.toString();

                case 'days':
                    return plan.days_storage.toString();

                case 'resolution':
                    return plan.resolution.alias.toString();

                case 'width':
                    return plan.resolution.width.toString();

                case 'height':
                    return plan.resolution.height.toString();

                default:
                    return 'error';
            }
        }
        else {
            return '';
        }
    }

    // Fecha o modal e muda o state da aplicação
    closeCancel() {
        this.camera.alias = this.cameraName;
        this.store.dispatch(CameraActions.close_modal_update_camera_cancel());
        this.dialogRef.close();
    }

    // cria a mudança de tabs Dados/Endereço
    changeTabs(id) {
        this.activeTab = id;
    }

    // Populando o componente de endereço
    SendDataToAddress(camera: Camera) {
        this.addressData.next(camera);
    }

    // Retorna o nome do plano
    firstWordInString(str: string): string {
        return str.split(' ')[0];
    }

    validateAddress(): boolean {
        if (this.cameraForm.get('street').value != undefined && this.cameraForm.get('CEP').value != undefined && this.cameraForm.get('bairro').value != undefined
      && this.cameraForm.get('estado').value != undefined && this.cameraForm.get('cidade').value != undefined
        ) {
            return true;
        } else {
            return false;
        }
    }

    // Atualiza as informações da câmera
    updateCameras() {
        let addresObject = null;
        this.loading = true;
        const userSub = localStorage.getItem('sub');
        if (this.validateAddress()) {
            addresObject = this.addAddress();
        }
        else {
            addresObject = this.addressEmpty();
        }
        const camera: Camera = {
            id: this.id_camera,
            alias: this.cameraForm.get('nome_camera').value,
            id_plan: typeof this.plano_id === 'number' ? this.plano_id : this.plano_id_actual,
            address: addresObject,
            link_rtsp: this.cameraForm.get('link_rtsp').value,
            is_camera_online: this.cameraForm.get('isCameraOnline').value,
            integrated_camera_status: this.retryBoolean ? this.retryBoolean : false,
            cam_model: {
                hash: this.intregatedCamForm.get('model_hash').value,
                name: this.intregatedCamForm.get('model_name').value
            },
            url_http: this.intregatedCamForm.get('url_http').value,
            cam_user: this.intregatedCamForm.get('user').value,
            cam_pass: this.intregatedCamForm.get('password').value,
        };
        this.store.dispatch(CameraActions.update_camera_infor({
            camera: camera,
            user_sub: userSub,
            child_sub: localStorage.getItem('clientView') ? localStorage.getItem('clientView') : null
        }));
        this.imgService.deleteCacheThumb(this.camera.thumbnail);
    }

    addAddress(): Object {
        const addressInfo = {
            rua: this.cameraForm.get('street').value,
            numero: this.cameraForm.get('num').value,
            complemento: this.cameraForm.get('complemento').value,
            cep: this.cameraForm.get('CEP').value,
            bairro: this.cameraForm.get('bairro').value,
            estado: this.cameraForm.get('estado').value,
            cidade: this.cameraForm.get('cidade').value,
            latitude: this.latitude ? String(this.latitude) : null,
            longitude: this.longitude ? String(this.longitude) : null,
        };
        return addressInfo;
    }

    addressEmpty(): Object {
        const addressInfo = {};
        return addressInfo;
    }

    // Valida o RTSP e após isso, chama a ação de updateCameras
    saveChanges() {
        this.loading = true;
        if (this.cameraForm.valid && this.editBoolean) {
            const isOnlineCamera = this.cameraForm.get('isCameraOnline').value;
            if (((this.link_rtsp != this.cameraForm.get('link_rtsp').value) ||
            ((typeof this.plano_id === 'number' && this.cameraForm.get('plan').value != this.plano_id))) && isOnlineCamera) {
                const plan = this.plansList.find(p => p.id == (typeof this.plano_id === 'number' ? this.plano_id : this.cameraForm.get('plan').value));
                const checkResolution: CheckResolution = {
                    uri: this.camera.protocol == 'RTMP' ? this.camera.link_rtmp : this.cameraForm.get('link_rtsp').value,
                    height: +plan.resolution.height,
                    width: +plan.resolution.width,
                    fps: plan.max_fps,
                };
                this.store.dispatch(CameraActions.check_camera_resolution({
                    check_resolution: checkResolution
                }));
            }else {
                this.updateCameras();
            }
        }
        else if ((localStorage.getItem('profile_name') == 'cd343bfc-17e8-11ec-9621-0242ac130002') && (this.cameraForm.get('nome_camera').valid) && (this.editBoolean))
        {
            this.updateCameras();
        }
        else {
            this.loading = false;
            this.validationResponse.validationResponseHandler(400, this.pageName, 'all-inputs-camera', 'cameras.all_inputs');
        }
    }


    changeAddress(newCameraAddress: Object) {
        const num = Number(newCameraAddress['numero']) ? Number(newCameraAddress['numero']) : null;
        this.cameraForm.get('CEP').setValue(newCameraAddress['cep']);
        this.cameraForm.get('num').setValue(num);
        this.cameraForm.get('bairro').setValue(newCameraAddress['bairro']);
        this.cameraForm.get('estado').setValue(newCameraAddress['estado']);
        this.cameraForm.get('cidade').setValue(newCameraAddress['cidade']);
        this.cameraForm.get('street').setValue(newCameraAddress['rua']);
        this.latitude = newCameraAddress['latitude'] ? Number(newCameraAddress['latitude']) : null;
        this.longitude = newCameraAddress['longitude'] ? Number(newCameraAddress['longitude']) : null;
    }

    integratedCameraDataChanges(camera: Camera) {
        this.intregatedCamForm.get('url_http').setValue(camera.integrated_camera.url_http);
        this.intregatedCamForm.get('user').setValue(camera.integrated_camera.login);
        this.intregatedCamForm.get('password').setValue(camera.integrated_camera.password);
        this.intregatedCamForm.get('model_hash').setValue(this.camera.integrated_camera.camera_model_hash);
        this.intregatedCamForm.get('model_name').setValue(this.camera.integrated_camera.camera_model_name);
    }

    saveDataChanges(camera: Camera) {
        this.cameraForm.get('nome_camera').setValue(camera.alias);
        this.cameraForm.get('link_rtsp').setValue(camera.link_rtsp);
        this.cameraForm.get('isCameraOnline').setValue(camera.is_camera_online);
        if (this.camera.integrated_camera != null) {
            this.intregatedCamForm.get('url_http').setValue(camera.integrated_camera.url_http);
            this.intregatedCamForm.get('user').setValue(camera.integrated_camera.login);
            this.intregatedCamForm.get('password').setValue(camera.integrated_camera.password);
            this.intregatedCamForm.get('model_hash').setValue(this.camera.integrated_camera.camera_model_hash);
            this.intregatedCamForm.get('model_name').setValue(this.camera.integrated_camera.camera_model_name);
        }
    }

    camEditDataTrue(value: boolean) {
        this.editBoolean = value;
        this.saveChanges();
    }

    camRetryDataTrue(value: boolean) {
        this.retryBoolean = value;
        this.updateCameras();
    }

    getCheckResolutionResult() {
        this.checkResolution = this.checkResolu$.subscribe(result => {
            if (result) {
                if (result.status === OperationStatus.Success) {
                    this.updateCameras();
                }
                else if (result.status === OperationStatus.Fail) {
                    this.validationResponse.validationResponseHandler(404, 'camera-form', 'preview-camera', 'cameras.preview_not_found');
                    this.loading = false;
                    this.store.dispatch(CameraActions.clear_camera_resolution());
                }
            }
        });
    }

    selectedPlan(id_plan) {
        const actualPlan = this.plansList.filter((plan) => {
            if (plan.id === id_plan) {
                document.getElementById(id_plan + plan.alias)?.classList.add('plan_active');
            }
            else {
                document.getElementById(plan.id + plan.alias)?.classList.add('plan_inactive');
            }
        });
    }

    changePlan(id_plan) {
        const newPlan = this.plansList.filter((plan) => {
            if (plan.id === id_plan) {
                document.getElementById(id_plan + plan.alias).classList.add('plan_active');
            }
            else {
                const planId = document.getElementById(plan.id + plan.alias);
                if (planId) {
                    if (planId.classList.contains('plan_active')) {
                        planId.classList.remove('plan_active');
                    }
                    planId.classList.add('plan_inactive');
                }
            }
        });
        if (this.cameraForm.get('plan').value !== id_plan) {
            this.selectNewPlan = false;
            this.plano_id = id_plan;
            // this.cameraForm.get('plan').setValue(id_plan);
        }
        else {
            this.selectNewPlan = true;
        }
    }
}
// Componente real, que possui a inicialização real do modal, recebendo os states e dados
@Component({
    selector: 'app-camera-edit',
    templateUrl: './camera-edit.component.html',
    styleUrls: ['./camera-edit.component.scss']
})
export class CameraEditComponent implements OnInit, OnDestroy {
    readonly modal_open$ = this.store.select((state: AppState) => state.camera.modal_open_update_camera);
    readonly cam$ = this.store.select((state: AppState) => state.camera.camera_view);
    @Output() loadingCameraList: EventEmitter<boolean> = new EventEmitter;
    @Output() exitModal: EventEmitter<boolean> = new EventEmitter<boolean>();
    currentDialog: MatDialogRef<any, any> = null;
    destroy = new Subject<void>();
    subDialog: Subscription;
    subCombined: Subscription;
    constructor(public dialog: MatDialog, private readonly store: Store<AppState>) {
    }

    ngOnInit() {
        this.subCombined = combineLatest([this.modal_open$, this.cam$]).pipe(takeUntil(this.destroy)).subscribe(([modal, cam]) => {
            if (modal == ModalState.Open && cam) {
                this.currentDialog = this.dialog.open(CameraEditModalComponent, {
                    panelClass: 'edit-dialog-modal-container',
                    backdropClass: 'lightBackdrop',
                    data: cam,
                    height: 'fit-content',
                    maxHeight: '95vh',
                });
                this.subDialog = this.currentDialog.afterClosed().subscribe(result => {
                    if (result) {
                        this.store.dispatch(CameraActions.close_modal_update_camera_cancel());
                        this.loadingCameraList.emit(true);
                        const userSub = localStorage.getItem('sub');
                        this.store.dispatch(CameraActions.update_camera_infor({
                            camera: result,
                            user_sub: userSub,
                            child_sub: localStorage.getItem('clientView') ? localStorage.getItem('clientView') : null
                        }));
                    }
                });
            } else if (modal == ModalState.Closed || modal == ModalState.Cancel) {
                this.dialog.closeAll();
                this.exitModal.emit(true);
            }
        });
    }

    ngOnDestroy() {
        if (this.subDialog) {
            this.subDialog.unsubscribe();
        }
        if (this.subCombined) {
            this.subCombined.unsubscribe();
        }
        this.destroy.next();
        this.destroy.complete();
    }
}
