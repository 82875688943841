import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from 'app/Shared/Services/shared.service';
import { Subscription } from 'rxjs';
import { ModalData, TypesModals } from '../../Shared/models';

@Component({
    selector: 'app-modal-content',
    templateUrl: './modal-content.component.html',
})
export class ModalContentComponent {
    typesModals = new TypesModals();
    // Variaveis de escolha
    hideComponent: boolean;
    manegeComponent: boolean;
    alertComponent: boolean;
    partnerComponent: boolean;
    addDetections: boolean;
    kitComponent: boolean;
    hideEditPaymentMethodModal: boolean;
    addClient: boolean;
    editClient: boolean;
    addKit: boolean;
    requestKits: boolean;
    deleteRTMP: boolean;
    editKit: boolean;
    addGroup: boolean;
    configGroup: boolean;
    addInfoGroup: boolean;
    addCamGuest: boolean;
    viewCamera: boolean;
    viewGLPI: boolean;
    configCalendar: boolean;
    createCalendar: boolean;
    allHolidayCalendar: boolean;
    deleteKit: boolean;

    modalData: ModalData;
    constructor(
        private service: SharedService,
        public dialogRef: MatDialogRef<ModalContentComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ModalData) {
        this.modalData = data;
        this.choiceComponent(data.tipoModal);
    }

    choiceComponent(data) {
        switch (data) {
            case this.typesModals.editPayments:
                this.hideComponent = false;
                break;

            case this.typesModals.manageCameras:
                this.manegeComponent = false;
                break;

            case this.typesModals.videoDetections:
                this.alertComponent = false;
                break;

            case this.typesModals.editPaymentMethod:
                this.hideEditPaymentMethodModal = false;
                break;

            case this.typesModals.addPartner:
                this.partnerComponent = false;
                break;

            case this.typesModals.addDetections:
                this.addDetections = false;
                break;

            case this.typesModals.addKits:
                this.kitComponent = false;
                break;

            case this.typesModals.addClient:
                this.addClient = false;
                break;

            case this.typesModals.editClient:
                this.editClient = false;
                break;

            case this.typesModals.addKitMaster:
                this.addKit = false;
                break;

            case this.typesModals.requestKit:
                this.requestKits = false;
                break;

            case this.typesModals.deleteRTMP:
                this.deleteRTMP = false;
                break;

            case this.typesModals.editKit:
                this.editKit = false;
                break;

            case this.typesModals.addGroupCamera:
                this.addGroup = false;
                break;

            case this.typesModals.configGroupCamera:
                this.configGroup = false;
                break;

            case this.typesModals.addInfoGroupCamera:
                this.addInfoGroup = false;
                break;

            case this.typesModals.inviteCamGuests:
                this.addCamGuest = false;
                break;

            case this.typesModals.viewLiveCamera:
                this.viewCamera = false;
                break;

            case this.typesModals.configHolidayCalendar:
                this.configCalendar = false;
                break;

            case this.typesModals.createHolidayCalendar:
                this.createCalendar = false;
                break;

            case this.typesModals.allHolidayCalendar:
                this.allHolidayCalendar = false;
                break;

            case this.typesModals.deleteKitPartner:
                this.deleteKit = false;
                break;

      // case this.typesModals.viewGLPI:
      //   this.viewGLPI = false;
      //   break;
        }
    }

    onNoClick(): void {
        this.hideComponent = true;
        this.manegeComponent = true;
        this.alertComponent = true;
        this.hideEditPaymentMethodModal = true;
        this.partnerComponent = true;
        this.addDetections = true;
        this.kitComponent = true;
        this.addClient = true;
        this.editClient = true;
        this.addKit = true;
        this.requestKits = true;
        this.deleteRTMP = true;
        this.editKit = true;
        this.addGroup = true;
        this.configGroup = true;
        this.addInfoGroup = true;
        this.addCamGuest = true;
        this.viewCamera = true;
        this.viewGLPI = true;
        this.configCalendar = true;
        this.createCalendar = true;
        this.allHolidayCalendar = true;
        this.deleteKit = true;
        this.dialogRef.close();
        this.service.returnDataToModal(null);
    }
}

/**
 * @title Injecting data when opening a dialog
 */
let dataObject: ModalData;
@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy {
    returnModalData: Subscription;
    constructor(public dialog: MatDialog, private service: SharedService) { }

    ngOnInit() {
    // Recebe o retorno das informações do swal3
        this.returnModalData = this.service.modelObjectResult.subscribe(returnObject => {
            if (returnObject) {
                dataObject = returnObject;
                this.openDialog();
            }
        });
    }

    ngOnDestroy() {
        if (this.returnModalData) {
            this.returnModalData.unsubscribe();
        }
    }

    openDialog() {
    // tslint:disable-next-line: no-use-before-declare
        this.dialog.open(ModalContentComponent, {
            height: dataObject.modalHeight,
            width: dataObject.modalWidth,
            data: dataObject,
            backdropClass: dataObject.backdropClass ? dataObject.backdropClass : null,
            panelClass: 'modal-component'
        });
    }
}
