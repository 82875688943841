import { Component, OnInit, OnDestroy, AfterViewInit} from '@angular/core';
import { Router } from '@angular/router';
import { ResultAlert } from 'app/Shared/models';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/model';
import { UserActions } from 'app/Users/Services/actions';
import { Subscription } from 'rxjs';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { SharedService } from 'app/Shared/Services/shared.service';
@Component({
    selector: 'app-user-partner-view',
    templateUrl: './user-partner-view.component.html',
    styleUrls: ['./user-partner-view.component.scss']
})
export class UserPartnerViewComponent implements OnInit, OnDestroy, AfterViewInit{
    readonly deletePartner$ = this.store.select((state: AppState) => state.Users.deletePartner);

    delete_partner_sub: Subscription;
    swalDecisionSub: Subscription;
    activeTab: string;
    client_name: string;
    client_sub: string;
    user_sub: string;

    constructor(private router: Router, private readonly store: Store<AppState>, private validationResponse: ValidationResponseHandlerModule,
        private service: SharedService) { }

    ngOnInit(): void {
        this.client_name = localStorage.getItem('clientName');
        this.user_sub = localStorage.getItem('sub');
        this.client_sub = localStorage.getItem('clientView');

        this.swalDecisionSub = this.service.swalDecision.subscribe(returnSwallObject => {
            if (returnSwallObject) {
                this.validateReturnMethodCalled(returnSwallObject as ResultAlert);
            }
        });

        this.delete_partner_sub = this.deletePartner$.subscribe(delete_partner => {
            if (delete_partner) {
                if (delete_partner.status === 2) {
                    this.validationResponse.validationResponseHandler(200, 'user', 'delete-partner-result', delete_partner.message);
                    this.goToList();
                } else {
                    this.validationResponse.validationResponseHandler(400, 'user', 'delete-partner-result-fail', delete_partner.message);
                    this.store.dispatch(UserActions.cleanup());
                    this.store.dispatch(UserActions.delete_partner_clear());
                }
            }
        });
    }

    ngOnDestroy() {
        if (this.delete_partner_sub) {
            this.delete_partner_sub.unsubscribe();
        }
        if (this.swalDecisionSub) {
            this.swalDecisionSub.unsubscribe();
        }
        this.store.dispatch(UserActions.cleanup());
        this.store.dispatch(UserActions.delete_partner_clear());
        this.service.cleanSwal();
    }

    ngAfterViewInit(): void {
        const savedTab = localStorage.getItem('activeTab');
        if (savedTab) {
            this.activeTab = savedTab;
        }
        else {
            this.activeTab = 'info';
        }
    }

    goToList() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/users/partners']);
    }

    changeTab(event) {
        const tabs = document.querySelectorAll('.btn-tab');
        tabs.forEach(element => {
            element.classList.remove('active');
        });
        this.activeTab = event.currentTarget.id;
        event.currentTarget.classList.add('active');
    }

    viewPartnerList() {
        this.router.navigateByUrl('users/partners');
    }

    validateReturnMethodCalled(returnResultSwal: ResultAlert) {

        if (returnResultSwal.methodCalled === 'deletePartner') {
            if (returnResultSwal.isConfirmed) {
                this.store.dispatch(UserActions.delete_partner({
                    sub: this.user_sub,
                    child_sub: this.client_sub
                }));
            }
        }
    }

}
