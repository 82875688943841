<div class="modal-card">
    <div class="header-modal">
      <div class="container-header">
        <span class="title-modal">Adicionar Kit</span>
        <span class="title-text">Kit para ser disponibilizado para parceiros</span>
      </div>
      <div (click)="closeModal()" class="close-modal">
        <i nz-icon nzType="close" nzTheme="outline"></i>
      </div>
    </div>

    <div class="form-container">
      <form [formGroup]="registerForm">

        <div class="label-container-product" *ngIf="!loadingPlans">
          <div class="label-input">
            <span>Produto :</span>
          </div>
          <nz-select nzBorderless formControlName="plan" nzPlaceHolder="Selecione o produto" class="input-select">
            <nz-option *ngFor="let plan of plans" [nzValue]="plan.id"
              [nzLabel]="plan.resolution.alias + ' - ' + plan.days_storage + (plan.days_storage == 1 ? ' dia' : ' dias')"></nz-option>
          </nz-select>
        </div>
        <div *ngIf="loadingPlans">
          <span nz-icon class="center" nzType="loading" nzTheme="outline" style="font-size:30px"></span>
        </div>

        <div class="label-container-master">
          <div class="label-container">
            <div class="label-input">
              <span>Quantidade de câmeras :</span>
            </div>
            <nz-input-number class="input-container" formControlName="cameraAmount" [nzMin]="0" [nzStep]="1"></nz-input-number>
            <mat-error *ngIf="registerForm.get('cameraAmount').hasError('required') && registerForm.get('cameraAmount').touched">
              Quantidade de câmeras <strong>é requerido</strong>
            </mat-error>
          </div>
          <div class="label-container-two">
            <div class="label-input">
              <span>Quantidade de detecções :</span>
            </div>
            <nz-input-number class="input-container" formControlName="detectionsAmount" [nzMin]="0" [nzStep]="1"></nz-input-number>
            <mat-error *ngIf="registerForm.get('detectionsAmount').hasError('required') && registerForm.get('detectionsAmount').touched">
              Quantidade de detecções <strong>é requerido</strong>
            </mat-error>
          </div>
        </div>

      </form>
    </div>

    <div class="row justify-content-end">
      <button class="btn btn-ant btn-primary enter-button" (click)="onRegister()">
        Adicionar Kit
        <span nz-icon nzType="loading" nzTheme="outline" class="posfix" *ngIf="loading"></span>
      </button>
    </div>
</div>
