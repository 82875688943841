<div class="filter-container clickable" (click)="toggleSelect()" [ngClass]="styles"
 [class.active]="((type != 'simple') && (activeList.length > 0) && !hideActiveFill)" [class.search-filter]="search">
    <span [ngStyle]="{'font-size': '17px'}" nz-icon [nzType]="iconType" *ngIf="hasIconPrefix" nzTheme="outline"></span>
    <span *ngIf="!search && !placeholder" class="placeholder">Ordenar por {{selectedText}}</span>
    <span *ngIf="!search && placeholder && !selectedText && !dashboard" class="parent-text placeholder"
    [ngStyle]="{'color': modelCamData? 'rgba(0, 45, 89, 1)' : '$fifth-blue'}">
        {{placeholderText}}
    </span>
    <span *ngIf="!search && placeholder && !selectedText && dashboard" class="parent-text placeholder">
        {{placeholderTextDashboard}}
    </span>
    <input nz-input [(ngModel)]="searchFilter" (keyup)="filterSearch($event)" *ngIf="search && !placeholder && dashboard"
            class="holiday-input" [placeholder]="selectedText"/>
    <input nz-input [(ngModel)]="searchFilter" (keyup)="filterSearch($event)" *ngIf="search && !placeholder && !dashboard"
            class="search-input" [placeholder]="'Ordenar por ' + selectedText"/>
    <input nz-input [(ngModel)]="searchFilter" (keyup)="filterSearch($event)"
            *ngIf="search && placeholder && !selectedText && !dashboard" [placeholder]="placeholderText" class="parent-text search-input">
    <input nz-input [(ngModel)]="searchFilter" (keyup)="filterSearch($event)"
    *ngIf="search && placeholder && !selectedText && dashboard" [placeholder]="placeholderTextDashboard" class="parent-text search-input">  
    
    <div class="selected-text" [class.holiday-table]="selectAll">
        <span *ngIf="placeholder && showSelected && selectedText" [class.selected-simple-multi]="simpleMulti" class="parent-title">{{selectedText}}</span>
        <span *ngIf="placeholder && showSelected && selectedDescription" class="parent-description">{{selectedDescription}}</span>
    </div>
    <i nz-icon nzType="down" nzTheme="outline"[hidden]="selectStatus || (modelCam && list == undefined)"></i>
    <i nz-icon nzType="up" nzTheme="outline" [hidden]="!selectStatus || (modelCam && list == undefined)"></i>
    <i nz-icon nzType="loading" nzTheme="outline" *ngIf="modelCam && list == undefined"></i>
</div>

<div class="filter-open" [ngClass]="styles" [class.analyticModal]="((type == 'simple') && selectStatus) && analyticModal" [class.open]="((type == 'simple') && selectStatus)" id="simple"
    infiniteScroll [infiniteScrollDistance]="0.01" [infiniteScrollThrottle]="1" [class.model]="modelCam"
    [scrollWindow]="false" (scrolled)="onScroll()" style="overflow-x: hidden;" [class.analytic]="analytic">
    <ul>
        <li [class.hasHover]="!hideHover" [class.active]="activeList.includes(modelCam ? element : element.id)" class="clickable simple-{{element.id}}"
        *ngFor="let element of processedList" (click)="modelCam ? change(element) : change(element.id)">
            <img [hidden]="!simpleMulti" src="../../../assets/img/Icons/Checkbox.svg" class="checkbox not-checked">
            <img [hidden]="!simpleMulti" src="../../../assets/img/Icons/Checkbox_checked.svg" class="checkbox checked">
            {{element.text}}
            <span [hidden]="!simpleMulti" nz-icon nzType="check" nzTheme="outline" class="alternate-checkbox"></span>
        </li>
    </ul>

    <div class="loadingAlerts" *ngIf="loading">
        <span nz-icon nzType="loading" nzTheme="outline"></span>
    </div>
</div>

<div class="filter-open" [ngClass]="styles" [class.blocked]="blocked" [class.open]="((type == 'multiselect') && selectStatus)" [class.hasSubmit]="hasSubmit"
    [class.right]="(position == 'right')" id="multiselect" infiniteScroll [infiniteScrollDistance]="0.01" [infiniteScrollThrottle]="1"
    [scrollWindow]="false" (scrolled)="onScroll()" style="overflow-x: hidden;">
    <ul>
        <li class="select-all" *ngIf="selectAll" (click)="allCameras()"
        [class.active]="activeList.length == processedList.length">
            Todas as câmeras
            <span nz-icon nzType="check" nzTheme="outline" class="alternate-checkbox"></span>
        </li>
        <li [class.hasHover]="!hideHover" [class.active]="activeList.includes(modelCam ? element : element.id)" class="clickable simple-{{element.id}}"
        *ngFor="let element of processedList" (click)="modelCam ? change(element) : change(element.id)">
            <img src="../../../assets/img/Icons/Checkbox.svg" class="checkbox not-checked">
            <img src="../../../assets/img/Icons/Checkbox_checked.svg" class="checkbox checked">
            {{element.text}}
            <span nz-icon nzType="check" nzTheme="outline" class="alternate-checkbox"></span>
        </li>
    </ul>

    <div class="loadingAlerts" *ngIf="loading">
        <span nz-icon nzType="loading" nzTheme="outline"></span>
    </div>

    <div class="submit-buttons" *ngIf="hasSubmit">
        <div class="btn btn-ant btn-secondary" (click)="cancel()">Cancelar</div>
        <div class="btn btn-ant btn-primary" (click)="submit()">Salvar</div>
    </div>
</div>

<div class="filter-open" [ngClass]="styles" [class.open]="((type == 'multiweight') && selectStatus)" id="multiweight"
    infiniteScroll [infiniteScrollDistance]="0.01" [infiniteScrollThrottle]="1" [class.multiweight]="type == 'multiweight'"
    [scrollWindow]="false" (scrolled)="onScroll()" style="overflow-x: hidden;">
    <ul>
        <li [class.hasHover]="!hideHover" [class.active]="activeList.includes(element.id)" class="clickable multiweight-{{element.id}}" *ngFor="let element of list" (click)="change(element.id)">
            <span class="title">{{element.title}}</span>
            <span class="description">{{element.description}}</span>
        </li>
    </ul>

    <div class="loadingAlerts" *ngIf="false">
        <span nz-icon nzType="loading" nzTheme="outline"></span>
    </div>
</div>