import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent, ModalContentComponent } from '../../components/modal/modal.component';
import { TimeRangePickerComponent } from '../../components/time-range-picker/time-range-picker.component';
import { TranslateModule } from '@ngx-translate/core';
import { CameraManegeComponent } from 'app/cameras/camera-manege/camera-manege.component';
import { ClientFormComponent } from 'app/Users/user-client-form/user-client-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserAddDetectionsComponent } from 'app/Users/user-add-detections/user-add-detections.component';
// Material
import { MatInputModule } from '@angular/material/input';
import { VideoDetectionComponent } from 'app/detections/video-detection/video-detection.component';
import { UserPartnersFormComponent } from 'app/Users/user-partners-form/user-partners-form.component';
import { UserAddKitFormComponent } from 'app/Users/user-add-kit-form/user-add-kit-form.component';
import { UserClientEditComponent } from 'app/Users/user-client-edit/user-client-edit.component';
import { UserKitsAddComponent } from 'app/Users/user-kits-add/user-kits-add.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatTableModule } from '@angular/material/table';
import { UserKitRequestComponent } from 'app/Users/user-kit-request/user-kit-request.component';
import { CameraRtmpDeletionTutorialComponent } from 'app/cameras/camera-rtmp-deletion-tutorial/camera-rtmp-deletion-tutorial.component';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzTableModule } from 'ng-zorro-antd/table';
import { ComponentsModule } from 'app/components/components.module';
import { EditKitModalComponent } from 'app/Users/edit-kit-modal/edit-kit-modal.component';
import { CameraGroupCreateComponent } from 'app/cameras/camera-group-create/camera-group-create.component';
import { CameraGroupConfigComponent } from 'app/cameras/camera-group-config/camera-group-config.component';
import { AddInfoGroupModalComponent } from 'app/cameras/add-info-group/add-info-group.component';
import { CameraLiveComponent } from 'app/cameras/camera-live/camera-live.component';
import { AddCamGuestComponent } from 'app/cameras/add-cam-guest/add-cam-guest.component';
import { FeedbackComponent } from 'app/components/feedback/feedback.component';
import { GLPIComponent } from 'app/glpi/glpi.component';
import { GlpiModule } from 'app/glpi/glpi.module';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { UserCalendarConfigComponent } from 'app/Users/user-calendar-config/user-calendar-config.component';
import { UserCalendarCreateComponent } from 'app/Users/user-calendar-create/user-calendar-create.component';
import { UserCalendarListComponent } from 'app/Users/user-calendar-list/user-calendar-list.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { UserKitDeleteComponent } from 'app/Users/user-kit-delete/user-kit-delete.component';

@NgModule({
    declarations: [
        TimeRangePickerComponent,
        ModalComponent,
        ModalContentComponent,
        CameraManegeComponent,
        VideoDetectionComponent,
        UserPartnersFormComponent,
        UserAddKitFormComponent,
        ClientFormComponent,
        UserClientEditComponent,
        UserKitsAddComponent,
        UserKitRequestComponent,
        CameraRtmpDeletionTutorialComponent,
        EditKitModalComponent,
        CameraGroupCreateComponent,
        CameraGroupConfigComponent,
        AddInfoGroupModalComponent,
        CameraLiveComponent,
        AddCamGuestComponent,
        FeedbackComponent,
        GLPIComponent,
        UserCalendarConfigComponent,
        UserCalendarCreateComponent,
        UserCalendarListComponent,
        UserKitDeleteComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        MatInputModule,
        InfiniteScrollModule,
        MatTableModule,
        NzInputNumberModule,
        NzSelectModule,
        NzIconModule,
        NzInputModule,
        NzSwitchModule,
        NzDropDownModule,
        NzToolTipModule,
        NzCheckboxModule,
        ComponentsModule,
        NzPopoverModule,
        NzTableModule,
        GlpiModule,
        UserAddDetectionsComponent,
        NgxMaskDirective,
        NgxMaskPipe,
        MatFormFieldModule,
        MatDatepickerModule
    ],
    exports: [
        TimeRangePickerComponent,
        TranslateModule,
        ModalComponent,
        ModalContentComponent,
        CameraManegeComponent,
        VideoDetectionComponent,
        UserPartnersFormComponent,
        UserAddDetectionsComponent,
        ClientFormComponent,
        UserClientEditComponent,
        UserKitsAddComponent,
        InfiniteScrollModule,
        UserKitRequestComponent,
        NzIconModule,
        CameraRtmpDeletionTutorialComponent,
        EditKitModalComponent,
        CameraGroupCreateComponent,
        CameraGroupConfigComponent,
        AddInfoGroupModalComponent,
        CameraLiveComponent,
        AddCamGuestComponent,
        FeedbackComponent,
        GLPIComponent,
        UserCalendarConfigComponent,
        UserCalendarCreateComponent,
        UserCalendarListComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModulesModule { }
