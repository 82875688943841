import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Camera } from '../models';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import Hls from 'hls.js';
import * as dashjs from 'dashjs';

@Component({
    selector: 'app-camera-live',
    templateUrl: './camera-live.component.html',
    styleUrls: ['./camera-live.component.scss']
})
export class CameraLiveComponent implements OnInit, OnDestroy, AfterViewInit {
    @Output() modalClose = new EventEmitter<string>();
    hls: Hls;
    dash = dashjs.MediaPlayer().create();
    dash_is_live = false;
    intervalUnsubscribe: NodeJS.Timeout;
    video: HTMLVideoElement;
    @ViewChildren('video') openVideo: QueryList<ElementRef>;

    camera: Camera;
    camName: string;
    freezeStreaming: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit(): void {
        this.camera = this.data.data.camera;
        this.camName = this.camera.alias;
        this.dash.updateSettings(
            {
                'streaming': {
                    delay: {
                        'liveDelay': 12,
                    },
                    retryAttempts: {
                        MPD: 3,
                        XLinkExpansion: 3,
                        InitializationSegment: 3,
                        IndexSegment: 3
                    },
                    buffer: {
                        fastSwitchEnabled: false,
                        'bufferToKeep': 20, // Amount of buffer to keep in seconds (past content)
                        'stableBufferTime': 12, // Stable buffer time (in seconds) to maintain before switching quality
                        'bufferTimeAtTopQualityLongForm': 60, // Buffer time for long-form content at top quality (in seconds)
                        'bufferTimeAtTopQuality': 30, // Buffer time at top quality (in seconds),
                    },
                    'timeShiftBuffer': {
                        fallbackToSegmentTimeline: true
                    },

                },
                debug: {
                    'dispatchEvent': true,
                    logLevel: dashjs.LogLevel.LOG_LEVEL_NONE
                }
            }
        );
    }

    ngAfterViewInit() {
        this.video = this.openVideo.first.nativeElement as HTMLVideoElement;
        this.loadPlayer(this.camera);
    }

    monitorVideoDisplay(video: HTMLVideoElement) {
        this.intervalUnsubscribe = setInterval(() => {
            if (video.readyState < video.HAVE_ENOUGH_DATA && !this.dash_is_live) {
                this.hls.destroy();
                this.video.src = '';
                this.loadPlayer(this.camera, true);
                this.dash_is_live = true;
                clearInterval(this.intervalUnsubscribe);
            }
        }, 15000);
    }

    async loadPlayer(camera: Camera, switchToDash = false) {
        if (switchToDash) {
            const url = camera.url_view.replace('playlist.m3u8', 'manifest_mvnumber.mpd');
            this.dash.initialize(this.video, url, true);
        } else {
            if (Hls.isSupported()) {
            // Cria um novo objeto Hls.
                const hls = new Hls({
                    'enableWorker': true,
                    'lowLatencyMode': false,
                    'backBufferLength': 90,
                    debug: false
                });
                // Carrega a fonte de vídeo fornecida na variável camera.url_view.
                hls.loadSource(camera.url_view);
                // Anexa o player ao elemento de vídeo associado.
                hls.attachMedia(this.video);
                // Salva a instância Hls criada para uso posterior.
                this.hls = hls;

                this.monitorVideoDisplay(this.video);

                // Evento acionado quando o arquivo m3u8 é baixado com sucesso e o player está pronto para tocar.
                hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
                    console.log(`Manifest loaded, found ${data.levels.length} quality level(s).`);
                    camera.status = true;
                });

                // Evento acionado sempre um novo fragmento de vídeo é carregado pelo player
                hls.on(Hls.Events.FRAG_LOADED, function (event, data) {
                    camera.status = true;
                });

                // Evento acionado para travar streaming
                this.video.addEventListener('loadedmetadata', () => {
                    this.freezeStream(hls);
                });

                let hiddenStartTime;
                document.addEventListener('visibilitychange', () => {
                    if (document.hidden) {
                        hiddenStartTime = Date.now();
                    } else {
                        const timeAway = (Date.now() - hiddenStartTime) / 1000;
                        if (timeAway >= 300) {
                            this.video.playbackRate = 0;
                            hls.stopLoad();
                            this.freezeStreaming = true;
                        } else {
                            this.freezeStream(hls);
                        }
                    }
                });

                // Evento acionado quando ocorre algum erro no player.
                hls.on(Hls.Events.ERROR, (event, data) => {
                    switch (data.type) {
                        case Hls.ErrorTypes.NETWORK_ERROR:
                            camera.status = false;
                            hls.startLoad();
                            // Tentar recarregar o conteúdo em caso de erro de rede.
                            break;
                        case Hls.ErrorTypes.MEDIA_ERROR:
                            hls.recoverMediaError();
                            // Tentar recuperar o conteúdo em caso de erro de mídia.
                            break;
                        default:
                            hls.destroy();
                            // Destruir o player em caso de outros tipos de erros.
                            break;
                    }
                });
            }
        }
    }

    ngOnDestroy(): void {
        this.hls.destroy();
        this.video.src = '';
        this.closeModal();
        clearInterval(this.intervalUnsubscribe);
    }

    freezeStream(hls) {
        setTimeout(() => {
            this.video.pause();
            hls.stopLoad();
            this.freezeStreaming = true;
        }, 300000);
    }


    closeModal() {
        this.modalClose.emit();
    }


}
