<div [ngStyle]="{'lt-md:min-width.px': 600}" class="lt-md:min-w-600px md:min-w-0 card custom-card container_global">
    <div class="flex flex-row" *ngIf="client_sub !== null">
        <div class="card">
            <div class="card-header card-config-analy">
                <div class="container_tolltip_infor">
                    <span class="need_help tooltip_without_flag">
                        <span class="tooltiptexthelp">Modo de configuração
                            <i class="material-icons icon_out">info_outline</i>
                        </span>
                    </span>
                    <div class="tooltiptext" id="tooltip_help">
                        <span class="tooltiptextblack">Em teste</span>
                        <br>
                        <span class="tooltiptextsoft">No modo de teste, os alertas gerados chegam para o parceiro, e não 
                            para o cliente.</span>
                        <br><br>
                        <span class="tooltiptextblack">Ativo</span>
                        <br>
                        <span class="tooltiptextsoft">No modo ativo, 
                            os alertas gerados chegam apenas para o cliente.</span>
                        <br>
                    </div>
                </div>
                <nz-radio-group [formControl]="testOption" class="radio-group-position">
                    <label nz-radio class="radio-button-mask" [nzValue]="2">Em teste</label>
                    <label nz-radio style="margin-left: 12%;" class="radio-button-mask" [nzValue]="1">Ativo</label>
                </nz-radio-group>
            </div>
        </div>
    </div>
    <div class="flex flex-row gap-3.5">
        <div class="flex w-1/2 lt-md:w-full" [ngClass]="'gt-sm:getContainerWidth()'">
            <div class="card">
                <div class="card-header card-config-analy">
                    <!-- Selecionar Tipo de Analíticos -->
                    <div class="row containerTitle">
                        <span class="TitleInterval">Detecção inteligente</span>
                    </div>
                    <app-filter [list]="typeListSimple" type="simple" placeholder="Selecionar..." [analytic]="true"
                        (filterStatusChange)="selectAnalytic($event)" [showSelected]="true" [styles]="['simple-alternate-style']"
                        [hasIconPrefix]="false" [hasToggle]="true" [selected]="selectedTypeAnalytic"></app-filter>
                </div>
            </div>
        </div>
        <div class="flex w-1/2 gt-sm:w-58 lt-md:w-full" *ngIf="newAnalytic.alias">
            <div class="card" *ngIf="showClasses()">
                <div class="container_title_top"><span class="title_for_classification title_for_classification_what">O que deseja detectar?</span>
                    <span class="title_for_classification objects">Objetos</span>
                </div>
                <div class="container_for_tags" *ngIf="!loadingClass; else loadingclass"
                    [formControl]="selectClassifiers" name="classifier" ngDefaultControl>
                    <div (click)="choiceObjetcs(classifiers)" [id]="classifiers['alias']" class="col border_style tags"
                        *ngFor="let classifiers of optionsClassifiers"
                        [ngClass]="{'border_style_active' : upClassifiers.includes(classifiers['alias'])}">
                        <span class="classification_tags">{{classifiers.alias}}</span>
                    </div>
                </div>
                <ng-template #loadingclass>
                    <span nz-icon nzType="loading" nzTheme="outline" style="font-size: 36px"></span>
                </ng-template>
            </div>
            <div class="card" *ngIf="newAnalytic.alias === 'Tampering'">
                <div class="card-header card-config-analy">
                    <div class="row containerTitle">
                        <span class="TitleInterval">Tipo de análise</span>
                    </div>
                    <app-filter [list]="selectAnalytics.value.events" type="simple" placeholder="Selecione aqui..."
                                (filterStatusChange)="selectAnalyticEvents($event)" [showSelected]="true" [styles]="['simple-alternate-style']"
                                [hasIconPrefix]="false" [hasToggle]="true" [selected]="selectedEventsAnalytics"></app-filter>
                </div>
            </div>
            <div class="card"  *ngIf="newAnalytic.alias === 'Detecção de Máscara'">
                <div class="card-header">
                    <div class="row style-title-alertas alert-label" style="margin-left: 0%;">
                        <label class="mask-label">Alertar quando estiver:</label>
                    </div>
                    <nz-radio-group [formControl]="checkOption" class="radio-group-position">
                        <label nz-radio class="radio-button-mask" (click)="this.newAnalytic.events = ['notmask']" [nzValue]="true">Sem a máscara</label>
                        <label nz-radio class="radio-button-mask" (click)="this.newAnalytic.events = ['mask']" [nzValue]="false">Com a máscara</label>
                    </nz-radio-group>
                </div>
            </div>
            <div class="card" *ngIf="newAnalytic.alias === 'Detecção de Capacete'">
                <div class="card-header">
                    <div class="row style-title-alertas alert-label" style="margin-left: 0%;">
                        <label class="mask-label">Alertar quando estiver:</label>
                    </div>
                    <nz-radio-group [formControl]="checkOption" class="radio-group-position">
                        <label nz-radio class="radio-button-mask" (click)="this.newAnalytic.events = ['nothelmet']" [nzValue]="true">Sem capacete</label>
                        <label nz-radio class="radio-button-mask" (click)="this.newAnalytic.events = ['helmet']" [nzValue]="false">Com capacete</label>
                    </nz-radio-group>
                </div>
            </div>
        </div>
    </div>
    <!-- INFORMAÇÕES EXTRA -->
    <div *ngIf="extraInformation()"
        class="row">
        <div class="card-info extra-info">
            <div class="card_analitics_agglomeration">
                <!-- Referen te apenas a aglomeração -->
                <div class="row" style="margin: 0; gap: .875rem;">
                    <div *ngIf="newAnalytic.alias === 'Dispersão' || newAnalytic.alias === 'Aglomeração e dispersão'"
                        class="aglomeration-max card-info">
                        <div class="card small-card">
                            <div class="card-header card-config-analy">
                                <span class="row style-title-alertas alert-label">
                                    Mínimo de pessoas</span>
                                <div class="flex" style="margin-top: 15px; margin-bottom: 20px;">
                                    <input class="style-input-time input-max-people" type="number" placeholder="0"
                                        [formControl]="crowdingMinPeople" maxlength="3" min="1" oninput="this.value = Math.abs(this.value.slice(0, this.maxLength))">
                                    <span class="style-input-time label-input">Objeto(s)</span>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div *ngIf="newAnalytic.alias === 'Aglomeração' || newAnalytic.alias === 'Aglomeração e dispersão'"
                        class="aglomeration-max">
                        <div class="card small-card">
                            <div class="card-header card-config-analy">
                                <span class="row style-title-alertas alert-label">
                                    Máximo de pessoas</span>
                                <div class="flex" style="margin-top: 15px; margin-bottom: 20px;">
                                    <input class="style-input-time input-max-people" type="number" placeholder="0"
                                        [formControl]="crowdingMaxPeople" maxlength="3" min="1" oninput="this.value = Math.abs(this.value.slice(0, this.maxLength))">
                                    <span class="style-input-time label-input">Objeto(s)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="newAnalytic.alias === 'Vadiagem'"
                        class="timer-card card-info">
                        <div class="card">
                            <div class="card-header card-config-analy">
                                <div class="row containerTitle" style="margin-top: 0px;">
                                    <span class="TitleInterval">Categoria de ocorrências</span>
                                </div>
                                <nz-select [formControl]="loiteringControl" nzPlaceHolder="Selecionar..." class="analytic-alias">
                                    <nz-option *ngFor="let loitering of loiterings"
                                        [nzValue]="loitering" [nzLabel]="loitering.viewValue"></nz-option>
                                </nz-select>
                            </div>
                        </div>
                    </div>
                    <!--Caso seja analítico de Cruzamento de Linha-->
                    <div *ngIf="newAnalytic.alias === 'Tampering'"
                         class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 col-xga-7 col-fhd-7 card-info">
                        <div class="card">
                            <div class="card-header card-config-analy">
                                <div class="row containerTitle" style="margin-top: 0px;">
                                    <span class="TitleInterval">Nível de corrupção</span>
                                </div>
                                <div class="row containerTitle">
                                    <span class="percentageTitle">{{formatterCorruptionTampergin()}}</span>
                                </div>
                                <nz-slider [nzTipFormatter]="null" (nzOnAfterChange)="validateFormFullInformation($event)"
                                           [(ngModel)]="dissimilarity" [nzMarks]="marks"></nz-slider>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="newAnalytic.alias !== 'Detecção de Emboscada' && newAnalytic.alias !== 'Vadiagem' && newAnalytic.alias !== 'Cruzamento de Linha'"
                        class="timer-card card-info last-card">
                        <div class="card small-card">
                            <div class="card-header card-config-analy">
                                <span class="row style-title-alertas alert-label">
                                    Alertar a partir de:</span>
                                <div class="input-time">
                                    <span class="style-title-alertas time-label">min</span>
                                    <input class="style-input-time input-max-time-min" type="text"
                                            [formControl]="crowdingMaxTimeMin" mask="s0">
                                    <span class="style-title-alertas time-label">seg</span>
                                    <input class="style-input-time input-max-time-sec" type="text"
                                            [formControl]="crowdingMaxTimeSec" mask="s0">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="newAnalytic.alias === 'Vadiagem'"
                        class="timer-card card-info last-card">
                        <div class="card">
                            <div class="card-header card-config-analy">
                                <span class="row style-title-alertas alert-label">Tempo de tolerância</span>
                                <div class="input-time">
                                    <span class="style-title-alertas time-label">min</span>
                                    <input class="style-input-time input-max-time-min" type="text"
                                            [formControl]="crowdingMaxTimeMin" mask="s0">
                                    <span class="style-title-alertas time-label">seg</span>
                                    <input class="style-input-time input-max-time-sec" type="text"
                                                [formControl]="crowdingMaxTimeSec" mask="s0">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="newAnalytic.alias === 'Detecção de Emboscada'"
                        class="timer-card card-info">
                        <div class="card">
                            <div class="card-header card-config-analy">
                                <div class="row">
                                    <span class="row style-title-alertas alert-label">Tempo de espera</span>
                                    <div class="input-time">
                                        <input class="style-input-time input-max-time-min" type="text"
                                            [formControl]="crowdingMaxTimeMin" mask="s0">
                                        <span class="style-title-alertas time-label" style="margin-left: 0 !important;">Minuto</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="newAnalytic.alias === 'Detecção de Emboscada'"
                        class="timer-card card-info">
                        <div class="card">
                            <div class="card-header card-config-analy">
                                <div class="row">
                                    <span class="row style-title-alertas alert-label"> Tempo limite</span>
                                    <div class="input-time">
                                        <input class="style-input-time input-max-time-min" type="text"
                                            [formControl]="toleranceTimeMin" mask="s0">
                                        <span class="style-title-alertas time-label" style="margin-left: 0 !important;">Minuto</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Caso seja analítico de Cruzamento de Linha-->
                    <div *ngIf="newAnalytic.alias === 'Cruzamento de Linha'"
                        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 card-info last-card">
                        <div class="card">
                            <div class="card-header card-config-analy">
                                <div class="row containerTitle" style="margin-top: 0px;">
                                    <span class="TitleInterval">Direção do cruzamento</span>
                                </div>
                                <app-filter [list]="selectAnalytics.value.events" type="simple" placeholder="Selecione aqui..."
                                    (filterStatusChange)="selectAnalyticEvents($event)" [showSelected]="true" [styles]="['simple-alternate-style']"
                                    [hasIconPrefix]="false" [hasToggle]="true" [selected]="selectedEventsAnalytics"></app-filter>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12" ngStyle.lt-xl="margin-top: 8px">
            <div class="card" style="height: 100% !important;">
                <div class="card-header card-config-analy" style="margin-top: 0;">
                    <div class="container_detection_interval">
                        <div class="containerTitleInterval">
                            <span class="TitleInterval">Intervalo de detecção</span>
                            <div *ngIf="isHolidayActive" class="holiday-calendar-list-button" (click)="openHolidayView()">
                                <span nz-icon nzType="calendar" nzTheme="outline" style="font-size: 14px; margin-right: 3px;"></span>
                                Calendário De Feriados habilitados
                            </div>
                        </div>
                        <div style="display: flex; margin-left: 10px; ">
                            <nz-radio-group>
                                <div class="detec-interval">
                                    <div class="col-6 col-md-3" style="margin-right: 22px">
                                        <label style="white-space: nowrap;" nz-radio class="radio_button_position" [nzValue]="1" (click)="getTimeSchedule(1)" [ngModel]="selectedComercial">Horário comercial</label>
                                        <div *ngIf="idSchedule == 1" class="row radio_button_position margin_radiobutton_Text">
                                            <span class="time_information">Dias úteis de 08:00h às 18:00h</span>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-3" style="margin-right: 15px">
                                        <label style="white-space: nowrap;" nz-radio class="radio_button_position" [nzValue]="2" (click)="getTimeSchedule(2)" [ngModel]="selectedNight">Período noturno</label>
                                        <div *ngIf="idSchedule == 2" class="row radio_button_position margin_radiobutton_Text">
                                            <span class="time_information">Dias úteis de 18:00h às 06:00h do dia seguinte</span>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-3">
                                        <label style="white-space: nowrap;" nz-radio class="radio_button_position" [nzValue]="3" (click)="getTimeSchedule(3)" [ngModel]="selectedOthers">Escolher os dias e horários</label>
                                    </div>
                                </div>
                            </nz-radio-group>
                        </div>
                    </div>
                    <div *ngIf="idSchedule == 3">
                        <div class="card custom-card card_analitics_days">
                            <span (click)="getSchedule()" *ngIf="!loadingLastSchedules; else loadingLastSchedule"
                                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 mr-auto returnOldRegister"
                                [ngClass]="hideButton ? 'hide' : ''">
                                Preencher com último registro</span>
                            <ng-template #loadingLastSchedule>
                                <span nz-icon class="spinner_last_schedule" nzType="loading" nzTheme="outline" style="font-size: 36px"></span>
                            </ng-template>
                            <div class="row row_schedule_days">
                                <div *ngFor="let selectDay of weekList; let i=index"
                                    class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xga-1 col-fhd-1 schedules_days_container">
                                    <label class="form-check-label"
                                        [ngStyle]="{'color':changeColorHtml(selectDay.active), 'font-weight':changeWeightHtml(selectDay.active)}">
                                        <input class="form-check-input" type="checkbox" [checked]="selectDay.active"
                                            (change)="changeCheckHtml(selectDay.day)" [formControl]="selectedDays">
                                        {{selectDay.day}}
                                        <span class="form-check-sign">
                                            <span class="check"></span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <span class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xga-6 col-fhd-6 mr-auto style-title-alertas title_alerts">
                                Alertar entre os horários
                            </span>
                            <div class="row ml-auto mr-auto container_Schedules">
                                <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xga-3 col-fhd-3">
                                    <div class="flex flex-col items-start">
                                        <input id="timeBegin" placeholder="00:00:00" showMaskTyped="true"
                                        mask="Hh:m0:s0" type="text" shownMaskExpression="__:__:__" [dropSpecialCharacters]="false"
                                            class="style-input-time analytic-schedule" [formControl]="timeBegin">
                                        <span class="style-title-alertas">Início</span>
                                    </div>
                                </div>
                                <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xga-3 col-fhd-3">
                                    <div class="flex flex-col items-start">
                                        <input id="timeEnd" placeholder="00:00:00" showMaskTyped="true"
                                        mask="Hh:m0:s0" type="text" shownMaskExpression="__:__:__" [dropSpecialCharacters]="false"
                                            class="style-input-time analytic-schedule" [formControl]="timeEnd">
                                        <span class="style-title-alertas">Fim</span>
                                    </div>
                                </div>
                                <div class="flex col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xga-3 col-fhd-3">
                                        <input class="form-check-input" type="checkbox" [formControl]="selectAllDays">
                                        <span class="font_title_style">Alertar todos os dias por 24 horas</span>
                                </div>
                                <div
                                    class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xga-3 col-fhd-3 add_text_position">
                                    <button (click)="addSchedule()" type="button"
                                        class="btn btn-sm btn-primary style-button-add-schedule">Adicionar</button>
                                </div>
                            </div>
                        </div>
                        <div class="row overflow_container" [ngStyle]="{'height': '100%', 'margin-top': '8px'}">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12">
                                <nz-table #basicTable [nzData]="weekList" [nzShowPagination]="false"
                                          [nzSize]="'middle'" [nzTableLayout]="'auto'" class="table style-table">
                                    <thead>
                                    <tr *ngIf="false">
                                        <th class="style-title-table">Dia</th>
                                        <th class="style-title-table">Receber alertas entre</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let data of basicTable.data; index as i">
                                        <td class="style-column-letter-table">{{weekList[i].day}} </td>
                                        <td style="width: 90%; padding: 0">
                                            <span class="schedule-week" *ngFor="let s of data.schedules"
                                                (mouseover)="s.close = true" (mouseout)="s.close = false">
                                                <div class="concat-time">
                                                    <div class="time-tag-wrapper">
                                                        {{s.concatTime}}
                                                        <span [hidden]="!s.close" class="inner-button" (click)="removeSchedule(s)">
                                                            <i nz-icon nzType="close"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </nz-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row" style="height: 9%; margin-top: 22px;">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 row_bottom">
        <button (click)="cancelEmit.emit()" type="button"
                class="btn btn-md btn-default tamButton button-clean">Cancelar</button>
        <button (click)="saveEmit.emit()" type="button"
                class="btn btn-md btn-primary tamButton button-save">Salvar</button>
    </div>
</div>
