import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoginService } from './Login/Services/login.service';
import { Subscription } from 'rxjs';
import PerfectScrollbar from 'perfect-scrollbar';
import { Router, NavigationEnd, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { GoogleAnalyticsService } from './Shared/Services/google-analytics.service';
import { OneSignal } from 'onesignal-ngx';
import { SharedService } from 'app/Shared/Services/shared.service';
import { ImageService } from './Shared/Services/image.service';
import { HasScrollService } from './Shared/Services/has-scroll.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    constructor(private loginService: LoginService, private scrollService: HasScrollService,
        public router: Router, private analytics: GoogleAnalyticsService, private oneSignal: OneSignal,
        private service: SharedService, private IS: ImageService) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                const container = document.querySelector('#container') as HTMLElement;
                container.scrollTo(0, 0);
                this.analytics.pageHit(event.urlAfterRedirects);
            }
        });
        this.oneSignal.init({
            appId: localStorage.getItem('Otag') ? localStorage.getItem('Otag') : '927f7de5-9ea0-4efb-bdbc-c0187316ce02'
        });
    }

    subNavSideShow: Subscription;
    routerSubscribe: Subscription;
    scrollYSubscribe: Subscription;
    scrollXSubscribe: Subscription;

    navSideShow: boolean;
    scrollbar: any;
    loadingBundle = false;
    wesafer: boolean;
    profileName: string;
    mapCam = false;
    dashComp = false;

    userSub: string;
    visibleGLPI = false;

    ngOnInit() {
        this.userSub = localStorage.getItem('sub');
        localStorage.removeItem('full-bundle');
        this.navSideShow = localStorage.getItem('sub') != null;
        const container = document.querySelector('#container') as HTMLElement;
        // this.scrollbar = new PerfectScrollbar(container);
        this.subNavSideShow = this.loginService.navSideShowEmitter.subscribe(show => this.navSideShow = show);
        this.scrollYSubscribe = this.scrollService.hasScrollEmitter.subscribe(value => this.mapCam = value);
        this.scrollXSubscribe = this.scrollService.hasScrollXEmitter.subscribe(value => this.dashComp = value);
        this.routerSubscribe = this.router.events.subscribe(event => {
            this.profileName = localStorage.getItem('profile_name');
            if (event instanceof RouteConfigLoadStart) {
                this.loadingBundle = true;
            } else if (event instanceof RouteConfigLoadEnd) {
                this.loadingBundle = false;
            }
        });

        if (localStorage.getItem('Envtag') === 'wecloud' || localStorage.getItem('Envtag') === null) {
            document.body.classList.add('wesafer');
        } else {
            document.body.classList.add('nextvision');
        }
        setTimeout(this.IS.deleteCacheThumb, 3.6e+6);
    }

    ngOnDestroy() {
        if (this.subNavSideShow) {
            this.subNavSideShow.unsubscribe();
        }
        if (this.routerSubscribe) {
            this.routerSubscribe.unsubscribe();
        }
        if (this.scrollYSubscribe) {
            this.scrollYSubscribe.unsubscribe();
        }
        if (this.scrollXSubscribe) {
            this.scrollXSubscribe.unsubscribe();
        }
    }

    openModalGLPI(event) {
        this.visibleGLPI = event;
    }

    currentYear() {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        return currentYear;
    }

    setOverflowStyles() {
        if (!this.mapCam && !this.dashComp) {
            return { 'overflow': 'visible' };
        }

        return {
            'overflow-y': this.mapCam ? 'hidden' : 'visible',
            'overflow-x': this.dashComp ? 'hidden' : 'visible'
        };
    }
}
