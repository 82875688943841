<div class="background">
    <table class="quantity-table">
        <thead>
            <th></th>
            <th class="col-1 text-center">Total</th>
            <th class="col-1 text-center">Distribuídas</th>
            <th class="col-1 text-center"></th>
            <th class="col-1 text-center">Disponível</th>
        </thead>
        <tbody>
            <tr>
                <td>Detecções Inteligentes</td>
                <td class="text-center">{{inventory.inventory.ai.used + inventory.inventory.ai.available}}</td>
                <td class="text-center">{{inventory.inventory.ai.used}}</td>
                <td>
                    <div class="progress-container">
                        <app-progress-bar [progress]="(inventory.inventory.ai.used/(inventory.inventory.ai.used + inventory.inventory.ai.available))*100"></app-progress-bar>
                    </div>
                </td>
                <td class="text-center">{{inventory.inventory.ai.available}}</td>
            </tr>
        </tbody>
    </table>
    
    <div class="filter-container">
        <div class="filter" [ngClass]="{'running': filter.length > 0}" (click)="toggleDropdown()">
            <span>Filtrar por câmera</span>
            <i class="material-icons down">expand_more</i>
            <i class="material-icons up">expand_less</i>
        </div>
        <div class="clear-filter" *ngIf="filter.length > 0">
            <span (click)="clearFilter()">Limpar filtro</span>
        </div>
        <div class="open-filter">
            <ul class="categories">
                <li *ngFor="let item of filterList" (click)="toggleSelection(item, $event)" [id]="item.resolution">
                    <div class="flex-container between">
                        <span style="text-transform: uppercase;">Câmera {{item.resolution}}</span>
                        <i class="material-icons right">chevron_right</i>
                        <i class="material-icons left">chevron_left</i>
                    </div>
                </li>
            </ul>
            <ul class="select">
                <li *ngFor="let item of possibleSelections" (click)="toggleFilter(item, $event)" [ngClass]="{'selected': item.selected}">
                    <div class="flex-container start">
                        <i class="material-icons uncheck" style="margin-right: 16px">check_box_outline_blank</i>
                        <i class="material-icons check" style="margin-right: 16px">check_box</i>
                        <span>{{item.days_storage}} {{item.days_storage == 1 ? 'dia' : 'dias'}}</span>
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <table mat-table [dataSource]="cameraList" [hidden]="!cameraList.length" class="style-table">
        <ng-container matColumnDef="plan">
            <th mat-header-cell *matHeaderCellDef>Plano</th>
            <td mat-cell *matCellDef="let camera">CÂMERA {{camera.alias}} <span>{{camera.days_storage}} {{camera.days_storage == 1 ? ' dias' : ' dia'}}</span></td>
        </ng-container>
        <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef class="col-1 text-center">Total</th>
            <td mat-cell *matCellDef="let camera" class="text-center">{{camera.available+camera.used}}</td>
        </ng-container>
        <ng-container matColumnDef="using">
            <th mat-header-cell *matHeaderCellDef class="col-1 text-center">Distribuídas</th>
            <td mat-cell *matCellDef="let camera" class="text-center">{{camera.used}}</td>
        </ng-container>
        <ng-container matColumnDef="graph">
            <th mat-header-cell *matHeaderCellDef class="col-1 text-center"></th>
            <td mat-cell *matCellDef="let camera" class="text-center">
                <div class="progress-container">
                    <app-progress-bar [progress]="(camera.used / (camera.used + camera.available)) * 100"></app-progress-bar>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="available">
            <th mat-header-cell *matHeaderCellDef class="col-1 text-center">Disponível</th>
            <td mat-cell *matCellDef="let camera" class="text-center">{{camera.available}}</td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
        <tr mat-row *matRowDef="let camera; columns: tableColumns"></tr>
    </table>

    <div class="no-camera" [hidden]="cameraList.length">
        <span>Ainda não há cameras disponíveis</span>
    </div>
</div>