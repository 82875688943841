import { CreditCard, Partners, Client, UserInventory, KitAmount, UpdateClient, Kit, UserNotification, UserClients,
    PartnerPermission, AssociatedUsers, Guest, PaginationGuests, PaginationClients, TicketKit, Notifications,
    Holidays } from '../models';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigAlarm, CognitoAttributes, UserChangePassword, TypePayment, UserBillings, UserBillingsHistory,
    UserChangeBillingAddress, UserBillingsDetails, CameraAmount, NotificationDays } from '../models';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { showAlert } from 'app/Shared/Helpers/showAlert';
import { getToken, getAccessToken } from 'app/Shared/Helpers/getToken';
import { Feedback, OperationStatus } from 'app/Shared/models';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})

export class UserService {

    constructor(
        private http: HttpClient
    ) { }

    // private userURL = 'api/users';
    private backend_url = environment.apiUrl;

    getUserData(sub: string, child_sub = null): Observable<CognitoAttributes | Feedback> {
        const url = `${this.backend_url}/api/resources/users/getprofiledata`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub,
            child_sub: child_sub
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401 && event['statusCode'] != 202) {
                    const result: Feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message
                    };
                    return result;
                }
                else {
                    const userData: CognitoAttributes = event['results'][0].data;
                    const permissions: PartnerPermission = event['results'][0].data.partner_permission;
                    userData.model = 'cognito';
                    userData.partner_permission = permissions ? permissions.view_live && permissions.view_recording : false;
                    return userData;
                }
            })
        );
    }

    getUserBillings(sub: string): Observable<UserBillings | Feedback> {
        const url = `${this.backend_url}/api/resources/billing/dashboard`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    const result: Feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message
                    };
                    return result;
                }
                else {
                    const userBillings: UserBillings = event['results'][0].data;
                    return userBillings;
                }
            })
        );
    }

    getUserBillingsHistory(sub: string, year: number): Observable<UserBillingsHistory | Feedback> {
        const url = `${this.backend_url}/api/resources/billing/billshistory`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub,
            year: year
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    const result: Feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message
                    };
                    return result;
                }
                else {
                    const userBillingsHistory: UserBillingsHistory = event['results'][0].data;
                    return userBillingsHistory;
                }
            })
        );
    }

    getUserBillingsDetails(sub: string, year: number, month: number): Observable<UserBillingsDetails | Feedback> {
        const url = `${this.backend_url}/api/resources/billing/billdetails`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub,
            year: year,
            month: month
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    const result: Feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message
                    };
                    return result;
                }
                else {
                    const userBillingsDetails: UserBillingsDetails = event['results'][0].data;
                    return userBillingsDetails;
                }
            })
        );
    }

    getUserPaymentMethod(sub: string): Observable<CreditCard | Feedback> {
        const url = `${this.backend_url}/api/resources/billing/carddetails`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != '200') {
                    const result: Feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message
                    };
                    return result;
                }
                else {
                    const userPaymentMethod: CreditCard = event['results'][0].data;
                    return userPaymentMethod;
                }
            })
        );
    }

    updateProfile(sub: string, attr: CognitoAttributes): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/users/updateprofile`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub,
            child_sub: attr.child_sub,
            name: attr.name,
            email: attr.email,
            email_suport: attr.email_suport,
            cpf: attr['custom:CPF'],
            cnpj: attr['custom:CNPJ'],
            phone_number: attr.phone_number,
            address: attr.address,
            birthdate: attr.birthdate,
            gender: attr.gender,
            picture: attr.picture,
            updated_at: attr.updated_at,
            family_name: attr.family_name,
            zoneinfo: attr.zoneinfo,
            cost_center: attr.cost_center ? attr.cost_center : '',
            cost_center_mark: attr.cost_center_mark
        };
        return this.http.put(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    const feedback: Feedback = {
                        model: 'feedback',
                        status: OperationStatus.Fail,
                        message: event['results'][0].message,
                        success: false
                    };
                    return feedback;
                }
                else {
                    const feedback: Feedback = {
                        model: 'feedback',
                        message: event['results'][0].message,
                        success: true,
                        status: OperationStatus.Success
                    };
                    return feedback;
                }
            })
        );
    }

    updateAssociated(sub: string, user: AssociatedUsers): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/userassociates/editassociateuser`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub,
            child_sub: user.sub,
            name: user.name,
            cpf: '',
            phone_number: user.phone_number,
            associate_permission: user.associate_permission
        };
        return this.http.put(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    const feedback: Feedback = {
                        model: 'feedback',
                        status: OperationStatus.Fail,
                        message: event['results'][0].message,
                        success: false
                    };
                    return feedback;
                }
                else {
                    const feedback: Feedback = {
                        model: 'feedback',
                        message: event['results'][0].message,
                        success: true,
                        status: OperationStatus.Success
                    };
                    return feedback;
                }
            })
        );
    }

    publishPicture(sub: string, imgBase64: string): Observable<string> {
        const url = `${this.backend_url}/api/resources/users/publishuserpicture`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub,
            file: imgBase64
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode']) {
                    if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                        showAlert(`Código ${event['statusCode']} :${event['results'][0].message}`, 'danger');
                        return '';
                    }
                    else {
                        return event['results'][0].data['urlPicture'];
                    }
                }
                else {
                    showAlert('Esse serviço está temporariamente fora do ar, tente novamente mais tarde', 'danger');
                    return '';
                }
            })
        );
    }

    notificationVisibility(sub: string, hashNotification: string | string[]): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/notification/notificationvisibility`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub,
            hashnotification: hashNotification
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    const feedback: Feedback = {
                        model: 'feedback',
                        status: OperationStatus.Fail,
                        message: event['results'][0].message,
                        success: false
                    };
                    return feedback;
                } else {
                    const feedback: Feedback = {
                        model: 'feedback',
                        message: event['results'][0].message,
                        success: true,
                        status: OperationStatus.Success
                    };
                    return feedback;
                }
            })
        );
    }

    getAlarmConfigurations(userSub: string): Observable<ConfigAlarm> {

        const url = `${this.backend_url}/api/resources/users/allalertsettingsanalytic`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: userSub
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    return null;
                }
                else {
                    if (event['results'][0].data.length != 0) {
                        const rawConfig = event['results'][0].data;
                        const conf: ConfigAlarm = {
                            checkApp: rawConfig.checkApp,
                            checkSms: rawConfig.checkSms,
                            checkAva: rawConfig.checkAva,
                            numbersCell: rawConfig.numbersCell.split(';')
                        };
                        return conf;
                    } else {
                        return null;
                    }
                }
            })
        );
    }

    createTickets(plans: TicketKit, sub: string) {
        const url = `${this.backend_url}/api/resources/kit/kitsolicitation`;

        const data = {
            sub: sub,
            plans: {
                kits: plans
            }
        };
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };

        return this.http.post(url, data, headers).pipe(
            map(event => {
                let feedback: Feedback;
                if (event['statusCode'] != 200) {
                    feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message,
                        statusCode: event['statusCode'],
                    };
                    return feedback;
                } else {
                    feedback = {
                        model: 'feedback',
                        success: true,
                        status: OperationStatus.Success,
                        message: event['results'][0].message,
                        data: event['results'][0].data
                    };
                    return feedback;
                }
            })
        );
    }

    createAlarmConfiguration(userSub: string, configs: ConfigAlarm, childSub: string): Observable<boolean> {
        const url = `${this.backend_url}/api/resources/users/activeanalytics`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data =
    {
        sub: userSub,
        app: configs.checkApp,
        avva: configs.checkAva,
        createDevice: configs.checkDevice,
        sms: configs.checkSms,
        telephone: configs.numbersCell.join(';'),
        child_sub: childSub,
        contactEmergency: configs.contactEmergency,
        phoneEmergency: configs.phoneEmergency
    };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    showAlert(`Código ${event['statusCode']} :${event['results'][0].message}`, 'danger');
                    return false;
                }
                else {
                    return true;
                }
            })
        );
    // return of(true);
    }

    updateAlarmConfigurations(userSub: string, configs: ConfigAlarm): Observable<boolean> {
    //Colocar aqui url da API
        const url = `${this.backend_url}/api/resources/users/updatealertsettingsanalytics`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data =
    {
        sub: userSub,
        app: configs.checkApp,
        avva: configs.checkAva,
        sms: configs.checkSms,
        telephone: configs.numbersCell.join(';')
    };
        return this.http.put(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    showAlert(`Código ${event['statusCode']} :${event['results'][0].message}`, 'danger');
                    return false;
                }
                else {
                    return true;
                }
            })
        );
    }

    deleteAlarmConfiguration(userSub: string): Observable<boolean> {
        const url = `${this.backend_url}/api/resources/users/disableanalytics`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: userSub
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    // showAlert(`Código ${event['statusCode']} :${event['results'][0].message}`, 'danger');
                    return false;
                }
                else {
                    return true;
                }
            })
        );
    }

    changePassword(upPass: UserChangePassword): Observable<boolean> {
        const url = `${this.backend_url}/api/resources/users/changepassword`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data =
    {
        accessToken: getAccessToken(),
        lastPassword: upPass.lastPassword,
        newPassword: upPass.newPassword
    };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    //showAlert(`Código ${event['statusCode']} :${event['results'][0].message}`, 'danger');
                    return false;
                }
                else {
                    return true;
                }
            })
        );
    // return of(true);
    }

    // Atualiza o endereço de cobrança
    changeBillingAddress(userSub: string, upAddress: UserChangeBillingAddress): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/billing/updatebillingaddress`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data =
    {
        sub: userSub,
        street: upAddress.street,
        number: upAddress.number,
        complement: upAddress.complement,
        zipCode: upAddress.zipCode,
        state: upAddress.state,
        city: upAddress.city,
        district: upAddress.district,

    };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    const feedback: Feedback = {
                        model: 'feedback',
                        status: OperationStatus.Fail,
                        message: event['results'][0].message,
                        success: false
                    };
                    return feedback;
                }
                else {
                    const feedback: Feedback = {
                        model: 'feedback',
                        message: 'Endereço alterado com sucesso',
                        success: true,
                        status: OperationStatus.Success
                    };
                    return feedback;
                }
            })
        );
    }

    updatePayment(paymentObject: TypePayment): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/billing/updatepaymentmethod`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = paymentObject;
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    const feedback: Feedback = {
                        model: 'feedback',
                        status: OperationStatus.Fail,
                        message: event['results'][0].message,
                        success: false
                    };
                    return feedback;
                }
                else {
                    const feedback: Feedback = {
                        model: 'feedback',
                        message: event['results'][0].message,
                        success: true,
                        status: OperationStatus.Success
                    };
                    return feedback;
                }
            })
        );
    }

    registerPartnerUser(userSub: string, name: string, cnpj: string, email: string, password: string, permission: boolean,
        linkContract: string, costCenter: string = null, costCenterMark: boolean = false): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/partners/registerpartneruser`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: userSub,
            name: name,
            email: email,
            password: password,
            cpf: cnpj.length > 11 ? '' : cnpj,
            cnpj: cnpj.length > 11 ? cnpj : '',
            partner_permission: permission,
            contract: linkContract,
            cost_center: costCenter ? costCenter : '',
            cost_center_mark: costCenterMark
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                let feedback: Feedback;
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message
                    };
                    return feedback;
                }
                else {
                    feedback = {
                        model: 'feedback',
                        success: true,
                        status: OperationStatus.Success,
                        message: event['results'][0].message
                    };
                    return feedback;
                }
            })
        );
    }

    editPartnerUser(sub: string, attr: CognitoAttributes): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/partners/editpartneruser`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub,
            child_sub: attr.child_sub,
            name: attr.name,
            email: attr.email,
            cnpj: attr['custom:CNPJ'],
            cpf: attr['custom:CPF'],
            phone_number: attr.phone_number,
            address: attr.address,
            picture: attr.picture,
            password: attr.password,
            partner_permission: attr.partner_permission,
            contract: attr.contract_link,
            cost_center: attr.cost_center ? attr.cost_center : '',
            cost_center_mark: attr.cost_center_mark
        };
        return this.http.put(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    const feedback: Feedback = {
                        model: 'feedback',
                        status: OperationStatus.Fail,
                        message: event['results'][0].message,
                        success: false
                    };
                    return feedback;
                }
                else {
                    const feedback: Feedback = {
                        model: 'feedback',
                        message: event['results'][0].message,
                        success: true,
                        status: OperationStatus.Success
                    };
                    return feedback;
                }
            })
        );
    }

    getPartnersList(sub: string, str_filter: string): Observable<Partners | Feedback> {
        const url = `${this.backend_url}/api/resources/partners/getpartnerslist`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub,
            str_filter: str_filter
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != '200') {
                    const result: Feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message
                    };
                    return result;
                }
                else {
                    const getPartnersList: Partners = event['results'][0].data;
                    return getPartnersList;
                }
            })
        );
    }

    //Serviço que retorna a lista de câmeras de um determinado usuário
    getClients(userSub: string, sub_client?: string, str_filter?: string, page?: number, limit?: number): Observable<PaginationClients | UserClients | Feedback> {
        const url = `${this.backend_url}/api/resources/partners/clients`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: userSub,
            sub_client: sub_client,
            str_filter: str_filter,
            page: page,
            limit: limit
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    const feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message
                    };
                    return feedback;
                }
                else {
                    return Array.isArray(event['results'][0].data) ? event['results'][0].data[0] : [];
                }
            })
        );
    }

    //Serviço que retorna a lista de câmeras de um determinado usuário
    getGuests(userSub: string, sub_client?: string, str_filter?: string, page?: number, limit?: number): Observable<PaginationGuests | Guest[] | Feedback> {
        const url = `${this.backend_url}/api/resources/partners/getguestslist`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: userSub,
            sub_client: sub_client,
            str_filter: str_filter,
            page: page,
            limit: limit
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    const feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message
                    };
                    return feedback;
                }
                else {
                    return Array.isArray(event['results'][0].data) ? event['results'][0].data : [];
                }
            })
        );
    }

    getKits(sub: string): Observable<Kit[]> {
        const url = `${this.backend_url}/api/resources/kit/getkits`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            }),
            params: new HttpParams({
                fromObject: {
                    'sub': sub
                }
            })
        };
        return this.http.get(url, headers).pipe(
            map(event => {
                let feedback: Feedback;
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message
                    };
                    return feedback;
                }
                else {
                    return Array.isArray(event['results'][0].data) ? event['results'][0].data : [];
                }
            })
        );
    }

    updateKits(kit: Kit, sub: string): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/kit/updatekit`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub,
            active: kit.active,
            kit_id: kit.id,
            status: kit.status,
            camera_amount: kit.cameras_amount,
            analytic_amount: kit.analytics_amount,
            plan_id: kit.plan_id
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                let feedback: Feedback;
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message,
                        statusCode: event['statusCode'],
                    };
                    return feedback;
                }
                else {
                    feedback = {
                        model: 'feedback',
                        success: true,
                        status: OperationStatus.Success,
                        message: event['results'][0].message,
                        data: event['results'][0].data
                    };
                    return feedback;
                }
            })
        );
    }

    deleteKits(kit: Kit, sub: string): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/kit/deletekit`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub,
            kit_id: kit.id,
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                let feedback: Feedback;
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message,
                        statusCode: event['statusCode'],
                    };
                    return feedback;
                }
                else {
                    feedback = {
                        model: 'feedback',
                        success: true,
                        status: OperationStatus.Success,
                        message: event['results'][0].message,
                        data: event['results'][0].data
                    };
                    return feedback;
                }
            })
        );
    }

    createKits(sub: string, kit: Kit): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/kit/createkits`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub,
            kits: [
                {
                    cameras_amount: kit.cameras_amount,
                    analytics_amount: kit.analytics_amount,
                    id_plan: kit.id_plan
                }
            ]
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                let feedback: Feedback;
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message
                    };
                    return feedback;
                }
                else {
                    feedback = {
                        model: 'feedback',
                        success: true,
                        status: OperationStatus.Success,
                        message: event['results'][0].message
                    };
                    return feedback;
                }
            })
        );
    }

    resendWelcomeMail(userSub: string, client_sub: string): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/users/sendwelcomeemail`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: userSub,
            sub_client: [client_sub]
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                let feedback: Feedback;
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message
                    };
                    return feedback;
                }
                else {
                    feedback = {
                        model: 'feedback',
                        success: true,
                        status: OperationStatus.Success,
                        message: event['results'][0].message
                    };
                    return feedback;
                }
            })
        );
    }

    userInventory(sub: string, child_sub: string): Observable<UserInventory | Feedback> {
        const url = `${this.backend_url}/api/resources/assets/userinventory`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub,
            child_sub: child_sub
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != '200' && event['statusCode'] != '204') {
                    const result: Feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message
                    };
                    return result;
                }
                else {
                    const userInventory: UserInventory = event['results'][0].data;
                    return userInventory;
                }
            })
        );
    }

    userNotification(sub: string): Observable<NotificationDays[] | Feedback> {
        const url = `${this.backend_url}/api/resources/notification/getusernotification`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != '200' && event['statusCode'] != '204') {
                    const result: Feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message
                    };
                    return result;
                }
                else {
                    const userNotification: NotificationDays[] = event['results'][0].data;
                    return userNotification;
                }
            })
        );
    }

    associatedUsers(sub: string, child_sub: string): Observable<AssociatedUsers | Feedback> {
        const url = `${this.backend_url}/api/resources/userassociates/getassociatedusers`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub,
            child_sub
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != '200' && event['statusCode'] != '204') {
                    const result: Feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message
                    };
                    return result;
                }
                else {
                    const associatedUsers: AssociatedUsers = event['results'][0].data[0].users;
                    return associatedUsers;
                }
            })
        );
    }

    transferItems(sub: string, buyers_sub: string, kits: KitAmount[], cameras: CameraAmount[], analytics_amount: number, guests_amount: number): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/assets/transferitems`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };

        const data = {
            sub: sub,
            buyer_sub: buyers_sub,
            kits: kits,
            cameras: cameras,
            analytics_amount: analytics_amount,
            guests_amount: guests_amount
        };

        return this.http.post(url, data, headers).pipe(
            map(event => {
                let feedback: Feedback;
                if (event['statusCode'] != '200' && event['statusCode'] != 401) {
                    feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message
                    };
                    return feedback;
                } else {
                    feedback = {
                        model: 'feedback',
                        success: true,
                        status: OperationStatus.Success,
                        message: event['results'][0].message
                    };
                    return feedback;
                }
            })
        );
    }

    addClient(sub: string, client: Client): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/users/registeruser`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        //Pré-processando dados
        client.cnpj = client.cnpj.replace(/\D/g, '');
        client.phonenumber = '+55' + client.phonenumber.replace(/\D/g, '');
        const data = {
            sub: sub,
            name: client.name,
            email: client.email,
            password: client.password,
            cpf: client.cnpj.length > 11 ? '' : client.cnpj,
            cnpj: client.cnpj.length > 11 ? client.cnpj : '',
            phone_number: client.phonenumber,
            cameras: client.cameras,
            analytics_amount: client.analytcsAmount,
            invites_count: client.guests_count,
            cost_center: client.costCenter ? client.costCenter : '',
            cost_center_mark: client.costCenterMark
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                let feedback: Feedback;
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message,
                        statusCode: event['statusCode'],
                    };
                    return feedback;
                }
                else {
                    feedback = {
                        model: 'feedback',
                        success: true,
                        status: OperationStatus.Success,
                        message: event['results'][0].message,
                        data: event['results'][0].data
                    };
                    return feedback;
                }
            })
        );
    }

    convertClient(sub: string, child_sub: string, client: Client): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/guest/convertguesttoclient`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub,
            child_sub: child_sub,
            cameras: client.cameras,
            analytics_amount: client.analytcsAmount,
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                let feedback: Feedback;
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message,
                        statusCode: event['statusCode'],
                    };
                    return feedback;
                }
                else {
                    feedback = {
                        model: 'feedback',
                        success: true,
                        status: OperationStatus.Success,
                        message: event['results'][0].message,
                        data: event['results'][0].data
                    };
                    return feedback;
                }
            })
        );
    }

    convertGuest(sub: string, child_sub: string, client: Client): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/userassociates/convertassociated`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub,
            child_sub: child_sub,
            cameras: client.cameras,
            analytics_amount: client.analytcsAmount,
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                let feedback: Feedback;
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message,
                        statusCode: event['statusCode'],
                    };
                    return feedback;
                }
                else {
                    feedback = {
                        model: 'feedback',
                        success: true,
                        status: OperationStatus.Success,
                        message: event['results'][0].message,
                        data: event['results'][0].data
                    };
                    return feedback;
                }
            })
        );
    }

    editClient(client: UpdateClient): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/users/updateclientinfo`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: client.sub,
            child_sub: client.child_sub,
            password: client.password,
            avva: client.avva,
            createDevice: client.createDevice,
            name: client.name,
            // email: client.email,
            telephone: client.telephone,
            contactEmergency: client.contactEmergency,
            phoneEmergency: client.phoneEmergency
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                let feedback: Feedback;
                if (event['statusCode'] != 200 && event['statusCode'] != 204 && event['statusCode'] != 401) {
                    feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message,
                        statusCode: event['statusCode'],
                    };
                    return feedback;
                }
                else {
                    feedback = {
                        model: 'feedback',
                        success: true,
                        status: OperationStatus.Success,
                        message: event['results'][0].message,
                        data: event['results'][0].data
                    };
                    return feedback;
                }
            })
        );
    }

    deleteClient(sub: string, client_sub: string): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/users/deleteclient`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub,
            child_sub: client_sub,
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                let feedback: Feedback;
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message,
                        statusCode: event['statusCode'],
                    };
                    return feedback;
                }
                else {
                    feedback = {
                        model: 'feedback',
                        success: true,
                        status: OperationStatus.Success,
                        message: event['results'][0].message,
                        data: event['results'][0].data
                    };
                    return feedback;
                }
            })
        );
    }

    blockClient(sub: string, client_sub: string, active: boolean): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/users/changestatus`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub,
            child_sub: client_sub,
            active: active
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                let feedback: Feedback;
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message,
                        statusCode: event['statusCode'],
                    };
                    return feedback;
                }
                else {
                    feedback = {
                        model: 'feedback',
                        success: true,
                        status: OperationStatus.Success,
                        message: event['results'][0].message,
                        data: event['results'][0].data
                    };
                    return feedback;
                }
            })
        );
    }

    deleteClientInventory(sub: string, client_sub: string, id_plan: number): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/assets/deleteinventory`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub,
            child_sub: client_sub,
            id_plan: id_plan
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                let feedback: Feedback;
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message,
                        statusCode: event['statusCode'],
                    };
                    return feedback;
                }
                else {
                    feedback = {
                        model: 'feedback',
                        success: true,
                        status: OperationStatus.Success,
                        message: event['results'][0].message,
                        data: event['results'][0].data
                    };
                    return feedback;
                }
            })
        );
    }

    inviteAssociate(sub: string, client_sub: string, email: string, resend: boolean, associates: AssociatedUsers[]): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/userassociates/registerassociatedusers`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };

        const data = {
            sub: sub,
            child_sub: client_sub,
            resend: resend
        };
        if (resend) {
            data['email'] = email;
        } else {
            data['associates'] = associates;
        }

        return this.http.post(url, data, headers).pipe(
            map(event => {
                let feedback: Feedback;
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message,
                        statusCode: event['statusCode'],
                    };
                    return feedback;
                }
                else {
                    feedback = {
                        model: 'feedback',
                        success: true,
                        status: OperationStatus.Success,
                        message: event['results'][0].message,
                        data: event['results'][0].data
                    };
                    return feedback;
                }
            })
        );
    }

    deleteAssociated(sub: string, child_sub: string): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/userassociates/deleteassociateduser`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub,
            child_sub: child_sub,
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                let feedback: Feedback;
                if (event['statusCode'] != 200) {
                    feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message,
                        statusCode: event['statusCode'],
                    };
                    return feedback;
                }
                else {
                    feedback = {
                        model: 'feedback',
                        success: true,
                        status: OperationStatus.Success,
                        message: event['results'][0].message,
                        data: event['results'][0].data
                    };
                    return feedback;
                }
            })
        );
    }

    deletePartner(sub: string, client_sub: string): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/partners/deletepartner`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub,
            child_sub: client_sub,
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                let feedback: Feedback;
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message,
                        statusCode: event['statusCode'],
                    };
                    return feedback;
                }
                else {
                    feedback = {
                        model: 'feedback',
                        success: true,
                        status: OperationStatus.Success,
                        message: event['results'][0].message,
                        data: event['results'][0].data
                    };
                    return feedback;
                }
            })
        );
    }

    getNationalHolidays(sub: string): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/analytic/getnationalholidays`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                let feedback: Feedback;
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message,
                        statusCode: event['statusCode'],
                    };
                    return feedback;
                }
                else {
                    feedback = {
                        model: 'feedback',
                        success: true,
                        status: OperationStatus.Success,
                        message: event['results'][0].message,
                        data: event['results'][0].data
                    };
                    return feedback;
                }
            })
        );
    }

    getCalendarList(sub: string, child_sub: string, idCamera?: number): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/analytic/allholidays`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub,
            child_sub: child_sub,
            idCamera: idCamera
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                let feedback: Feedback;
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message,
                        statusCode: event['statusCode'],
                    };
                    return feedback;
                }
                else {
                    feedback = {
                        model: 'feedback',
                        success: true,
                        status: OperationStatus.Success,
                        message: event['results'][0].message,
                        data: event['results'][0].data
                    };
                    return feedback;
                }
            })
        );
    }

    createCalendar(sub: string, child_sub: string, holidays: Holidays[]): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/analytic/createcalendar`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub,
            child_sub: child_sub,
            holidays: holidays
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                let feedback: Feedback;
                if (event['statusCode'] == 204) {
                    feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Unloaded,
                        message: event['results'][0].message,
                        statusCode: event['statusCode'],
                    };
                    return feedback;
                }
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message,
                        statusCode: event['statusCode'],
                    };
                    return feedback;
                }
                else {
                    feedback = {
                        model: 'feedback',
                        success: true,
                        status: OperationStatus.Success,
                        message: event['results'][0].message,
                        data: event['results'][0].data
                    };
                    return feedback;
                }
            })
        );
    }

    updateCalendar(sub: string, child_sub: string, holidays: Holidays[]): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/analytic/updatecalendar`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub,
            child_sub: child_sub,
            holidays: holidays
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                let feedback: Feedback;
                if (event['statusCode'] == 403) {
                    feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message,
                        statusCode: event['statusCode'],
                    };
                    return feedback;
                }
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message,
                        statusCode: event['statusCode'],
                    };
                    return feedback;
                }
                else {
                    feedback = {
                        model: 'feedback',
                        success: true,
                        status: OperationStatus.Success,
                        message: event['results'][0].message,
                        data: event['results'][0].data
                    };
                    return feedback;
                }
            })
        );
    }

    deleteCalendar(sub: string, foreignhash: string, hash_holiday?: string, child_sub?: string): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/analytic/deletecalendar`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub,
            foreignhash: foreignhash,
            hash_holiday: hash_holiday,
            child_sub: child_sub
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                let feedback: Feedback;
                if (event['statusCode'] != 200) {
                    feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message,
                        statusCode: event['statusCode'],
                    };
                    return feedback;
                }
                else {
                    feedback = {
                        model: 'feedback',
                        success: true,
                        status: OperationStatus.Success,
                        message: event['results'][0].message,
                        data: event['results'][0].data
                    };
                    return feedback;
                }
            })
        );
    }

    deleteMasterKit(sub: string, child_sub: string, id_plan: number, cameras_amount: number, analytics_amount: number): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/assets/deletekit`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub,
            child_sub: child_sub,
            id_plan: id_plan,
            cameras_amount: cameras_amount,
            analytics_amount: analytics_amount
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                let feedback: Feedback;
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message,
                        statusCode: event['statusCode'],
                    };
                    return feedback;
                }
                else {
                    feedback = {
                        model: 'feedback',
                        success: true,
                        status: OperationStatus.Success,
                        message: event['results'][0].message,
                        data: event['results'][0].data
                    };
                    return feedback;
                }
            })
        );
    }

}
